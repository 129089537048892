import React, {
  ClassAttributes,
  ReactNode,
  MouseEvent,
  useMemo,
  useState,
} from 'react'
import classnames from 'classnames'
// @ts-ignore
import { uniqueId, isEmpty, get } from 'lodash'
import { Loading } from '..'
import Pagination, { PaginationData } from '../Pagination/next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Hashes from 'jshashes'
import styles from './styles.module.css'
import TableColFilter from './TableColFilter'

export type SortDirection = 'asc' | 'desc' | ''

export interface Col<RowsT = any> {
  className?: string
  text: ReactNode
  sortable?: boolean
  colSpan?: number
  field?: keyof Row<RowsT>
  render?: (row: Row<RowsT>) => ReactNode
  tooltip?: boolean
  tooltipText?: string
}

export interface TableFooter {
  className?: string
  colSpan?: number
  text: string | number | ReactNode
}

// TODO move to shared/util
type UnArray<T> = T extends Array<infer U> ? U : T

type Row<Rows> = UnArray<Rows>

interface Sort<RowsT> {
  by: string
  dir?: SortDirection
}

export interface TableProps<RowsT> extends ClassAttributes<HTMLTableElement> {
  className?: string
  cols: Col<RowsT>[]
  isLoading: boolean
  pagination?: PaginationData
  totalRow?: TableFooter[]
  data: RowsT
  defaultHiddenColumns?: string[]
  onRowClick?(row: Row<RowsT>, event: MouseEvent): void
  onSortChange?(by: keyof Row<RowsT>, dir: SortDirection)
  sort?: Sort<RowsT>
  enableSettings?: boolean
}

// Renders a sort icon based on the `dir`.
const sortIcon = (dir: SortDirection) => {
  let sortIcon

  switch (dir) {
    case 'asc':
      sortIcon = 'sort-up'
      break
    case 'desc':
      sortIcon = 'sort-down'
      break
    default:
      sortIcon = 'sort'
      break
  }

  return <FontAwesomeIcon icon={sortIcon} className={styles.sortIndicator} />
}

// Increment the current sort direction to the next logical value
const incrementSort = (dir: SortDirection): SortDirection => {
  switch (dir) {
    case 'asc':
      return 'desc'
    case 'desc':
      return 'asc'
    default:
      return 'asc'
  }
}

const Table = <RowsT extends any[]>({
  className,
  cols,
  totalRow,
  pagination,
  isLoading,
  data,
  defaultHiddenColumns,
  onSortChange,
  sort,
  onRowClick,
  enableSettings = true,
  ...rest
}: TableProps<RowsT>) => {
  const [hiddenCols, setHiddenCols] = useState<string[]>([])

  const getSortDirectionByField = (field: keyof Row<RowsT>): SortDirection => {
    return sort && sort.by === field ? sort.dir : null
  }

  const colHeader = ({
    sortable,
    field,
    text,
    tooltip = false,
    tooltipText = '',
  }: Col<RowsT>) => {
    if (!sortable) {
      return <div>{text}</div>
    }

    const dir = getSortDirectionByField(field)

    return (
      <button
        onClick={() => {
          onSortChange(field, incrementSort(dir))
        }}
      >
        {tooltip && (
          <span
            data-tip={tooltipText}
            data-for="global-tooltip"
            data-place="top"
          >
            <FontAwesomeIcon
              icon={['fas', 'info-circle']}
              className={styles.icon}
              style={{ marginRight: '6px' }}
            />
          </span>
        )}
        {text}
        {sortIcon(dir)}
      </button>
    )
  }
  const renderCell = (row: Row<RowsT>, col: Col<RowsT>): ReactNode => {
    return col.render ? col.render(row) : get(row, col.field)
  }

  /*
    Creates a hash based on column name. Used as an identifier for storing settings
   */
  const tableHash = useMemo(
    () => new Hashes.MD5().hex(cols.map(c => c.field).join('')),
    [cols]
  )

  const visibleCols = useMemo(
    () =>
      cols.filter(
        c => !c.field || (c.field && !hiddenCols.includes(c.field.toString()))
      ),
    [hiddenCols, cols]
  )

  return (
    <div className={styles.containerContainer}>
      {enableSettings && (
        <TableColFilter
          tableHash={tableHash}
          cols={cols}
          defaultHiddenCols={
            !isEmpty(defaultHiddenColumns) ? defaultHiddenColumns : []
          }
          onSetHiddenCols={setHiddenCols}
        />
      )}
      <div className={classnames(styles.container, className)}>
        <table className={styles.table} {...rest}>
          <thead className={styles.headerRow}>
            <tr>
              {visibleCols.map(h => (
                <th
                  className={classnames(h.className, {
                    [styles.sortedField]:
                      h.sortable && getSortDirectionByField(h.field),
                  })}
                  key={uniqueId('header-')}
                >
                  {colHeader(h)}
                </th>
              ))}
            </tr>
          </thead>
          {!isEmpty(data) && !isLoading && (
            <tbody>
              {data.map(row => (
                <tr
                  className={classnames(row.className, styles.contentRows, {
                    [styles.clickable]: !!onRowClick,
                  })}
                  key={uniqueId('content-')}
                  onClick={e => onRowClick && onRowClick(row, e)}
                  tabIndex={0}
                >
                  {visibleCols.map(col => (
                    <td
                      colSpan={col.colSpan || 1}
                      key={uniqueId('col-')}
                      className={classnames(col.className)}
                    >
                      {renderCell(row, col)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          {isLoading && (
            <tbody>
              <tr>
                <td
                  colSpan={visibleCols.length}
                  className={classnames(styles.statusContainer, styles.loading)}
                >
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && isEmpty(data) && (
            <tbody>
              <tr>
                <td
                  colSpan={visibleCols.length}
                  className={classnames(styles.statusContainer, styles.done)}
                >
                  No data is available to display.
                </td>
              </tr>
            </tbody>
          )}
          {visibleCols.length === 0 && !isEmpty(data) && !isLoading && (
            <tbody>
              <tr>
                <td className={classnames(styles.statusContainer, styles.done)}>
                  All columns are hidden
                </td>
              </tr>
            </tbody>
          )}
          {visibleCols.length === cols.length &&
            !isEmpty(data) &&
            !isEmpty(totalRow) &&
            !isLoading && (
              <tfoot>
                <tr>
                  {totalRow.map(col => (
                    <td
                      colSpan={col.colSpan}
                      key={uniqueId('total-')}
                      className={classnames(col.className)}
                    >
                      {col.text}
                    </td>
                  ))}
                </tr>
              </tfoot>
            )}
        </table>
      </div>
      {pagination && !isEmpty(data) && <Pagination {...pagination} />}
    </div>
  )
}

export default Table
