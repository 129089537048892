import { Dispatch } from 'redux'
import { stringify } from 'query-string'
import _capitalize from 'lodash/capitalize'
import filesaver from 'file-saver'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'
import { stripHyphens } from '../utils/fileExport'

import { Action } from '../typings/reducer'
import { ReportByDateType, ReportFilters, ReportType } from '../typings/report'
import { cleanArray, formatJsonToCsv } from '../utils/format'
import { DateTime } from 'luxon'

export enum ReportsConstants {
  FETCH = 'reports/FETCH',
  FETCH_SUCCESS = 'reports/FETCH_SUCCESS',
  FETCH_FAILURE = 'reports/FETCH_FAILURE',
  RESET = 'reports/RESET',
  EXPORT_FETCH = 'reports/EXPORT_FETCH',
  EXPORT_FETCH_SUCCESS = 'reports/EXPORT_FETCH_SUCCESS',
  EXPORT_FETCH_FAILURE = 'reports/EXPORT_FETCH_FAILURE',
  EXPORT_FETCH_RESET = 'reports/EXPORT_FETCH_RESET',
  FILTERS = 'reports/FILTERS',
  RESET_VERTICAL_FILTERS = 'reports/RESET_VERTICAL_FILTERS',
}

export interface ReportsAction extends Action {
  type: ReportsConstants
  data?: ReportByDateType
  reportType?: ReportType
  filters?: ReportFilters
  error?: string
}

function fetchReport(): ReportsAction {
  return {
    type: ReportsConstants.FETCH,
  }
}

function fetchReportSuccess(
  data: ReportByDateType,
  filters?: ReportFilters
): ReportsAction {
  return {
    type: ReportsConstants.FETCH_SUCCESS,
    data,
    filters,
  }
}

function fetchReportFailure(error: string): ReportsAction {
  return {
    type: ReportsConstants.FETCH_FAILURE,
    error,
  }
}

export function resetReport(): ReportsAction {
  return {
    type: ReportsConstants.RESET,
  }
}

function fetchReportForExport(): ReportsAction {
  return {
    type: ReportsConstants.EXPORT_FETCH,
  }
}

function fetchReportForExportSuccess(filters?: ReportFilters): ReportsAction {
  return {
    type: ReportsConstants.EXPORT_FETCH_SUCCESS,
    filters,
  }
}

function fetchReportForExportFailure(error: string): ReportsAction {
  return {
    type: ReportsConstants.EXPORT_FETCH_FAILURE,
    error,
  }
}

export function resetReportForExport(): ReportsAction {
  return {
    type: ReportsConstants.EXPORT_FETCH_RESET,
  }
}

export const getReport = cancelFetchOnReentrySync(
  fetch => (reportType: ReportType, filters?: ReportFilters) =>
    swallowCancellation(async dispatch => {
      dispatch(fetchReport())

      const { ok, bodyParsed, errorParsed } = await fetchGetApi(
        fetch,
        `/reports/${reportType}?${stringify(filters)}`
      )

      if (ok) {
        dispatch(fetchReportSuccess(bodyParsed.data, filters))
      } else {
        dispatch(triggerError(errorParsed, `${_capitalize(reportType)} Report`))
        dispatch(fetchReportFailure(errorParsed))
      }
    })
)

export const exportReport = (
  reportType: ReportType,
  filters?: ReportFilters
) => async (dispatch: Dispatch) => {
  dispatch(fetchReportForExport())

  const { page, size, ...modifiedFilters } = filters

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    `/reports/${reportType}/export?${stringify(filters)}`
  )

  if (ok) {
    const filename = `${reportType}-by-${
      modifiedFilters.groupBy
    }_${stripHyphens(modifiedFilters.startDate)}-${stripHyphens(
      modifiedFilters.endDate
    )}.csv`

    const file = new Blob([bodyParsed], {
      type: 'text/csv;charset=utf8',
    })

    filesaver.saveAs(file, filename)

    dispatch(fetchReportForExportSuccess(modifiedFilters))
  } else {
    dispatch(
      triggerError(errorParsed, `${_capitalize(reportType)} Report Export`)
    )
    dispatch(fetchReportForExportFailure(errorParsed))
  }
}

export const exportCsvReport = (
  reportName: string,
  data: any,
  filters?: ReportFilters
) => {
  let dates = `_${stripHyphens(DateTime.local().toISODate())}`
  if (filters) {
    if (filters.start && filters.end) {
      dates = `_${stripHyphens(filters.start)}-${stripHyphens(filters.end)}`
    }
  }
  const filename = `${reportName}${dates}.csv`

  const file = new Blob([data], {
    type: 'text/csv;charset=utf8',
  })

  filesaver.saveAs(file, filename)
}

export const dumpData = (
  reportName: string,
  data: any,
  filters?: ReportFilters
) => {
  if (!data) {
    return
  }
  const csvData = formatJsonToCsv(cleanArray(data))
  exportCsvReport(reportName, csvData, {
    ...filters,
  })
}

export const filterChange = (filters?: ReportFilters) => async (
  dispatch: Dispatch
) => {
  dispatch(filterChangeAction(filters))
}

function filterChangeAction(filters?: ReportFilters): ReportsAction {
  return {
    type: ReportsConstants.FILTERS,
    filters,
  }
}

export const resetVerticalFilters = () => async (dispatch: Dispatch) => {
  dispatch(resetVerticalFiltersAction())
}

function resetVerticalFiltersAction(): ReportsAction {
  return {
    type: ReportsConstants.RESET_VERTICAL_FILTERS,
  }
}
