import React, { FunctionComponent } from 'react'

import styles from './styles.module.css'

interface PageDescriptionProps {
  content: string
}

const PageDescription: FunctionComponent<PageDescriptionProps> = ({
  content,
}) => content && <div className={styles.container}>{content}</div>

export default PageDescription
