import getConfig from '../config'

const REACT_APP_FEATURE_MYFI_REPORTS = getConfig('myfiReportsFeatureFlag')

const REACT_APP_FEATURE_AUTH0 = getConfig('auth0FeatureFlag')

const ENABLE_NEW_PAYMENT_FLAG = getConfig('enableNewPayment')

// Export all available feature flags
export const ENABLE_MYFI_REPORTS =
  (REACT_APP_FEATURE_MYFI_REPORTS &&
    REACT_APP_FEATURE_MYFI_REPORTS.toLowerCase() == 'true') ||
  false

export const ENABLE_AUTH0_AUTHENTICATION =
  (REACT_APP_FEATURE_AUTH0 &&
    REACT_APP_FEATURE_AUTH0.toLowerCase() == 'true') ||
  false

export const FRONTEND_TTL = getConfig('fontEndTtl')

export const ENABLE_NEW_PAYMENT =
  (ENABLE_NEW_PAYMENT_FLAG &&
    ENABLE_NEW_PAYMENT_FLAG.toLowerCase() == 'true') ||
  false
