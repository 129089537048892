import { Reducer } from 'redux'

import { PublisherAction, PublisherConstants } from '../actions/publisher'
import { PublisherStore } from '../typings/publisher'

export const initialState: PublisherStore = {
  publisherId: null,
  publisherName: null,
  reportingApiAuthId: null,
  productsApiAuthId: null,
  reportingApiSecret: null,
  productsApiSecret: null,
  fetching: false,
  fetched: false,
  error: null,
}

export const publisherReducer: Reducer = (
  state = { ...initialState },
  action: PublisherAction
): PublisherStore => {
  switch (action.type) {
    case PublisherConstants.FETCH:
      return {
        ...initialState,
        fetching: true,
        fetched: false,
      }

    case PublisherConstants.FETCH_SUCCESS:
      return {
        ...action.data,
        fetching: false,
        fetched: true,
        error: null,
      }

    case PublisherConstants.FETCH_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      }

    case PublisherConstants.ENABLE:
      return {
        ...initialState,
        fetching: true,
        fetched: false,
      }

    case PublisherConstants.ENABLE_SUCCESS:
      return {
        ...action.data,
        fetching: false,
        fetched: true,
        error: null,
      }

    case PublisherConstants.ENABLE_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      }

    case PublisherConstants.DISABLE:
      return {
        ...initialState,
        fetching: true,
        fetched: false,
      }

    case PublisherConstants.DISABLE_SUCCESS:
      return {
        ...action.data,
        fetching: false,
        fetched: true,
        error: null,
      }

    case PublisherConstants.DISABLE_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      }

    case PublisherConstants.ROTATE:
      return {
        ...initialState,
        fetching: true,
        fetched: false,
      }

    case PublisherConstants.ROTATE_SUCCESS:
      return {
        ...action.data,
        fetching: false,
        fetched: true,
        error: null,
      }

    case PublisherConstants.ROTATE_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      }

    default:
      return state
  }
}
