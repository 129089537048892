import { Dispatch } from 'redux'

import { triggerError } from './app'
import { fetchGetApi, fetchPostApi } from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { Website, WebsitesData } from '../typings/website'

export enum WebsitesConstants {
  FETCH = 'websites/FETCH',
  FETCH_SUCCESS = 'websites/FETCH_SUCCESS',
  FETCH_FAILURE = 'websites/FETCH_FAILURE',
  CREATE = 'websites/CREATE',
  CREATE_SUCCESS = 'websites/CREATE_SUCCESS',
  CREATE_FAILURE = 'website/CREATE_FAILURE',
}

export interface WebsitesAction extends Action {
  type: WebsitesConstants
  data?: WebsitesData | Website
  error?: string
}

function fetchWebsites(): WebsitesAction {
  return {
    type: WebsitesConstants.FETCH,
  }
}

function fetchWebsitesSuccess(data: WebsitesData): WebsitesAction {
  return {
    type: WebsitesConstants.FETCH_SUCCESS,
    data,
  }
}

function fetchWebsitesFailure(error: string): WebsitesAction {
  return {
    type: WebsitesConstants.FETCH_FAILURE,
    error,
  }
}

function createWebsite(): WebsitesAction {
  return {
    type: WebsitesConstants.CREATE,
  }
}

function createWebsiteSuccess(website: Website): WebsitesAction {
  return {
    type: WebsitesConstants.CREATE_SUCCESS,
    data: website,
  }
}

function createWebsiteFailure(error: string): WebsitesAction {
  return {
    type: WebsitesConstants.CREATE_FAILURE,
    error,
  }
}

export const getWebsites = () => async (dispatch: Dispatch) => {
  dispatch(fetchWebsites())

  const endpoint = '/websites'

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchWebsitesSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Websites'))
    dispatch(fetchWebsitesFailure(errorParsed))
  }
}

export const addWebsite = (website: Website) => async (dispatch: Dispatch) => {
  dispatch(createWebsite())

  const endpoint = '/websites'

  const { ok, bodyParsed, errorParsed } = await fetchPostApi(
    fetch,
    endpoint,
    website
  )

  if (ok) {
    dispatch(createWebsiteSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'New Website'))
    dispatch(createWebsiteFailure(errorParsed))
  }
}
