import React, { FunctionComponent, useEffect, useContext } from 'react'

import { PageDescription, PageHeading, Tabs } from '../../../../components'
import ReportActions from '../../../../components/ReportActions'
import { usePaymentsHistoryReportingLazyQuery } from '../../../../generated/types'
import { PaymentsHistoryReportingQueryVariables } from '../../../../generated/types'
import PaymentHistory from './PaymentHistory'
import { HistoryTableContext } from './index'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.css'
import { dumpData } from '../../../../actions/reports'
import { get } from 'lodash'
import { checkHttpError, triggerError } from '../../../../actions/app'

const History: FunctionComponent = () => {
  const user = useSelector<State, UserStore>(state => state.user)
  const dispatch = useDispatch()

  const {
    sort,
    pagination: { page, size },
  } = useContext(HistoryTableContext)

  const [
    paymentHistory,
    { data, loading, error },
  ] = usePaymentsHistoryReportingLazyQuery()

  const [
    paymentHistoryExport,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = usePaymentsHistoryReportingLazyQuery()

  const fetchExport = () => {
    paymentHistoryExport({
      variables: {
        ...variables(),
        take: get(data, `paymentsHistoryReporting.pagination.totalEntries`, 0),
      },
    })
  }

  if (error) {
    dispatch(triggerError(checkHttpError(error.message), 'Payment Report'))
  }

  useEffect(() => {
    dumpData('paymentsHistory', exportData?.paymentsHistoryReporting?.page)
  }, [exportData])

  const variables = () => {
    return {
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPublisherId: user.affiliateId,
    } as PaymentsHistoryReportingQueryVariables
  }

  return (
    <>
      <PageHeading title="Payments">
        <Tabs
          items={[
            {
              title: 'Outstanding Balance',
              isSelected: false,
              to: './outstanding-balance',
            },
            {
              title: 'Payment History',
              isSelected: true,
              to: './history',
            },
          ]}
        />

        <ReportActions
          className={styles.actions}
          fetchExport={fetchExport}
          disabled={
            !get(data, `paymentsHistoryReporting.pagination.totalEntries`, 0)
          }
          exportStatus={{
            loading: exportLoading,
            hasError: exportError != null,
          }}
          reportStatus={{ loading: loading, hasError: error != null }}
        />
      </PageHeading>

      <PageDescription content="This report reflects all historical payments processed in your account within the last 24 months." />

      <PaymentHistory
        getData={paymentHistory}
        data={data}
        loading={loading}
        error={error != null}
      />
    </>
  )
}

export default History
