import React, { FunctionComponent, ReactNode } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface FilterBoxProps {
  label: string
  children: ReactNode
  className?: string
  includeTooltip?: boolean
  tooltipText?: string
}

const FilterBox: FunctionComponent<FilterBoxProps> = ({
  label,
  children,
  className,
  includeTooltip = false,
  tooltipText = '',
}) => {
  const heading = label ? `${label}:` : '‎'
  return (
    <div className={classnames(className, styles.container)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {includeTooltip && (
          <div
            data-tip={tooltipText}
            data-for="global-tooltip"
            data-place="top"
          >
            <FontAwesomeIcon
              icon={['fas', 'info-circle']}
              className={styles.icon}
              style={{ marginRight: '6px' }}
            />
          </div>
        )}
        <h4>{heading}</h4>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default FilterBox
