import React, { FunctionComponent, ReactNode } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

interface LinkButtonProps extends RouteComponentProps {
  to: string | Function
  children: ReactNode
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

const LinkButton: FunctionComponent<LinkButtonProps> = props => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props
  return (
    <button
      {...rest} // `children` is just another prop!
      onClick={event => {
        onClick && onClick(event)

        if (typeof to === 'string') {
          history.push(to)
        } else {
          to()
        }
      }}
    />
  )
}

export default withRouter(LinkButton)
