import * as React from 'react'
import styles from './styles.module.css'
import { DatePicker, FilterBox, Select } from '../index'
import dateOptions from '../../utils/dateOptions'
import { convertPeriodToDates } from '../../utils/dates'
import { get } from 'lodash'

interface Dates {
  startDate?: string
  endDate?: string
  dateRange: string
  maxBacktrackForStartDate?: string
}

interface Props {
  onChange(dates: Dates): void
  dates: Partial<Dates>
}

const DateRangeFilter = ({ onChange, dates }: Props) => {
  return (
    <div className={styles.dateContainer}>
      <FilterBox
        label="Preset date range"
        includeTooltip={true}
        tooltipText={'You are seeing data in UTC time'}
      >
        <Select
          selected={get(dates, 'dateRange', '')}
          options={dateOptions}
          onChange={e => {
            const dateRange = e.currentTarget.value
            onChange({ dateRange, ...convertPeriodToDates(dateRange) })
          }}
        />
      </FilterBox>
      <FilterBox label="Start date">
        <DatePicker
          value={dates.startDate}
          min={dates.maxBacktrackForStartDate}
          max={dates.endDate}
          onChange={startDate =>
            onChange({ startDate, endDate: dates.endDate, dateRange: 'custom' })
          }
        />
      </FilterBox>
      <FilterBox label="End date">
        <DatePicker
          value={dates.endDate}
          min={dates.startDate}
          onChange={endDate =>
            onChange({
              endDate,
              startDate: dates.startDate,
              dateRange: 'custom',
            })
          }
        />
      </FilterBox>
    </div>
  )
}

export default DateRangeFilter
