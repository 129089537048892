import { stringify } from 'query-string'
import React, { FunctionComponent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styles from './styles.module.css'
import classnames from 'classnames'

interface GerenateDetailReportLink {
  id: string | number
  value: string | number
  detailPath: string
  whereCriteria: string
  rightAligment?: boolean
  queryParams?: ObjectArray[]
}

export const GenerateDetailReportLink: FunctionComponent<GerenateDetailReportLink> = ({
  id,
  value,
  detailPath,
  whereCriteria,
  rightAligment,
  queryParams,
}) => {
  const history = useHistory()

  const detailParams = {
    [whereCriteria]: id,
  }

  const convertedQueryParams = queryParams
    ? convertObjectArrayToQueryParams(queryParams)
    : []

  if (value == 0 || id === 0) {
    return (
      <div
        style={{
          paddingRight: '1rem',
        }}
      >
        {value}
      </div>
    )
  }
  return (
    <Link
      to={{
        pathname: detailPath,
        search: queryParams
          ? `${convertedQueryParams}`
          : `?${stringify(detailParams)}`,
        state: {
          performanceReportUrl: `${history.location.pathname}${history.location.search}`,
        },
      }}
      className={
        rightAligment ? classnames(styles.right, styles.link) : styles.link
      }
    >
      {value}
    </Link>
  )
}

type ObjectArray = {
  start?: string
  startDate: string
  end?: string
  endDate: string
  dateRange: string
  dateType?: string
  event?: string
  vertical?: string
  verticalId?: string
  program?: string
  product?: string
  productType?: number
  campaign?: string
  clickedProduct?: string
  displayedProductName?: string
  clickedProductName?: string
  integrationType?: string
  subTracking?: string
}

const convertObjectArrayToQueryParams = (objArray: ObjectArray[]): string => {
  let queryParams = ''
  objArray.forEach((obj, index: number) => {
    Object.entries(obj).forEach(([key, value]) => {
      queryParams += value
        ? `${
            index === 0 && key === Object.keys(obj)[0] ? '?' : '&'
          }${key}=${value}`
        : ''
    })
  })
  return queryParams
}
