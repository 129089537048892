import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'

import { PublicWrapper, Terms } from '../components'

import { getCurrentTerms } from '../actions/terms'

import { State } from '../reducers'
import { TermsStore } from '../typings/support'

interface PublicTermsProps {
  terms?: TermsStore
  getCurrentTerms(): void
}

const PublicTermsContainer: FunctionComponent<PublicTermsProps> = ({
  terms,
  getCurrentTerms,
}) => {
  useEffect(() => {
    getCurrentTerms()
  }, [getCurrentTerms])

  return (
    <PublicWrapper>
      <Terms content={terms} />
    </PublicWrapper>
  )
}

const mapStateToProps = (state: State) => ({
  terms: state.terms,
})

const mapDispatchToProps = dispatch => ({
  getCurrentTerms: () => dispatch(getCurrentTerms()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicTermsContainer)
