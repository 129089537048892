import { Reducer } from 'redux'

import { PaymentsAction, PaymentsConstants } from '../actions/payments'
import { PaymentsStore } from '../typings/payments'

const initialState: PaymentsStore = {
  dataType: null,
  outstandingBalance: {
    transactions: [],
    totals: null,
    summary: null,
    pagination: null,
    export: {
      hasData: false,
      status: null,
      error: null,
    },
    hasData: false,
    status: null,
    error: null,
  },
  paymentHistory: {
    transactions: [],
    totals: null,
    summary: null,
    pagination: null,
    export: {
      hasData: false,
      status: null,
      error: null,
    },
    hasData: false,
    status: null,
    error: null,
  },
  paymentDetails: {
    transactions: [],
    totals: null,
    summary: null,
    pagination: null,
    export: {
      hasData: false,
      status: null,
      error: null,
    },
    hasData: false,
    status: null,
    error: null,
  },
}

export const paymentsReducer: Reducer = (
  state = { ...initialState },
  action: PaymentsAction
): PaymentsStore => {
  switch (action.type) {
    case PaymentsConstants.FETCH:
      return {
        ...state,
        dataType: action.dataType,
        [action.dataType]: {
          ...state[action.dataType],
          hasData: false,
          status: 'fetching',
          error: null,
        },
      }
    case PaymentsConstants.FETCH_SUCCESS:
      return {
        ...state,
        [action.dataType]: {
          ...state[action.dataType],
          ...action.data,
          hasData: action.data.transactions.length > 0,
          status: 'fetched',
          error: null,
        },
      }
    case PaymentsConstants.FETCH_FAILURE:
      return {
        ...state,
        [action.dataType]: {
          ...initialState[action.dataType],
          hasData: false,
          status: null,
          error: action.error,
        },
      }
    case PaymentsConstants.RESET:
      return {
        ...state,
        [action.dataType]: {
          ...initialState[action.dataType],
        },
      }
    case PaymentsConstants.EXPORT_FETCH:
      return {
        ...state,
        [action.dataType]: {
          ...state[action.dataType],
          export: {
            ...initialState[action.dataType].export,
            status: 'fetching',
          },
        },
      }
    case PaymentsConstants.EXPORT_FETCH_SUCCESS:
      return {
        ...state,
        [action.dataType]: {
          ...state[action.dataType],
          export: {
            hasData: true,
            status: 'fetched',
            error: null,
          },
        },
      }
    case PaymentsConstants.EXPORT_FETCH_FAILURE:
      return {
        ...state,
        [action.dataType]: {
          ...state[action.dataType],
          export: initialState[action.dataType].export,
        },
      }
    default:
      return state
  }
}
