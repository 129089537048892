import { Dispatch } from 'redux'

import { triggerError } from './app'
import { fetchGetApi } from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { Filter } from '../typings/myFiReportsFilters'

export enum MyFiReportsFiltersConstants {
  FETCH_VERTICAL_OPTIONS = 'myFiReportsFilters/FETCH_VERTICAL_OPTIONS',
  FETCH_VERTICAL_OPTIONS_SUCCESS = 'myFiReportsFilters/FETCH_VERTICAL_OPTIONS_SUCCESS',
  FETCH_VERTICAL_OPTIONS_FAILURE = 'myFiReportsFilters/FETCH_VERTICAL_OPTIONS_FAILURE',
  FETCH_TRAFFIC_SOURCE = 'myFiReportsFilters/FETCH_TRAFFIC_SOURCE',
  FETCH_TRAFFIC_SOURCE_SUCCESS = 'myFiReportsFilters/FETCH_TRAFFIC_SOURCE_SUCCESS',
  FETCH_TRAFFIC_SOURCE_FAILURE = 'myFiReportsFilters/FETCH_TRAFFIC_SOURCE_FAILURE',
  FETCH_CAMPAIGN = 'myFiReportsFilters/FETCH_CAMPAIGN',
  FETCH_CAMPAIGN_SUCCESS = 'myFiReportsFilters/FETCH_CAMPAIGN_SUCCESS',
  FETCH_CAMPAIGN_FAILURE = 'myFiReportsFilters/FETCH_CAMPAIGN_FAILURE',
  FETCH_PRODUCT = 'myFiReportsFilters/FETCH_PRODUCT',
  FETCH_PRODUCT_SUCCESS = 'myFiReportsFilters/FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILURE = 'myFiReportsFilters/FETCH_PRODUCT_FAILURE',
}

export interface MyFiReportsFiltersAction extends Action {
  type: MyFiReportsFiltersConstants
  data?: Filter[]
  error?: string
}

function fetchVerticalFilters(): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_VERTICAL_OPTIONS,
  }
}

function fetchVerticalFiltersSuccess(data: Filter[]): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_VERTICAL_OPTIONS_SUCCESS,
    data,
  }
}

function fetchVerticalFiltersFailure(error: string): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_VERTICAL_OPTIONS_FAILURE,
    error,
  }
}

function fetchTrafficSourceFilters(): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_TRAFFIC_SOURCE,
  }
}

function fetchTrafficSourceFiltersSuccess(
  data: Filter[]
): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_TRAFFIC_SOURCE_SUCCESS,
    data,
  }
}

function fetchTrafficSourceFiltersFailure(
  error: string
): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_TRAFFIC_SOURCE_FAILURE,
    error,
  }
}

function fetchCampaignFilters(): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_CAMPAIGN,
  }
}

function fetchCampaignFiltersSuccess(data: Filter[]): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_CAMPAIGN_SUCCESS,
    data,
  }
}

function fetchCampaignFiltersFailure(error: string): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_CAMPAIGN_FAILURE,
    error,
  }
}

function fetchProductFilters(): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_PRODUCT,
  }
}

function fetchProductFiltersSuccess(data: Filter[]): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_PRODUCT_SUCCESS,
    data,
  }
}

function fetchProductFiltersFailure(error: string): MyFiReportsFiltersAction {
  return {
    type: MyFiReportsFiltersConstants.FETCH_PRODUCT_FAILURE,
    error,
  }
}

// FETCH Vertical Options
export const getVerticalFilters = affiliateId => async (dispatch: Dispatch) => {
  dispatch(fetchVerticalFilters())
  const type = 'vertical'
  const endpoint = `/myfi-reports/filter-options?affiliate_id=${affiliateId}&type=${type}`
  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchVerticalFiltersSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'MyFiReportsFilters'))
    dispatch(fetchVerticalFiltersFailure(errorParsed))
  }
}

// FETCH Traffic Source Options
export const getTrafficSourceFilters = affiliateId => async (
  dispatch: Dispatch
) => {
  dispatch(fetchTrafficSourceFilters())
  const type = 'traffic_source'
  const endpoint = `/myfi-reports/filter-options?affiliate_id=${affiliateId}&type=${type}`
  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchTrafficSourceFiltersSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'MyFiReportsFilters'))
    dispatch(fetchTrafficSourceFiltersFailure(errorParsed))
  }
}

// FETCH Campaign Options
export const getCampaignFilters = affiliateId => async (dispatch: Dispatch) => {
  dispatch(fetchCampaignFilters())

  const type = 'campaign'
  const endpoint = `/myfi-reports/filter-options?affiliate_id=${affiliateId}&type=${type}`
  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchCampaignFiltersSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'MyFiReportsFilters'))
    dispatch(fetchCampaignFiltersFailure(errorParsed))
  }
}

// FETCH Product Options
export const getProductFilters = affiliateId => async (dispatch: Dispatch) => {
  dispatch(fetchProductFilters())

  const type = 'product'
  const endpoint = `/myfi-reports/filter-options?affiliate_id=${affiliateId}&type=${type}`
  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchProductFiltersSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'MyFiReportsFilters'))
    dispatch(fetchProductFiltersFailure(errorParsed))
  }
}
