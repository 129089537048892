import { Reducer } from 'redux'

import {
  ProductTypesAction,
  ProductTypesConstants,
} from '../actions/productTypes'

import { ProductTypesStore } from '../typings/stores/ProductTypesStore'

const initialState: ProductTypesStore = {
  data: [],
  fetching: false,
  error: null,
}

export const productTypesReducer: Reducer = (
  state = { ...initialState },
  action: ProductTypesAction
): ProductTypesStore => {
  switch (action.type) {
    case ProductTypesConstants.FETCH:
      return {
        ...initialState,
        fetching: true,
      }
    case ProductTypesConstants.FETCH_SUCCESS:
      return {
        data: action.payload,
        error: initialState.error,
        fetching: false,
      }
    case ProductTypesConstants.FETCH_FAILURE:
      return {
        ...initialState,
        error: action.error,
      }
    default:
      return state
  }
}
