import { Reducer } from 'redux'
import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'

import { LinksAction, LinksConstants } from '../actions/links'
import { LinksStore } from '../typings/stores/LinksStore'

const initialState: LinksStore = {
  preloaded: [],
  pageSize: 10,
  currentPage: 1,
  hasData: false,
  status: null,
  error: null,
  exporting: false,
}

// NOTE: This is the correct type signature, but we have incompatible types below.
export const linksReducer: Reducer<LinksStore, LinksAction> = (
  state = { ...initialState },
  action
): LinksStore => {
  switch (action.type) {
    case LinksConstants.FETCH:
      return {
        ...state,
        preloaded: initialState.preloaded,
        hasData: false,
        status: 'fetching',
        error: initialState.error,
      }
    case LinksConstants.FETCH_SUCCESS:
      return {
        ...state,
        preloaded: action.payload,
        hasData: action.payload.length > 0,
        status: 'fetched',
        error: initialState.error,
      }
    case LinksConstants.FETCH_FAILURE:
      return {
        ...initialState,
        hasData: false,
        status: null,
        error: action.error,
      }
    case LinksConstants.FETCH_INFO:
      return {
        ...state,
        linkInfo: {
          data: null,
          hasData: false,
          status: 'fetching',
          error: null,
        },
      }
    case LinksConstants.FETCH_INFO_SUCCESS:
      return {
        ...state,
        linkInfo: {
          data: action.linkInfo,
          hasData: !_isEmpty(action.linkInfo),
          status: 'fetched',
          error: null,
        },
      }
    case LinksConstants.FETCH_INFO_FAILURE:
      return {
        ...state,
        linkInfo: {
          data: state.linkInfo.data,
          hasData: false,
          status: null,
          error: action.error,
        },
      }
    case LinksConstants.FETCH_EXPORT:
      return {
        ...state,
        exporting: true,
      }
    case LinksConstants.FETCH_EXPORT_SUCCESS:
      return {
        ...state,
        exporting: false,
      }
    default:
      return state
  }
}
