import { Store, createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import rootReducer, { State } from './reducers'

const composeEnhancers =
  process.env['NODE_ENV'] === 'development' &&
  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    : compose

const store: Store<State> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
)

export { store }
