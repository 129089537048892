import React, { FunctionComponent, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { ForgotPassword, PublicWrapper } from '../components'

import { getUserFromSession } from '../actions/user'
import { requestCircuitPasswordReset } from '../actions/password'
import { useStoredUser } from '../utils/sorcery'

import { State } from '../reducers'
import { PasswordStore } from '../typings/password'
import { UserStore } from '../typings/stores/UserStore'

interface ForgotPasswordProps extends RouteComponentProps {
  user: UserStore
  password: PasswordStore
  requestPasswordReset(): void
  getUserFromSession(): void
}

const ForgotPasswordContainer: FunctionComponent<ForgotPasswordProps> = ({
  history,
  user,
  password,
  requestPasswordReset,
  getUserFromSession,
}) => {
  const sessionChecked = useStoredUser(getUserFromSession, user)

  useEffect(() => {
    // If user exists in memory and is logged in, redirect to main app.
    if (sessionChecked && !user.loggingIn && user.loggedIn) {
      history.push('/')
    }
  }, [sessionChecked, user.loggingIn, user.loggedIn, history])

  return (
    <PublicWrapper>
      <ForgotPassword
        password={password}
        requestPasswordReset={requestPasswordReset}
      />
    </PublicWrapper>
  )
}

const mapStateToProps = (state: State) => ({
  user: state.user,
  password: state.password,
})

const mapDispatchToProps = dispatch => ({
  getUserFromSession: () => dispatch(getUserFromSession()),
  requestPasswordReset: (u: string) => dispatch(requestCircuitPasswordReset(u)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)
)
