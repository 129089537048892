import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { debounce, get } from 'lodash'
import { rebuild } from 'react-tooltip'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import { AdvertisersStore } from '../../typings/stores/AdvertiserStore'
import { formatAdvertisers } from '../../utils/format'
import { getPrograms } from '../../actions/advertisers'
import { getLinks, getLinksExport } from '../../actions/links'
import { LinksTableContext } from '.'
import { PageHeading, SearchInput, Select } from '../../components'
import { Product } from '../../typings/product'
import {
  sortTableData,
  usePaginatedData,
} from '../../contexts/TableContext/utils'
import { State } from '../../reducers'
import { useToggle } from '../../utils/customHooks'
import Table from '../../components/Table/next'
import {
  applyLinkFilters,
  linksToTableRows,
  renderCommissionValue,
  renderLinkTypes,
} from './utils'
import ViewLink from './ViewLink'

import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Website } from '../../typings/website'
import { AppContext } from '../../contexts/App'

interface LinksPageProps {
  programId?: string
}

const OLD_LINKS_PAGE = '/app/links'

const LinksPage: FunctionComponent<LinksPageProps> = ({ programId }) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const links = useSelector<State, Product[]>(state => state.links.preloaded)
  const exporting = useSelector<State, boolean>(state => state.links.exporting)

  const linksLoading = useSelector<State, boolean>(
    state => state.links.status === 'fetching'
  )

  const advertisers = useSelector<State, AdvertisersStore>(
    state => state.advertisers
  )

  const activeProperty = useSelector<State, Website>(
    state => state.user.activeWebsite
  )

  const user = useSelector<State, any>(state => state.user)

  const viewingProduct = useMemo(() => links.find(l => l.id === programId), [
    programId,
    links,
  ])

  const {
    setFilter,
    changePage,
    changePageSize,
    changeSort,
    filters,
    pagination: { size, page },
    sort,
  } = useContext(LinksTableContext)

  const advertisersList = useMemo(() => formatAdvertisers(advertisers), [
    advertisers,
  ])

  // Build the table row content from the page
  const mappedLinks = useMemo(() => linksToTableRows(links), [links])

  // Local toggle states
  const [showBannerLinks, toggleBannerLinks] = useToggle(true)
  const [showTextLinks, toggleTextLinks] = useToggle(true)

  // Filter and sort results
  const filteredLinks = useMemo(() => {
    const filtered = applyLinkFilters(mappedLinks, {
      ...filters,
      showBannerLinks,
      showTextLinks,
    })

    return sortTableData(filtered, sort)
  }, [filters, sort, mappedLinks, showBannerLinks, showTextLinks])

  // Build a window for our page
  const paginatedPage = usePaginatedData(filteredLinks, page, size)

  // Rebind tooltips
  useEffect(() => {
    rebuild()
  })

  // Fetch our data on initial mount
  useEffect(() => {
    dispatch(getPrograms(activeProperty))
    dispatch(getLinks(activeProperty))
  }, [activeProperty])
  return (
    <>
      <PageHeading title="Links" className={styles.heading}>
        <div className={styles.filters}>
          <h4>Show link types:</h4>

          <div className={styles.buttonContainer}>
            <button
              className={classnames(styles.toggleButton, styles.text, {
                [styles.selected]: showTextLinks,
              })}
              onClick={toggleTextLinks}
            >
              Text
            </button>

            <button
              className={classnames(styles.toggleButton, styles.banner, {
                [styles.selected]: showBannerLinks,
              })}
              onClick={toggleBannerLinks}
            >
              Banner
            </button>
          </div>
        </div>

        <div className={styles.advertiserFilter}>
          <h4>{`${
            location.pathname === OLD_LINKS_PAGE ? 'Advertiser:' : 'Program:'
          }`}</h4>

          <Select
            placeholder={`${
              location.pathname === OLD_LINKS_PAGE
                ? 'All Advertisers'
                : 'All Programs'
            }`}
            loadingMessage={`${
              location.pathname === OLD_LINKS_PAGE
                ? 'Loading advertisers'
                : 'Loading programs'
            }`}
            isLoading={
              advertisers.status === 'fetching' || advertisers.error != null
            }
            className={styles.advertiserSelector}
            selected={get(filters, 'program', '')}
            onChange={e => {
              return setFilter(
                'program',
                e.currentTarget.value === '' ? null : e.currentTarget.value
              )
            }}
            options={advertisersList}
          />
        </div>

        <div className={styles.searchContainer}>
          <SearchInput
            initialVal={get(filters, 'search', '')}
            onChange={debounce(search => setFilter('search', search), 700)}
          />
        </div>

        <div>
          <button
            onClick={() =>
              dispatch(getLinksExport(location.pathname, activeProperty))
            }
            className={styles.export}
            title="Export Products"
          >
            {exporting ? (
              <FontAwesomeIcon
                icon={['fas', 'spinner']}
                pulse
                className={styles.icon}
              />
            ) : (
              <FontAwesomeIcon
                icon={['fa', 'download']}
                className={styles.icon}
              />
            )}
          </button>
        </div>
      </PageHeading>

      <Table
        onRowClick={({ id }) => history.push(`/links/view/${id}`)}
        data={paginatedPage}
        onSortChange={changeSort}
        sort={sort}
        cols={[
          {
            text: 'Product ID',
            sortable: true,
            className: styles.productId,
            field: 'id',
          },
          {
            text: 'Product',
            sortable: true,
            className: styles.wrap,
            field: 'name',
          },
          {
            text: `${
              location.pathname === OLD_LINKS_PAGE ? 'Advertiser' : 'Program'
            }`,
            sortable: true,
            className: styles.advertiserName,
            field: 'programName',
          },
          user.role.name !== 'portalintegration' && {
            text: 'Commission',
            sortable: true,
            field: 'commission',
            render: ({ commission: { value, dynamic } }) =>
              renderCommissionValue(value, dynamic),
          },
          {
            text: 'Link Types',
            className: styles.nostretch,
            render: ({ linkCounts: { banner, text } }) =>
              renderLinkTypes(text, banner),
          },
        ]}
        isLoading={linksLoading}
        pagination={{
          currentPage: page,
          onPageSizeUpdate: changePageSize,
          onPaginate: changePage,
          pageSize: size,
          total: filteredLinks.length,
        }}
      />
      {viewingProduct && (
        <ViewLink close={() => history.goBack()} product={viewingProduct} />
      )}
    </>
  )
}

export default LinksPage
