import React, { FunctionComponent } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _uniqueId from 'lodash/uniqueId'
import _isEmpty from 'lodash/isEmpty'

import { PageHeading } from '../../../components'
import UserListPanel from './UserListPanel'
import UserForm from './UserForm'

import styles from './styles.module.css'

import { AppUser, AppUsersStore } from '../../../typings/user'
import { formMethods } from '../../../containers/AppUsers'

interface AppUsersProps extends RouteComponentProps {
  basePath: string
  appUsers: AppUsersStore
  formMethod?: formMethods
  selectedUser: AppUser
  close(): void
  validateUser(): boolean
  editUser(): void
  addUser(): void
}

const AppUsers: FunctionComponent<AppUsersProps> = ({
  history,
  basePath,
  appUsers,
  formMethod,
  selectedUser,
  close,
  validateUser,
  editUser,
  addUser,
}) => {
  return (
    <>
      <PageHeading title="Users" className={styles.heading}>
        <div className={styles.actionContainer}>
          <button
            className={styles.add}
            onClick={() => {
              history.push(`${basePath}/add`)
            }}
            data-rv="app-users-add"
          >
            Add New User
          </button>
        </div>
      </PageHeading>
      <div className={styles.listContainer}>
        {appUsers.data.records.map(user => (
          <UserListPanel
            key={_uniqueId(`${user.id}-`)}
            basePath={basePath}
            user={user}
            close={close}
            editUser={editUser}
          />
        ))}
      </div>
      {!_isEmpty(selectedUser) && formMethod === 'edit' && (
        <UserForm
          formMethod="edit"
          selectedUser={selectedUser}
          close={close}
          validateUser={validateUser}
          editUser={editUser}
        />
      )}
      {formMethod === 'add' && (
        <UserForm
          formMethod="add"
          close={close}
          validateUser={validateUser}
          addUser={addUser}
        />
      )}
    </>
  )
}

export default withRouter(AppUsers)
