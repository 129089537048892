import React, { FunctionComponent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Close from './Icons/close'
import Logo from './Icons/logo'
import Advertiser from './Icons/advertiser'
import Check from './Icons/check'
import Link from './Icons/link'
import Overview from './Icons/overview'
import Report from './Icons/report'
import Payment from './Icons/payment'
import Support from './Icons/support'
import Alert from './Icons/alert'
import { Icons } from './icons'

interface SvgProps {
  name: Icons
  className?: string
}

const Svg: FunctionComponent<SvgProps> = ({ name, className }) => {
  switch (name) {
    case 'advertiser':
      return <Advertiser className={className} />
    case 'alert':
      return <Alert className={className} />
    case 'check':
      return <Check className={className} />
    case 'close':
      return <Close className={className} />
    case 'laptop-code':
      return (
        <FontAwesomeIcon icon={['fas', 'laptop-code']} className={className} />
      )
    case 'link':
      return <Link className={className} />
    case 'overview':
      return <Overview className={className} />
    case 'report':
      return <Report className={className} />
    case 'rv-logo':
      return <Logo className={className} />
    case 'payment':
      return <Payment className={className} />
    case 'support':
      return <Support className={className} />
  }

  return null
}

Svg.defaultProps = {
  className: '',
}

export default Svg
