import _flow from 'lodash/flow'
import _partialRight from 'lodash/partialRight'
import _omitBy from 'lodash/omitBy'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'

// This method only allows key/value pairs with string values since it's used
// to prep an object to be converted into URL query params
export const pruneObject = (object: object) =>
  _flow(
    _partialRight(_omitBy, _isNil),
    _partialRight(_omitBy, _isEmpty)
  )(object)
