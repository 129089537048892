import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'
import { LinkButton } from '..'

export interface Tab {
  title: string
  isSelected: boolean
  to: string | Function
}

interface TabsProps {
  items: Tab[]
}

const Tabs: FunctionComponent<TabsProps> = ({ items }) => (
  <div className={styles.container}>
    <ul role="tablist">
      {items.map(item => (
        <li
          role="tab"
          key={`tab-${item.title.split(' ').join('-')}`}
          className={classnames(styles.tab, {
            [styles.selected]: item.isSelected,
          })}
        >
          <LinkButton to={item.to}>{item.title.toUpperCase()}</LinkButton>
        </li>
      ))}
    </ul>
  </div>
)

export default Tabs
