import React, { FunctionComponent } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { AppContainer, LinkInfoContainer } from '../../containers'
import { Err401, Err404 } from '../../components'
import { ENABLE_NEW_PAYMENT } from '../../utils/featureFlags'

import AdvertisersRouter from './advertisers'
import LinksRouter from './links'
import AlertsRouter from './alerts'
import SupportRouter from './support'
import PropertiesRouter from './properties'
import AppUsersRouter from './appUsers'
import Reports from './reports'
import Payments from './payments'

import RestrictedRoute from '../util/RestrictedRoute'
import PublisherRouter from './publisher'

const AppRouter: FunctionComponent<RouteComponentProps> = ({ match }) => {
  return (
    <AppContainer>
      <Switch>
        <Redirect exact path={match.path} to="/reports/overview-report" />
        <Route path={`/unauthorized`} render={() => <Err401 />} />
        <RestrictedRoute path={`/programs`} render={AdvertisersRouter} />
        <RestrictedRoute path={`/reports`} component={Reports} />
        {ENABLE_NEW_PAYMENT && (
          <RestrictedRoute path={`/payments`} component={Payments} />
        )}
        <RestrictedRoute
          path={`/links`}
          minimumRequiredRole="portalintegration"
          component={LinksRouter}
        />
        <RestrictedRoute
          path={`/link-info`}
          minimumRequiredRole="portalintegration"
          component={LinkInfoContainer}
        />
        <RestrictedRoute path={`/alerts`} component={AlertsRouter} />
        <RestrictedRoute
          path={`/support`}
          minimumRequiredRole="portalintegration"
          component={SupportRouter}
        />
        <RestrictedRoute path={`/properties`} component={PropertiesRouter} />
        <RestrictedRoute
          path={`/users`}
          minimumRequiredRole="manager"
          component={AppUsersRouter}
        />
        <RestrictedRoute
          path={`/publisher`}
          minimumRequiredRole="manager"
          component={PublisherRouter}
        />
        <Route render={() => <Err404 isInternal />} />
      </Switch>
    </AppContainer>
  )
}

export default AppRouter
