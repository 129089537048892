import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'

import { Err404 } from '../../components'

import {
  OverviewReport,
  ClickDetailReport,
  TransactionDetailReport,
} from '../../pages'
import { PerformanceReport } from '../../pages'

interface ReportsRouterProps extends RouteComponentProps<{ page: string }> {}

const ReportsRouter: FunctionComponent<ReportsRouterProps> = ({ match }) => (
  <Switch>
    <Redirect exact path={match.path} to={`${match.path}/overview-report`} />
    <Route path={`${match.path}/overview-report`} component={OverviewReport} />
    <Route path={`${match.path}/click-details`} component={ClickDetailReport} />
    <Route
      path={`${match.path}/event-details`}
      component={TransactionDetailReport}
    />
    <Route
      path={`${match.path}/vertical-summary`}
      component={PerformanceReport}
    />
    <Route component={Err404} />
  </Switch>
)

export default ReportsRouter
