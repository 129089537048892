import { parse, extract, ParsedQuery } from 'query-string'
import { isEmpty, has, forEach } from 'lodash'

interface LinkInfoResponse {
  isValid: boolean
  params: { [key: string]: string }
}

/**
 * Match all urls from a given string that have the lid parameter in them
 * https://regexr.com/4u68m
 */
const URL_REGEX = '(//?.*lid.[^\'">]+)'

export const parseSnippet = (code: string): LinkInfoResponse => {
  const defaultResponse = {
    isValid: false,
    params: {},
  }

  if (isEmpty(code)) {
    return {
      ...defaultResponse,
      isValid: false,
    }
  }

  const regex = new RegExp(URL_REGEX)
  const matches = regex.exec(code)

  if (!matches) {
    return {
      ...defaultResponse,
      isValid: false,
    }
  }

  // Convert "URL" into a usable object
  const url = matches[1] // Capture group 1
  const queryString = extract(url)
  const params = parse(queryString)

  if (!has(params, 'lid') || isEmpty(params.lid)) {
    return {
      ...defaultResponse,
      isValid: false,
    }
  }

  // Remove array query params as they are unneeded
  let parsedParams = {}
  forEach(params, (p, k) => {
    if (typeof p === 'string') {
      parsedParams[k] = params[k]
    }
  })

  return {
    isValid: true,
    params: parsedParams,
  }
}
