import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, get } from 'lodash'
import { pruneObject } from '../../utils/objectManagement'

import { AlertsStore } from '../../typings/stores/AlertsStore'
import { AlertsTableContext } from '.'
import { PageHeading, SearchInput } from '../../components'
import AlertTable from './AlertsTable'

import { getAlerts } from '../../actions/alerts'
import { State } from '../../reducers'

import styles from './styles.module.css'
import { Website } from '../../typings/website'
import { applyAlertFilters } from './utils'
import { sortTableData } from '../../contexts/TableContext/utils'
import { rebuild } from 'react-tooltip'

const Alerts: FunctionComponent = () => {
  const dispatch = useDispatch()

  const alertsLoading = useSelector<State, boolean>(
    state => state.alerts.status === 'fetching'
  )

  const alerts = useSelector<State, AlertsStore>(state => state.alerts)

  const activeProperty = useSelector<State, Website>(
    state => state.user.activeWebsite
  )
  const {
    changePage,
    changePageSize,
    changeSort,
    filters,
    pagination: { page, size },
    setFilter,
    sort,
  } = useContext(AlertsTableContext)
  const fetchAlerts = () =>
    dispatch(getAlerts({ ...filters, ...pruneObject(sort), size, page }))

  // Filter and sort results
  const filteredAlerts = useMemo(() => {
    const filtered = applyAlertFilters(alerts.data, {
      ...filters,
    })

    return sortTableData(filtered, sort)
  }, [filters, sort, alerts.data])

  // Rebind tooltips
  useEffect(() => {
    rebuild()
  })

  // This handles the case when you pass a subject but you are on something other than page 1
  useEffect(() => {
    if (page !== 1) {
      changePage(1)
    }
  }, [filters.search])

  useEffect(() => {
    fetchAlerts()
  }, [activeProperty])

  return (
    <>
      <PageHeading title="Alerts" className={styles.heading}>
        <div className={styles.searchContainer}>
          <SearchInput
            initialVal={get(filters, 'search', '')}
            onChange={debounce(value => setFilter('search', value), 700)}
          />
        </div>
      </PageHeading>

      <AlertTable
        loading={alertsLoading}
        alerts={filteredAlerts}
        paginate={false}
        onSortChange={changeSort}
        sort={sort}
        onPageSizeUpdate={changePageSize}
        onPaginate={changePage}
      />
    </>
  )
}

export default Alerts
