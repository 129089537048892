import { DateTime } from 'luxon'
import { DateRange } from '../typings/dates'

const dateFormat = 'y-MM-dd'

export type Period =
  | 'today'
  | 'yesterday'
  | 'this_week'
  | 'this_month'
  | 'last_month'
  | 'last_week'
  | 'last_quarter'
  | 'this_quarter'
  | 'year_to_date'

export const convertPeriodToDates = (
  period: Period = null,
  today: Date = null
): DateRange => {
  let startDate = DateTime.local()
  let endDate = DateTime.local()

  if (today !== null) {
    startDate = DateTime.fromJSDate(today)
    endDate = DateTime.fromJSDate(today)
  }

  // NOTE: Luxon uses ISO weeks (Monday - Sunday) while we use
  // Sunday - Saturday. We have to account for this when generating dates
  // dynamically.

  switch (period) {
    case 'today':
      return {
        period,
        startDate: startDate.toFormat(dateFormat),
        endDate: endDate.toFormat(dateFormat),
      }
    case 'yesterday':
      return {
        period,
        startDate: startDate.minus({ days: 1 }).toFormat(dateFormat),
        endDate: endDate.minus({ days: 1 }).toFormat(dateFormat),
      }
    case 'this_week':
      return {
        period,
        startDate: startDate
          .startOf('week')
          .minus({ days: 1 })
          .toFormat(dateFormat),
        endDate: endDate
          .endOf('week')
          .minus({ days: 1 })
          .toFormat(dateFormat),
      }
    case 'this_month':
      return {
        period,
        startDate: startDate.startOf('month').toFormat(dateFormat),
        endDate: endDate.endOf('month').toFormat(dateFormat),
      }
    case 'last_month':
      return {
        period,
        startDate: startDate
          .minus({ months: 1 })
          .startOf('month')
          .toFormat(dateFormat),
        endDate: endDate
          .minus({ months: 1 })
          .endOf('month')
          .toFormat(dateFormat),
      }
    case 'this_quarter':
      return {
        period,
        startDate: startDate.startOf('quarter').toFormat(dateFormat),
        endDate: endDate.endOf('quarter').toFormat(dateFormat),
      }
    case 'last_quarter':
      return {
        period,
        startDate: startDate
          .minus({ months: 3 })
          .startOf('quarter')
          .toFormat(dateFormat),
        endDate: endDate
          .minus({ months: 3 })
          .endOf('quarter')
          .toFormat(dateFormat),
      }
    case 'year_to_date':
      return {
        period,
        startDate: startDate.startOf('year').toFormat(dateFormat),
        endDate: endDate.toFormat(dateFormat),
      }
    case 'last_week':
    default:
      return {
        period,
        startDate: startDate
          .minus({ weeks: 1 })
          .startOf('week')
          .minus({ days: 1 })
          .toFormat(dateFormat),
        endDate: endDate
          .minus({ weeks: 1 })
          .endOf('week')
          .minus({ days: 1 })
          .toFormat(dateFormat),
      }
  }
}

/**
 * Converts our generated dates from the above function and prepares them for
 * usage in our API (which requires time). This also makes sure we are always
 * using UTC dates by default when communicating with our API unless useTimezone is set to true.
 *
 * @param start
 * @param end
 * @param useUtc
 */
export const prepareDatesForApi = (
  start: string,
  end: string,
  useUtc: boolean = false
): { start: DateTime; end: DateTime } => {
  return {
    start: DateTime.fromSQL(start, { zone: useUtc ? 'utc' : 'local' }).startOf(
      'day'
    ),
    end: DateTime.fromSQL(end, { zone: useUtc ? 'utc' : 'local' }).endOf('day'),
  }
}
