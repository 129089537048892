import { useEffect, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'

import { UserStore } from '../typings/stores/UserStore'

// I have no idea what this thing is, hence sorcery. It's not really a custom
// hook because I have to pass a connected method into it (getUserFromSession).
// I mainly pulled this out to reduce duplication since it's called in 3
// different containers (App, Login, Tos).

export const useStoredUser = (
  getUserFromSession: Function,
  user: UserStore
): boolean => {
  const [sessionChecked, setSessionChecked] = useState(false)

  useEffect(() => {
    // If no user is in memory, pull from session
    const checkSession = async () => {
      if ((_isEmpty(user) || _isEmpty(user.username)) && !user.loggingIn) {
        await getUserFromSession()
      }

      await setSessionChecked(true)
    }

    // Since hooks trigger every render, we want to make sure we only
    // check the session once on initial render
    if (sessionChecked === false) {
      checkSession()
    }
  }, [user, sessionChecked, getUserFromSession])

  return sessionChecked
}
