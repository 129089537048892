import { combineReducers } from 'redux'

import { UserConstants } from '../actions/user'

import { advertiserReducer } from './advertisers'
import { alertsReducer } from './alerts'
import { appReducer } from './app'
import { appUsersReducer } from './appUsers'
import { bannerReferralsReducer } from './bannerReferrals'
import { categoriesReducer } from './categories'
import { contactReducer } from './contact'
import { detailReportReducer } from './detailReport'
import { linksReducer } from './links'
import { passwordReducer } from './password'
import { paymentsReducer } from './payments'
import { performanceReducer } from './performance'
import { productTypesReducer } from './productTypes'
import { recentSalesReducer } from './recentSales'
import { reportsReducer } from './reports'
import { termsReducer } from './terms'
import { userReducer } from './user'
import { websitesReducer } from './websites'
import { propertiesReducer } from './properties'
import { myfiReportsLookerReducer } from './myfiReportsLooker'
import { myFiReportsFiltersReducer } from './myFiReportsFilters'

import { AdvertisersStore } from '../typings/stores/AdvertiserStore'
import { AlertsStore } from '../typings/stores/AlertsStore'
import { AppStore } from '../typings/stores/AppStore'
import { AppUsersStore } from '../typings/user'
import { BannerReferralsStore } from '../typings/stores/BannerReferralsStore'
import { CategoriesStore } from '../typings/stores/CategoriesStore'
import { ContactStore } from '../typings/contact'
import { DetailReportStore } from '../typings/stores/DetailReportStore'
import { LinksStore } from '../typings/stores/LinksStore'
import { PasswordStore } from '../typings/password'
import { PaymentsStore } from '../typings/payments'
import { PerformanceStore } from '../typings/stores/PerformanceStore'
import { ProductTypesStore } from '../typings/stores/ProductTypesStore'
import { RecentSalesStore } from '../typings/stores/RecentSalesStore'
import { ReportsStore } from '../typings/stores/ReportsStore'
import { TermsStore } from '../typings/support'
import { UserStore } from '../typings/stores/UserStore'
import { WebsitesStore } from '../typings/website'
import { PropertiesStore } from '../typings/property'
import { MyFiReportsLookerStore } from '../typings/stores/MyFiReportsLookerStore'
import { MyFiReportsFiltersStore } from '../typings/myFiReportsFilters'
import { PublisherStore } from '../typings/publisher'
import { publisherReducer } from './publisher'
import { IntegrationDocsStore } from '../typings/stores/IntegrationDocsStore'
import { integrationDocsReducer } from './integrationDocs'

export interface State {
  advertisers: AdvertisersStore
  alerts: AlertsStore
  app: AppStore
  appUsers: AppUsersStore
  bannerReferrals: BannerReferralsStore
  categories: CategoriesStore
  contact: ContactStore
  detailReport: DetailReportStore
  links: LinksStore
  integrationDocs: IntegrationDocsStore
  password: PasswordStore
  payments: PaymentsStore
  performance: PerformanceStore
  productTypes: ProductTypesStore
  publisher: PublisherStore
  recentSales: RecentSalesStore
  reports: ReportsStore
  terms: TermsStore
  user: UserStore
  websites: WebsitesStore
  properties: PropertiesStore
  myFiReportsLooker: MyFiReportsLookerStore
  myFiReportsFilters: MyFiReportsFiltersStore
}

const rootReducer = combineReducers<State>({
  advertisers: advertiserReducer,
  alerts: alertsReducer,
  app: appReducer,
  appUsers: appUsersReducer,
  bannerReferrals: bannerReferralsReducer,
  categories: categoriesReducer,
  contact: contactReducer,
  detailReport: detailReportReducer,
  links: linksReducer,
  integrationDocs: integrationDocsReducer,
  password: passwordReducer,
  payments: paymentsReducer,
  performance: performanceReducer,
  productTypes: productTypesReducer,
  publisher: publisherReducer,
  recentSales: recentSalesReducer,
  reports: reportsReducer,
  terms: termsReducer,
  user: userReducer,
  websites: websitesReducer,
  properties: propertiesReducer,
  myFiReportsLooker: myfiReportsLookerReducer,
  myFiReportsFilters: myFiReportsFiltersReducer,
})

const root = (state, action): State => {
  if (action.type === UserConstants.LOGOUT) {
    // setting undefined will trigger the states to be reinitialized with default data
    state = undefined
  }

  return rootReducer(state, action)
}

export default root
