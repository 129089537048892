import React, { FunctionComponent } from 'react'
import { uniqueId } from 'lodash'

import Item, { MenuItem } from './Item'

import styles from './styles.module.css'

interface SideProps {
  menuList: MenuItem[]
  className: string
}

const Side: FunctionComponent<SideProps> = ({ menuList, className }) => (
  <nav className={className} data-rv="side-menu">
    <div className={styles.placeholder} data-rv="side-menu-placeholder">
      {process.env.NODE_ENV !== 'production' && process.env.NODE_ENV}
    </div>
    <ul className={styles.container} data-rv="side-menu-list">
      {menuList &&
        menuList.map(({ name, icon, path, className, minimumRequiredRole }) => (
          <Item
            name={name}
            icon={icon}
            path={path}
            className={className}
            minimumRequiredRole={minimumRequiredRole}
            key={uniqueId()}
          />
        ))}
    </ul>
  </nav>
)

export default Side
