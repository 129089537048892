import React, { FunctionComponent, useEffect } from 'react'
import { map, uniqueId } from 'lodash'
import classnames from 'classnames'

import { Loading, PageDescription } from '../../../../components'

import accounting from 'accounting'
import styles from '../styles.module.css'
import { OutstandingBalanceReportingSummaryQueryVariables } from '../../../../generated/types'
import { useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

interface SummaryProps {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
}

const Summary: FunctionComponent<SummaryProps> = ({
  getData,
  data,
  loading,
  error,
}) => {
  const user = useSelector<State, UserStore>(state => state.user)

  const variables = () => {
    return {
      externalPublisherId: user.affiliateId,
    } as OutstandingBalanceReportingSummaryQueryVariables
  }

  const fetchData = () => {
    getData({ variables: variables() })
  }

  useEffect(fetchData, [user.affiliateId])

  if (loading) {
    return (
      <div className={classnames(styles.stateContainer, styles.loading)}>
        <Loading />
      </div>
    )
  }

  // Initialize variables
  let totalAmount = -1
  let threshold = -1
  const mappedPayload = new Map<string, Map<string, number>>()
  const verticals = new Set<string>()
  const months = new Set<string>()

  if (data) {
    // When the data is already loaded we can iterate over and fill the variables
    map(data, payload => {
      totalAmount = payload.summary.reduce((a, v) => a + v.total, 0)
      threshold = payload.summary[0]?.threshold ?? -1

      payload.summary.forEach(item => {
        const { date, total, verticalName } = item
        const dateObj = new Date(`${date}-15`)
        const month = dateObj.toLocaleDateString('en-US', {
          month: 'long',
          year: 'numeric',
        })
        if (!mappedPayload.has(month)) {
          mappedPayload.set(month, new Map())
        }
        if (!verticals.has(verticalName)) {
          verticals.add(verticalName)
        }
        if (!months.has(month)) {
          months.add(month)
        }
        mappedPayload.get(month).set(verticalName, total)
      })
    })
  }

  return (
    <div className={styles.container}>
      <div key={uniqueId('section-')} className={classnames(styles.section)}>
        {verticals.size > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Sales</th>
                {Array.from(verticals).map(verticalName => (
                  <th key={verticalName}>{verticalName}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(months).map(date => (
                <tr key={date}>
                  <th>{date}</th>
                  {Array.from(verticals).map(verticalName => (
                    <th key={verticalName}>
                      {accounting.formatMoney(
                        mappedPayload.get(date)?.get(verticalName) || 0,
                        '$'
                      )}
                    </th>
                  ))}
                  <th>
                    {accounting.formatMoney(
                      Array.from(mappedPayload.get(date).values()).reduce(
                        (a, v) => a + v,
                        0
                      ),
                      '$'
                    )}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
      <div
        key={uniqueId('section-')}
        className={classnames(styles.section, styles.totalSection)}
      >
        <div className={styles.header}>
          <h4>Total</h4>
          <span>{accounting.formatMoney(totalAmount, '$')}</span>
        </div>
        {threshold > totalAmount && (
          <PageDescription
            content={`Commissions will be paid once threshold of ${accounting.formatMoney(
              threshold,
              '$'
            )} is met`}
          />
        )}
      </div>
    </div>
  )
}

export default Summary
