import 'whatwg-fetch'
import history from './../browserHistory'
import { get } from 'lodash'

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || ''

interface FetchOptions {
  endpoint: string
  body?: Object
  method: string
  contentType: string
  token?: string
}

/**
 * Fetch API using Get throwing an error on non-2xx response
 *
 * @returns returning the response.json() object
 */
const fetchGetApi = (fetch, endpoint, redirectToLoginOnFail = true) => {
  const data = {
    endpoint: `/api${endpoint}`,
    method: 'GET',
    contentType: 'application/json',
  }

  return fetchWithErrors(fetch, data, {
    redirectToLoginOnFail,
  })
}

/**
 * Fetch API using Get throwing an error on non-2xx response
 *
 * @returns returning the response.json() object
 */
const fetchGetApiWithFullUrl = async (fetch, endpoint) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  const fetchOpts: any = {
    method: 'GET',
    headers: new Headers({ ...headers }),
  }

  const res = await fetch(endpoint, fetchOpts)
  if (!res.ok) {
    return await errorParser(res)
  }
  return await bodyParser(res)
}

const fetchGetPublic = (fetch, endpoint: string) => {
  const data = {
    endpoint: `/public${endpoint}`,
    method: 'GET',
    contentType: 'application/json',
  }

  return fetchWithErrors(fetch, data)
}

/**
 * Fetch API using Post throwing an error on non-2xx responsenpm
 *
 * @returns returning the response.json() object
 */
const fetchPostApi = (
  fetch,
  endpoint: string,
  body: Object,
  token?: string
) => {
  const data = {
    endpoint: `/api${endpoint}`,
    body,
    method: 'POST',
    contentType: 'application/json',
    token,
  }

  return fetchWithErrors(fetch, data)
}

const fetchPostPublic = (fetch, endpoint: string, body: Object) => {
  const data = {
    endpoint: `/public${endpoint}`,
    body,
    method: 'POST',
    contentType: 'application/json',
  }

  return fetchWithErrors(fetch, data)
}

const fetchPutPublic = (fetch, endpoint: string, body: Object) => {
  const data = {
    endpoint: `/public${endpoint}`,
    body,
    method: 'PUT',
    contentType: 'application/json',
  }

  return fetchWithErrors(fetch, data)
}

/**
 * Fetch API using Put throwing an error on non-2xx response
 *
 * @returns returning the response.json() object
 */
const fetchPutApi = (fetch, endpoint: string, body: Object, token?: string) => {
  const data = {
    endpoint: `/api${endpoint}`,
    body,
    method: 'PUT',
    contentType: 'application/json',
    token,
  }

  return fetchWithErrors(fetch, data)
}

/**
 * Fetch API using Delete throwing an error on non-2xx responsenpm
 *
 * @returns returning the response.json() object
 */
const fetchDeleteApi = (fetch, endpoint: string, token?: string) => {
  const data = {
    endpoint: `/api${endpoint}`,
    method: 'DELETE',
    contentType: 'application/json',
    token,
  }

  return fetchWithErrors(fetch, data)
}

const bodyParser = async res => {
  const contentType = res.headers.get('Content-Type')

  if (!contentType) {
    res.bodyParsed = res.status
  } else if (contentType.includes('application/json')) {
    res.bodyParsed = await res.json()
  } else if (contentType.includes('text')) {
    res.bodyParsed = await res.text()
  } else {
    res.bodyParsed = res.status
  }

  return res
}

const errorParser = async res => {
  const contentType = res.headers.get('Content-Type')

  if (contentType && contentType.includes('application/json')) {
    res.errorParsed = get(await res.json(), 'data.error', 'Unknown Error')
  } else if (contentType && contentType.includes('text')) {
    res.errorParsed = await res.text()
  } else {
    res.errorParsed = res.status.toString()
  }

  return res
}

interface FetchWithErrors {
  bodyParsed: any
  errorParsed: any
  ok: boolean
}

/**
 * Fetch API throwing an error on non-2xx response
 *
 * @returns returning the response.json() object
 */
const fetchWithErrors = async (
  fetch,
  data: FetchOptions,
  options?: {
    redirectToLoginOnFail: boolean
  }
): Promise<FetchWithErrors> => {
  const endpoint = `${SERVER_URL}${data.endpoint}`

  const headers = {
    'Content-Type': data.contentType,
  }

  const fetchOpts: any = {
    credentials: 'include',
    method: data.method,
    headers: new Headers({ ...headers }),
  }

  if (data.method !== 'GET' && data.method !== 'HEAD') {
    fetchOpts.body =
      typeof data.body === 'string' ? data.body : JSON.stringify(data.body)
  }

  const res = await fetch(endpoint, fetchOpts)
  if (!res.ok) {
    if (res.status === 401 && options.redirectToLoginOnFail) {
      history.push('/login')
      return null
    }

    return await errorParser(res)
  }

  return await bodyParser(res)
}

const cancelFetchOnReentrySync = wrappedFunc => {
  let currentAbort = new AbortController()

  return (...args) => {
    currentAbort.abort()
    currentAbort = new AbortController()

    let signal = currentAbort.signal

    const injectedFetch = (input, init = {}) =>
      fetch(input, {
        ...init,
        signal,
      })

    return wrappedFunc(injectedFetch)(...args)
  }
}

const swallowCancellation = wrappedFunc => async (...args) => {
  try {
    await wrappedFunc(...args)
  } catch (ex) {
    if (ex.name === 'AbortError') {
      return // Request has been canceled, so do nothing
    }

    throw ex
  }
}

export {
  fetchDeleteApi,
  fetchGetApi,
  fetchGetApiWithFullUrl,
  fetchGetPublic,
  fetchPostApi,
  fetchPostPublic,
  fetchPutApi,
  fetchPutPublic,
  fetchWithErrors,
  cancelFetchOnReentrySync,
  swallowCancellation,
}
