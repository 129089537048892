import React, { FunctionComponent, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'

import { Properties } from '../components'

import { getProperties, addProperty } from '../actions/properties'

import { State } from '../reducers'
import { Property, PropertiesStore } from '../typings/property'
import { PublisherStore } from '../typings/publisher'
import { getPublisher } from '../actions/publisher'
import { UserStore } from '../typings/stores/UserStore'

interface PropertiesProps extends RouteComponentProps {
  basePath: string
  properties?: PropertiesStore
  showAddForm?: boolean
  activeProperty: Property
  publisher?: PublisherStore
  getProperties(): void
  addProperty(): void
  getPublisher(publisherId: string): Promise<void>
}

const PropertiesContainer: FunctionComponent<PropertiesProps> = ({
  history,
  basePath,
  properties,
  showAddForm,
  activeProperty,
  publisher,
  getProperties,
  addProperty,
  getPublisher,
}) => {
  const user = useSelector<State, UserStore>(state => state.user)

  useEffect(() => {
    getProperties()
  }, [getProperties, activeProperty])

  useEffect(() => {
    if (publisher.publisherName === null) {
      getPublisher(user.affiliateId)
    }
  }, [])

  return (
    <Properties
      basePath={basePath}
      properties={properties}
      showAddForm={showAddForm}
      closeAdd={() => {
        history.push(basePath)
        getProperties()
      }}
      addProperty={addProperty}
    />
  )
}

const mapStateToProps = (state: State) => ({
  properties: state.properties,
  activeProperty: state.user.activeProperty,
  publisher: state.publisher,
})

const mapDispatchToProps = dispatch => ({
  getProperties: () => dispatch(getProperties()),
  addProperty: (p: Property, pn: string) => dispatch(addProperty(p, pn)),
  getPublisher: (p: string) => dispatch(getPublisher(p)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PropertiesContainer)
)
