import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toast, ToastId } from 'react-toastify'
import { ExportContext } from '../../contexts/ExportContext'

import styles from './styles.module.css'

interface ExportToastProps {
  reportName: string
}

const ExportToast: FunctionComponent<ExportToastProps> = ({ reportName }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingText}>{reportName} Report</div>
      </div>
      <div className={styles.message}>Exporting...</div>
    </div>
  )
}

const ExportIndicator: FunctionComponent = () => {
  const { exportState, stopExport } = useContext(ExportContext)
  const [toastId, setToastId] = useState<ToastId>(null)

  useEffect(() => {
    if (exportState.running) {
      const toastId = toast.info(
        () => <ExportToast reportName={exportState.name} />,
        {
          closeOnClick: false,
          onClose: stopExport,
          progress: 0,
        }
      )
      setToastId(toastId)
    }
  }, [exportState.running])

  useEffect(() => {
    toast.update(toastId, { progress: exportState.progress })
  }, [exportState.progress])

  return null
}

export default ExportIndicator
