import { Reducer } from 'redux'

import { ContactStore } from '../typings/contact'
import { ContactAction, ContactConstants } from '../actions/contact'

const initialState: ContactStore = {
  data: {
    subject: '',
    message: '',
  },
  sending: false,
  sent: false,
  error: null,
}

export const contactReducer: Reducer = (
  state = { ...initialState },
  action: ContactAction
): ContactStore => {
  switch (action.type) {
    case ContactConstants.SEND:
      return {
        data: initialState.data,
        sending: true,
        sent: false,
        error: null,
      }
    case ContactConstants.SEND_SUCCESS:
      return {
        data: {
          subject: action.data.subject,
          message: action.data.message,
        },
        sending: false,
        sent: true,
        error: null,
      }
    case ContactConstants.SEND_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: action.error,
      }
    default:
      return state
  }
}
