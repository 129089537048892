import React, { FunctionComponent, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import styles from './styles.module.css'

interface SearchInputProps {
  onChange(val: string): void
  initialVal?: string
}

const SearchInput: FunctionComponent<SearchInputProps> = ({
  initialVal,
  onChange,
}) => {
  const [value, setValue] = useState(initialVal)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setValue(initialVal)
  }, [initialVal])

  const placeholder = focused ? '' : 'Search'

  return (
    <div
      className={classnames(styles.container, { [styles.focused]: focused })}
    >
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={e => {
          const newVal = e.currentTarget.value

          setValue(newVal)
          onChange(newVal)
        }}
      />
      {!value ? (
        <div className={styles.icon}>
          <FontAwesomeIcon icon="search" />
        </div>
      ) : (
        <button
          className={classnames(styles.icon, styles.clearButton)}
          onClick={() => {
            setValue('')
            onChange('')
          }}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      )}
    </div>
  )
}

SearchInput.defaultProps = {
  initialVal: '',
}

export default SearchInput
