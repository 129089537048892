import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { State } from '../reducers'
import { UserStore } from '../typings/stores/UserStore'

interface UserProps extends RouteComponentProps {
  user: UserStore
  getUserInfo: Function
}

class User extends Component<UserProps> {
  state = {
    subnavTitle: 'User',
  }

  render() {
    return (
      <div>
        <pre>{JSON.stringify(this.props.user, null, 2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => ({
  user: state.user,
})

export default withRouter(connect(mapStateToProps, null)(User))
