import { Reducer } from 'redux'

import { AppUsersAction, AppUsersConstants } from '../actions/appUsers'
import { AppUsersStore } from '../typings/user'

export const initialState: AppUsersStore = {
  data: {
    records: [],
    pagination: null,
  },
  hasData: false,
  fetching: false,
  fetched: false,
  error: null,
}

export const appUsersReducer: Reducer = (
  state = { ...initialState },
  action: AppUsersAction
): AppUsersStore => {
  switch (action.type) {
    case AppUsersConstants.FETCH:
      return {
        ...initialState,
        fetching: true,
      }

    case AppUsersConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        hasData: action.data.records.length > 0,
        fetching: false,
        fetched: true,
        error: null,
      }

    case AppUsersConstants.FETCH_FAILURE:
    case AppUsersConstants.EDIT_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      }

    case AppUsersConstants.ADD_FAILURE:
    case AppUsersConstants.VALIDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state
  }
}
