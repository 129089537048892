import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/client'
import * as ApolloReactHooks from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any
  /** The javascript `Date` as string. Type represents the date (`YYYY-MM-DD`) as the ISO Date string */
  ISODate: any
}

export type Adjustment = ProductAttributablePayload & {
  __typename?: 'Adjustment'
  adjustmentType: AdjustmentType
  commission?: Maybe<Scalars['Float']>
  externalAdjustmentId: Scalars['String']
  externalPaymentId?: Maybe<Scalars['String']>
  externalProductId?: Maybe<Scalars['String']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPublisherId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  note?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
  product?: Maybe<Product>
}

export type AdjustmentPaginatedPayload = PaginatedPayload & {
  __typename?: 'AdjustmentPaginatedPayload'
  page?: Maybe<Array<Maybe<Adjustment>>>
  pagination?: Maybe<PaginationPayload>
}

export type AdjustmentSorts = {
  commission?: Maybe<Sort>
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
  processedAt?: Maybe<Sort>
}

export enum AdjustmentType {
  BONUS = 'BONUS',
  CPA = 'CPA',
  REVERSAL = 'REVERSAL',
}

export type Campaign = {
  __typename?: 'Campaign'
  externalCampaignId: Scalars['String']
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
}

export type CampaignPaginatedPayload = PaginatedPayload & {
  __typename?: 'CampaignPaginatedPayload'
  page?: Maybe<Array<Maybe<Campaign>>>
  pagination?: Maybe<PaginationPayload>
}

export type CampaignSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
}

export type Click = {
  __typename?: 'Click'
  browserName?: Maybe<Scalars['String']>
  browserVersion?: Maybe<Scalars['String']>
  clickedAt?: Maybe<Scalars['DateTime']>
  deviceModel?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  deviceVendor?: Maybe<Scalars['String']>
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickId: Scalars['String']
  externalClickedProductId: Scalars['String']
  externalConversionClickId?: Maybe<Scalars['String']>
  externalDisplayedProductId: Scalars['String']
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalReferralClickId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  inlineAnonymousId?: Maybe<Scalars['String']>
  inlineSessionId?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  isReferral?: Maybe<Scalars['Boolean']>
  osName?: Maybe<Scalars['String']>
  osVersion?: Maybe<Scalars['String']>
  pagePath?: Maybe<Scalars['String']>
  placement?: Maybe<Scalars['String']>
  postalState?: Maybe<Scalars['String']>
  referrerUrl?: Maybe<Scalars['String']>
  subTracking1?: Maybe<Scalars['String']>
  subTracking2?: Maybe<Scalars['String']>
  subTracking3?: Maybe<Scalars['String']>
  subTracking4?: Maybe<Scalars['String']>
  subTracking5?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ClickDetailReportingAggregates = {
  __typename?: 'ClickDetailReportingAggregates'
  advertiserId?: Maybe<Scalars['String']>
  advertiserName?: Maybe<Scalars['String']>
  campaignId?: Maybe<Scalars['String']>
  campaignName?: Maybe<Scalars['String']>
  clickDate?: Maybe<Scalars['DateTime']>
  clickId?: Maybe<Scalars['String']>
  clickedProductId?: Maybe<Scalars['String']>
  clickedProductName?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['Float']>
  conversionClickDate?: Maybe<Scalars['DateTime']>
  conversionClickId?: Maybe<Scalars['String']>
  conversionReferrerUrl?: Maybe<Scalars['String']>
  creditBucket?: Maybe<Scalars['String']>
  creditScoreExact?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  displayedProductId?: Maybe<Scalars['String']>
  displayedProductName?: Maybe<Scalars['String']>
  hasReferralClick?: Maybe<Scalars['Boolean']>
  integrationTypeId?: Maybe<Scalars['String']>
  integrationTypeName?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  pagePath?: Maybe<Scalars['String']>
  productTypeId?: Maybe<Scalars['Int']>
  productTypeName?: Maybe<Scalars['String']>
  referrerUrl?: Maybe<Scalars['String']>
  sales?: Maybe<Scalars['Int']>
  state?: Maybe<Scalars['String']>
  subTracking?: Maybe<Scalars['String']>
  subTracking2?: Maybe<Scalars['String']>
  subTracking3?: Maybe<Scalars['String']>
  subTracking4?: Maybe<Scalars['String']>
  subTracking5?: Maybe<Scalars['String']>
  trueConversion?: Maybe<Scalars['Boolean']>
  verticalId?: Maybe<Scalars['String']>
  verticalName?: Maybe<Scalars['String']>
}

export type ClickDetailReportingOverviewPayload = {
  __typename?: 'ClickDetailReportingOverviewPayload'
  totalClickedProductClicks?: Maybe<Scalars['Int']>
  totalCommission?: Maybe<Scalars['Float']>
  totalDisplayProductClicks?: Maybe<Scalars['Int']>
  totalSales?: Maybe<Scalars['Int']>
}

export type ClickDetailReportingPaginatedPayload = {
  __typename?: 'ClickDetailReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<ClickDetailReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
  totals?: Maybe<ClickDetailReportingOverviewPayload>
}

export type ClickDetailReportingSorts = {
  advertiserId?: Maybe<Sort>
  advertiserName?: Maybe<Sort>
  campaignId?: Maybe<Sort>
  campaignName?: Maybe<Sort>
  clickDate?: Maybe<Sort>
  clickId?: Maybe<Sort>
  clickedProductId?: Maybe<Sort>
  clickedProductName?: Maybe<Sort>
  commission?: Maybe<Sort>
  creditBucket?: Maybe<Sort>
  creditScoreExact?: Maybe<Sort>
  deviceType?: Maybe<Sort>
  displayedProductId?: Maybe<Sort>
  displayedProductName?: Maybe<Sort>
  integrationTypeId?: Maybe<Sort>
  integrationTypeName?: Maybe<Sort>
  ipAddress?: Maybe<Sort>
  loanAmount?: Maybe<Sort>
  pagePath?: Maybe<Sort>
  productTypeId?: Maybe<Sort>
  productTypeName?: Maybe<Sort>
  referralClickId?: Maybe<Sort>
  referrerUrl?: Maybe<Sort>
  sales?: Maybe<Sort>
  state?: Maybe<Sort>
  subTracking?: Maybe<Sort>
  trueConversion?: Maybe<Sort>
  verticalId?: Maybe<Sort>
  verticalName?: Maybe<Sort>
}

export type ClickPaginatedPayload = PaginatedPayload & {
  __typename?: 'ClickPaginatedPayload'
  page?: Maybe<Array<Maybe<Click>>>
  pagination?: Maybe<PaginationPayload>
}

export type ClickSorts = {
  clickedAt?: Maybe<Sort>
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
}

export type CreateAdjustmentInput = {
  adjustmentType: AdjustmentType
  commission: Scalars['Float']
  externalAdjustmentId: Scalars['String']
  externalPaymentId?: Maybe<Scalars['String']>
  externalProductId?: Maybe<Scalars['String']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPublisherId?: Maybe<Scalars['String']>
  externalTransactionEventIds?: Maybe<Array<Scalars['String']>>
  note?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
}

export type CreateCampaignInput = {
  externalCampaignId: Scalars['String']
  name: Scalars['String']
}

export type CreateClickInput = {
  browserName?: Maybe<Scalars['String']>
  browserVersion?: Maybe<Scalars['String']>
  clickedAt: Scalars['DateTime']
  deviceModel?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  deviceVendor?: Maybe<Scalars['String']>
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickId: Scalars['String']
  externalClickedProductId: Scalars['String']
  externalDisplayedProductId: Scalars['String']
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalReferralClickId?: Maybe<Scalars['String']>
  inlineAnonymousId?: Maybe<Scalars['String']>
  inlineSessionId?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  isReferral?: Maybe<Scalars['Boolean']>
  osName?: Maybe<Scalars['String']>
  osVersion?: Maybe<Scalars['String']>
  pagePath?: Maybe<Scalars['String']>
  placement?: Maybe<Scalars['String']>
  postalState?: Maybe<Scalars['String']>
  referrerUrl?: Maybe<Scalars['String']>
  subTracking1?: Maybe<Scalars['String']>
  subTracking2?: Maybe<Scalars['String']>
  subTracking3?: Maybe<Scalars['String']>
  subTracking4?: Maybe<Scalars['String']>
  subTracking5?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type CreateIntegrationTypesInput = {
  externalIntegrationTypeId: Scalars['String']
  name: Scalars['String']
}

export type CreatePaymentInput = {
  adjustment: Scalars['Int']
  commission: Scalars['Int']
  externalAdjustmentIds?: Maybe<Array<Scalars['String']>>
  externalPaymentId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalTransactionEventIds?: Maybe<Array<Scalars['String']>>
  note?: Maybe<Scalars['String']>
  processedAt: Scalars['DateTime']
  status: Scalars['String']
  total: Scalars['Int']
}

export type CreateProductInput = {
  externalIntegrationTypeId?: Maybe<Scalars['String']>
  externalProductId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalProgramId?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  isReferral?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  productIngestId?: Maybe<Scalars['String']>
  type?: Maybe<ProductType>
  widgetComponent?: Maybe<Scalars['String']>
}

export type CreateProductPayload = {
  __typename?: 'CreateProductPayload'
  externalIntegrationTypeId?: Maybe<Scalars['String']>
  externalProductId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalProgramId?: Maybe<Scalars['String']>
  internalName?: Maybe<Scalars['String']>
  isReferral?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  productIngestId?: Maybe<Scalars['String']>
  type?: Maybe<ProductType>
  widgetComponent?: Maybe<Scalars['String']>
}

export type CreateProductTypesInput = {
  externalTypeId: Scalars['Int']
  externalVerticalId?: Maybe<Scalars['String']>
  typeName: Scalars['String']
}

export type CreateProductVerticalsInput = {
  externalVerticalId: Scalars['String']
  verticalName: Scalars['String']
}

export type CreateProgramInput = {
  externalProgramId: Scalars['String']
  name: Scalars['String']
}

export type CreatePropertyInput = {
  externalPropertyId: Scalars['String']
  name: Scalars['String']
  status: Scalars['String']
}

export type CreatePropertyProductInput = {
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickedProductId: Scalars['String']
  externalDisplayedProductId: Scalars['String']
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
}

export type CreatePublisherInput = {
  externalPublisherId: Scalars['String']
  name: Scalars['String']
  payoutThreshold?: Maybe<Scalars['Int']>
  status: Scalars['String']
}

export type CreateTransactionInput = {
  affiliateBaseRevenue?: Maybe<Scalars['Int']>
  clickedAt: Scalars['DateTime']
  commission?: Maybe<Scalars['Int']>
  creditBucket?: Maybe<CreditBucketTypeEnum>
  creditScoreExact?: Maybe<Scalars['String']>
  crossSale?: Maybe<Scalars['Boolean']>
  eventType: Scalars['String']
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickId: Scalars['String']
  externalClickedProductId: Scalars['String']
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalReferralClickId?: Maybe<Scalars['String']>
  externalTransactionEventId: Scalars['String']
  loanAmount?: Maybe<Scalars['Int']>
  pagePath?: Maybe<Scalars['String']>
  processedAt: Scalars['DateTime']
  revenueType?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  subTracking1?: Maybe<Scalars['String']>
  subTracking2?: Maybe<Scalars['String']>
  subTracking3?: Maybe<Scalars['String']>
  subTracking4?: Maybe<Scalars['String']>
  subTracking5?: Maybe<Scalars['String']>
  totalRevenue?: Maybe<Scalars['Int']>
  trueConversion?: Maybe<Scalars['Boolean']>
  updatingTransactionEventId?: Maybe<Scalars['String']>
}

export enum CreditBucketTypeEnum {
  EXCELLENT = 'EXCELLENT',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  NONE = 'NONE',
  POOR = 'POOR',
}

export enum DateType {
  CLICKDATE = 'clickDate',
  PROCESSDATE = 'processDate',
}

export type DeleteAdjustmentPayload = {
  __typename?: 'DeleteAdjustmentPayload'
  adjustment?: Maybe<Adjustment>
}

export type DeleteCampaignPayload = {
  __typename?: 'DeleteCampaignPayload'
  campaign?: Maybe<Campaign>
}

export type DeleteClickPayload = {
  __typename?: 'DeleteClickPayload'
  click?: Maybe<Click>
}

export type DeleteIntegrationTypesPayload = {
  __typename?: 'DeleteIntegrationTypesPayload'
  integrationTypes?: Maybe<IntegrationTypes>
}

export type DeletePaymentPayload = {
  __typename?: 'DeletePaymentPayload'
  payment?: Maybe<Payment>
}

export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload'
  product?: Maybe<CreateProductPayload>
}

export type DeleteProductTypesPayload = {
  __typename?: 'DeleteProductTypesPayload'
  productTypes?: Maybe<ProductTypes>
}

export type DeleteProductVerticalsPayload = {
  __typename?: 'DeleteProductVerticalsPayload'
  productVerticals?: Maybe<ProductVerticals>
}

export type DeleteProgramPayload = {
  __typename?: 'DeleteProgramPayload'
  program?: Maybe<Program>
}

export type DeletePropertyPayload = {
  __typename?: 'DeletePropertyPayload'
  property?: Maybe<Property>
}

export type DeletePropertyProductPayload = {
  __typename?: 'DeletePropertyProductPayload'
  propertyProduct?: Maybe<PropertyProduct>
}

export type DeletePublisherPayload = {
  __typename?: 'DeletePublisherPayload'
  publisher?: Maybe<Publisher>
}

export type DeleteTransactionPayload = {
  __typename?: 'DeleteTransactionPayload'
  transaction?: Maybe<Transaction>
}

export enum EventType {
  APP = 'app',
  DEC = 'dec',
  SALE = 'sale',
}

export enum GroupBy {
  BYCAMPAIGN = 'byCampaign',
  BYCLICKEDPRODUCT = 'byClickedProduct',
  BYDATE = 'byDate',
  BYDISPLAYEDPRODUCT = 'byDisplayedProduct',
  BYINTEGRATIONTYPE = 'byIntegrationType',
  BYPRODUCTTYPE = 'byProductType',
  BYPROGRAM = 'byProgram',
  BYSUBTRACKING = 'bySubTracking',
}

export type IntegrationTypes = {
  __typename?: 'IntegrationTypes'
  externalIntegrationTypeId: Scalars['String']
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
}

export type IntegrationTypesPaginatedPayload = PaginatedPayload & {
  __typename?: 'IntegrationTypesPaginatedPayload'
  page?: Maybe<Array<Maybe<IntegrationTypes>>>
  pagination?: Maybe<PaginationPayload>
}

export type IntegrationTypesSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
}

export type Mutation = {
  __typename?: 'Mutation'
  createAdjustment?: Maybe<Adjustment>
  createCampaign?: Maybe<Campaign>
  createClick?: Maybe<Click>
  createIntegrationTypes?: Maybe<IntegrationTypes>
  createPayment?: Maybe<Payment>
  createProduct?: Maybe<CreateProductPayload>
  createProductTypes?: Maybe<ProductTypes>
  createProductVerticals?: Maybe<ProductVerticals>
  createProgram?: Maybe<Program>
  createProperty?: Maybe<Property>
  createPropertyProduct?: Maybe<PropertyProduct>
  createPublisher?: Maybe<Publisher>
  createTransaction?: Maybe<Transaction>
  deleteAdjustment?: Maybe<DeleteAdjustmentPayload>
  deleteCampaign?: Maybe<DeleteCampaignPayload>
  deleteClick?: Maybe<DeleteClickPayload>
  deleteIntegrationTypes?: Maybe<DeleteIntegrationTypesPayload>
  deletePayment?: Maybe<DeletePaymentPayload>
  deleteProduct?: Maybe<DeleteProductPayload>
  deleteProductTypes?: Maybe<DeleteProductTypesPayload>
  deleteProductVerticals?: Maybe<DeleteProductVerticalsPayload>
  deleteProgram?: Maybe<DeleteProgramPayload>
  deleteProperty?: Maybe<DeletePropertyPayload>
  deletePropertyProduct?: Maybe<DeletePropertyProductPayload>
  deletePublisher?: Maybe<DeletePublisherPayload>
  deleteTransaction?: Maybe<DeleteTransactionPayload>
}

export type MutationCreateAdjustmentArgs = {
  adjustment: CreateAdjustmentInput
}

export type MutationCreateCampaignArgs = {
  campaign: CreateCampaignInput
}

export type MutationCreateClickArgs = {
  click: CreateClickInput
}

export type MutationCreateIntegrationTypesArgs = {
  integrationTypes: CreateIntegrationTypesInput
}

export type MutationCreatePaymentArgs = {
  payment: CreatePaymentInput
}

export type MutationCreateProductArgs = {
  product: CreateProductInput
}

export type MutationCreateProductTypesArgs = {
  productTypes: CreateProductTypesInput
}

export type MutationCreateProductVerticalsArgs = {
  productVerticals: CreateProductVerticalsInput
}

export type MutationCreateProgramArgs = {
  program: CreateProgramInput
}

export type MutationCreatePropertyArgs = {
  property: CreatePropertyInput
}

export type MutationCreatePropertyProductArgs = {
  propertyProduct: CreatePropertyProductInput
}

export type MutationCreatePublisherArgs = {
  publisher: CreatePublisherInput
}

export type MutationCreateTransactionArgs = {
  transaction: CreateTransactionInput
}

export type MutationDeleteAdjustmentArgs = {
  externalAdjustmentId: Scalars['String']
}

export type MutationDeleteCampaignArgs = {
  externalCampaignId: Scalars['String']
}

export type MutationDeleteClickArgs = {
  externalClickId: Scalars['String']
}

export type MutationDeleteIntegrationTypesArgs = {
  externalIntegrationTypeId: Scalars['String']
}

export type MutationDeletePaymentArgs = {
  externalPaymentId: Scalars['String']
}

export type MutationDeleteProductArgs = {
  externalProductId: Scalars['String']
}

export type MutationDeleteProductTypesArgs = {
  externalTypeId: Scalars['Int']
}

export type MutationDeleteProductVerticalsArgs = {
  externalVerticalId: Scalars['String']
}

export type MutationDeleteProgramArgs = {
  externalProgramId: Scalars['String']
}

export type MutationDeletePropertyArgs = {
  externalPropertyId: Scalars['String']
}

export type MutationDeletePropertyProductArgs = {
  externalClickedProductId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalPropertyId: Scalars['String']
}

export type MutationDeletePublisherArgs = {
  externalPublisherId: Scalars['String']
}

export type MutationDeleteTransactionArgs = {
  externalTransactionEventId: Scalars['String']
}

export type OutstandingBalanceReportingAggregates = {
  __typename?: 'OutstandingBalanceReportingAggregates'
  adjustments?: Maybe<Scalars['Float']>
  clickId?: Maybe<Scalars['String']>
  clickedProductId?: Maybe<Scalars['String']>
  clickedProductName?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['Float']>
  crossSale?: Maybe<Scalars['Boolean']>
  eventId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
  productId?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Float']>
  verticalName?: Maybe<Scalars['String']>
}

export type OutstandingBalanceReportingPaginatedPayload = {
  __typename?: 'OutstandingBalanceReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<OutstandingBalanceReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
}

export type OutstandingBalanceReportingSorts = {
  clickId?: Maybe<Sort>
  clickedProductName?: Maybe<Sort>
  commission?: Maybe<Sort>
  eventId?: Maybe<Sort>
  processedAt?: Maybe<Sort>
  productName?: Maybe<Sort>
  programName?: Maybe<Sort>
  total?: Maybe<Sort>
  verticalName?: Maybe<Sort>
}

export type OutstandingBalanceReportingSummary = {
  __typename?: 'OutstandingBalanceReportingSummary'
  date?: Maybe<Scalars['String']>
  threshold?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Float']>
  verticalName?: Maybe<Scalars['String']>
}

export type OutstandingBalanceReportingSummaryPayload = {
  __typename?: 'OutstandingBalanceReportingSummaryPayload'
  summary?: Maybe<Array<Maybe<OutstandingBalanceReportingSummary>>>
}

export type OverviewReportingAggregates = {
  __typename?: 'OverviewReportingAggregates'
  commission?: Maybe<Scalars['Float']>
  conversionRate?: Maybe<Scalars['Float']>
  epc?: Maybe<Scalars['Float']>
  productClicks?: Maybe<Scalars['Int']>
  sales?: Maybe<Scalars['Int']>
  totalCTR?: Maybe<Scalars['Float']>
  totalCtr?: Maybe<Scalars['Float']>
  totalImpressions?: Maybe<Scalars['Int']>
  uniqueClicks?: Maybe<Scalars['Int']>
  verticalId?: Maybe<Scalars['String']>
  verticalName?: Maybe<Scalars['String']>
  viewableCTR?: Maybe<Scalars['Float']>
  viewableCtr?: Maybe<Scalars['Float']>
  viewableImpressions?: Maybe<Scalars['Int']>
}

export type OverviewReportingPaginatedPayload = {
  __typename?: 'OverviewReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<OverviewReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
  totals?: Maybe<OverviewReportingSummaryPayload>
}

export type OverviewReportingSorts = {
  commission?: Maybe<Sort>
  conversionRate?: Maybe<Sort>
  epc?: Maybe<Sort>
  productClicks?: Maybe<Sort>
  sales?: Maybe<Sort>
  totalCtr?: Maybe<Sort>
  totalImpressions?: Maybe<Sort>
  uniqueClicks?: Maybe<Sort>
  verticalId?: Maybe<Sort>
  verticalName?: Maybe<Sort>
  viewableCtr?: Maybe<Sort>
  viewableImpressions?: Maybe<Sort>
}

export type OverviewReportingSummaryPayload = {
  __typename?: 'OverviewReportingSummaryPayload'
  totalCommission?: Maybe<Scalars['Float']>
  totalConversionRate?: Maybe<Scalars['Float']>
  totalEpc?: Maybe<Scalars['Float']>
  totalImpressions?: Maybe<Scalars['Int']>
  totalProductClicks?: Maybe<Scalars['Int']>
  totalSales?: Maybe<Scalars['Int']>
  totalUniqueClicks?: Maybe<Scalars['Int']>
  viewableImpressions?: Maybe<Scalars['Int']>
}

export type PaginatedPayload = {
  pagination?: Maybe<PaginationPayload>
}

export type PaginationPayload = {
  __typename?: 'PaginationPayload'
  currentPage?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  totalEntries?: Maybe<Scalars['Int']>
  totalPages?: Maybe<Scalars['Int']>
}

export type Payment = {
  __typename?: 'Payment'
  adjustment?: Maybe<Scalars['Int']>
  commission?: Maybe<Scalars['Int']>
  externalPaymentId: Scalars['String']
  externalPublisherId?: Maybe<Scalars['String']>
  externalTransactionEventIds?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['ID']>
  note?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
  status?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Int']>
}

export type PaymentDetailReportingAggregates = {
  __typename?: 'PaymentDetailReportingAggregates'
  adjustments?: Maybe<Scalars['Float']>
  clickId?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['Float']>
  eventId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
  productId?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
  propertyId?: Maybe<Scalars['String']>
  subTracking1?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Float']>
}

export type PaymentDetailReportingPaginatedPayload = {
  __typename?: 'PaymentDetailReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<PaymentDetailReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
}

export type PaymentDetailReportingSorts = {
  adjustments?: Maybe<Sort>
  clickId?: Maybe<Sort>
  commission?: Maybe<Sort>
  eventId?: Maybe<Sort>
  note?: Maybe<Sort>
  processedAt?: Maybe<Sort>
  productId?: Maybe<Sort>
  productName?: Maybe<Sort>
  programName?: Maybe<Sort>
  propertyId?: Maybe<Sort>
  subTracking1?: Maybe<Sort>
  total?: Maybe<Sort>
}

export type PaymentDetailReportingSummary = {
  __typename?: 'PaymentDetailReportingSummary'
  adjustments?: Maybe<Scalars['Float']>
  bonuses?: Maybe<Scalars['Float']>
  breakdown?: Maybe<Scalars['Float']>
  sales?: Maybe<Scalars['Float']>
}

export type PaymentDetailReportingSummaryPayload = {
  __typename?: 'PaymentDetailReportingSummaryPayload'
  summary?: Maybe<Array<Maybe<PaymentDetailReportingSummary>>>
}

export type PaymentPaginatedPayload = PaginatedPayload & {
  __typename?: 'PaymentPaginatedPayload'
  page?: Maybe<Array<Maybe<Payment>>>
  pagination?: Maybe<PaginationPayload>
}

export type PaymentSorts = {
  adjustment?: Maybe<Sort>
  commission?: Maybe<Sort>
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
  processedAt?: Maybe<Sort>
  status?: Maybe<Sort>
}

export type PaymentsHistoryReportingAggregates = {
  __typename?: 'PaymentsHistoryReportingAggregates'
  adjustment?: Maybe<Scalars['Float']>
  commission?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  paymentId?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
  status?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Float']>
}

export type PaymentsHistoryReportingPaginatedPayload = {
  __typename?: 'PaymentsHistoryReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<PaymentsHistoryReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
}

export type PaymentsHistoryReportingSorts = {
  commission?: Maybe<Sort>
  paymentId?: Maybe<Sort>
  processedAt?: Maybe<Sort>
  status?: Maybe<Sort>
  total?: Maybe<Sort>
}

export type Product = {
  __typename?: 'Product'
  externalIntegrationTypeId?: Maybe<Scalars['String']>
  externalProductId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalProgramId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  integrationType?: Maybe<IntegrationTypes>
  internalName?: Maybe<Scalars['String']>
  isReferral?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  productIngestId?: Maybe<Scalars['String']>
  program?: Maybe<Program>
  type?: Maybe<ProductType>
  widgetComponent?: Maybe<Scalars['String']>
}

export type ProductAttributablePayload = {
  product?: Maybe<Product>
}

export type ProductPaginatedPayload = PaginatedPayload & {
  __typename?: 'ProductPaginatedPayload'
  page?: Maybe<Array<Maybe<Product>>>
  pagination?: Maybe<PaginationPayload>
}

export type ProductSorts = {
  id?: Maybe<Sort>
  name?: Maybe<Sort>
}

export enum ProductType {
  CLICKED = 'CLICKED',
  DISPLAY = 'DISPLAY',
}

export type ProductTypes = {
  __typename?: 'ProductTypes'
  externalTypeId: Scalars['Int']
  externalVerticalId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  typeName: Scalars['String']
}

export type ProductTypesPaginatedPayload = PaginatedPayload & {
  __typename?: 'ProductTypesPaginatedPayload'
  page?: Maybe<Array<Maybe<ProductTypes>>>
  pagination?: Maybe<PaginationPayload>
}

export type ProductTypesSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
}

export type ProductVerticals = {
  __typename?: 'ProductVerticals'
  externalVerticalId: Scalars['String']
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  verticalName: Scalars['String']
}

export type ProductVerticalsPaginatedPayload = PaginatedPayload & {
  __typename?: 'ProductVerticalsPaginatedPayload'
  page?: Maybe<Array<Maybe<ProductVerticals>>>
  pagination?: Maybe<PaginationPayload>
}

export type ProductVerticalsSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
}

export type Program = {
  __typename?: 'Program'
  externalProgramId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type ProgramPaginatedPayload = PaginatedPayload & {
  __typename?: 'ProgramPaginatedPayload'
  page?: Maybe<Array<Maybe<Program>>>
  pagination?: Maybe<PaginationPayload>
}

export type ProgramSorts = {
  id?: Maybe<Sort>
  name?: Maybe<Sort>
}

export type Property = {
  __typename?: 'Property'
  externalPropertyId: Scalars['String']
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  status: Scalars['String']
}

export type PropertyPaginatedPayload = PaginatedPayload & {
  __typename?: 'PropertyPaginatedPayload'
  page?: Maybe<Array<Maybe<Property>>>
  pagination?: Maybe<PaginationPayload>
}

export type PropertyProduct = {
  __typename?: 'PropertyProduct'
  campaignName?: Maybe<Scalars['String']>
  clickedProductName?: Maybe<Scalars['String']>
  displayedProductName?: Maybe<Scalars['String']>
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickedProductId: Scalars['String']
  externalDisplayedProductId: Scalars['String']
  externalProductTypeId?: Maybe<Scalars['String']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalVerticalId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  integrationTypeId?: Maybe<Scalars['String']>
  integrationTypeName?: Maybe<Scalars['String']>
  productTypeName?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
  verticalName?: Maybe<Scalars['String']>
}

export type PropertyProductPaginatedPayload = PaginatedPayload & {
  __typename?: 'PropertyProductPaginatedPayload'
  page?: Maybe<Array<Maybe<PropertyProduct>>>
  pagination?: Maybe<PaginationPayload>
}

export type PropertyProductSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
}

export type PropertySorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
  name?: Maybe<Sort>
}

export type Publisher = {
  __typename?: 'Publisher'
  externalPublisherId: Scalars['String']
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  payoutThreshold?: Maybe<Scalars['Int']>
  status: Scalars['String']
}

export type PublisherPaginatedPayload = PaginatedPayload & {
  __typename?: 'PublisherPaginatedPayload'
  page?: Maybe<Array<Maybe<Publisher>>>
  pagination?: Maybe<PaginationPayload>
}

export type PublisherSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
  name?: Maybe<Sort>
}

export type Query = {
  __typename?: 'Query'
  /** An adjustment is a financial modification to a transaction or affiliate's commission done on our behalf. It is not something requested by an advertiser. */
  adjustment?: Maybe<Adjustment>
  /** An adjustment is a financial modification to a transaction or affiliate's commission done on our behalf. It is not something requested by an advertiser. */
  adjustments?: Maybe<AdjustmentPaginatedPayload>
  /** Campaigns used accross the application */
  campaign?: Maybe<Campaign>
  /** Campaigns used accross the application */
  campaigns?: Maybe<CampaignPaginatedPayload>
  /** A click is when a user has clicked on a referral link for an advertised product. */
  click?: Maybe<Click>
  /** Click Details reporting for Mortgage, Credit Cards, Loans, Deposits, Investing, Health, Insurance, Reatil, Home Equity, Personal Finance and Home Services. */
  clickDetailReporting?: Maybe<ClickDetailReportingPaginatedPayload>
  /** A click is when a user has clicked on a referral link for an advertised product. */
  clicks?: Maybe<ClickPaginatedPayload>
  /** Integration types used for each Product */
  integrationType?: Maybe<IntegrationTypes>
  /** Integration types used for each Product */
  integrationTypes?: Maybe<IntegrationTypesPaginatedPayload>
  /** Balance and Payments report */
  outstandingBalanceReporting?: Maybe<
    OutstandingBalanceReportingPaginatedPayload
  >
  /** Balance and Payments report */
  outstandingBalanceReportingSummary?: Maybe<
    OutstandingBalanceReportingSummaryPayload
  >
  /** Overview reporting of clicks, sales, transactions and impressions */
  overviewReporting?: Maybe<OverviewReportingPaginatedPayload>
  /** A payment is financial compensation paid to an affiliate based on specific transactions and adjustments. */
  payment?: Maybe<Payment>
  /** Payment Details report */
  paymentDetailReporting?: Maybe<PaymentDetailReportingPaginatedPayload>
  /** Payment Details report */
  paymentDetailReportingSummary?: Maybe<PaymentDetailReportingSummaryPayload>
  /** A payment is financial compensation paid to an affiliate based on specific transactions and adjustments. */
  payments?: Maybe<PaymentPaginatedPayload>
  /** Payments summary report */
  paymentsHistoryReporting?: Maybe<PaymentsHistoryReportingPaginatedPayload>
  /** A product is something that can be advertised on affiliate websites usually for financial compensation. */
  product?: Maybe<Product>
  /** Properties from Products table */
  productFamilies?: Maybe<PropertyProductPaginatedPayload>
  /** Product types used for each Product */
  productType?: Maybe<ProductTypes>
  /** Product types used for each Product */
  productTypes?: Maybe<ProductTypesPaginatedPayload>
  /** Product verticals used for each Product */
  productVertical?: Maybe<ProductVerticals>
  /** Product verticals used for each Product */
  productVerticals?: Maybe<ProductVerticalsPaginatedPayload>
  /** A product is something that can be advertised on affiliate websites usually for financial compensation. */
  products?: Maybe<ProductPaginatedPayload>
  /** An program is an external partner that provides us with financial compensation for driving traffic to their products. */
  program?: Maybe<Program>
  /** An program is an external partner that provides us with financial compensation for driving traffic to their products. */
  programs?: Maybe<ProgramPaginatedPayload>
  /** Properties used accross the application */
  properties?: Maybe<PropertyPaginatedPayload>
  /** Properties used accross the application */
  property?: Maybe<Property>
  /** Properties from Products table */
  propertyProduct?: Maybe<PropertyProduct>
  /** Properties from Products table */
  propertyProducts?: Maybe<PropertyProductPaginatedPayload>
  /** Publishers used accross the application */
  publisher?: Maybe<Publisher>
  /** Publishers used accross the application */
  publishers?: Maybe<PublisherPaginatedPayload>
  /** This is a helper endpoint that returns specific portal changes that would be helpful for a user to know about. */
  releaseNote?: Maybe<ReleaseNote>
  /** This is a helper endpoint that returns specific portal changes that would be helpful for a user to know about. */
  releaseNotes?: Maybe<ReleaseNotePaginatedPayload>
  /** _Also known as an approval._ A sale is when a user's application for an advertised product is approved. */
  sale?: Maybe<Sale>
  /** _Also known as an approval._ A sale is when a user's application for an advertised product is approved. */
  sales?: Maybe<SalePaginatedPayload>
  /** This is the main entity where all new events are registered, acceptances, etc. */
  transaction?: Maybe<Transaction>
  /** Transaction details reporting of clicks, campaing and commissions */
  transactionDetailsReporting?: Maybe<
    TransactionDetailsReportingPaginatedPayload
  >
  /** This is the main entity where all new events are registered, acceptances, etc. */
  transactions?: Maybe<TransactionPaginatedPayload>
  /** Vertical summary reporting of clicks, sales, transactions and impressions */
  verticalSummaryReporting?: Maybe<VerticalSummaryReportingPaginatedPayload>
}

export type QueryAdjustmentArgs = {
  externalAdjustmentId: Scalars['String']
}

export type QueryAdjustmentsArgs = {
  externalPaymentId?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<AdjustmentSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryCampaignArgs = {
  externalCampaignId: Scalars['String']
}

export type QueryCampaignsArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<CampaignSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryClickArgs = {
  externalClickId: Scalars['String']
}

export type QueryClickDetailReportingArgs = {
  deviceType?: Maybe<Scalars['String']>
  end: Scalars['DateTime']
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickedProductId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalIntegrationTypeId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalVerticalId?: Maybe<Scalars['String']>
  includeTotals?: Maybe<Scalars['Boolean']>
  onlyReferralClicks?: Maybe<Scalars['Boolean']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ClickDetailReportingSorts>>>
  start: Scalars['DateTime']
  subTracking?: Maybe<Scalars['String']>
  take?: Maybe<Scalars['Int']>
}

export type QueryClicksArgs = {
  end: Scalars['DateTime']
  externalClickedProductId?: Maybe<Scalars['String']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPropertyId?: Maybe<Scalars['Int']>
  externalPublisherId?: Maybe<Scalars['String']>
  externalReferralClickId?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ClickSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryIntegrationTypeArgs = {
  externalIntegrationTypeId: Scalars['String']
}

export type QueryIntegrationTypesArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<IntegrationTypesSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryOutstandingBalanceReportingArgs = {
  externalPublisherId: Scalars['String']
  externalVerticalId?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<OutstandingBalanceReportingSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryOutstandingBalanceReportingSummaryArgs = {
  externalPublisherId: Scalars['String']
}

export type QueryOverviewReportingArgs = {
  end: Scalars['DateTime']
  externalPropertyId: Scalars['String']
  externalPublisherId?: Maybe<Scalars['String']>
  includeImpressions?: Maybe<Scalars['Boolean']>
  includeTotals?: Maybe<Scalars['Boolean']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<OverviewReportingSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryPaymentArgs = {
  externalPaymentId: Scalars['String']
}

export type QueryPaymentDetailReportingArgs = {
  externalPaymentId: Scalars['String']
  externalPublisherId: Scalars['String']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<PaymentDetailReportingSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryPaymentDetailReportingSummaryArgs = {
  externalPaymentId: Scalars['String']
  externalPublisherId: Scalars['String']
}

export type QueryPaymentsArgs = {
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<PaymentSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryPaymentsHistoryReportingArgs = {
  externalPublisherId: Scalars['String']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<PaymentsHistoryReportingSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryProductArgs = {
  externalProductId: Scalars['String']
}

export type QueryProductFamiliesArgs = {
  externalPropertyId?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
}

export type QueryProductTypeArgs = {
  externalTypeId: Scalars['Int']
}

export type QueryProductTypesArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ProductTypesSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryProductVerticalArgs = {
  externalVerticalId: Scalars['String']
}

export type QueryProductVerticalsArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ProductVerticalsSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryProductsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>
  search?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ProductSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryProgramArgs = {
  externalProgramId: Scalars['String']
}

export type QueryProgramsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>
  search?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ProgramSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QueryPropertiesArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<PropertySorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryPropertyArgs = {
  externalPropertyId: Scalars['String']
}

export type QueryPropertyProductArgs = {
  externalClickedProductId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalPropertyId: Scalars['String']
}

export type QueryPropertyProductsArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<PropertyProductSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryPublisherArgs = {
  externalPublisherId: Scalars['String']
}

export type QueryPublishersArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<PublisherSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryReleaseNoteArgs = {
  id: Scalars['Int']
}

export type QueryReleaseNotesArgs = {
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<ReleaseNoteSorts>>>
  take?: Maybe<Scalars['Int']>
}

export type QuerySaleArgs = {
  transactionEventId: Scalars['String']
}

export type QuerySalesArgs = {
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<SaleSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryTransactionArgs = {
  externalTransactionEventId: Scalars['String']
}

export type QueryTransactionDetailsReportingArgs = {
  dateType: DateType
  end: Scalars['DateTime']
  event?: Maybe<EventType>
  externalPropertyId: Scalars['String']
  filters?: Maybe<TransactionDetailsReportingFilters>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<TransactionDetailsReportingSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryTransactionsArgs = {
  end: Scalars['DateTime']
  externalPropertyId?: Maybe<Scalars['Int']>
  externalPublisherId?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<TransactionSorts>>>
  start: Scalars['DateTime']
  take?: Maybe<Scalars['Int']>
}

export type QueryVerticalSummaryReportingArgs = {
  end: Scalars['DateTime']
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickedProductId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPropertyId: Scalars['String']
  externalPublisherId?: Maybe<Scalars['String']>
  externalVerticalId: Scalars['String']
  groupBy: GroupBy
  includeImpressions?: Maybe<Scalars['Boolean']>
  includeTotals?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Float']>
  pagePath?: Maybe<Scalars['String']>
  skip?: Maybe<Scalars['Int']>
  sorts?: Maybe<Array<Maybe<VerticalSummaryReportingSorts>>>
  start: Scalars['DateTime']
  subTracking?: Maybe<Scalars['String']>
  take?: Maybe<Scalars['Int']>
}

export type ReleaseNote = {
  __typename?: 'ReleaseNote'
  id?: Maybe<Scalars['ID']>
  insertedAt?: Maybe<Scalars['DateTime']>
  markdownContent?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ReleaseNotePaginatedPayload = PaginatedPayload & {
  __typename?: 'ReleaseNotePaginatedPayload'
  page?: Maybe<Array<Maybe<ReleaseNote>>>
  pagination?: Maybe<PaginationPayload>
}

export type ReleaseNoteSorts = {
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
  title?: Maybe<Sort>
}

export type Sale = {
  __typename?: 'Sale'
  affiliateBaseRevenue?: Maybe<Scalars['Int']>
  commission?: Maybe<Scalars['Int']>
  externalAdjustmentId?: Maybe<Scalars['String']>
  externalPaymentId?: Maybe<Scalars['String']>
  externalTransactionEventId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  totalRevenue?: Maybe<Scalars['Int']>
}

export type SalePaginatedPayload = PaginatedPayload & {
  __typename?: 'SalePaginatedPayload'
  page?: Maybe<Array<Maybe<Sale>>>
  pagination?: Maybe<PaginationPayload>
}

export type SaleSorts = {
  affiliateBaseRevenue?: Maybe<Sort>
  commission?: Maybe<Sort>
  id?: Maybe<Sort>
  totalRevenue?: Maybe<Sort>
  updatedAt?: Maybe<Sort>
}

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Transaction = {
  __typename?: 'Transaction'
  clickedAt: Scalars['DateTime']
  creditBucket?: Maybe<CreditBucketTypeEnum>
  creditScoreExact?: Maybe<Scalars['String']>
  crossSale?: Maybe<Scalars['Boolean']>
  eventType: Scalars['String']
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickId: Scalars['String']
  externalClickedProductId: Scalars['String']
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalReferralClickId?: Maybe<Scalars['String']>
  externalTransactionEventId: Scalars['String']
  id?: Maybe<Scalars['ID']>
  insertedAt: Scalars['DateTime']
  loanAmount?: Maybe<Scalars['Int']>
  pagePath?: Maybe<Scalars['String']>
  processedAt: Scalars['DateTime']
  revenueType?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  subTracking1?: Maybe<Scalars['String']>
  subTracking2?: Maybe<Scalars['String']>
  subTracking3?: Maybe<Scalars['String']>
  subTracking4?: Maybe<Scalars['String']>
  subTracking5?: Maybe<Scalars['String']>
  trueConversion?: Maybe<Scalars['Boolean']>
}

export type TransactionDetailsReportingAggregates = {
  __typename?: 'TransactionDetailsReportingAggregates'
  campaignName?: Maybe<Scalars['String']>
  clickedAt?: Maybe<Scalars['DateTime']>
  clickedProductName?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['Float']>
  creditBucket?: Maybe<Scalars['String']>
  creditScoreExact?: Maybe<Scalars['String']>
  crossSale?: Maybe<Scalars['Boolean']>
  deviceType?: Maybe<Scalars['String']>
  displayedProductName?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickId?: Maybe<Scalars['String']>
  externalClickedProductId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalPaymentId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['String']>
  externalPropertyId?: Maybe<Scalars['String']>
  externalPublisherId?: Maybe<Scalars['String']>
  integrationTypeId?: Maybe<Scalars['String']>
  integrationTypeName?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  page?: Maybe<Scalars['String']>
  postalState?: Maybe<Scalars['String']>
  processedAt?: Maybe<Scalars['DateTime']>
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
  referrerUrl?: Maybe<Scalars['String']>
  saleId?: Maybe<Scalars['String']>
  subTracking?: Maybe<Scalars['String']>
  subTracking2?: Maybe<Scalars['String']>
  subTracking3?: Maybe<Scalars['String']>
  subTracking4?: Maybe<Scalars['String']>
  subTracking5?: Maybe<Scalars['String']>
  trueConversion?: Maybe<Scalars['Boolean']>
  typeName?: Maybe<Scalars['String']>
  verticalId?: Maybe<Scalars['String']>
  verticalName?: Maybe<Scalars['String']>
}

export type TransactionDetailsReportingFilters = {
  deviceType?: Maybe<Scalars['String']>
  externalCampaignId?: Maybe<Scalars['String']>
  externalClickedProductId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalIntegrationTypeId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['String']>
  externalProgramId?: Maybe<Scalars['String']>
  externalPublisherId?: Maybe<Scalars['String']>
  externalVerticalId?: Maybe<Scalars['String']>
  subTracking?: Maybe<Scalars['String']>
}

export type TransactionDetailsReportingPaginatedPayload = {
  __typename?: 'TransactionDetailsReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<TransactionDetailsReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
}

export type TransactionDetailsReportingSorts = {
  campaignName?: Maybe<Sort>
  clickedAt?: Maybe<Sort>
  clickedProductName?: Maybe<Sort>
  commission?: Maybe<Sort>
  creditBucket?: Maybe<Sort>
  creditScoreExact?: Maybe<Sort>
  crossSale?: Maybe<Sort>
  deviceType?: Maybe<Sort>
  displayedProductName?: Maybe<Sort>
  eventType?: Maybe<Sort>
  externalCampaignId?: Maybe<Sort>
  externalClickId?: Maybe<Sort>
  externalClickedProductId?: Maybe<Sort>
  externalDisplayedProductId?: Maybe<Sort>
  externalPaymentId?: Maybe<Sort>
  externalProductTypeId?: Maybe<Sort>
  externalPropertyId?: Maybe<Sort>
  externalPublisherId?: Maybe<Sort>
  integrationTypeId?: Maybe<Sort>
  integrationTypeName?: Maybe<Sort>
  ipAddress?: Maybe<Sort>
  loanAmount?: Maybe<Sort>
  page?: Maybe<Sort>
  postalState?: Maybe<Sort>
  processedAt?: Maybe<Sort>
  programId?: Maybe<Sort>
  programName?: Maybe<Sort>
  referrerUrl?: Maybe<Sort>
  saleId?: Maybe<Sort>
  subTracking?: Maybe<Sort>
  trueConversion?: Maybe<Sort>
  typeName?: Maybe<Sort>
  verticalId?: Maybe<Sort>
  verticalName?: Maybe<Sort>
}

export type TransactionPaginatedPayload = PaginatedPayload & {
  __typename?: 'TransactionPaginatedPayload'
  page?: Maybe<Array<Maybe<Transaction>>>
  pagination?: Maybe<PaginationPayload>
}

export type TransactionSorts = {
  clickedAt?: Maybe<Sort>
  id?: Maybe<Sort>
  insertedAt?: Maybe<Sort>
  processedAt?: Maybe<Sort>
}

export type VerticalSummaryReportingAggregates = {
  __typename?: 'VerticalSummaryReportingAggregates'
  campaignId?: Maybe<Scalars['String']>
  campaignName?: Maybe<Scalars['String']>
  clickedProductId?: Maybe<Scalars['String']>
  clickedProductName?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['Float']>
  conversionRate?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['String']>
  displayedProductId?: Maybe<Scalars['String']>
  displayedProductName?: Maybe<Scalars['String']>
  epc?: Maybe<Scalars['Float']>
  integrationTypeId?: Maybe<Scalars['String']>
  integrationTypeName?: Maybe<Scalars['String']>
  productClicks?: Maybe<Scalars['Int']>
  productTypeId?: Maybe<Scalars['String']>
  productTypeName?: Maybe<Scalars['String']>
  programId?: Maybe<Scalars['String']>
  programName?: Maybe<Scalars['String']>
  sales?: Maybe<Scalars['Int']>
  subTracking?: Maybe<Scalars['String']>
  totalApplications?: Maybe<Scalars['Int']>
  totalCTR?: Maybe<Scalars['Float']>
  totalCtr?: Maybe<Scalars['Float']>
  totalDeclines?: Maybe<Scalars['Int']>
  totalImpressions?: Maybe<Scalars['Int']>
  uniqueClicks?: Maybe<Scalars['Int']>
  viewableCTR?: Maybe<Scalars['Float']>
  viewableCtr?: Maybe<Scalars['Float']>
  viewableImpressions?: Maybe<Scalars['Int']>
}

export type VerticalSummaryReportingPaginatedPayload = {
  __typename?: 'VerticalSummaryReportingPaginatedPayload'
  page?: Maybe<Array<Maybe<VerticalSummaryReportingAggregates>>>
  pagination?: Maybe<PaginationPayload>
  totals?: Maybe<VerticalSummaryReportingTotalsPayload>
}

export type VerticalSummaryReportingSorts = {
  campaignId?: Maybe<Sort>
  campaignName?: Maybe<Sort>
  clickedProductId?: Maybe<Sort>
  clickedProductName?: Maybe<Sort>
  commission?: Maybe<Sort>
  conversionRate?: Maybe<Sort>
  day?: Maybe<Sort>
  displayedProductId?: Maybe<Sort>
  displayedProductName?: Maybe<Sort>
  epc?: Maybe<Sort>
  integrationTypeId?: Maybe<Sort>
  integrationTypeName?: Maybe<Sort>
  productClicks?: Maybe<Sort>
  productTypeId?: Maybe<Sort>
  productTypeName?: Maybe<Sort>
  programId?: Maybe<Sort>
  programName?: Maybe<Sort>
  sales?: Maybe<Sort>
  subTracking?: Maybe<Sort>
  totalApplications?: Maybe<Sort>
  totalCtr?: Maybe<Sort>
  totalDeclines?: Maybe<Sort>
  totalImpressions?: Maybe<Sort>
  uniqueClicks?: Maybe<Sort>
  viewableCtr?: Maybe<Sort>
  viewableImpressions?: Maybe<Sort>
}

export type VerticalSummaryReportingTotalsPayload = {
  __typename?: 'VerticalSummaryReportingTotalsPayload'
  totalAllApplications?: Maybe<Scalars['Int']>
  totalAllDeclines?: Maybe<Scalars['Int']>
  totalCommission?: Maybe<Scalars['Float']>
  totalConversionRate?: Maybe<Scalars['Float']>
  totalEpc?: Maybe<Scalars['Float']>
  totalImpressions?: Maybe<Scalars['Int']>
  totalProductClicks?: Maybe<Scalars['Int']>
  totalSales?: Maybe<Scalars['Int']>
  totalUniqueClicks?: Maybe<Scalars['Int']>
  viewableImpressions?: Maybe<Scalars['Int']>
}

export type GetProductsByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type GetProductsByIdsQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'ProductPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Product' } & Pick<
              Product,
              'name' | 'id' | 'externalProductId'
            >
          >
        >
      >
    }
  >
}

export type SearchProductsQueryVariables = Exact<{
  search: Scalars['String']
}>

export type SearchProductsQuery = { __typename?: 'Query' } & {
  products?: Maybe<
    { __typename?: 'ProductPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Product' } & Pick<
              Product,
              'name' | 'id' | 'externalProductId'
            >
          >
        >
      >
    }
  >
}

export type GetReleaseNotesQueryVariables = Exact<{
  sorts?: Maybe<Array<ReleaseNoteSorts> | ReleaseNoteSorts>
}>

export type GetReleaseNotesQuery = { __typename?: 'Query' } & {
  releaseNotes?: Maybe<
    { __typename?: 'ReleaseNotePaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ReleaseNote' } & Pick<
              ReleaseNote,
              'id' | 'title' | 'markdownContent' | 'insertedAt'
            >
          >
        >
      >
    }
  >
}

export type GetLatestReleaseNoteQueryVariables = Exact<{ [key: string]: never }>

export type GetLatestReleaseNoteQuery = { __typename?: 'Query' } & {
  releaseNotes?: Maybe<
    { __typename?: 'ReleaseNotePaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ReleaseNote' } & Pick<
              ReleaseNote,
              'id' | 'title' | 'markdownContent' | 'insertedAt'
            >
          >
        >
      >
    }
  >
}

export type ClickDetailReportingQueryVariables = Exact<{
  start: Scalars['DateTime']
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  sorts?: Maybe<
    Array<Maybe<ClickDetailReportingSorts>> | Maybe<ClickDetailReportingSorts>
  >
  externalPropertyId: Scalars['String']
  externalPublisherId: Scalars['String']
  externalVerticalId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalProgramId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalClickedProductId?: Maybe<Scalars['String']>
  externalCampaignId?: Maybe<Scalars['String']>
  subTracking?: Maybe<Scalars['String']>
  onlyReferralClicks?: Maybe<Scalars['Boolean']>
  includeTotals?: Maybe<Scalars['Boolean']>
  deviceType?: Maybe<Scalars['String']>
}>

export type ClickDetailReportingQuery = { __typename?: 'Query' } & {
  clickDetailReporting?: Maybe<
    { __typename?: 'ClickDetailReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ClickDetailReportingAggregates' } & Pick<
              ClickDetailReportingAggregates,
              | 'clickId'
              | 'clickDate'
              | 'clickedProductId'
              | 'clickedProductName'
              | 'displayedProductId'
              | 'displayedProductName'
              | 'productTypeId'
              | 'productTypeName'
              | 'integrationTypeId'
              | 'integrationTypeName'
              | 'advertiserId'
              | 'advertiserName'
              | 'campaignId'
              | 'campaignName'
              | 'subTracking'
              | 'subTracking2'
              | 'subTracking3'
              | 'subTracking4'
              | 'subTracking5'
              | 'pagePath'
              | 'state'
              | 'loanAmount'
              | 'creditScoreExact'
              | 'creditBucket'
              | 'deviceType'
              | 'sales'
              | 'commission'
              | 'hasReferralClick'
              | 'trueConversion'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
      totals?: Maybe<
        { __typename?: 'ClickDetailReportingOverviewPayload' } & Pick<
          ClickDetailReportingOverviewPayload,
          | 'totalDisplayProductClicks'
          | 'totalClickedProductClicks'
          | 'totalSales'
          | 'totalCommission'
        >
      >
    }
  >
}

export type ProductFamiliesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  externalPropertyId?: Maybe<Scalars['String']>
}>

export type ProductFamiliesQuery = { __typename?: 'Query' } & {
  productFamilies?: Maybe<
    { __typename?: 'PropertyProductPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'PropertyProduct' } & Pick<
              PropertyProduct,
              | 'externalPublisherId'
              | 'externalPropertyId'
              | 'externalCampaignId'
              | 'externalDisplayedProductId'
              | 'externalClickedProductId'
              | 'campaignName'
              | 'externalProductTypeId'
              | 'productTypeName'
              | 'externalProgramId'
              | 'programName'
              | 'externalVerticalId'
              | 'verticalName'
              | 'clickedProductName'
              | 'displayedProductName'
              | 'integrationTypeName'
              | 'integrationTypeId'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
    }
  >
}

export type OverviewReportingQueryVariables = Exact<{
  start: Scalars['DateTime']
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  sorts?: Maybe<
    Array<Maybe<OverviewReportingSorts>> | Maybe<OverviewReportingSorts>
  >
  externalPropertyId: Scalars['String']
  externalPublisherId?: Maybe<Scalars['String']>
  includeImpressions?: Maybe<Scalars['Boolean']>
  includeTotals?: Maybe<Scalars['Boolean']>
}>

export type OverviewReportingQuery = { __typename?: 'Query' } & {
  overviewReporting?: Maybe<
    { __typename?: 'OverviewReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'OverviewReportingAggregates' } & Pick<
              OverviewReportingAggregates,
              | 'verticalId'
              | 'verticalName'
              | 'productClicks'
              | 'uniqueClicks'
              | 'sales'
              | 'commission'
              | 'conversionRate'
              | 'epc'
              | 'totalImpressions'
              | 'viewableImpressions'
              | 'totalCtr'
              | 'viewableCtr'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
      totals?: Maybe<
        { __typename?: 'OverviewReportingSummaryPayload' } & Pick<
          OverviewReportingSummaryPayload,
          | 'totalProductClicks'
          | 'totalUniqueClicks'
          | 'totalSales'
          | 'totalCommission'
          | 'totalConversionRate'
          | 'totalEpc'
          | 'totalImpressions'
          | 'viewableImpressions'
        >
      >
    }
  >
}

export type PaymentDetailReportingQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  sorts?: Maybe<
    | Array<Maybe<PaymentDetailReportingSorts>>
    | Maybe<PaymentDetailReportingSorts>
  >
  externalPaymentId: Scalars['String']
  externalPublisherId: Scalars['String']
}>

export type PaymentDetailReportingQuery = { __typename?: 'Query' } & {
  paymentDetailReporting?: Maybe<
    { __typename?: 'PaymentDetailReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'PaymentDetailReportingAggregates' } & Pick<
              PaymentDetailReportingAggregates,
              | 'clickId'
              | 'eventId'
              | 'processedAt'
              | 'productName'
              | 'programName'
              | 'commission'
              | 'adjustments'
              | 'total'
              | 'programId'
              | 'note'
              | 'productId'
              | 'propertyId'
              | 'subTracking1'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
    }
  >
}

export type PaymentDetailReportingSummaryQueryVariables = Exact<{
  externalPaymentId: Scalars['String']
  externalPublisherId: Scalars['String']
}>

export type PaymentDetailReportingSummaryQuery = { __typename?: 'Query' } & {
  paymentDetailReportingSummary?: Maybe<
    { __typename?: 'PaymentDetailReportingSummaryPayload' } & {
      summary?: Maybe<
        Array<
          Maybe<
            { __typename?: 'PaymentDetailReportingSummary' } & Pick<
              PaymentDetailReportingSummary,
              'breakdown' | 'sales' | 'adjustments' | 'bonuses'
            >
          >
        >
      >
    }
  >
}

export type PaymentsHistoryReportingQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  sorts?: Maybe<
    | Array<Maybe<PaymentsHistoryReportingSorts>>
    | Maybe<PaymentsHistoryReportingSorts>
  >
  externalPublisherId: Scalars['String']
}>

export type PaymentsHistoryReportingQuery = { __typename?: 'Query' } & {
  paymentsHistoryReporting?: Maybe<
    { __typename?: 'PaymentsHistoryReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'PaymentsHistoryReportingAggregates' } & Pick<
              PaymentsHistoryReportingAggregates,
              'paymentId' | 'processedAt' | 'total' | 'status'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
    }
  >
}

export type OutstandingBalanceReportingQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  sorts?: Maybe<
    | Array<Maybe<OutstandingBalanceReportingSorts>>
    | Maybe<OutstandingBalanceReportingSorts>
  >
  externalPublisherId: Scalars['String']
}>

export type OutstandingBalanceReportingQuery = { __typename?: 'Query' } & {
  outstandingBalanceReporting?: Maybe<
    { __typename?: 'OutstandingBalanceReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'OutstandingBalanceReportingAggregates' } & Pick<
              OutstandingBalanceReportingAggregates,
              | 'clickId'
              | 'processedAt'
              | 'verticalName'
              | 'productName'
              | 'programName'
              | 'commission'
              | 'adjustments'
              | 'total'
              | 'crossSale'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
    }
  >
}

export type OutstandingBalanceReportingSummaryQueryVariables = Exact<{
  externalPublisherId: Scalars['String']
}>

export type OutstandingBalanceReportingSummaryQuery = {
  __typename?: 'Query'
} & {
  outstandingBalanceReportingSummary?: Maybe<
    { __typename?: 'OutstandingBalanceReportingSummaryPayload' } & {
      summary?: Maybe<
        Array<
          Maybe<
            { __typename?: 'OutstandingBalanceReportingSummary' } & Pick<
              OutstandingBalanceReportingSummary,
              'date' | 'total' | 'verticalName' | 'threshold'
            >
          >
        >
      >
    }
  >
}

export type TransactionDetailsReportingQueryVariables = Exact<{
  start: Scalars['DateTime']
  end: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  sorts?: Maybe<
    | Array<Maybe<TransactionDetailsReportingSorts>>
    | Maybe<TransactionDetailsReportingSorts>
  >
  externalPropertyId: Scalars['String']
  dateType: DateType
  filters?: Maybe<TransactionDetailsReportingFilters>
  event: EventType
}>

export type TransactionDetailsReportingQuery = { __typename?: 'Query' } & {
  transactionDetailsReporting?: Maybe<
    { __typename?: 'TransactionDetailsReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TransactionDetailsReportingAggregates' } & Pick<
              TransactionDetailsReportingAggregates,
              | 'clickedAt'
              | 'commission'
              | 'creditBucket'
              | 'crossSale'
              | 'eventType'
              | 'externalClickId'
              | 'externalPaymentId'
              | 'externalPropertyId'
              | 'externalPublisherId'
              | 'externalDisplayedProductId'
              | 'externalClickedProductId'
              | 'externalProductTypeId'
              | 'saleId'
              | 'ipAddress'
              | 'loanAmount'
              | 'creditScoreExact'
              | 'deviceType'
              | 'page'
              | 'postalState'
              | 'processedAt'
              | 'clickedProductName'
              | 'displayedProductName'
              | 'programName'
              | 'campaignName'
              | 'integrationTypeId'
              | 'integrationTypeName'
              | 'subTracking'
              | 'subTracking2'
              | 'subTracking3'
              | 'subTracking4'
              | 'subTracking5'
              | 'typeName'
              | 'verticalName'
              | 'trueConversion'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalPages' | 'totalEntries'
        >
      >
    }
  >
}

export type VerticalSummaryReportingQueryVariables = Exact<{
  end: Scalars['DateTime']
  start: Scalars['DateTime']
  skip?: Maybe<Scalars['Int']>
  take?: Maybe<Scalars['Int']>
  subTracking?: Maybe<Scalars['String']>
  sorts?: Maybe<
    | Array<Maybe<VerticalSummaryReportingSorts>>
    | Maybe<VerticalSummaryReportingSorts>
  >
  groupBy: GroupBy
  externalVerticalId: Scalars['String']
  externalPropertyId: Scalars['String']
  externalPublisherId?: Maybe<Scalars['String']>
  externalProductTypeId?: Maybe<Scalars['Int']>
  externalCampaignId?: Maybe<Scalars['String']>
  externalProgramId?: Maybe<Scalars['String']>
  externalDisplayedProductId?: Maybe<Scalars['String']>
  externalClickedProductId?: Maybe<Scalars['String']>
  pagePath?: Maybe<Scalars['String']>
  includeImpressions?: Maybe<Scalars['Boolean']>
  includeTotals?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Float']>
}>

export type VerticalSummaryReportingQuery = { __typename?: 'Query' } & {
  verticalSummaryReporting?: Maybe<
    { __typename?: 'VerticalSummaryReportingPaginatedPayload' } & {
      page?: Maybe<
        Array<
          Maybe<
            { __typename?: 'VerticalSummaryReportingAggregates' } & Pick<
              VerticalSummaryReportingAggregates,
              | 'productTypeId'
              | 'clickedProductId'
              | 'displayedProductId'
              | 'campaignId'
              | 'programId'
              | 'subTracking'
              | 'day'
              | 'programName'
              | 'campaignName'
              | 'productTypeName'
              | 'clickedProductName'
              | 'displayedProductName'
              | 'integrationTypeId'
              | 'integrationTypeName'
              | 'productClicks'
              | 'uniqueClicks'
              | 'sales'
              | 'commission'
              | 'conversionRate'
              | 'epc'
              | 'totalImpressions'
              | 'viewableImpressions'
              | 'totalCtr'
              | 'viewableCtr'
              | 'totalApplications'
              | 'totalDeclines'
            >
          >
        >
      >
      pagination?: Maybe<
        { __typename?: 'PaginationPayload' } & Pick<
          PaginationPayload,
          'currentPage' | 'pageSize' | 'totalEntries' | 'totalPages'
        >
      >
      totals?: Maybe<
        { __typename?: 'VerticalSummaryReportingTotalsPayload' } & Pick<
          VerticalSummaryReportingTotalsPayload,
          | 'totalProductClicks'
          | 'totalUniqueClicks'
          | 'totalSales'
          | 'totalCommission'
          | 'totalConversionRate'
          | 'totalEpc'
          | 'totalAllApplications'
          | 'totalAllDeclines'
          | 'totalImpressions'
          | 'viewableImpressions'
        >
      >
    }
  >
}

export const GetProductsByIdsDocument = gql`
  query GetProductsByIds($ids: [String!]) {
    products(ids: $ids) {
      page {
        name
        id
        externalProductId
      }
    }
  }
`

/**
 * __useGetProductsByIdsQuery__
 *
 * To run a query within a React component, call `useGetProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetProductsByIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >(GetProductsByIdsDocument, baseOptions)
}
export function useGetProductsByIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >(GetProductsByIdsDocument, baseOptions)
}
export type GetProductsByIdsQueryHookResult = ReturnType<
  typeof useGetProductsByIdsQuery
>
export type GetProductsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetProductsByIdsLazyQuery
>
export type GetProductsByIdsQueryResult = ApolloReactCommon.QueryResult<
  GetProductsByIdsQuery,
  GetProductsByIdsQueryVariables
>
export const SearchProductsDocument = gql`
  query SearchProducts($search: String!) {
    products(search: $search) {
      page {
        name
        id
        externalProductId
      }
    }
  }
`

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchProductsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >(SearchProductsDocument, baseOptions)
}
export function useSearchProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >(SearchProductsDocument, baseOptions)
}
export type SearchProductsQueryHookResult = ReturnType<
  typeof useSearchProductsQuery
>
export type SearchProductsLazyQueryHookResult = ReturnType<
  typeof useSearchProductsLazyQuery
>
export type SearchProductsQueryResult = ApolloReactCommon.QueryResult<
  SearchProductsQuery,
  SearchProductsQueryVariables
>
export const GetReleaseNotesDocument = gql`
  query GetReleaseNotes($sorts: [ReleaseNoteSorts!]) {
    releaseNotes(sorts: $sorts) {
      page {
        id
        title
        markdownContent
        insertedAt
      }
    }
  }
`

/**
 * __useGetReleaseNotesQuery__
 *
 * To run a query within a React component, call `useGetReleaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseNotesQuery({
 *   variables: {
 *      sorts: // value for 'sorts'
 *   },
 * });
 */
export function useGetReleaseNotesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetReleaseNotesQuery,
    GetReleaseNotesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetReleaseNotesQuery,
    GetReleaseNotesQueryVariables
  >(GetReleaseNotesDocument, baseOptions)
}
export function useGetReleaseNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReleaseNotesQuery,
    GetReleaseNotesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetReleaseNotesQuery,
    GetReleaseNotesQueryVariables
  >(GetReleaseNotesDocument, baseOptions)
}
export type GetReleaseNotesQueryHookResult = ReturnType<
  typeof useGetReleaseNotesQuery
>
export type GetReleaseNotesLazyQueryHookResult = ReturnType<
  typeof useGetReleaseNotesLazyQuery
>
export type GetReleaseNotesQueryResult = ApolloReactCommon.QueryResult<
  GetReleaseNotesQuery,
  GetReleaseNotesQueryVariables
>
export const GetLatestReleaseNoteDocument = gql`
  query GetLatestReleaseNote {
    releaseNotes(take: 1, sorts: [{ insertedAt: DESC }]) {
      page {
        id
        title
        markdownContent
        insertedAt
      }
    }
  }
`

/**
 * __useGetLatestReleaseNoteQuery__
 *
 * To run a query within a React component, call `useGetLatestReleaseNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestReleaseNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestReleaseNoteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestReleaseNoteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLatestReleaseNoteQuery,
    GetLatestReleaseNoteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLatestReleaseNoteQuery,
    GetLatestReleaseNoteQueryVariables
  >(GetLatestReleaseNoteDocument, baseOptions)
}
export function useGetLatestReleaseNoteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLatestReleaseNoteQuery,
    GetLatestReleaseNoteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLatestReleaseNoteQuery,
    GetLatestReleaseNoteQueryVariables
  >(GetLatestReleaseNoteDocument, baseOptions)
}
export type GetLatestReleaseNoteQueryHookResult = ReturnType<
  typeof useGetLatestReleaseNoteQuery
>
export type GetLatestReleaseNoteLazyQueryHookResult = ReturnType<
  typeof useGetLatestReleaseNoteLazyQuery
>
export type GetLatestReleaseNoteQueryResult = ApolloReactCommon.QueryResult<
  GetLatestReleaseNoteQuery,
  GetLatestReleaseNoteQueryVariables
>
export const ClickDetailReportingDocument = gql`
  query clickDetailReporting(
    $start: DateTime!
    $end: DateTime!
    $skip: Int
    $take: Int
    $sorts: [ClickDetailReportingSorts]
    $externalPropertyId: String!
    $externalPublisherId: String!
    $externalVerticalId: String
    $externalProductTypeId: Int
    $externalProgramId: String
    $externalDisplayedProductId: String
    $externalClickedProductId: String
    $externalCampaignId: String
    $subTracking: String
    $onlyReferralClicks: Boolean
    $includeTotals: Boolean
    $deviceType: String
  ) {
    clickDetailReporting(
      start: $start
      end: $end
      skip: $skip
      take: $take
      sorts: $sorts
      externalPropertyId: $externalPropertyId
      externalPublisherId: $externalPublisherId
      externalVerticalId: $externalVerticalId
      externalProductTypeId: $externalProductTypeId
      externalProgramId: $externalProgramId
      externalDisplayedProductId: $externalDisplayedProductId
      externalClickedProductId: $externalClickedProductId
      externalCampaignId: $externalCampaignId
      subTracking: $subTracking
      onlyReferralClicks: $onlyReferralClicks
      includeTotals: $includeTotals
      deviceType: $deviceType
    ) {
      page {
        clickId
        clickDate
        clickedProductId
        clickedProductName
        displayedProductId
        displayedProductName
        productTypeId
        productTypeName
        integrationTypeId
        integrationTypeName
        advertiserId
        advertiserName
        campaignId
        campaignName
        subTracking
        subTracking2
        subTracking3
        subTracking4
        subTracking5
        pagePath
        state
        loanAmount
        creditScoreExact
        creditBucket
        deviceType
        sales
        commission
        hasReferralClick
        trueConversion
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
      totals {
        totalDisplayProductClicks
        totalClickedProductClicks
        totalSales
        totalCommission
      }
    }
  }
`

/**
 * __useClickDetailReportingQuery__
 *
 * To run a query within a React component, call `useClickDetailReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickDetailReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickDetailReportingQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sorts: // value for 'sorts'
 *      externalPropertyId: // value for 'externalPropertyId'
 *      externalPublisherId: // value for 'externalPublisherId'
 *      externalVerticalId: // value for 'externalVerticalId'
 *      externalProductTypeId: // value for 'externalProductTypeId'
 *      externalProgramId: // value for 'externalProgramId'
 *      externalDisplayedProductId: // value for 'externalDisplayedProductId'
 *      externalClickedProductId: // value for 'externalClickedProductId'
 *      externalCampaignId: // value for 'externalCampaignId'
 *      subTracking: // value for 'subTracking'
 *      onlyReferralClicks: // value for 'onlyReferralClicks'
 *      includeTotals: // value for 'includeTotals'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useClickDetailReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClickDetailReportingQuery,
    ClickDetailReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClickDetailReportingQuery,
    ClickDetailReportingQueryVariables
  >(ClickDetailReportingDocument, baseOptions)
}
export function useClickDetailReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClickDetailReportingQuery,
    ClickDetailReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ClickDetailReportingQuery,
    ClickDetailReportingQueryVariables
  >(ClickDetailReportingDocument, baseOptions)
}
export type ClickDetailReportingQueryHookResult = ReturnType<
  typeof useClickDetailReportingQuery
>
export type ClickDetailReportingLazyQueryHookResult = ReturnType<
  typeof useClickDetailReportingLazyQuery
>
export type ClickDetailReportingQueryResult = ApolloReactCommon.QueryResult<
  ClickDetailReportingQuery,
  ClickDetailReportingQueryVariables
>
export const ProductFamiliesDocument = gql`
  query productFamilies($skip: Int, $take: Int, $externalPropertyId: String) {
    productFamilies(
      skip: $skip
      take: $take
      externalPropertyId: $externalPropertyId
    ) {
      page {
        externalPublisherId
        externalPropertyId
        externalCampaignId
        externalDisplayedProductId
        externalClickedProductId
        campaignName
        externalProductTypeId
        productTypeName
        externalProgramId
        programName
        externalVerticalId
        verticalName
        clickedProductName
        displayedProductName
        integrationTypeName
        integrationTypeId
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
    }
  }
`

/**
 * __useProductFamiliesQuery__
 *
 * To run a query within a React component, call `useProductFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFamiliesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      externalPropertyId: // value for 'externalPropertyId'
 *   },
 * });
 */
export function useProductFamiliesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProductFamiliesQuery,
    ProductFamiliesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProductFamiliesQuery,
    ProductFamiliesQueryVariables
  >(ProductFamiliesDocument, baseOptions)
}
export function useProductFamiliesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProductFamiliesQuery,
    ProductFamiliesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProductFamiliesQuery,
    ProductFamiliesQueryVariables
  >(ProductFamiliesDocument, baseOptions)
}
export type ProductFamiliesQueryHookResult = ReturnType<
  typeof useProductFamiliesQuery
>
export type ProductFamiliesLazyQueryHookResult = ReturnType<
  typeof useProductFamiliesLazyQuery
>
export type ProductFamiliesQueryResult = ApolloReactCommon.QueryResult<
  ProductFamiliesQuery,
  ProductFamiliesQueryVariables
>
export const OverviewReportingDocument = gql`
  query OverviewReporting(
    $start: DateTime!
    $end: DateTime!
    $skip: Int
    $take: Int
    $sorts: [OverviewReportingSorts]
    $externalPropertyId: String!
    $externalPublisherId: String
    $includeImpressions: Boolean = false
    $includeTotals: Boolean = false
  ) {
    overviewReporting(
      start: $start
      end: $end
      skip: $skip
      take: $take
      sorts: $sorts
      externalPropertyId: $externalPropertyId
      externalPublisherId: $externalPublisherId
      includeImpressions: $includeImpressions
      includeTotals: $includeTotals
    ) {
      page {
        verticalId
        verticalName
        productClicks
        uniqueClicks
        sales
        commission
        conversionRate
        epc
        totalImpressions
        viewableImpressions
        totalCtr
        viewableCtr
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
      totals {
        totalProductClicks
        totalUniqueClicks
        totalSales
        totalCommission
        totalConversionRate
        totalEpc
        totalImpressions
        viewableImpressions
      }
    }
  }
`

/**
 * __useOverviewReportingQuery__
 *
 * To run a query within a React component, call `useOverviewReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewReportingQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sorts: // value for 'sorts'
 *      externalPropertyId: // value for 'externalPropertyId'
 *      externalPublisherId: // value for 'externalPublisherId'
 *      includeImpressions: // value for 'includeImpressions'
 *      includeTotals: // value for 'includeTotals'
 *   },
 * });
 */
export function useOverviewReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OverviewReportingQuery,
    OverviewReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OverviewReportingQuery,
    OverviewReportingQueryVariables
  >(OverviewReportingDocument, baseOptions)
}
export function useOverviewReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverviewReportingQuery,
    OverviewReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OverviewReportingQuery,
    OverviewReportingQueryVariables
  >(OverviewReportingDocument, baseOptions)
}
export type OverviewReportingQueryHookResult = ReturnType<
  typeof useOverviewReportingQuery
>
export type OverviewReportingLazyQueryHookResult = ReturnType<
  typeof useOverviewReportingLazyQuery
>
export type OverviewReportingQueryResult = ApolloReactCommon.QueryResult<
  OverviewReportingQuery,
  OverviewReportingQueryVariables
>
export const PaymentDetailReportingDocument = gql`
  query paymentDetailReporting(
    $skip: Int
    $take: Int
    $sorts: [PaymentDetailReportingSorts]
    $externalPaymentId: String!
    $externalPublisherId: String!
  ) {
    paymentDetailReporting(
      skip: $skip
      take: $take
      sorts: $sorts
      externalPaymentId: $externalPaymentId
      externalPublisherId: $externalPublisherId
    ) {
      page {
        clickId
        eventId
        processedAt
        productName
        programName
        commission
        adjustments
        total
        programId
        note
        productId
        propertyId
        subTracking1
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
    }
  }
`

/**
 * __usePaymentDetailReportingQuery__
 *
 * To run a query within a React component, call `usePaymentDetailReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailReportingQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sorts: // value for 'sorts'
 *      externalPaymentId: // value for 'externalPaymentId'
 *      externalPublisherId: // value for 'externalPublisherId'
 *   },
 * });
 */
export function usePaymentDetailReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentDetailReportingQuery,
    PaymentDetailReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaymentDetailReportingQuery,
    PaymentDetailReportingQueryVariables
  >(PaymentDetailReportingDocument, baseOptions)
}
export function usePaymentDetailReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentDetailReportingQuery,
    PaymentDetailReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaymentDetailReportingQuery,
    PaymentDetailReportingQueryVariables
  >(PaymentDetailReportingDocument, baseOptions)
}
export type PaymentDetailReportingQueryHookResult = ReturnType<
  typeof usePaymentDetailReportingQuery
>
export type PaymentDetailReportingLazyQueryHookResult = ReturnType<
  typeof usePaymentDetailReportingLazyQuery
>
export type PaymentDetailReportingQueryResult = ApolloReactCommon.QueryResult<
  PaymentDetailReportingQuery,
  PaymentDetailReportingQueryVariables
>
export const PaymentDetailReportingSummaryDocument = gql`
  query paymentDetailReportingSummary(
    $externalPaymentId: String!
    $externalPublisherId: String!
  ) {
    paymentDetailReportingSummary(
      externalPaymentId: $externalPaymentId
      externalPublisherId: $externalPublisherId
    ) {
      summary {
        breakdown
        sales
        adjustments
        bonuses
      }
    }
  }
`

/**
 * __usePaymentDetailReportingSummaryQuery__
 *
 * To run a query within a React component, call `usePaymentDetailReportingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailReportingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailReportingSummaryQuery({
 *   variables: {
 *      externalPaymentId: // value for 'externalPaymentId'
 *      externalPublisherId: // value for 'externalPublisherId'
 *   },
 * });
 */
export function usePaymentDetailReportingSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentDetailReportingSummaryQuery,
    PaymentDetailReportingSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaymentDetailReportingSummaryQuery,
    PaymentDetailReportingSummaryQueryVariables
  >(PaymentDetailReportingSummaryDocument, baseOptions)
}
export function usePaymentDetailReportingSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentDetailReportingSummaryQuery,
    PaymentDetailReportingSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaymentDetailReportingSummaryQuery,
    PaymentDetailReportingSummaryQueryVariables
  >(PaymentDetailReportingSummaryDocument, baseOptions)
}
export type PaymentDetailReportingSummaryQueryHookResult = ReturnType<
  typeof usePaymentDetailReportingSummaryQuery
>
export type PaymentDetailReportingSummaryLazyQueryHookResult = ReturnType<
  typeof usePaymentDetailReportingSummaryLazyQuery
>
export type PaymentDetailReportingSummaryQueryResult = ApolloReactCommon.QueryResult<
  PaymentDetailReportingSummaryQuery,
  PaymentDetailReportingSummaryQueryVariables
>
export const PaymentsHistoryReportingDocument = gql`
  query paymentsHistoryReporting(
    $skip: Int
    $take: Int
    $sorts: [PaymentsHistoryReportingSorts]
    $externalPublisherId: String!
  ) {
    paymentsHistoryReporting(
      skip: $skip
      take: $take
      sorts: $sorts
      externalPublisherId: $externalPublisherId
    ) {
      page {
        paymentId
        processedAt
        total
        status
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
    }
  }
`

/**
 * __usePaymentsHistoryReportingQuery__
 *
 * To run a query within a React component, call `usePaymentsHistoryReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsHistoryReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsHistoryReportingQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sorts: // value for 'sorts'
 *      externalPublisherId: // value for 'externalPublisherId'
 *   },
 * });
 */
export function usePaymentsHistoryReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentsHistoryReportingQuery,
    PaymentsHistoryReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaymentsHistoryReportingQuery,
    PaymentsHistoryReportingQueryVariables
  >(PaymentsHistoryReportingDocument, baseOptions)
}
export function usePaymentsHistoryReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentsHistoryReportingQuery,
    PaymentsHistoryReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaymentsHistoryReportingQuery,
    PaymentsHistoryReportingQueryVariables
  >(PaymentsHistoryReportingDocument, baseOptions)
}
export type PaymentsHistoryReportingQueryHookResult = ReturnType<
  typeof usePaymentsHistoryReportingQuery
>
export type PaymentsHistoryReportingLazyQueryHookResult = ReturnType<
  typeof usePaymentsHistoryReportingLazyQuery
>
export type PaymentsHistoryReportingQueryResult = ApolloReactCommon.QueryResult<
  PaymentsHistoryReportingQuery,
  PaymentsHistoryReportingQueryVariables
>
export const OutstandingBalanceReportingDocument = gql`
  query outstandingBalanceReporting(
    $skip: Int
    $take: Int
    $sorts: [OutstandingBalanceReportingSorts]
    $externalPublisherId: String!
  ) {
    outstandingBalanceReporting(
      skip: $skip
      take: $take
      sorts: $sorts
      externalPublisherId: $externalPublisherId
    ) {
      page {
        clickId
        processedAt
        verticalName
        productName
        programName
        commission
        adjustments
        total
        crossSale
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
    }
  }
`

/**
 * __useOutstandingBalanceReportingQuery__
 *
 * To run a query within a React component, call `useOutstandingBalanceReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingBalanceReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingBalanceReportingQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sorts: // value for 'sorts'
 *      externalPublisherId: // value for 'externalPublisherId'
 *   },
 * });
 */
export function useOutstandingBalanceReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OutstandingBalanceReportingQuery,
    OutstandingBalanceReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OutstandingBalanceReportingQuery,
    OutstandingBalanceReportingQueryVariables
  >(OutstandingBalanceReportingDocument, baseOptions)
}
export function useOutstandingBalanceReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OutstandingBalanceReportingQuery,
    OutstandingBalanceReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OutstandingBalanceReportingQuery,
    OutstandingBalanceReportingQueryVariables
  >(OutstandingBalanceReportingDocument, baseOptions)
}
export type OutstandingBalanceReportingQueryHookResult = ReturnType<
  typeof useOutstandingBalanceReportingQuery
>
export type OutstandingBalanceReportingLazyQueryHookResult = ReturnType<
  typeof useOutstandingBalanceReportingLazyQuery
>
export type OutstandingBalanceReportingQueryResult = ApolloReactCommon.QueryResult<
  OutstandingBalanceReportingQuery,
  OutstandingBalanceReportingQueryVariables
>
export const OutstandingBalanceReportingSummaryDocument = gql`
  query outstandingBalanceReportingSummary($externalPublisherId: String!) {
    outstandingBalanceReportingSummary(
      externalPublisherId: $externalPublisherId
    ) {
      summary {
        date
        total
        verticalName
        threshold
      }
    }
  }
`

/**
 * __useOutstandingBalanceReportingSummaryQuery__
 *
 * To run a query within a React component, call `useOutstandingBalanceReportingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingBalanceReportingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingBalanceReportingSummaryQuery({
 *   variables: {
 *      externalPublisherId: // value for 'externalPublisherId'
 *   },
 * });
 */
export function useOutstandingBalanceReportingSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OutstandingBalanceReportingSummaryQuery,
    OutstandingBalanceReportingSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OutstandingBalanceReportingSummaryQuery,
    OutstandingBalanceReportingSummaryQueryVariables
  >(OutstandingBalanceReportingSummaryDocument, baseOptions)
}
export function useOutstandingBalanceReportingSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OutstandingBalanceReportingSummaryQuery,
    OutstandingBalanceReportingSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OutstandingBalanceReportingSummaryQuery,
    OutstandingBalanceReportingSummaryQueryVariables
  >(OutstandingBalanceReportingSummaryDocument, baseOptions)
}
export type OutstandingBalanceReportingSummaryQueryHookResult = ReturnType<
  typeof useOutstandingBalanceReportingSummaryQuery
>
export type OutstandingBalanceReportingSummaryLazyQueryHookResult = ReturnType<
  typeof useOutstandingBalanceReportingSummaryLazyQuery
>
export type OutstandingBalanceReportingSummaryQueryResult = ApolloReactCommon.QueryResult<
  OutstandingBalanceReportingSummaryQuery,
  OutstandingBalanceReportingSummaryQueryVariables
>
export const TransactionDetailsReportingDocument = gql`
  query transactionDetailsReporting(
    $start: DateTime!
    $end: DateTime!
    $skip: Int
    $take: Int
    $sorts: [TransactionDetailsReportingSorts]
    $externalPropertyId: String!
    $dateType: DateType!
    $filters: TransactionDetailsReportingFilters
    $event: EventType!
  ) {
    transactionDetailsReporting(
      start: $start
      end: $end
      skip: $skip
      take: $take
      sorts: $sorts
      externalPropertyId: $externalPropertyId
      dateType: $dateType
      filters: $filters
      event: $event
    ) {
      page {
        clickedAt
        commission
        creditBucket
        crossSale
        eventType
        externalClickId
        externalPaymentId
        externalPropertyId
        externalPublisherId
        externalDisplayedProductId
        externalClickedProductId
        externalProductTypeId
        saleId
        ipAddress
        loanAmount
        creditScoreExact
        creditBucket
        deviceType
        page
        postalState
        processedAt
        clickedProductName
        displayedProductName
        programName
        campaignName
        integrationTypeId
        integrationTypeName
        subTracking
        subTracking2
        subTracking3
        subTracking4
        subTracking5
        typeName
        verticalName
        trueConversion
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalEntries
      }
    }
  }
`

/**
 * __useTransactionDetailsReportingQuery__
 *
 * To run a query within a React component, call `useTransactionDetailsReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionDetailsReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionDetailsReportingQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sorts: // value for 'sorts'
 *      externalPropertyId: // value for 'externalPropertyId'
 *      dateType: // value for 'dateType'
 *      filters: // value for 'filters'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransactionDetailsReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TransactionDetailsReportingQuery,
    TransactionDetailsReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TransactionDetailsReportingQuery,
    TransactionDetailsReportingQueryVariables
  >(TransactionDetailsReportingDocument, baseOptions)
}
export function useTransactionDetailsReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TransactionDetailsReportingQuery,
    TransactionDetailsReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TransactionDetailsReportingQuery,
    TransactionDetailsReportingQueryVariables
  >(TransactionDetailsReportingDocument, baseOptions)
}
export type TransactionDetailsReportingQueryHookResult = ReturnType<
  typeof useTransactionDetailsReportingQuery
>
export type TransactionDetailsReportingLazyQueryHookResult = ReturnType<
  typeof useTransactionDetailsReportingLazyQuery
>
export type TransactionDetailsReportingQueryResult = ApolloReactCommon.QueryResult<
  TransactionDetailsReportingQuery,
  TransactionDetailsReportingQueryVariables
>
export const VerticalSummaryReportingDocument = gql`
  query verticalSummaryReporting(
    $end: DateTime!
    $start: DateTime!
    $skip: Int
    $take: Int
    $subTracking: String
    $sorts: [VerticalSummaryReportingSorts]
    $groupBy: GroupBy!
    $externalVerticalId: String!
    $externalPropertyId: String!
    $externalPublisherId: String
    $externalProductTypeId: Int
    $externalCampaignId: String
    $externalProgramId: String
    $externalDisplayedProductId: String
    $externalClickedProductId: String
    $pagePath: String
    $includeImpressions: Boolean = false
    $includeTotals: Boolean = false
    $offset: Float
  ) {
    verticalSummaryReporting(
      start: $start
      end: $end
      skip: $skip
      take: $take
      subTracking: $subTracking
      sorts: $sorts
      groupBy: $groupBy
      externalVerticalId: $externalVerticalId
      externalPropertyId: $externalPropertyId
      externalPublisherId: $externalPublisherId
      externalProductTypeId: $externalProductTypeId
      externalCampaignId: $externalCampaignId
      externalProgramId: $externalProgramId
      externalDisplayedProductId: $externalDisplayedProductId
      externalClickedProductId: $externalClickedProductId
      pagePath: $pagePath
      includeImpressions: $includeImpressions
      includeTotals: $includeTotals
      offset: $offset
    ) {
      page {
        productTypeId
        clickedProductId
        displayedProductId
        campaignId
        programId
        subTracking
        day
        programName
        campaignName
        productTypeName
        clickedProductName
        displayedProductName
        integrationTypeId
        integrationTypeName
        productClicks
        uniqueClicks
        sales
        commission
        conversionRate
        epc
        totalImpressions
        viewableImpressions
        totalCtr
        viewableCtr
        totalApplications
        totalDeclines
      }
      pagination {
        currentPage
        pageSize
        totalEntries
        totalPages
      }
      totals {
        totalProductClicks
        totalUniqueClicks
        totalSales
        totalCommission
        totalConversionRate
        totalEpc
        totalAllApplications
        totalAllDeclines
        totalImpressions
        viewableImpressions
      }
    }
  }
`

/**
 * __useVerticalSummaryReportingQuery__
 *
 * To run a query within a React component, call `useVerticalSummaryReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerticalSummaryReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerticalSummaryReportingQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      subTracking: // value for 'subTracking'
 *      sorts: // value for 'sorts'
 *      groupBy: // value for 'groupBy'
 *      externalVerticalId: // value for 'externalVerticalId'
 *      externalPropertyId: // value for 'externalPropertyId'
 *      externalPublisherId: // value for 'externalPublisherId'
 *      externalProductTypeId: // value for 'externalProductTypeId'
 *      externalCampaignId: // value for 'externalCampaignId'
 *      externalProgramId: // value for 'externalProgramId'
 *      externalDisplayedProductId: // value for 'externalDisplayedProductId'
 *      externalClickedProductId: // value for 'externalClickedProductId'
 *      pagePath: // value for 'pagePath'
 *      includeImpressions: // value for 'includeImpressions'
 *      includeTotals: // value for 'includeTotals'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useVerticalSummaryReportingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VerticalSummaryReportingQuery,
    VerticalSummaryReportingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    VerticalSummaryReportingQuery,
    VerticalSummaryReportingQueryVariables
  >(VerticalSummaryReportingDocument, baseOptions)
}
export function useVerticalSummaryReportingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VerticalSummaryReportingQuery,
    VerticalSummaryReportingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    VerticalSummaryReportingQuery,
    VerticalSummaryReportingQueryVariables
  >(VerticalSummaryReportingDocument, baseOptions)
}
export type VerticalSummaryReportingQueryHookResult = ReturnType<
  typeof useVerticalSummaryReportingQuery
>
export type VerticalSummaryReportingLazyQueryHookResult = ReturnType<
  typeof useVerticalSummaryReportingLazyQuery
>
export type VerticalSummaryReportingQueryResult = ApolloReactCommon.QueryResult<
  VerticalSummaryReportingQuery,
  VerticalSummaryReportingQueryVariables
>
