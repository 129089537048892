import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import localforage from 'localforage'
import styles from './styles.module.css'
import { Website } from '../../../../typings/website'
import { useEventListener } from '../../../../utils/customHooks'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

interface WebsiteSelectorProps {
  activeWebsite: Website
  websites: Array<Website>
  updateActiveWebsite(website: Website): void
}

const WebsiteSelector: FunctionComponent<WebsiteSelectorProps> = ({
  activeWebsite,
  websites,
  updateActiveWebsite,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [savedActiveWebsite, setSavedActiveWebsite] = useState(activeWebsite)
  const user = useSelector<State, UserStore>(state => state.user)

  const containerRef = useRef<HTMLDivElement>()

  const handleGlobalClick = e => {
    const container: HTMLDivElement = containerRef.current
    const target: HTMLElement = e.target

    if (isOpen && container !== target && !container.contains(target)) {
      setIsOpen(false)
    }
  }

  const handleOnclickForWebsite = (website: Website) => {
    updateActiveWebsite(website)
    localforage.setItem(
      `rvmn:affiliate:${user.affiliateId}:properties-website`,
      website
    )
    setSavedActiveWebsite(website)
  }

  useEventListener('click', document, handleGlobalClick)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    localforage
      .getItem<Website>(`rvmn:affiliate:${user.affiliateId}:properties-website`)
      .then(website => {
        if (website) {
          setSavedActiveWebsite(website)
        } else {
          setSavedActiveWebsite(activeWebsite)
          localforage.setItem(
            `rvmn:affiliate:${user.affiliateId}:properties-website`,
            activeWebsite
          )
        }
      })
  }, [activeWebsite])

  // Sort the websites by approval status
  websites.sort(
    (a, b) =>
      a.approvalStatus && a.approvalStatus.localeCompare(b.approvalStatus)
  )

  const filteredWebsites = websites
    .filter(
      website => website.id !== savedActiveWebsite.id && website.approvalStatus
    )
    .map(website => (
      <li className={styles.item} key={_.uniqueId('websites-')}>
        <button
          role="menuitem"
          onClick={() => handleOnclickForWebsite(website)}
          data-rv="website-selector-dropdown-item"
          className={classnames(styles.item, {
            [styles.approved]:
              website.approvalStatus?.toLowerCase() === 'active',
            [styles.declined]:
              website.approvalStatus?.toLowerCase() === 'inactive',
          })}
        >
          {website.url}
          <div className={styles.right}>
            {website.approvalStatus?.toLowerCase() === 'inactive'
              ? '(inactive)'
              : ''}
          </div>
        </button>
      </li>
    ))

  filteredWebsites.push(
    <li className={styles.item} key={_.uniqueId('websites-')}>
      <Link
        role="menuitem"
        to="/properties"
        className={styles.manage}
        data-rv="website-selector-manage-websites"
      >
        View Properties
      </Link>
    </li>
  )

  return (
    <div
      className={styles.container}
      ref={containerRef}
      data-rv="website-selector"
    >
      {filteredWebsites.length > 0 ? (
        <button
          onClick={handleClick}
          aria-haspopup={true}
          aria-expanded={isOpen}
          aria-controls="website-selector"
          data-rv="website-selector-button"
        >
          <span className={styles.icon}>
            <FontAwesomeIcon icon={['fas', 'globe-americas']} />
          </span>
          <span
            className={styles.website}
            data-rv="website-selector-active-website"
          >
            {savedActiveWebsite.url}
          </span>
          <span className={styles.caret}>
            <FontAwesomeIcon icon={['fas', 'sort-down']} />
          </span>
        </button>
      ) : (
        <div className={styles.tile}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={['fas', 'globe-americas']} />
          </span>
          <span
            className={styles.website}
            data-rv="website-selector-active-website"
          >
            {savedActiveWebsite.url}
          </span>
        </div>
      )}

      {isOpen && (
        <ul
          id="website-selector"
          role="menu"
          className={styles.dropdown}
          data-rv="website-selector-dropdown"
        >
          {filteredWebsites}
        </ul>
      )}
    </div>
  )
}

export default WebsiteSelector
