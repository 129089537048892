import React, { FunctionComponent, useEffect } from 'react'
import { map, uniqueId } from 'lodash'
import classnames from 'classnames'

import { Loading } from '../../../../components'

import accounting from 'accounting'
import styles from '../styles.module.css'
import { PaymentDetailReportingSummaryQueryVariables } from '../../../../generated/types'
import { useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'
interface PaymentSummaryProps {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
  paymentId: string
  paymentTotal: string
}

const PaymentSummary: FunctionComponent<PaymentSummaryProps> = ({
  getData,
  data,
  loading,
  error,
  paymentId,
  paymentTotal,
}) => {
  const user = useSelector<State, UserStore>(state => state.user)
  const variables = () => {
    return {
      externalPaymentId: paymentId,
      externalPublisherId: user.affiliateId,
    } as PaymentDetailReportingSummaryQueryVariables
  }

  const fetchData = () => {
    getData({ variables: variables() })
  }

  useEffect(fetchData, [paymentId, user.affiliateId])

  if (loading) {
    return (
      <div className={classnames(styles.stateContainer, styles.loading)}>
        <Loading />
      </div>
    )
  }

  const summaryKeys = ['sales', 'adjustments', 'bonuses']

  let totalAmount = paymentTotal
  return (
    <div className={styles.container}>
      {map(data, payload => {
        if (payload.summary.length > 0) {
          const summary = payload.summary[0]
          totalAmount = summary.breakdown
          return (
            <div
              key={uniqueId('section-')}
              className={classnames(styles.section)}
            >
              <div className={styles.topsection}>
                <div className={styles.header}>
                  <h4>Breakdown</h4>
                  <span>{accounting.formatMoney(totalAmount, '$')}</span>
                </div>
                <div className={styles.breakdown}>
                  {map(summaryKeys, key => {
                    const name = key[0].toUpperCase() + key.slice(1)
                    return (
                      <div
                        key={uniqueId('summary-breakdown-')}
                        className={styles.row}
                      >
                        <div className={styles.breakdownName}>
                          <span>{name}</span>
                        </div>
                        <span>
                          {accounting.formatMoney(summary[key] || 0, '$')}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }
        return (
          <div
            key={uniqueId('section-')}
            className={classnames(styles.section)}
          >
            <div className={styles.header}>
              <h4>No breakdown available</h4>
            </div>
          </div>
        )
      })}
      <div
        key={uniqueId('section-')}
        className={classnames(styles.section, styles.totalSection)}
      >
        <div className={styles.header}>
          <h4>Total</h4>
          <span>{accounting.formatMoney(totalAmount, '$')}</span>
        </div>
      </div>
    </div>
  )
}

export default PaymentSummary
