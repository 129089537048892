import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { PageHeading } from '../../../../components'
import Table, { Col, TableFooter } from '../../../../components/Table/next'
import ReportActions from '../../../../components/ReportActions'
import ReportMenu from '../../Menu'
import VerticalSummaryFilterDisplay from '../Filters'

import { prepareDatesForApi } from '../../../../utils/dates'

import {
  VerticalSummaryGroupBy,
  VerticalSummaryTableContext,
  VerticalSummaryVariables,
} from './index'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

import styles from './styles.module.css'
import { VerticalSummaryReportingQueryVariables } from '../../../../generated/types'
import { VerticalSummary } from '../../../../typings/detailReport'
import accounting from 'accounting'
import { GenerateDetailReportLink } from '../../../../components/LinkRow'
import { DateTime } from 'luxon'
import DateDisplay from '../../../../components/DateDisplay'
import { dumpData } from '../../../../actions/reports'
import { decode } from 'he'
import { ReportsStore } from '../../../../typings/stores/ReportsStore'
import { useLocation } from 'react-router'

interface VerticalSummaryTableProps {
  groupBy: VerticalSummaryGroupBy
  cols: Col[]
  footerPadding: number
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }: { variables: VerticalSummaryVariables }) => void
  exportData: any
  exportLoading: boolean
  exportError: boolean
  getExportData: ({
    variables,
  }: {
    variables: VerticalSummaryVariables
  }) => void
  reportCallback: (param: string) => void
}

const VerticalSummaryTable: FunctionComponent<VerticalSummaryTableProps> = ({
  groupBy,
  cols,
  footerPadding,
  getData,
  data,
  loading,
  error,
  getExportData,
  exportData,
  exportLoading,
  exportError,
  reportCallback,
}) => {
  const user = useSelector<State, UserStore>(state => state.user)
  const report = useSelector<State, ReportsStore>(state => state.reports)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const verticalParam = queryParams.get('verticalId')

  const {
    changePage,
    changePageSize,
    filters,
    pagination: { size, page },
    setFilters,
    sort,
    changeSort,
  } = useContext(VerticalSummaryTableContext)

  let verticalId = ''
  let verticalIdExists = false
  if (verticalParam) {
    verticalId = verticalParam
  } else if (report.filters?.vertical) {
    verticalId = report.filters?.vertical.toString()
  }
  if (verticalId != '') {
    verticalIdExists = true
  }

  const updateVariables = () => {
    const { startDate, endDate, includeImpressions } = filters
    const queryVariables = {
      ...prepareDatesForApi(startDate, endDate, true),
      skip: (page - 1) * size,
      take: size,
      groupBy: filters.groupBy,
      externalPropertyId: user.activeWebsite.id.toString(),
      externalPublisherId: user.affiliateId,
      externalVerticalId: verticalId,
      externalCampaignId: filters?.campaign,
      externalClickedProductId: filters.clickedProduct,
      externalDisplayedProductId: filters.product,
      externalProductTypeId: filters.productType
        ? parseInt(filters.productType)
        : null,
      externalProgramId: filters.program,
      subTracking: filters.subTracking,
      includeImpressions: includeImpressions === 'true',
      includeTotals: true,
    } as VerticalSummaryReportingQueryVariables
    return sort.dir
      ? {
          ...queryVariables,
          sorts: [
            sort.by === 'productTypeId'
              ? {
                  [sort.by]: sort.dir.toUpperCase(),
                  productTypeName: sort.dir.toUpperCase(),
                }
              : { [sort.by]: sort.dir.toUpperCase() },
          ],
        }
      : queryVariables
  }

  const fetchData = useCallback(() => {
    if (verticalIdExists && verticalId != '') {
      getData({ variables: updateVariables() })
      hasManageColumnsOrderRun = false
    }
  }, [
    filters,
    size,
    page,
    sort,
    user.activeWebsite,
    user.affiliateId,
    verticalIdExists,
  ])

  const fetchExport = () => {
    getExportData({
      variables: {
        ...updateVariables(),
        take: get(data, `verticalSummaryReporting.pagination.totalEntries`, 0),
        includeTotals: false,
      },
    })
  }

  useEffect(() => {
    dumpData('verticalSummary', exportData?.verticalSummaryReporting?.page, {
      ...filters,
    })
  }, [exportData])

  useEffect(fetchData, [sort, page, size, user.activeWebsite, verticalIdExists])

  const pagination = {
    currentPage: get(
      data,
      `verticalSummaryReporting.pagination.currentPage`,
      1
    ),
    pageSize: get(data, `verticalSummaryReporting.pagination.pageSize`, 10),
    total: get(data, `verticalSummaryReporting.pagination.totalEntries`, 0),
  }

  const totalsData = {
    totalProductClicks: get(
      data,
      'verticalSummaryReporting.totals.totalProductClicks',
      0
    ),
    totalUniqueClicks: get(
      data,
      'verticalSummaryReporting.totals.totalUniqueClicks',
      0
    ),
    totalSales: get(data, 'verticalSummaryReporting.totals.totalSales', 0),
    totalCommission: get(
      data,
      'verticalSummaryReporting.totals.totalCommission',
      0
    ),
    totalConversionRate: get(
      data,
      'verticalSummaryReporting.totals.totalConversionRate',
      0
    ),
    totalEpc: get(data, 'verticalSummaryReporting.totals.totalEpc', 0),
    totalAllApplications: get(
      data,
      'verticalSummaryReporting.totals.totalAllApplications',
      0
    ),
    totalAllDeclines: get(
      data,
      'verticalSummaryReporting.totals.totalAllDeclines',
      0
    ),
    totalImpressions: get(
      data,
      'verticalSummaryReporting.totals.totalImpressions',
      0
    ),
    viewableImpressions: get(
      data,
      'verticalSummaryReporting.totals.viewableImpressions',
      0
    ),
  }

  const hiddenCols = [
    'totalImpressions',
    'viewableImpressions',
    'totalCtr',
    'viewableCtr',
  ]

  if (filters.includeImpressions !== 'true') {
    cols = cols.filter(col => !hiddenCols.includes(col.field.toString()))
  }

  const totalRowBuilder: TableFooter[] = [
    {
      text: 'Totals',
      colSpan: footerPadding - 4,
      className: styles.footerHeader,
    },
    filters.includeImpressions === 'true' && {
      text: accounting.formatNumber(totalsData.totalImpressions),
      className: styles.right,
    },
    filters.includeImpressions === 'true' && {
      text: accounting.formatNumber(totalsData.viewableImpressions),
      className: styles.right,
    },
    {
      text: totalsData
        ? accounting.formatNumber(
            totalsData ? totalsData.totalProductClicks : 0
          )
        : 0,
      className: styles.right,
    },
    {
      text: totalsData
        ? accounting.formatNumber(totalsData ? totalsData.totalUniqueClicks : 0)
        : 0,
      className: styles.right,
    },
    filters.includeImpressions === 'true' && {
      text: '-', // totalCTR
      className: styles.right,
    },
    filters.includeImpressions === 'true' && {
      text: '-', // totalViewableCTR
      className: styles.right,
    },
    {
      text: totalsData
        ? accounting.formatNumber(totalsData ? totalsData.totalSales : 0)
        : 0,
      className: styles.right,
    },
    {
      text: totalsData
        ? (totalsData ? totalsData.totalConversionRate * 100 : 0).toFixed(2) +
          '%'
        : 0,
      className: styles.right,
    },
    {
      text:
        data &&
        accounting.formatMoney(
          totalsData ? totalsData.totalCommission : 0,
          '$'
        ),
      className: styles.right,
    },
    {
      text:
        data &&
        accounting.formatMoney(totalsData ? totalsData.totalEpc : 0, '$'),
      className: styles.right,
    },
  ].filter(el => !!el)
  if (verticalId === '5') {
    cols.splice(
      7,
      0,
      {
        text: 'Applications',
        field: 'totalApplications',
        sortable: true,
        render: row => (
          <GenerateDetailReportLink
            whereCriteria="totalApplications"
            id={get(row, 'totalApplications', '')}
            value={accounting.formatNumber(get(row, 'totalApplications', ''))}
            detailPath="/reports/event-details"
            rightAligment={true}
            queryParams={[
              {
                startDate: get(row, 'day', '') ?? filters.startDate,
                endDate: get(row, 'day', '') ?? filters.endDate,
                start: get(row, 'day', '') ?? filters.startDate,
                end: get(row, 'day', '') ?? filters.endDate,
                dateRange: 'custom',
                dateType: 'clickDate',
                event: 'app',
                vertical: verticalId,
                program: get(row, 'programId', '') ?? filters.program,
                productType:
                  get(row, 'productTypeId', '') ?? +filters.productType,
                campaign: get(row, 'campaignId', '') ?? filters.campaign,
                product: filters.product,
                clickedProduct: filters.clickedProduct,
                displayedProductName: get(row, 'displayedProductName')
                  ? decode(get(row, 'displayedProductName', '')).replace(
                      '&',
                      '%26'
                    )
                  : '',
                clickedProductName: get(row, 'clickedProductName')
                  ? decode(get(row, 'clickedProductName', '')).replace(
                      '&',
                      '%26'
                    )
                  : '',
                integrationType: get(row, 'integrationTypeId', ''),
                subTracking: get(row, 'subTracking', '') ?? filters.subTracking,
              },
            ]}
          />
        ),
        className: styles.right,
      },
      {
        text: 'Declines',
        field: 'totalDeclines',
        sortable: true,
        render: row => (
          <GenerateDetailReportLink
            whereCriteria="totalDeclines"
            id={get(row, 'totalDeclines', '')}
            value={accounting.formatNumber(get(row, 'totalDeclines', ''))}
            detailPath="/reports/event-details"
            rightAligment={true}
            queryParams={[
              {
                startDate: get(row, 'day', '') ?? filters.startDate,
                endDate: get(row, 'day', '') ?? filters.endDate,
                start: get(row, 'day', '') ?? filters.startDate,
                end: get(row, 'day', '') ?? filters.endDate,
                dateRange: 'custom',
                dateType: 'clickDate',
                event: 'dec',
                vertical: verticalId,
                program: get(row, 'programId', '') ?? filters.program,
                productType:
                  get(row, 'productTypeId', '') ?? +filters.productType,
                campaign: get(row, 'campaignId', '') ?? filters.campaign,
                product: filters.product,
                clickedProduct: filters.clickedProduct,
                displayedProductName: get(row, 'displayedProductName')
                  ? decode(get(row, 'displayedProductName', '')).replace(
                      '&',
                      '%26'
                    )
                  : '',
                clickedProductName: get(row, 'clickedProductName')
                  ? decode(get(row, 'clickedProductName', '')).replace(
                      '&',
                      '%26'
                    )
                  : '',
                integrationType: get(row, 'integrationTypeId', ''),
                subTracking: get(row, 'subTracking', '') ?? filters.subTracking,
              },
            ]}
          />
        ),
        className: styles.right,
      }
    )
    totalRowBuilder.splice(
      7,
      0,
      {
        text: data
          ? accounting.formatNumber(
              totalsData ? totalsData.totalAllApplications : 0
            )
          : 0,
        className: styles.right,
      },
      {
        text: data
          ? accounting.formatNumber(
              totalsData ? totalsData.totalAllDeclines : 0
            )
          : 0,
        className: styles.right,
      }
    )
  }
  if (filters.groupBy === 'byDate') {
    cols.shift()
    cols.unshift({
      text: 'Date',
      field: 'day',
      sortable: true,
      tooltip: true,
      tooltipText: 'You are seeing data in UTC time',
      render: row => (
        <DateDisplay
          datetime={DateTime.fromISO(get(row, 'day', ''), { zone: 'utc' })}
        />
      ),
      className: styles.center,
    })
  }

  if (filters.groupBy === 'byDisplayedProduct') {
    cols.shift()
    cols.unshift({
      text: 'Name',
      field: 'displayedProductName',
      sortable: true,
      render: row =>
        get(row, 'displayedProductName', '')
          ? decode(get(row, 'displayedProductName', ''))
          : 'N/A',
    })
  }

  if (filters.groupBy === 'byProgram') {
    cols.shift()
    cols.unshift({
      text: 'Name',
      field: 'programName',
      sortable: true,
      render: row =>
        get(row, 'programName', '')
          ? decode(get(row, 'programName', ''))
          : 'N/A',
    })
  }

  if (filters.groupBy === 'byClickedProduct') {
    cols.shift()
    cols.unshift({
      text: 'Name',
      field: 'clickedProductName',
      sortable: true,
      render: row =>
        get(row, 'clickedProductName', '')
          ? decode(get(row, 'clickedProductName', ''))
          : 'N/A',
    })
  }

  if (filters.groupBy === 'byCampaign') {
    cols.shift()
    cols.unshift({
      text: 'Name',
      field: 'campaignName',
      sortable: true,
      render: row =>
        get(row, 'campaignName', '')
          ? decode(get(row, 'campaignName', ''))
          : 'N/A',
    })
  }

  if (filters.groupBy === 'bySubTracking') {
    cols.shift()
    cols.unshift({
      text: 'SubTracking',
      field: 'subTracking',
      sortable: true,
      render: row =>
        get(row, 'subTracking', '')
          ? decode(get(row, 'subTracking', ''))
          : 'N/A',
    })
  }

  if (filters.groupBy === 'byIntegrationType') {
    cols.shift()
    cols.unshift({
      text: 'Name',
      field: 'integrationTypeName',
      sortable: true,
      render: row =>
        get(row, 'integrationTypeName', '')
          ? decode(get(row, 'integrationTypeName', ''))
          : 'N/A',
    })
  }

  const [propertyHasData, setPropertyHasData] = useState(true)
  const [pageDescription, setPageDescription] = useState(
    'This report contains aggregated information for clicks, sales, and commissions filtered by a specified date range.'
  )

  useEffect(() => {
    if (!propertyHasData) {
      setPageDescription('No reporting data available for this property yet.')
    }
  }, [propertyHasData])

  useEffect(() => {
    if (!propertyHasData) {
      window.location.reload()
    }
  }, [user.activeWebsite])

  const [visibleCols, setVisibleCols] = useState(cols)
  const [totalsRow, setTotalsRow] = useState(totalRowBuilder)

  const manageColumnsOrder = () => {
    const uniqueColsByKey = [
      ...new Map(cols.map(item => [item['field'], item])).values(),
    ]
    setVisibleCols(uniqueColsByKey)
    setTotalsRow(totalRowBuilder)
  }

  let hasManageColumnsOrderRun = false

  useEffect(() => {
    if (!hasManageColumnsOrderRun) {
      manageColumnsOrder()
      hasManageColumnsOrderRun = true
    }
  }, [data])

  return (
    <>
      <ReportMenu />

      <PageHeading
        title="Vertical Summary"
        description={pageDescription}
      ></PageHeading>

      {propertyHasData && (
        <>
          <VerticalSummaryFilterDisplay
            filters={filters}
            setFilters={setFilters}
            changeSort={changeSort}
            setPropertyHasData={setPropertyHasData}
          />

          <ReportActions
            fetchReport={fetchData}
            fetchExport={fetchExport}
            subscriptionVariables={updateVariables()}
            disabled={
              !get(data, `verticalSummaryReporting.pagination.totalEntries`, 0)
            }
            reportStatus={{ loading: loading, hasError: error }}
            exportStatus={{ loading: exportLoading, hasError: exportError }}
            reportCallback={reportCallback}
          />

          <Table<VerticalSummary[]>
            className={styles.table}
            data={data && get(data, `verticalSummaryReporting.page`)}
            cols={visibleCols}
            totalRow={totalsRow}
            sort={sort}
            onSortChange={changeSort}
            pagination={{
              onPageSizeUpdate: changePageSize,
              onPaginate: changePage,
              ...pagination,
            }}
            isLoading={loading}
          />
        </>
      )}
    </>
  )
}

export default VerticalSummaryTable
