import React, { FunctionComponent } from 'react'

import getConfig from '../../../../config'

import styles from './styles.module.css'

const SubmittedDisplay: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Forgot Password</h3>
      <div className={styles.description}>
        <p>
          We've sent password reset instructions to the email provided if we
          were able to find an associated account. If you don't receive an
          email, please check your spam folder or contact our{' '}
          <a href={`mailto:${getConfig('supportEmail')}`}>support team</a>.
        </p>
      </div>
    </div>
  )
}

export default SubmittedDisplay
