import React, { Component, SyntheticEvent } from 'react'
import isEmpty from 'lodash/isEmpty'

interface ImageLoaderProps {
  imagePath?: string
  filename: string
  name?: string
  imageClass: string
  brokenImage?: string
}

class ImageLoader extends Component<ImageLoaderProps> {
  render() {
    const noImagePath = `${process.env.PUBLIC_URL}/images/no_img_available.png`
    const { imagePath, filename, name, imageClass, brokenImage } = this.props

    const fullImagePath = imagePath ? `${imagePath}/${filename}` : filename

    return !isEmpty(filename) ? (
      <img
        className={imageClass}
        src={fullImagePath}
        alt={name}
        onError={(e: SyntheticEvent<HTMLImageElement>) => {
          e.stopPropagation()

          e.currentTarget.src = brokenImage
        }}
      />
    ) : (
      <img className={imageClass} alt="" src={noImagePath} />
    )
  }
}

export default ImageLoader
