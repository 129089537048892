import { Dispatch } from 'redux'
import { stringify } from 'query-string'
import filesaver from 'file-saver'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'
import { stripHyphens } from '../utils/fileExport'

import { Action } from '../typings/reducer'
import {
  BannerReferrals,
  BannerReferralsFilters,
} from '../typings/bannerReferrals'

export enum BannerReferralsConstants {
  FETCH = 'bannerReferrals/FETCH',
  FETCH_SUCCESS = 'bannerReferrals/FETCH_SUCCESS',
  FETCH_FAILURE = 'bannerReferrals/FETCH_FAILURE',
  RESET = 'bannerReferrals/RESET',
  EXPORT_FETCH = 'bannerReferrals/EXPORT_FETCH',
  EXPORT_FETCH_SUCCESS = 'bannerReferrals/EXPORT_FETCH_SUCCESS',
  EXPORT_FETCH_FAILURE = 'bannerReferrals/EXPORT_FETCH_FAILURE',
  EXPORT_FETCH_RESET = 'bannerReferrals/EXPORT_FETCH_RESET',
}

export interface BannerReferralsAction extends Action {
  type: BannerReferralsConstants
  data?: BannerReferrals
  filters?: BannerReferralsFilters
  error?: string
}

function fetchBannerReferrals(): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.FETCH,
  }
}

function fetchBannerReferralsSuccess(
  data: BannerReferrals,
  filters?: BannerReferralsFilters
): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.FETCH_SUCCESS,
    data,
    filters,
  }
}

function fetchBannerReferralsFailure(error: string): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.FETCH_FAILURE,
    error,
  }
}

export function resetBannerReferrals(): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.RESET,
  }
}

function fetchBannerReferralsForExport(): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.EXPORT_FETCH,
  }
}

function fetchBannerReferralsForExportSuccess(
  filters?: BannerReferralsFilters
): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.EXPORT_FETCH_SUCCESS,
    filters,
  }
}

function fetchBannerReferralsForExportFailure(
  error: string
): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.EXPORT_FETCH_FAILURE,
    error,
  }
}

export function resetBannerReferralsForExport(): BannerReferralsAction {
  return {
    type: BannerReferralsConstants.EXPORT_FETCH_RESET,
  }
}

export const getBannerReferrals = cancelFetchOnReentrySync(
  fetch => (filters?: BannerReferralsFilters) =>
    swallowCancellation(async dispatch => {
      dispatch(fetchBannerReferrals())

      const { ok, bodyParsed, errorParsed } = await fetchGetApi(
        fetch,
        `/reports/banner-referrals?${stringify(filters)}`
      )

      if (ok) {
        dispatch(fetchBannerReferralsSuccess(bodyParsed.data, filters))
      } else {
        dispatch(triggerError(errorParsed, 'Banner Referrals'))
        dispatch(fetchBannerReferralsFailure(errorParsed))
      }
    })
)

export const exportBannerReferrals = (
  filters?: BannerReferralsFilters
) => async (dispatch: Dispatch) => {
  dispatch(fetchBannerReferralsForExport())

  const { page, size, ...modifiedFilters } = filters

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    `/reports/banner-referrals/export?${stringify(modifiedFilters)}`
  )

  if (ok) {
    const filename = `banner_referrals_${stripHyphens(
      modifiedFilters.startDate
    )}-${stripHyphens(modifiedFilters.endDate)}.csv`

    const file = new Blob([bodyParsed], {
      type: 'text/csv;charset=utf-8',
    })

    filesaver.saveAs(file, filename)

    dispatch(fetchBannerReferralsForExportSuccess(modifiedFilters))
  } else {
    dispatch(triggerError(errorParsed, 'Banner Referrals Export'))
    dispatch(fetchBannerReferralsForExportFailure(errorParsed))
  }
}
