import { Reducer } from 'redux'
import { AdvertiserAction, AdvertisersConstants } from '../actions/advertisers'
import { AdvertisersStore } from '../typings/stores/AdvertiserStore'

const initialState: AdvertisersStore = {
  preloaded: [],
  hasData: false,
  status: null,
  error: null,
}

/**
 * Reducer
 */
export const advertiserReducer: Reducer<AdvertisersStore, AdvertiserAction> = (
  state = { ...initialState },
  action
) => {
  switch (action.type) {
    case AdvertisersConstants.FETCH:
      return {
        ...state,
        preloaded: initialState.preloaded,
        hasData: false,
        status: 'fetching',
        error: initialState.error,
      }

    case AdvertisersConstants.FETCH_SUCCESS:
      return {
        ...state,
        preloaded: action.data.records,
        hasData: action.data.records.length > 0,
        status: 'fetched',
        error: initialState.error,
      }

    case AdvertisersConstants.FETCH_FAILURE:
      return {
        ...initialState,
        hasData: false,
        status: null,
        error: action.error,
      }

    default:
      return state
  }
}
