import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApolloError } from '@apollo/client'
import { convertPeriodToDates } from '../../../../utils/dates'

import { Col } from '../../../../components/Table/next'
import OverviewReportTable from './Table'

import TableContext, {
  createTableContext,
} from '../../../../contexts/TableContext'
import { checkHttpError, triggerError } from '../../../../actions/app'

import {
  OverviewReportingSorts,
  OverviewReportingPaginatedPayload,
  OverviewReportingQueryVariables,
} from '../../../../generated/types'
import { ReportsStore } from '../../../../typings/stores/ReportsStore'
import { State } from '../../../../reducers'

export type OverviewReportGroupBy = keyof Omit<
  OverviewReportingPaginatedPayload,
  '__typename'
>
export type OverviewReportVariables = OverviewReportingQueryVariables

export type OverviewReportSorts = keyof OverviewReportingSorts

export type OverviewReportFilters = {
  groupBy: string
  dateRange: string
  start: string
  end: string
  productIds: string
  externalPropertyId: string
  userVariables: string
  includeImpressions: string
}

export const OverviewReportTableContext = createTableContext<
  OverviewReportFilters,
  OverviewReportSorts
>()

interface OverviewReportTableContainerProps {
  groupBy: OverviewReportGroupBy
  cols: Col[]
  footerPadding?: number
  getData: ({ variables }: { variables: OverviewReportVariables }) => void
  refetch?: any
  networkStatus?: any
  data: any
  loading: boolean
  error?: ApolloError
  exportData: any
  exportLoading: boolean
  exportError: ApolloError
  getExportData: ({ variables }) => void
  abort: () => void
  reportCallback: (param: string) => void
}

const { startDate, endDate, period } = convertPeriodToDates('this_month')

const OverviewReportTableContainer: FunctionComponent<OverviewReportTableContainerProps> = ({
  groupBy,
  cols,
  footerPadding,
  getData,
  refetch,
  networkStatus,
  data,
  loading,
  error,
  getExportData,
  exportData,
  exportLoading,
  exportError,
  abort,
  reportCallback,
}) => {
  const dispatch = useDispatch()
  const reports = useSelector<State, ReportsStore>(state => state.reports)
  // TODO: We should refactor this out with RVMN-235
  if (error) {
    dispatch(triggerError(checkHttpError(error.message), 'Overview Report'))
  }

  return (
    <TableContext<OverviewReportFilters, OverviewReportSorts>
      Context={OverviewReportTableContext}
      defaultFilters={{
        dateRange: period,
        start: startDate,
        end: endDate,
        ...(reports.filters?.vertical && {
          vertical: reports.filters.vertical,
        }),
      }}
      queryParams={[
        'groupBy',
        'dateRange',
        'start',
        'end',
        'includeImpressions',
      ]}
    >
      <OverviewReportTable
        groupBy={groupBy}
        cols={cols}
        footerPadding={footerPadding}
        getData={getData}
        data={data}
        loading={loading}
        error={error != null}
        getExportData={getExportData}
        exportData={exportData}
        exportLoading={exportLoading}
        exportError={exportError != null}
        reportCallback={reportCallback}
      />
    </TableContext>
  )
}

export default OverviewReportTableContainer
