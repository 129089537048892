import React, { FunctionComponent, ReactNode } from 'react'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import { ErrorContainer } from '../../containers'
import Side from './Side'
import Top from './Top'

import { MenuItem } from './Side/Item'
import { UserStore } from '../../typings/stores/UserStore'
import { Website } from '../../typings/website'

import styles from './styles.module.css'
import {
  ENABLE_MYFI_REPORTS,
  ENABLE_NEW_PAYMENT,
} from '../../utils/featureFlags'

interface AppFrameProps {
  user: UserStore
  children: ReactNode
  updateActiveWebsite(w: Website): void
}

const menuList: MenuItem[] = [
  {
    name: 'Overview Report',
    icon: 'overview',
    path: '/reports/overview-report',
    minimumRequiredRole: 'user',
  },
  {
    name: 'Vertical Summary',
    icon: 'report',
    path: '/reports/vertical-summary',
    minimumRequiredRole: 'user',
  },
  {
    name: 'Click Details',
    icon: 'report',
    path: '/reports/click-details',
    minimumRequiredRole: 'user',
  },
  {
    name: 'Event Details',
    icon: 'report',
    path: '/reports/event-details',
    minimumRequiredRole: 'user',
  },
  {
    name: 'Programs',
    icon: 'advertiser',
    path: '/programs',
    minimumRequiredRole: 'user',
  },
  {
    name: 'Integrations',
    icon: 'link',
    path: '/links',
    minimumRequiredRole: 'portalintegration',
  },
  {
    name: 'Link Info',
    icon: 'laptop-code',
    className: styles.link,
    path: '/link-info',
    minimumRequiredRole: 'portalintegration',
  },
]
if (ENABLE_NEW_PAYMENT) {
  menuList.push({
    name: 'Payments',
    icon: 'payment',
    path: '/payments',
    minimumRequiredRole: 'admin',
  })
}
menuList.push({
  name: 'Alerts',
  icon: 'alert',
  path: '/alerts',
  minimumRequiredRole: 'user',
})
menuList.push({
  name: 'Support',
  icon: 'support',
  path: '/support',
  minimumRequiredRole: 'portalintegration',
})

const AppFrame: FunctionComponent<AppFrameProps> = ({
  user,
  children,
  updateActiveWebsite,
}) => {
  let isFiltered = false

  if (!ENABLE_MYFI_REPORTS) {
    isFiltered = true
  }

  return (
    <div className={styles.container} data-rv="app-frame">
      <Side
        className={classnames(
          !isFiltered && styles.highlight,
          styles.left,
          styles.column
        )}
        menuList={menuList}
      />
      <div className={classnames(styles.right, styles.column)}>
        <ErrorContainer />
        <Top
          className={styles.topContainer}
          userName={`${user.firstName} ${user.lastName}`}
          userInitials={`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
          activeWebsite={user.activeWebsite}
          websites={user.websites}
          updateActiveWebsite={updateActiveWebsite}
        />
        <div className={styles.content} data-rv="content">
          {children}
        </div>
        <ReactTooltip
          id="global-tooltip"
          role="tooltip"
          place="left"
          effect="float"
          className={styles.tooltip}
        />
      </div>
    </div>
  )
}

export default AppFrame
