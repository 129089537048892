import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'

export default class ReCaptcha extends Component {
  // Add the script to the page so we don't have to do it globally
  componentDidMount = () => {
    const gApi = 'https://www.google.com/recaptcha/api.js'
    const scriptExists = document.querySelectorAll(`script[src='${gApi}']`)

    if (scriptExists.length < 1) {
      const script = document.createElement('script')
      script.src = gApi
      script.async = true

      document.body.appendChild(script)
    }
  }

  render() {
    const classes = classnames('g-recaptcha', styles.container)

    return (
      <div
        className={classes}
        data-sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
        data-rv="recaptcha"
      />
    )
  }
}
