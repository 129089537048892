import React, { FunctionComponent, useState, useEffect } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import { Link } from 'react-router-dom'

import { CopyrightFooter } from '../..'
import ForgotPasswordForm from './Form'
import SubmittedDisplay from './Submitted'

import { PasswordStore } from '../../../typings/password'

import linkTransitions from '../../../utils/transitions/additionalLinks.module.css'
import dialogTransitions from '../../../utils/transitions/dialog.module.css'
import styles from './styles.module.css'

interface ForgotPasswordProps {
  password: PasswordStore
  requestPasswordReset(): void
}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({
  password,
  requestPasswordReset,
}) => {
  const [showLinks, setShowLinks] = useState(false)

  useEffect(() => {
    const loadDelay = setTimeout(() => {
      setShowLinks(true)
    }, 600)

    return () => {
      setShowLinks(false)
      clearTimeout(loadDelay)
    }
  }, [password.submitted])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SwitchTransition>
          <CSSTransition
            key={password.submitted}
            addEndListener={(node, done) =>
              node.addEventListener('transitioned', done, false)
            }
            classNames={dialogTransitions}
            timeout={300}
            appear
            mountOnEnter
            unmountOnExit
          >
            {password.submitted ? (
              <SubmittedDisplay />
            ) : (
              <ForgotPasswordForm requestPasswordReset={requestPasswordReset} />
            )}
          </CSSTransition>
        </SwitchTransition>
        <CSSTransition
          in={showLinks}
          timeout={300}
          classNames={linkTransitions}
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.additionalLinks}>
            <Link className={styles.link} to="/new/login" data-rv="reset-login">
              Back to Login
            </Link>
          </div>
        </CSSTransition>
      </div>
      <CopyrightFooter delay={900} />
    </div>
  )
}

export default ForgotPassword
