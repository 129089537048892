import _isEmpty from 'lodash/isEmpty'

import { fetchGetApi, fetchPostApi } from '../utils/fetchWrapper'
import getConfig from '../config'

import { Action } from '../typings/reducer'
import { Website } from '../typings/website'
import { UserStore } from '../typings/stores/UserStore'

import history from '../browserHistory'
import { resetVerticalFilters } from './reports'
import localforage from 'localforage'

export enum UserConstants {
  LOGIN = 'user/LOGIN',
  LOGIN_SUCCESS = 'user/LOGIN_SUCCESS',
  LOGIN_FAILURE = 'user/LOGIN_FAILURE',
  LOGOUT = 'user/LOGOUT',
  CHECK_SESSION = 'user/CHECK_SESSION',
  CHECK_SESSION_SUCCESS = 'user/CHECK_SESSION_SUCCESS',
  CHECK_SESSION_FAILURE = 'user/CHECK_SESSION_FAILURE',
  RELOAD = 'user/RELOAD',
  CHANGE_WEBSITE = 'user/CHANGE_WEBSITE',
  UPDATE_SUCCESS = 'user/UPDATE_SUCCESS',
  UPDATE_FAILURE = 'user/UPDATE_FAILURE',
}

export interface UserAction extends Action {
  type: UserConstants
  payload?: UserStore
  activeWebsite?: Website
  error?: string
}

async function getActiveWebsite(payload): Promise<Website> {
  // Verify for active website from storage
  let activeWebsite = payload.activeWebsite
  const storageWebsite = await localforage.getItem<Website>(
    `rvmn:affiliate:${payload.affiliateId}:properties-website`
  )
  if (
    storageWebsite &&
    payload.websites.some(
      w => JSON.stringify(w) == JSON.stringify(storageWebsite)
    )
  ) {
    activeWebsite = storageWebsite
  }
  return activeWebsite
}

function login(): UserAction {
  return {
    type: UserConstants.LOGIN,
  }
}

function loginSuccess(payload: UserStore) {
  return {
    type: UserConstants.LOGIN_SUCCESS,
    payload,
  }
}

function loginFailure(error: string): UserAction {
  return {
    type: UserConstants.LOGIN_FAILURE,
    error,
  }
}

function userUpdateSuccess(payload: UserStore): UserAction {
  return {
    type: UserConstants.UPDATE_SUCCESS,
    payload,
  }
}

function userUpdateFailure(error: string): UserAction {
  return {
    type: UserConstants.UPDATE_FAILURE,
    error,
  }
}

function logout(): UserAction {
  return {
    type: UserConstants.LOGOUT,
  }
}

function checkingSession(): UserAction {
  return {
    type: UserConstants.CHECK_SESSION,
  }
}

function checkingSessionSuccess(): UserAction {
  return {
    type: UserConstants.CHECK_SESSION_SUCCESS,
  }
}

function checkingSessionFailure(error: string): UserAction {
  return {
    type: UserConstants.CHECK_SESSION_FAILURE,
    error,
  }
}

function reloadUser(payload: UserStore): UserAction {
  return {
    type: UserConstants.RELOAD,
    payload,
  }
}

function setActiveWebsite(website: Website): UserAction {
  return {
    type: UserConstants.CHANGE_WEBSITE,
    activeWebsite: website,
  }
}

export const loginPortalUser = () => async dispatch => {
  dispatch(login())

  const { ok, bodyParsed, errorParsed } = await fetchPostApi(
    fetch,
    '/auth/auth0/portal-users/login',
    {}
  )

  if (ok) {
    bodyParsed.activeWebsite = await getActiveWebsite(bodyParsed)
    dispatch(loginSuccess(bodyParsed))
  } else {
    dispatch(loginFailure(errorParsed))
    const baseUrl = getConfig('bffHostname')
    window.location.href = baseUrl + '/api/auth/auth0/logout?failed'
  }
}

export const logoutPortalUser = () => async dispatch => {
  dispatch(logout())

  const { ok, errorParsed } = await fetchGetApi(fetch, '/auth/logout')

  if (!ok) {
    console.error(errorParsed)
  }
}

export const loginUser = (
  username: string,
  password: string
) => async dispatch => {
  dispatch(login())

  const { ok, bodyParsed, errorParsed } = await fetchPostApi(
    fetch,
    `/auth/login`,
    {
      username,
      password,
    }
  )

  if (ok) {
    bodyParsed.activeWebsite = await getActiveWebsite(bodyParsed)
    dispatch(loginSuccess(bodyParsed))
  } else {
    dispatch(loginFailure(errorParsed))
  }
}

export const logoutUser: Function = () => async dispatch => {
  dispatch(logout())

  const { ok, errorParsed } = await fetchGetApi(fetch, '/auth/logout')

  if (ok) {
    history.push('/login')
  } else {
    console.error(errorParsed)
  }
}

export const getUserFromSession = () => async dispatch => {
  dispatch(checkingSession())

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(
    fetch,
    '/auth/isLoggedIn'
  )

  if (ok) {
    if (!_isEmpty(bodyParsed)) {
      bodyParsed.activeWebsite = await getActiveWebsite(bodyParsed)
      dispatch(reloadUser(bodyParsed))
    } else {
      dispatch(logout())
    }

    dispatch(checkingSessionSuccess())
  } else {
    dispatch(checkingSessionFailure(errorParsed))
  }
}

export const changeActiveWebsite = (website: Website) => async dispatch => {
  dispatch(resetVerticalFilters())
  dispatch(setActiveWebsite(website))

  const { ok, bodyParsed, errorParsed } = await fetchPostApi(
    fetch,
    '/auth/updateWebsite',
    {
      website,
    }
  )

  if (ok) {
    dispatch(userUpdateSuccess(bodyParsed))
  } else {
    dispatch(userUpdateFailure(errorParsed))
  }
}
