import React, { FunctionComponent } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import TableContext, {
  createTableContext,
} from '../../../../contexts/TableContext'
import Table from './Table'

export type DetailsSorts =
  | 'adjustments'
  | 'commission'
  | 'programName'
  | 'processedAt'
  | 'productName'
  | 'total'
  | 'paymentId'

export interface DetailsFilters {}

export const DetailsTableContext = createTableContext<
  DetailsFilters,
  DetailsSorts
>()

interface DetailsContainerMatchParams {
  paymentId: string
}

const DetailsContainer: FunctionComponent = () => {
  const { params } = useRouteMatch<DetailsContainerMatchParams>()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const paymentTotal = query.get('total') || '0'

  return (
    <TableContext<DetailsFilters, DetailsSorts>
      Context={DetailsTableContext}
      defaultSort={{
        by: 'processedAt',
        dir: 'desc',
      }}
      defaultPagination={{
        page: 1,
        size: 10,
      }}
    >
      <Table paymentId={params.paymentId} paymentTotal={paymentTotal} />
    </TableContext>
  )
}

export default DetailsContainer
