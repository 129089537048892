import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { Alerts } from '../../pages'
import { Err404 } from '../../components'

const AlertsRouter: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={Alerts} />
    <Route component={Err404} />
  </Switch>
)

export default AlertsRouter
