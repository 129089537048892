import { Dispatch } from 'redux'
import { stringify } from 'query-string'
import _capitalize from 'lodash/capitalize'
import filesaver from 'file-saver'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { ReportFilters, ReportType } from '../typings/report'
import { DetailReport } from '../typings/detailReport'
import { stripHyphens } from '../utils/fileExport'

export enum DetailReportConstants {
  FETCH = 'detailReport/FETCH',
  FETCH_SUCCESS = 'detailReport/FETCH_SUCCESS',
  FETCH_FAILURE = 'detailReport/FETCH_FAILURE',
  EXPORT_FETCH = 'detailReport/EXPORT_FETCH',
  EXPORT_FETCH_SUCCESS = 'detailReport/EXPORT_FETCH_SUCCESS',
  EXPORT_FETCH_FAILURE = 'detailReport/EXPORT_FETCH_FAILURE',
}

export interface DetailReportAction extends Action {
  type: DetailReportConstants
  data?: DetailReport
  reportType?: ReportType
  filters?: ReportFilters
  error?: string
}

function fetchDetailReport(): DetailReportAction {
  return {
    type: DetailReportConstants.FETCH,
  }
}

function fetchDetailSuccess(
  reportType: ReportType,
  data: DetailReport,
  filters?: ReportFilters
): DetailReportAction {
  return {
    type: DetailReportConstants.FETCH_SUCCESS,
    reportType,
    data,
    filters,
  }
}

function fetchDetailReportFailure(error: string): DetailReportAction {
  return {
    type: DetailReportConstants.FETCH_FAILURE,
    error,
  }
}

function fetchDetailReportForExport(): DetailReportAction {
  return {
    type: DetailReportConstants.EXPORT_FETCH,
  }
}

function fetchDetailReportForExportSuccess(
  reportType: ReportType,
  filters?: ReportFilters
): DetailReportAction {
  return {
    type: DetailReportConstants.EXPORT_FETCH_SUCCESS,
    reportType,
    filters,
  }
}

function fetchDetailReportForExportFailure(error: string): DetailReportAction {
  return {
    type: DetailReportConstants.EXPORT_FETCH_FAILURE,
    error,
  }
}

export const getDetailReport = cancelFetchOnReentrySync(
  fetch => (reportType: ReportType, filters?: ReportFilters) =>
    swallowCancellation(async dispatch => {
      dispatch(fetchDetailReport())

      const { ok, errorParsed, bodyParsed } = await fetchGetApi(
        fetch,
        `/reports/${reportType}-details?${stringify(filters)}`
      )

      if (ok) {
        dispatch(fetchDetailSuccess(reportType, bodyParsed.data, filters))
      } else {
        dispatch(
          triggerError(errorParsed, `${_capitalize(reportType)} Detail Report`)
        )
        dispatch(fetchDetailReportFailure(errorParsed))
      }
    })
)

export const exportDetailReport = (
  reportType: ReportType,
  filters?: ReportFilters
) => async (dispatch: Dispatch) => {
  dispatch(fetchDetailReportForExport())

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(
    fetch,
    `/reports/${reportType}-details/export?${stringify(filters)}`
  )

  if (ok) {
    const filename = `${reportType}-details_${stripHyphens(
      filters.startDate
    )}-${stripHyphens(filters.endDate)}.csv`

    const file = new Blob([bodyParsed], {
      type: 'text/csv;charset=utf8',
    })

    filesaver.saveAs(file, filename)

    dispatch(fetchDetailReportForExportSuccess(reportType, filters))
  } else {
    dispatch(triggerError(errorParsed, `${_capitalize(reportType)} Report`))
    dispatch(fetchDetailReportForExportFailure(errorParsed))
  }
}
