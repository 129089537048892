import React, { FunctionComponent, useState, useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { Loading } from '../../components'
import TosApproval from './TosApproval'

import { TermsStore } from '../../typings/support'

import loadingTransitions from '../../utils/transitions/loading.module.css'
import contentTransitions from './content.module.css'
import footerTransitions from './footer.module.css'
import styles from './styles.module.css'
import termsStyles from './terms.module.css'

interface TermsProps {
  content?: TermsStore
  approvalFooter?: boolean
  internal?: boolean
  acceptTerms?(): void
}

const formatHtmlContent = content => {
  const div = document.createElement('div')

  div.innerHTML = content

  return div.innerHTML
}

const Terms: FunctionComponent<TermsProps> = ({
  content,
  approvalFooter,
  internal,
  acceptTerms,
}) => {
  const [htmlContent, setHtmlContent] = useState()

  useEffect(() => {
    if (content.hasData) {
      setHtmlContent(formatHtmlContent(content.data.terms))
    }
  }, [content.data.terms, content.hasData])

  return (
    <div className={styles.container}>
      {content.fetching ? (
        <CSSTransition
          in={content.fetching}
          classNames={loadingTransitions}
          timeout={300}
          appear
          mountOnEnter
          unmountOnExit
        >
          <div className={classnames(styles.statusContainer, styles.done)}>
            <Loading className={styles.loading} />
          </div>
        </CSSTransition>
      ) : (
        <>
          <SwitchTransition>
            <CSSTransition
              key={content.fetched}
              classNames={contentTransitions}
              timeout={300}
              appear
              mountOnEnter
              unmountOnExit
            >
              {!content.hasData && !_isEmpty(content.error) ? (
                <div
                  className={classnames(styles.statusContainer, styles.done)}
                >
                  Unable to load the Terms and Conditions. Please refresh or
                  contact our support team if problems persist.
                </div>
              ) : (
                <>
                  <div
                    className={classnames(styles.content, {
                      [styles.internal]: internal,
                    })}
                  >
                    <div
                      className={termsStyles.terms}
                      dangerouslySetInnerHTML={{
                        __html: !_isEmpty(htmlContent) ? htmlContent : '',
                      }}
                    />
                  </div>
                </>
              )}
            </CSSTransition>
          </SwitchTransition>
          {approvalFooter && content.hasData && (
            <CSSTransition
              in={approvalFooter}
              classNames={footerTransitions}
              timeout={100}
              appear
              mountOnEnter
              unmountOnExit
            >
              <TosApproval
                acceptTerms={acceptTerms}
                tosId={content.data.tosId}
              />
            </CSSTransition>
          )}
        </>
      )}
    </div>
  )
}

export default Terms
