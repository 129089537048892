import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { IconsProps } from './iconsProps'

const Check: FunctionComponent<IconsProps> = ({ className }) => (
  <svg
    width="15px"
    height="11px"
    viewBox="0 0 15 11"
    version="1.1"
    className={classnames(className)}
  >
    <title>check</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Check-/-Complete"
        transform="translate(-4.000000, -7.000000)"
        fill="#FFFFFF"
        stroke="#FFFFFF"
      >
        <path
          d="M17.5898434,8.13791052 L9.84679416,15.8592882 L5.80682246,11.8307433 C5.62162918,11.6467321 5.32216771,11.6471262 5.13776248,11.8315314 C4.95375129,12.0167247 4.95414532,12.3161861 5.13855054,12.5001973 L9.5126582,16.8620901 C9.54496851,16.8940064 9.58397731,16.9133138 9.62180402,16.9341973 C9.63795918,16.9432599 9.65175017,16.958233 9.66869339,16.9653255 C9.72582748,16.9885732 9.78611381,17 9.84679416,17 C9.90708048,17 9.96776083,16.9885732 10.0245009,16.9653255 C10.0418381,16.958233 10.0552351,16.9432599 10.0713903,16.9341973 C10.109611,16.9133138 10.1482258,16.8940064 10.1805361,16.8620901 L18.2577213,8.80736453 C18.4425205,8.6229593 18.4429146,8.32389186 18.2585093,8.13869858 C18.0744981,7.95429335 17.7754307,7.9535053 17.5898434,8.13791052"
          id="Fill-24"
        />
      </g>
    </g>
  </svg>
)

export default Check
