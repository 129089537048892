import React, { FunctionComponent, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { PublisherPage } from '../components'

import {
  getPublisher,
  enableReporting,
  disableReporting,
  rotateSecret,
} from '../actions/publisher'

import { State } from '../reducers'
import { PublisherStore } from '../typings/publisher'
import { AppUser } from '../typings/user'

interface PublisherProps extends RouteComponentProps {
  user?: AppUser
  publisher?: PublisherStore
  getPublisher(publisherId: string): Promise<void>
  enableReporting(publisherId: string, resource: string): Promise<void>
  disableReporting(publisherId: string, resource: string): Promise<void>
  rotateSecret(publisherId: string, resource: string): Promise<void>
}

const PublisherContainer: FunctionComponent<PublisherProps> = ({
  publisher,
  user,
  getPublisher,
  enableReporting,
  disableReporting,
  rotateSecret,
}) => {
  useEffect(() => {
    if (!publisher.fetching && !publisher.fetched) {
      getPublisher(user.affiliateId)
    }
  }, [publisher, user])
  return (
    <PublisherPage
      user={user}
      publisher={publisher}
      enableReporting={enableReporting}
      disableReporting={disableReporting}
      rotateSecret={rotateSecret}
    />
  )
}

const mapStateToProps = (state: State) => ({
  publisher: state.publisher,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  getPublisher: (p: string) => dispatch(getPublisher(p)),
  enableReporting: (p: string, resource: string) =>
    dispatch(enableReporting(p, resource)),
  disableReporting: (p: string, resource: string) =>
    dispatch(disableReporting(p, resource)),
  rotateSecret: (p: string, resource: string) =>
    dispatch(rotateSecret(p, resource)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PublisherContainer)
)
