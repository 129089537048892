import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useHistory } from 'react-router-dom'
import accounting from 'accounting'
import styles from '../styles.module.css'
import Table, { TableFooter } from '../../../../components/Table/next'
import { HistoryTableContext } from './index'
import { PaymentsHistoryReportingQueryVariables } from '../../../../generated/types'
import { DateTimeDisplay, GenericButton } from '../../../../components'
import { DateTime } from 'luxon'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

interface PaymentHistory {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
}

const PaymentHistory: FunctionComponent<PaymentHistory> = ({
  getData,
  data,
  loading,
  error,
}) => {
  const history = useHistory()

  function handleClick(paymentId, total) {
    history.push(`/payments/details/${paymentId}?total=${total}`)
  }

  const {
    sort,
    pagination: { page, size },
    changePage,
    changePageSize,
    changeSort,
  } = useContext(HistoryTableContext)

  const getTotal = (data, param) =>
    data.reduce((sum, page) => sum + page[param], 0)

  const totalRowBuilder: TableFooter[] = [
    {
      text: 'Total',
      colSpan: 2,
      className: styles.footerHeader,
    },
    {
      text: data
        ? accounting.formatMoney(
            getTotal(data.paymentsHistoryReporting.page, 'total'),
            '$'
          )
        : 0,
      className: styles.right,
    },
    {
      text: '',
      colSpan: 2,
    },
  ]

  const allColumns = [
    {
      text: 'Payment ID',
      sortable: true,
      field: 'paymentId',
    },
    {
      text: 'Payment Date (UTC)',
      field: 'processedAt',
      sortable: true,
      render: row =>
        row.processedAt && (
          <DateTimeDisplay
            datetime={DateTime.fromISO(row.processedAt, { zone: 'utc' })}
          />
        ),
    },
    {
      text: 'Amount',
      field: 'total',
      sortable: true,
      className: styles.right,
      render: ({ total }) => accounting.formatMoney(total, '$'),
    },
    {
      text: 'Status',
      field: 'status',
      sortable: true,
    },
    {
      text: '',
      className: styles.nostretch,
      render: ({ paymentId, total }) => (
        <GenericButton onClick={() => handleClick(paymentId, total)} small>
          View
        </GenericButton>
      ),
    },
  ]
  const [tableColumns, setTableColumns] = useState(allColumns)

  const user = useSelector<State, UserStore>(state => state.user)

  const variables = () => {
    return {
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPublisherId: user.affiliateId,
    } as PaymentsHistoryReportingQueryVariables
  }

  const fetchData = () => {
    getData({ variables: variables() }), setTableColumns(allColumns)
  }

  useEffect(fetchData, [sort, page, size, user.affiliateId])

  const pagination = {
    currentPage: get(
      data,
      `paymentsHistoryReporting.pagination.currentPage`,
      1
    ),
    pageSize: get(data, `paymentsHistoryReporting.pagination.pageSize`, 10),
    total: get(data, `paymentsHistoryReporting.pagination.totalEntries`, 0),
  }

  return (
    <Table
      className={styles.table}
      cols={tableColumns}
      isLoading={loading}
      onSortChange={changeSort}
      totalRow={totalRowBuilder}
      sort={sort}
      data={data && get(data, `paymentsHistoryReporting.page`)}
      pagination={{
        onPageSizeUpdate: changePageSize,
        onPaginate: changePage,
        ...pagination,
      }}
    />
  )
}

export default PaymentHistory
