import { SelectOption } from '../components/Select'

const dateOptions: SelectOption[] = [
  { value: 'today', text: 'Today' },
  { value: 'yesterday', text: 'Yesterday' },
  { value: 'this_week', text: 'This Week' },
  { value: 'last_week', text: 'Last Week' },
  { value: 'this_month', text: 'This Month' },
  { value: 'last_month', text: 'Last Month' },
  { value: 'last_quarter', text: 'Last Quarter' },
  { value: 'year_to_date', text: 'Year to Date' },
  { value: 'custom', text: 'Custom' },
]

export default dateOptions
