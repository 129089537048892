import { Dispatch } from 'redux'

import { triggerError } from './app'
import {
  fetchGetApi,
  fetchGetPublic,
  fetchPostApi,
} from '../utils/fetchWrapper'

import { getUserFromSession } from './user'

import { Action } from '../typings/reducer'
import { TermsData } from '../typings/support'

export enum TermsConstants {
  FETCH = 'support/terms/FETCH',
  FETCH_SUCCESS = 'support/terms/FETCH_SUCCESS',
  FETCH_FAILURE = 'support/terms/FETCH_FAILURE',
  ACCEPT = 'support/terms/ACCEPT',
  ACCEPT_SUCCESS = 'support/terms/ACCEPT_SUCCESS',
  ACCEPT_FAILURE = 'support/terms/ACCEPT_FAILURE',
}

export interface TermsAction extends Action {
  type: TermsConstants
  data?: TermsData
  error?: string
}

function fetchTerms(): TermsAction {
  return {
    type: TermsConstants.FETCH,
  }
}

function fetchTermsSuccess(data: TermsData): TermsAction {
  return {
    type: TermsConstants.FETCH_SUCCESS,
    data,
  }
}

function fetchTermsFailure(error: string): TermsAction {
  return {
    type: TermsConstants.FETCH_FAILURE,
    error,
  }
}

function acceptTerms(): TermsAction {
  return {
    type: TermsConstants.ACCEPT,
  }
}

function acceptTermsSuccess(): TermsAction {
  return {
    type: TermsConstants.ACCEPT_SUCCESS,
  }
}

function acceptTermsFailure(error: string): TermsAction {
  return {
    type: TermsConstants.ACCEPT_FAILURE,
    error,
  }
}

export const getApprovedTerms = () => async (dispatch: Dispatch) => {
  dispatch(fetchTerms())

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    '/support/terms'
  )

  if (ok) {
    dispatch(fetchTermsSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Terms and Conditions'))
    dispatch(fetchTermsFailure(errorParsed))
  }
}

export const getCurrentTerms = () => async (dispatch: Dispatch) => {
  dispatch(fetchTerms())

  const { ok, bodyParsed, errorParsed } = await fetchGetPublic(fetch, '/terms')

  if (ok) {
    dispatch(fetchTermsSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Terms and Conditions'))
    dispatch(fetchTermsFailure(errorParsed))
  }
}

export const acceptCurrentTerms = (initials: string, tosId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(acceptTerms())

  const { ok, errorParsed } = await fetchPostApi(
    fetch,
    `/support/accept-terms/`,
    {
      initials: initials.toUpperCase(),
      tos_id: tosId,
    }
  )

  if (ok) {
    // acceptTerms should return the following data
    // {
    //   tos_map_id,
    //   account_id,
    //   tos_id,
    //   user_id,
    //   initials,
    //   ip,
    //   date_created,
    // }
    //
    // We aren't doing anything with it for now, but documenting it just in case

    await fetchGetApi(fetch, '/auth/updateTosConfirmation')

    dispatch(acceptTermsSuccess())

    // The idea here is that we re-pull our session and the rerouting logic on the TOS acceptance page should automatically fire and force us into the app
    getUserFromSession()(dispatch)
  } else {
    dispatch(triggerError(errorParsed, 'Accepting Terms and Conditions'))
    dispatch(acceptTermsFailure(errorParsed))
  }
}
