import React, { Component, ReactNode } from 'react'
import classnames from 'classnames'

import getConfig from '../../config'

import headerStyles from './header.module.css'
import styles from './styles.module.css'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  error: string
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    error: null,
  }

  componentDidCatch(e: Error) {
    // @ts-ignore
    const nr = window.newrelic

    this.setState({ error: e.message })

    if (nr && typeof nr.noticeError === 'function') {
      nr.noticeError(e)
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    const email = getConfig('supportEmail')

    if (!error) {
      return children
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.error}>
          <div
            className={classnames(headerStyles.header, styles.headerOverrides)}
          >
            <button
              onClick={() => (window.location.pathname = '/app')}
              className={headerStyles.backLink}
            >
              Back to home
            </button>
          </div>

          <h1>An error has occurred</h1>

          <p>
            Please contact&nbsp;
            <a className={styles.email} href={`mailto:${email}`}>
              {email}
            </a>
          </p>
        </div>
      </div>
    )
  }
}
