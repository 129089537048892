import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { PublisherContainer } from '../../containers'
import { Err404 } from '../../components'

interface PublisherRouterProps extends RouteComponentProps {}

const PublisherRouter: FunctionComponent<PublisherRouterProps> = ({
  match,
}) => (
  <Switch>
    <Route path={match.path} render={props => <PublisherContainer />} />
    <Route component={Err404} />
  </Switch>
)

export default PublisherRouter
