import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from 'react'

import { PageDescription, PageHeading, Tabs } from '../../../../components'
import Summary from './Summary'

import {
  OutstandingBalanceReportingQueryVariables,
  useOutstandingBalanceReportingLazyQuery,
  useOutstandingBalanceReportingSummaryLazyQuery,
} from '../../../../generated/types'
import OutstandingBalance from './OutstandingBalance'
import ReportActions from '../../../../components/ReportActions'
import { OutstandingBalanceTableContext } from './index'
import { dumpData } from '../../../../actions/reports'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'
import styles from '../styles.module.css'

const OutstandingBalanceTable: FunctionComponent = () => {
  const user = useSelector<State, UserStore>(state => state.user)
  const [isExporting, setIsExporting] = useState(false)
  const [pointer, setPointer] = useState(0)
  const [dataToExport, setDataToExport] = useState([])
  const recordsPerPage = 10000

  const {
    sort,
    pagination: { page, size },
  } = useContext(OutstandingBalanceTableContext)

  const [
    outstandingBalance,
    { data, loading, error },
  ] = useOutstandingBalanceReportingLazyQuery()

  const [
    outstandingBalanceExport,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useOutstandingBalanceReportingLazyQuery()

  const [
    outstandingBalanceSummary,
    { data: dataSummary, loading: loadingSummary, error: errorSummary },
  ] = useOutstandingBalanceReportingSummaryLazyQuery()

  // useEffect(() => {
  //   dumpData(
  //     'outstandingBalance',
  //     exportData?.outstandingBalanceReporting?.page
  //   )
  // }, [exportData])

  const fetchExport = () => {
    setDataToExport([])
    setPointer(0)
    setIsExporting(true)
    outstandingBalanceExport({
      variables: {
        ...variables(),
        take: recordsPerPage,
      },
    })
  }

  useEffect(() => {
    const pagedData = exportData?.outstandingBalanceReporting?.page
    if (pagedData) {
      setDataToExport(v => [...v, ...pagedData])
      setPointer(v => v + pagedData.length)
    }
  }, [exportData])

  useEffect(() => {
    if (
      isExporting &&
      pointer <
        get(data, `outstandingBalanceReporting.pagination.totalEntries`, 0)
    ) {
      outstandingBalanceExport({
        variables: {
          ...variables(),
          skip: pointer,
          take: recordsPerPage,
        },
      })
    } else {
      if (dataToExport.length) {
        dumpData('outstandingBalance', dataToExport)
        setIsExporting(false)
        setDataToExport([])
        setPointer(0)
      }
    }
  }, [pointer])

  const variables = () => {
    return {
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPublisherId: user.affiliateId,
    } as OutstandingBalanceReportingQueryVariables
  }

  return (
    <>
      <PageHeading title="Payments">
        <Tabs
          items={[
            {
              title: 'Outstanding Balance',
              isSelected: true,
              to: './outstanding-balance',
            },
            {
              title: 'Payment History',
              isSelected: false,
              to: './history',
            },
          ]}
        />
        <ReportActions
          className={styles.actions}
          fetchExport={fetchExport}
          disabled={
            !get(data, `outstandingBalanceReporting.pagination.totalEntries`, 0)
          }
          reportStatus={{ loading: loading, hasError: error != null }}
          exportStatus={{
            loading: exportLoading,
            hasError: exportError != null,
          }}
          totalsStatus={{
            loading: loadingSummary,
            hasError: errorSummary != null,
          }}
        />
      </PageHeading>

      <PageDescription content="This report reflects all the commissions to be paid in the next payment." />

      <Summary
        getData={outstandingBalanceSummary}
        data={dataSummary}
        loading={loadingSummary}
        error={errorSummary != null}
      />

      <OutstandingBalance
        getData={outstandingBalance}
        data={data}
        loading={loading}
        error={error != null}
      />
    </>
  )
}

export default OutstandingBalanceTable
