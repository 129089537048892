import React, { FunctionComponent, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { PublicWrapper, Terms } from '../components'

import { getUserFromSession } from '../actions/user'
import { getCurrentTerms, acceptCurrentTerms } from '../actions/terms'
import { useStoredUser } from '../utils/sorcery'

import { State } from '../reducers'
import { TermsStore } from '../typings/support'
import { UserStore } from '../typings/stores/UserStore'

interface TosProps extends RouteComponentProps {
  user: UserStore
  terms?: TermsStore
  getUserFromSession(): void
  getCurrentTerms(): void
  acceptTerms(): void
}

const TosContainer: FunctionComponent<TosProps> = ({
  history,
  user,
  terms,
  getUserFromSession,
  getCurrentTerms,
  acceptTerms,
}) => {
  const sessionChecked = useStoredUser(getUserFromSession, user)

  useEffect(() => {
    // If user exists in memory, is logged in, and has approved the current TOS,
    // then redirect to main app
    if (
      sessionChecked &&
      !user.loggingIn &&
      user.loggedIn &&
      user.tosConfirmed
    ) {
      history.push('/')
    } else if (sessionChecked && !user.loggingIn && !user.loggedIn) {
      // If no user is found in memory, or found user is logged out and not in
      // the process of logging in, redirect to logout page
      history.push('/logout')
    }
  }, [
    sessionChecked,
    user.loggingIn,
    user.loggedIn,
    user.tosConfirmed,
    history,
  ])

  useEffect(() => {
    getCurrentTerms()
  }, [getCurrentTerms])

  return (
    <PublicWrapper>
      <Terms content={terms} acceptTerms={acceptTerms} approvalFooter />
    </PublicWrapper>
  )
}

const mapStateToProps = (state: State) => ({
  user: state.user,
  terms: state.terms,
})

const mapDispatchToProps = dispatch => ({
  getUserFromSession: () => dispatch(getUserFromSession()),
  getCurrentTerms: () => dispatch(getCurrentTerms()),
  acceptTerms: (i: string, t: string) => dispatch(acceptCurrentTerms(i, t)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TosContainer)
)
