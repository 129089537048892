import React, { ReactNode, FunctionComponent, useState, useEffect } from 'react'
import { Transition } from 'react-transition-group'
import classnames from 'classnames'

import Svg from '../Svg'

import styles from './styles.module.css'

interface PublicWrapperProps {
  children?: ReactNode
}

const PublicWrapper: FunctionComponent<PublicWrapperProps> = ({ children }) => {
  const [showElements, setShowElements] = useState(false)

  useEffect(() => {
    setShowElements(true)
  }, [])

  return (
    <div className={styles.container}>
      <Transition in={showElements} timeout={600}>
        {state => (
          <div
            className={classnames(styles.top, {
              [styles.slideIn]: state === 'entering',
              [styles.show]: state === 'entered',
              [styles.fadeOut]: state === 'exiting',
              [styles.hide]: state === 'exited',
            })}
          >
            <Svg name="rv-logo" />
          </div>
        )}
      </Transition>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default PublicWrapper
