import React, { FunctionComponent } from 'react'

import AdvertisersTable from './AdvertisersTable'
import TableContext, { createTableContext } from '../../contexts/TableContext'

export type AdvertiserSorts = 'name'

export interface AdvertiserFilters {
  search?: string
  status?: 'all' | 'approved'
}

export const AdvertisersTableContext = createTableContext<
  AdvertiserFilters,
  AdvertiserSorts
>()

const AdvertisersContainer: FunctionComponent = () => {
  return (
    <TableContext<AdvertiserFilters, AdvertiserSorts>
      Context={AdvertisersTableContext}
      queryParams={['search', 'status']}
      defaultFilters={{
        search: '',
        status: 'all',
      }}
    >
      <AdvertisersTable />
    </TableContext>
  )
}

export default AdvertisersContainer
