import { Dispatch } from 'redux'
import produce from 'immer'

import { triggerError } from './app'
import { fetchGetApi } from '../utils/fetchWrapper'
import { Action } from '../typings/reducer'
import { Alert } from '../typings/alert'
import { Pagination } from '../typings/report'
import { AlertFilters } from '../pages/Alerts'
import { AlertsStore } from '../typings/stores/AlertsStore'

export enum AlertsConstants {
  FETCH = 'alerts/FETCH',
  FETCH_SUCCESS = 'alerts/FETCH_SUCCESS',
  FETCH_FAILURE = 'alerts/FETCH_FAILURE',
}

interface AlertData {
  alerts: Alert[]
  pagination: Pagination
}

export interface AlertsAction extends Action {
  type: AlertsConstants
  data?: AlertData
  error?: string
  filters?: AlertFilters
}

function fetchAlerts(): AlertsAction {
  return {
    type: AlertsConstants.FETCH,
  }
}

function fetchAlertsSuccess(
  data: AlertData,
  filters?: AlertFilters
): AlertsAction {
  return {
    type: AlertsConstants.FETCH_SUCCESS,
    data,
    filters,
  }
}

function fetchAlertsFailure(error: string): AlertsAction {
  return {
    type: AlertsConstants.FETCH_FAILURE,
    error,
  }
}

export const getAlerts = (filters?: AlertFilters) => async (
  dispatch: Dispatch
) => {
  dispatch(fetchAlerts())

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(
    fetch,
    '/new/alerts'
  )

  if (ok) {
    dispatch(fetchAlertsSuccess(bodyParsed.data, filters))
  } else {
    dispatch(triggerError(errorParsed, 'Alerts'))
    dispatch(fetchAlertsFailure(errorParsed))
  }
}

export const getAlert = (id: string, contentId: string) => async (
  dispatch: Dispatch,
  getState,
  useSelector
) => {
  const { ok, bodyParsed, errorParsed } = await fetchGetApi(
    fetch,
    `/new/alerts/${id}/?contentId=${contentId}`
  )

  if (ok) {
    let state: AlertsStore = getState().alerts

    const nextState: AlertsStore = produce(state, draftState => {
      draftState.data.map(a => {
        if (a.id == bodyParsed.id) {
          a.content = bodyParsed.content
        }
      })
    })

    dispatch(
      fetchAlertsSuccess({
        alerts: nextState.data,
        pagination: nextState.pagination,
      })
    )
  } else {
    dispatch(triggerError(errorParsed, 'Alerts'))
    dispatch(fetchAlertsFailure(errorParsed))
  }
}
