import React, { FunctionComponent } from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'

import { SupportContainer } from '../../containers'
import { Err404 } from '../../components'

interface SupportRouterProps extends RouteComponentProps<{}> {}

const SupportRouter: FunctionComponent<SupportRouterProps> = ({ match }) => (
  <Switch>
    <Redirect exact path={match.path} to={`${match.path}/faq`} />
    <Route
      path={`${match.path}/contact`}
      render={() => (
        <SupportContainer supportType="contact" basePath={match.url} />
      )}
    />
    <Route
      path={`${match.path}/terms`}
      render={() => (
        <SupportContainer supportType="terms" basePath={match.url} />
      )}
    />
    <Route
      path={`${match.path}/faq`}
      render={() => <SupportContainer supportType="faq" basePath={match.url} />}
    />
    <Route component={Err404} />
  </Switch>
)

export default SupportRouter
