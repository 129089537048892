import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { IconsProps } from './iconsProps'

const Report: FunctionComponent<IconsProps> = ({ className }) => (
  <svg
    width="35px"
    height="27px"
    viewBox="0 0 35 27"
    version="1.1"
    className={classnames(className)}
  >
    <title>report</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="LeftRail/overview"
        transform="translate(-28.000000, -379.000000)"
        fill="#FFFFFF"
      >
        <g id="LeftRail">
          <g id="Tabs" transform="translate(0.000000, 90.000000)">
            <g id="REPORTS" transform="translate(0.000000, 270.000000)">
              <g id="analytics" transform="translate(28.000000, 19.000000)">
                <path
                  d="M33.9840889,0 L0.575978625,0 C0.257918004,0 0,0.253388186 0,0.565862704 L0,26.4341373 C0,26.7466781 0.257918004,27 0.575978625,27 L33.9840214,27 C34.3021495,27 34.56,26.7466118 34.56,26.4341373 L34.56,0.565862704 C34.5600675,0.253388186 34.3021495,0 33.9840889,0 Z M33.4081102,25.8683409 L1.15202475,25.8683409 L1.15202475,1.13172541 L33.4081102,1.13172541 L33.4081102,25.8683409 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M6.48,5.4 C4.69343039,5.4 3.24,6.85343039 3.24,8.64 C3.24,10.4265696 4.69343039,11.88 6.48,11.88 C8.26656961,11.88 9.72,10.4265696 9.72,8.64 C9.72,6.85343039 8.26656961,5.4 6.48,5.4 Z M6.48,6.54354124 C6.61340309,6.54354124 6.74372405,6.55660683 6.87022583,6.58052693 L6.12655886,8.06826288 L4.46340888,8.06826288 C4.71299528,7.18938374 5.52225825,6.54354124 6.48,6.54354124 Z M6.48,10.7363918 C5.52225825,10.7363918 4.71306229,10.0905493 4.46347589,9.21167011 L6.36870792,9.21167011 L8.13370254,9.92619075 C7.749708,10.4187303 7.15137067,10.7363918 6.48,10.7363918 Z M8.56392919,8.86687236 L7.268023,8.3422384 L7.89269253,7.09249731 C8.31253371,7.47608983 8.57645876,8.02779303 8.57645876,8.64 C8.57645876,8.7166515 8.57203656,8.79229796 8.56392919,8.86687236 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M19.9351159,6.48 L10.3048155,6.48 C9.98180655,6.48 9.72,6.7218071 9.72,7.02 C9.72,7.31825618 9.98187509,7.56 10.3048155,7.56 L19.9351845,7.56 C20.2581934,7.56 20.52,7.3181929 20.52,7.02 C20.5199315,6.7218071 20.2581249,6.48 19.9351159,6.48 Z"
                  id="Path"
                />
                <path
                  d="M17.826841,8.64 L11.3330965,8.64 C11.0386533,8.64 10.8,8.8818071 10.8,9.18 C10.8,9.47825618 11.0387158,9.72 11.3330965,9.72 L17.8269035,9.72 C18.1213467,9.72 18.36,9.4781929 18.36,9.18 C18.36,8.8818071 18.1212842,8.64 17.826841,8.64 Z"
                  id="Path"
                />
                <path
                  d="M31.8154844,18.36 L17.8645156,18.36 C17.5416723,18.36 17.28,18.6018071 17.28,18.9 C17.28,19.1982562 17.5417408,19.44 17.8645156,19.44 L31.8154844,19.44 C32.1383277,19.44 32.4,19.1981929 32.4,18.9 C32.4,18.6018071 32.1382592,18.36 31.8154844,18.36 Z"
                  id="Path"
                />
                <path
                  d="M31.8099553,20.52 L21.1100447,20.52 C20.7841475,20.52 20.52,20.7618071 20.52,21.06 C20.52,21.3582562 20.7842167,21.6 21.1100447,21.6 L31.8099553,21.6 C32.1358525,21.6 32.4,21.3581929 32.4,21.06 C32.4,20.7618071 32.1357833,20.52 31.8099553,20.52 Z"
                  id="Path"
                />
                <path
                  d="M30.7478288,22.68 L14.6121712,22.68 C14.296146,22.68 14.04,22.9218071 14.04,23.22 C14.04,23.5181929 14.2962131,23.76 14.6121712,23.76 L30.7478288,23.76 C31.063854,23.76 31.32,23.5182562 31.32,23.22 C31.32,22.9218071 31.0637869,22.68 30.7478288,22.68 Z"
                  id="Path"
                />
                <path
                  d="M18.0659757,3.24008957 L18.1970367,3.10902552 C18.4141474,2.89190998 18.4141474,2.5399522 18.1970367,2.32296694 C17.9799261,2.1058514 17.6279763,2.1058514 17.4109959,2.32296694 L17.2799349,2.4539007 L17.1488738,2.32283666 C16.9317631,2.10572111 16.5798133,2.10572111 16.362833,2.32283666 C16.1457223,2.5399522 16.1457223,2.89190998 16.362833,3.10889524 L16.4938941,3.23995929 L16.362833,3.37102333 C16.1457223,3.58813888 16.1457223,3.94009666 16.362833,4.15714706 C16.4713557,4.26567226 16.6136208,4.32 16.7558859,4.32 C16.8980859,4.32 17.0404162,4.2657374 17.1489389,4.15714706 L17.28,4.02608301 L17.4110611,4.15714706 C17.5195838,4.26567226 17.6618489,4.32 17.8041141,4.32 C17.946314,4.32 18.0886443,4.2657374 18.197167,4.15714706 C18.4142777,3.94003152 18.4142777,3.58807374 18.197167,3.37108848 L18.0659757,3.24008957 Z"
                  id="Path"
                />
                <path
                  d="M23.1915982,3.24 L20.0084018,3.24 C19.6944585,3.24 19.44,3.4818071 19.44,3.78 C19.44,4.07825618 19.6945252,4.32 20.0084018,4.32 L23.1915982,4.32 C23.5055415,4.32 23.76,4.0781929 23.76,3.78 C23.76,3.4818071 23.5054748,3.24 23.1915982,3.24 Z"
                  id="Path"
                />
                <path
                  d="M14.5515982,3.24 L11.3684018,3.24 C11.0544585,3.24 10.8,3.4818071 10.8,3.78 C10.8,4.07825618 11.0545252,4.32 11.3684018,4.32 L14.5515982,4.32 C14.8655415,4.32 15.12,4.0781929 15.12,3.78 C15.12,3.4818071 14.8655415,3.24 14.5515982,3.24 Z"
                  id="Path"
                />
                <path
                  d="M30.9781026,6.82346585 C30.059812,5.90547116 28.8386838,5.4 27.5399487,5.4 C26.2412137,5.4 25.0200855,5.90553951 24.1017949,6.82346585 C22.2060684,8.71843597 22.2060684,11.8017007 24.1017949,13.6965342 C25.0201538,14.6144605 26.2412137,15.12 27.5399487,15.12 C28.8386838,15.12 30.059812,14.6144605 30.9781026,13.6965342 C32.8739658,11.801564 32.8739658,8.71829928 30.9781026,6.82346585 Z M23.8960513,10.8597857 C23.7108205,9.7254827 24.0537265,8.52105891 24.9270256,7.64823951 C25.1268205,7.44860711 25.3458974,7.27562881 25.579265,7.12889455 L26.7564957,9.90638888 L23.8960513,10.8597857 Z M30.1530085,12.8717605 C29.4550256,13.5694146 28.5270256,13.9536437 27.5400171,13.9536437 C26.5529402,13.9536437 25.6249402,13.5694146 24.9269573,12.8717605 C24.6536581,12.5985901 24.4324615,12.2928197 24.2628889,11.9670245 L27.7245641,10.8132435 C27.8788889,10.7617806 28.0047692,10.6482615 28.0717778,10.5000236 C28.1387863,10.3517175 28.1407009,10.182293 28.0772479,10.0325516 L26.6533846,6.67324605 C26.9400855,6.60292022 27.2371795,6.56649295 27.5399487,6.56649295 C28.5270256,6.5664246 29.4550256,6.95065377 30.1530085,7.64830786 C31.5937607,9.08844961 31.5937607,11.4316871 30.1530085,12.8717605 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M4.82144309,20.52 L2.7385569,20.52 C2.41900473,20.52 2.16,20.752284 2.16,21.0387331 L2.16,23.2412669 C2.16,23.5277768 2.41907253,23.76 2.7385569,23.76 L4.82144309,23.76 C5.14099526,23.76 5.39999999,23.527716 5.39999999,23.2412669 L5.39999999,21.0387331 C5.40006779,20.7522232 5.14106306,20.52 4.82144309,20.52 Z M4.24288619,22.7224729 L3.3171816,22.7224729 L3.3171816,21.5574055 L4.24288619,21.5574055 L4.24288619,22.7224729 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M9.14144309,18.36 L7.0585569,18.36 C6.73900473,18.36 6.48,18.6341616 6.48,18.9722535 L6.48,23.1477465 C6.48,23.4859102 6.73907253,23.76 7.0585569,23.76 L9.14144309,23.76 C9.46099526,23.76 9.71999999,23.4858384 9.71999999,23.1477465 L9.71999999,18.9722535 C9.72006779,18.6341616 9.46099526,18.36 9.14144309,18.36 Z M8.56288619,22.535493 L7.6371816,22.535493 L7.6371816,19.5845788 L8.56288619,19.5845788 L8.56288619,22.535493 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M12.3814431,17.28 L10.2985569,17.28 C9.97900473,17.28 9.72,17.5228221 9.72,17.8222666 L9.72,23.2177334 C9.72,23.517305 9.97907253,23.76 10.2985569,23.76 L12.3814431,23.76 C12.7009953,23.76 12.96,23.5171779 12.96,23.2177334 L12.96,17.8222666 C12.96,17.5227585 12.7009275,17.28 12.3814431,17.28 Z M11.8028862,22.6754668 L10.8771816,22.6754668 L10.8771816,18.3645967 L11.8028862,18.3645967 L11.8028862,22.6754668 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M21.0073374,12.96 L12.4726626,12.96 C12.1453195,12.96 11.88,13.2018071 11.88,13.5 C11.88,13.7982562 12.1453889,14.04 12.4726626,14.04 L21.0073374,14.04 C21.3346805,14.04 21.6,13.7981929 21.6,13.5 C21.6000694,13.2017438 21.33475,12.96 21.0073374,12.96 Z"
                  id="Path"
                />
                <circle id="Oval" cx="31.86" cy="2.7" r="1" />
                <circle id="Oval" cx="31.86" cy="24.3" r="1" />
                <circle id="Oval" cx="2.7" cy="24.3" r="1" />
                <circle id="Oval" cx="2.7" cy="2.7" r="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Report
