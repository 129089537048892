import React, { FunctionComponent } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { LinkFilters } from '../../typings/link'
import LinksTable from './LinksTable'
import TableContext, { createTableContext } from '../../contexts/TableContext'
import IntegrationDocTable from './IntegrationDocTable'

export type LinkSorts = 'id' | 'name' | 'programName' | 'commission'

export const LinksTableContext = createTableContext<LinkFilters, LinkSorts>()

interface LinksContainerMatchParams {
  programId?: string
}

const LinksContainer: FunctionComponent = () => {
  const { params } = useRouteMatch<LinksContainerMatchParams>()

  return (
    <TableContext<LinkFilters, LinkSorts>
      Context={LinksTableContext}
      queryParams={['program', 'linkTypes', 'search']}
    >
      <IntegrationDocTable />
      <LinksTable programId={params.programId} />
    </TableContext>
  )
}

export default LinksContainer
