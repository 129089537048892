import React, { FunctionComponent } from 'react'

import TableContext, {
  createTableContext,
} from '../../../../contexts/TableContext'
import Table from './Table'

export type OutstandingBalanceSorts =
  | 'clickId'
  | 'programName'
  | 'commission'
  | 'total'
  | 'processedAt'
  | 'productName'

export interface OutstandingBalanceFilters {}

export const OutstandingBalanceTableContext = createTableContext<
  OutstandingBalanceFilters,
  OutstandingBalanceSorts
>()

const OutstandingBalanceContainer: FunctionComponent = () => {
  return (
    <TableContext<OutstandingBalanceFilters, OutstandingBalanceSorts>
      Context={OutstandingBalanceTableContext}
      defaultSort={{
        by: 'processedAt',
        dir: 'asc',
      }}
      defaultPagination={{
        size: 10,
        page: 1,
      }}
    >
      <Table />
    </TableContext>
  )
}

export default OutstandingBalanceContainer
