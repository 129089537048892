import React, { FunctionComponent, useContext, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _has from 'lodash/has'
import _get from 'lodash/get'

import _uniqueId from 'lodash/uniqueId'
import _isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSync,
  faClipboard,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons'

import { Modal, PageHeading } from '../..'

import styles from './styles.module.css'
import { PublisherStore } from '../../../typings/publisher'
import { AppUser } from '../../../typings/user'
import { AppContext } from '../../../contexts'

library.add(faSync)
library.add(faClipboard)
library.add(faClipboardCheck)

interface PublisherProps extends RouteComponentProps {
  publisher: PublisherStore
  user: AppUser
  enableReporting(publisherId: string, resource: string): Promise<void>
  disableReporting(publisherId: string, resource: string): Promise<void>
  rotateSecret(publisherId: string, resource: string): Promise<void>
}

const PublisherPage: FunctionComponent<PublisherProps> = ({
  publisher,
  user,
  enableReporting,
  disableReporting,
  rotateSecret,
}) => {
  const { isAuthorized } = useContext(AppContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [resource, setResource] = useState(null)

  const handleRotateSecret = async (e, resource: string) => {
    e.preventDefault()
    if (!publisher.fetching) {
      await rotateSecret(publisher.publisherId, resource)
    }
  }

  const handleDisableApi = async (e, resource: string) => {
    e.preventDefault()
    await disableReporting(publisher.publisherId, resource)
    setModalOpen(false)
  }

  const handleApiToggle = async (e, resource: string) => {
    e.preventDefault()

    if (resource === 'REPORTING_API') {
      if (publisher.reportingApiAuthId) {
        setModalOpen(true)
        return
      }
    } else if (resource === 'PRODUCTS_API') {
      if (publisher.productsApiAuthId) {
        setModalOpen(true)
        return
      }
    }

    await enableReporting(publisher.publisherId, resource)
    return
  }

  const close = async e => {
    setModalOpen(false)
  }

  const disableToggleApi = !(
    _has(user, 'role.name') &&
    !_isEmpty(user.role.name) &&
    isAuthorized('admin')
  )
  return (
    <>
      <PageHeading title="Publishers" className={styles.heading}></PageHeading>
      {publisher.fetched && (
        <div>
          <div className={classnames(styles.panel)}>
            <div className={styles.content}>
              <div className={styles.rows}>
                <div className={styles.label}>Publisher Name:</div>
                <div className={styles.data}>{publisher.publisherName}</div>
                <div className={styles.label}>Publisher ID:</div>
                <div className={styles.data}>{publisher.publisherId}</div>
              </div>
            </div>
          </div>
          <div
            className={classnames(styles.panel, {
              [styles.inactive]: user.active != true,
            })}
          >
            <div className={styles.content}>
              <div className={styles.rows}>
                <div className={styles.label}>Reporting API Client ID:</div>
                <div className={styles.data}>
                  {publisher.reportingApiAuthId}
                </div>
                <div className={styles.label}>Reporting API Client Secret:</div>
                <div className={styles.data}>
                  {publisher.reportingApiSecret ||
                    '*******************************************'}
                </div>
              </div>
            </div>
            <div className={styles.manage}>
              <button
                type="button"
                className={styles.edit}
                onClick={async e => {
                  await handleRotateSecret(e, 'REPORTING_API')
                }}
                disabled={publisher.reportingApiAuthId ? false : true}
              >
                <FontAwesomeIcon icon="sync" className={styles.icon} />
                Rotate Secret
              </button>
              <button
                type="button"
                className={styles.status}
                onClick={async e => {
                  setResource('REPORTING_API')
                  await handleApiToggle(e, 'REPORTING_API')
                }}
                disabled={disableToggleApi}
              >
                {publisher.reportingApiAuthId ? (
                  <div className={classnames(styles.indicator, styles.active)}>
                    <span className={styles.label}>{'Enabled'}</span>
                    <FontAwesomeIcon icon={['fas', 'toggle-on']} />
                  </div>
                ) : (
                  <div
                    className={classnames(styles.indicator, styles.inactive)}
                  >
                    <span className={styles.label}>{'Disabled'}</span>
                    <FontAwesomeIcon icon={['fas', 'toggle-off']} />
                  </div>
                )}
              </button>
            </div>
          </div>
          <div
            className={classnames(styles.panel, {
              [styles.inactive]: user.active != true,
            })}
          >
            <div className={styles.content}>
              <div className={styles.rows}>
                <div className={styles.label}>Products API Client ID:</div>
                <div className={styles.data}>{publisher.productsApiAuthId}</div>
                <div className={styles.label}>Products API Client Secret:</div>
                <div className={styles.data}>
                  {publisher.productsApiSecret ||
                    '*******************************************'}
                </div>
              </div>
            </div>
            <div className={styles.manage}>
              <button
                type="button"
                className={styles.edit}
                onClick={async e => {
                  await handleRotateSecret(e, 'PRODUCTS_API')
                }}
                disabled={publisher.productsApiAuthId ? false : true}
              >
                <FontAwesomeIcon icon="sync" className={styles.icon} />
                Rotate Secret
              </button>
              <button
                type="button"
                className={styles.status}
                onClick={async e => {
                  setResource('PRODUCTS_API')
                  await handleApiToggle(e, 'PRODUCTS_API')
                }}
                disabled={disableToggleApi}
              >
                {publisher.productsApiAuthId ? (
                  <div className={classnames(styles.indicator, styles.active)}>
                    <span className={styles.label}>{'Enabled'}</span>
                    <FontAwesomeIcon icon={['fas', 'toggle-on']} />
                  </div>
                ) : (
                  <div
                    className={classnames(styles.indicator, styles.inactive)}
                  >
                    <span className={styles.label}>{'Disabled'}</span>
                    <FontAwesomeIcon icon={['fas', 'toggle-off']} />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <br />

      {publisher.fetched && (
        <div>
          <p>
            These credentials let you securely access our APIs. Please keep
            these secrets in a secure location. Your Reporting API Secret can
            only be only be seen seen when you initially create it. If you
            rotate your secret, the old will be invalidated, and a new one is
            created for you.
          </p>
        </div>
      )}

      <br />

      {publisher.fetched && (
        <div>
          <p>
            Please go to the{' '}
            <a href="https://reporting-api.rvmedianetwork.com/" target="_blank">
              Reporting API documentation
            </a>{' '}
            for more details .
          </p>
        </div>
      )}

      <Modal isOpen={modalOpen} close={close} className={styles.modal}>
        <div className={styles.container}>
          <div className={styles.content}>
            <span className={styles.titleNote}>
              <strong>
                Are you sure?
                <br />
                <br />
              </strong>
              If you disable your API credentials no one will be able access the{' '}
              {resource}. <br /> <br />
              If you lost your secret, you can click "Rotate Secret" to generate
              a new one. <br /> This will invalidate your previous secret you.
            </span>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.button}
              onClick={async e => {
                handleDisableApi(e, resource)
              }}
            >
              Disable {resource}
            </button>
            <button
              className={styles.button}
              onClick={async e => {
                close(e)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default withRouter(PublisherPage)
