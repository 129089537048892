import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface GenericInputProps {
  defaultValue?: string | string[]
  placeholder?: string
  className?: string
  onChange?(event?: ChangeEvent<HTMLInputElement>): void
  value?: string | number
  name?: string
}

const GenericInput: FunctionComponent<GenericInputProps> = ({
  defaultValue,
  placeholder,
  className,
  onChange,
  value,
  name,
}) => {
  const [focused, setFocused] = useState(false)

  return (
    <div className={classnames(styles.container, className)}>
      <input
        type="text"
        defaultValue={defaultValue}
        value={value}
        className={classnames(styles.input, {
          [styles.focused]: focused,
        })}
        placeholder={placeholder}
        name={name}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onChange}
      />
    </div>
  )
}

export default GenericInput
