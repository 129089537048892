import React, { FunctionComponent, useEffect } from 'react'
import { rebuild } from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'

import { PageHeading } from '../../components'
import { State } from '../../reducers'
import Table from '../../components/Table/next'

import styles from './styles.module.css'
import { Website } from '../../typings/website'
import { getIntegrationDocs } from '../../actions/integrationDocs'
import { IntegrationDoc } from '../../typings/integrationDoc'

const IntegrationDocTable: FunctionComponent = () => {
  const dispatch = useDispatch()
  const integrationDocs = useSelector<State, IntegrationDoc[]>(
    state => state.integrationDocs.data
  )

  const loading = useSelector<State, boolean>(
    state => state.integrationDocs.status === 'fetching'
  )
  const hasData = useSelector<State, boolean>(
    state => state.integrationDocs.hasData
  )

  const activeProperty = useSelector<State, Website>(
    state => state.user.activeWebsite
  )

  // Rebind tooltips
  useEffect(() => {
    rebuild()
  })

  // Fetch our data on initial mount
  useEffect(() => {
    dispatch(getIntegrationDocs(activeProperty))
  }, [activeProperty])

  return hasData ? (
    <div className={styles.integrationDocs}>
      <PageHeading
        title="Integration Documentation"
        className={styles.heading}
      />

      <Table
        data={integrationDocs}
        cols={[
          {
            text: 'Name',
            className: styles.wrap,
            field: 'name',
          },
          {
            text: 'Url',
            field: 'url',
            render: row => (
              <a
                href={row.url}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {row.url}
              </a>
            ),
          },
        ]}
        isLoading={loading}
      />
    </div>
  ) : (
    <> </>
  )
}

export default IntegrationDocTable
