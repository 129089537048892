import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import validator from 'validator'

import { Loading } from '../../..'

import { PasswordStore } from '../../../../typings/password'
import { FormMethods } from '../../../../containers/ResetPassword'

import fieldAlertTransitions from '../../../../utils/transitions/fieldAlert.module.css'
import styles from './styles.module.css'

interface FieldErrors {
  newPassword?: string
  confirm?: string
}

interface ResetPasswordFormProps extends RouteComponentProps {
  formMethod: FormMethods
  passwordStore: PasswordStore
  resetPassword(userId: string, password: string): void
}

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = ({
  history,
  formMethod,
  passwordStore,
  resetPassword,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [displayErrors, setDisplayErrors] = useState<FieldErrors>({
    newPassword: '',
    confirm: '',
  })

  const isInitialMount = useRef(true)

  // New Password field errors
  useEffect(() => {
    if (_isEmpty(displayErrors.newPassword)) {
      let errorMessage = ''

      if (_isEmpty(newPassword)) {
        errorMessage = 'Please enter a new password'
      } else if (!validator.isLength(newPassword, { min: 8 })) {
        errorMessage = 'Password must be at least 8 characters'
      }

      setDisplayErrors(prev => ({
        ...prev,
        newPassword: errorMessage,
      }))
    }
  }, [newPassword, displayErrors.newPassword])

  // Password confirmation field errors
  useEffect(() => {
    if (
      _isEmpty(displayErrors.confirm) &&
      !_isEmpty(newPassword) &&
      (_isEmpty(newPasswordConfirm) || newPasswordConfirm !== newPassword) &&
      !isInitialMount.current
    ) {
      setDisplayErrors(prev => ({
        ...prev,
        confirm: 'Passwords do not match',
      }))
    }
  }, [newPassword, newPasswordConfirm, displayErrors.confirm])

  // Reset if errors are addressed
  useEffect(() => {
    if (
      !isInitialMount.current &&
      _isEmpty(displayErrors.newPassword) &&
      _isEmpty(displayErrors.confirm)
    ) {
      setSubmitted(false)
    }
  }, [displayErrors, submitted])

  // Disable submit until form is valid
  useEffect(() => {
    if (
      !submitted &&
      !isSubmitting &&
      _isEmpty(displayErrors.newPassword) &&
      _isEmpty(displayErrors.confirm) &&
      !isInitialMount.current
    ) {
      setEnableSubmit(true)
    } else {
      setEnableSubmit(false)
    }
  }, [displayErrors, isSubmitting, submitted])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
  }, [isInitialMount])

  // On successful update, redirect to login page with success message
  const redirectSuccess = () => {
    if (
      _has(passwordStore, 'user') &&
      passwordStore.submitted &&
      !passwordStore.submitting &&
      _isEmpty(passwordStore.error)
    ) {
      history.push(`/login?result=success&username=${passwordStore.user.email}`)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (
      !submitted &&
      !isSubmitting &&
      _isEmpty(displayErrors.newPassword) &&
      _isEmpty(displayErrors.confirm)
    ) {
      setIsSubmitting(true)

      await resetPassword(passwordStore.user.id, newPassword)
      await redirectSuccess()
    }
  }

  return (
    <form className={styles.container}>
      <h3 className={styles.title}>
        {formMethod[0].toUpperCase() + formMethod.slice(1)} Password
      </h3>
      <div className={styles.row}>
        <input
          type="password"
          name="newPassword"
          placeholder="New Password"
          autoComplete="off"
          onChange={e => {
            const np = e.currentTarget.value

            if (!_isEmpty(displayErrors.newPassword)) {
              setDisplayErrors(prev => ({
                ...prev,
                newPassword: '',
              }))
            }

            setNewPassword(np)
          }}
          data-rv="reset-new-password"
        />
      </div>
      <div className={styles.row}>
        <input
          type="password"
          name="newPasswordConfirm"
          placeholder="Confirm New Password"
          autoComplete="off"
          onChange={e => {
            const npc = e.currentTarget.value

            if (!_isEmpty(displayErrors.confirm)) {
              setDisplayErrors(prev => ({
                ...prev,
                confirm: '',
              }))
            }

            setNewPasswordConfirm(npc)
          }}
          data-rv="reset-new-password-confirm"
        />
      </div>
      <div className={classnames(styles.row, styles.center)}>
        {isSubmitting ? (
          <button className={styles.loading} disabled>
            <Loading className={styles.spinner} />
          </button>
        ) : (
          <>
            <CSSTransition
              in={
                !_isEmpty(displayErrors.newPassword) ||
                !_isEmpty(displayErrors.confirm)
              }
              timeout={600}
              classNames={fieldAlertTransitions}
              appear
              mountOnEnter
              unmountOnExit
            >
              <div className={styles.error} data-rv="reset-error">
                {displayErrors.newPassword || displayErrors.confirm}
              </div>
            </CSSTransition>
            <button
              className={styles.button}
              onClick={async e => {
                setSubmitted(true)
                await handleSubmit(e)
              }}
              disabled={enableSubmit === false}
              data-rv="reset-submit"
            >
              {formMethod[0].toUpperCase() + formMethod.slice(1)} Password
            </button>
          </>
        )}
      </div>
    </form>
  )
}

export default withRouter(ResetPasswordForm)
