import _find from 'lodash/find'
import _map from 'lodash/map'

interface Term {
  term: string
  definition: string
}

const Dictionary: Term[] = [
  {
    term: 'cross sale',
    definition:
      'A cross sale is when a user clicks on one product, but gets approved on a different product.',
  },
  {
    term: 'commission zero',
    definition:
      'There was a problem retrieving the commission from this product at this time.',
  },
  {
    term: 'missing product',
    definition:
      "This product's information has not yet been loaded into the system.",
  },
  {
    term: 'missing advertiser',
    definition:
      "This advertiser's information has not yet been loaded into the system.",
  },
]

export const getDefinedTerms = () => {
  return _map(Dictionary, 'term')
}

export const getDefinition = (term: string): string => {
  const foundWord = _find(Dictionary, { term })

  if (foundWord) {
    return foundWord.definition
  }

  return null
}
