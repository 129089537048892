import React, { FunctionComponent, useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import _has from 'lodash/has'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AppContext } from '../../../../contexts'

import styles from './styles.module.css'

import { AppUser, AppUserFields } from '../../../../typings/user'

interface UserListPanelProps extends RouteComponentProps {
  basePath: string
  user: AppUser
  close(): void
  editUser?(originalUser: AppUser, editedUser: AppUserFields): void
}

const UserListPanel: FunctionComponent<UserListPanelProps> = ({
  history,
  basePath,
  user,
  close,
  editUser,
}) => {
  const { activeUser, isAuthorized } = useContext(AppContext)

  const handleStatusToggle = async e => {
    e.preventDefault()

    const { timeInserted, ...submitableUser } = user

    const updatedUser = {
      ...submitableUser,
      role: user.role.name,
      active: !user.active,
    }

    await editUser(user, updatedUser)
    await close()
  }

  const disableToggleStatus = !(
    _has(user, 'role.name') &&
    !_isEmpty(user.role.name) &&
    activeUser.id !== user.id &&
    isAuthorized('admin')
  )

  return (
    <div
      className={classnames(styles.panel, {
        [styles.inactive]: user.active != true,
      })}
    >
      <div className={styles.content}>
        <h4 className={styles.name}>{`${user.firstName} ${user.lastName}`}</h4>
        <div className={styles.rows}>
          <h5 className={styles.label}>Email:</h5>
          <span className={styles.data}>{user.email}</span>
        </div>
        <div className={styles.panelFooter}>
          <div
            className={classnames(styles.role, {
              [styles.admin]:
                _has(user, 'role.name') && _get(user, 'role.name') === 'admin',
              [styles.manager]:
                _has(user, 'role.name') &&
                _get(user, 'role.name') === 'manager',
              [styles.content]:
                _has(user, 'role.name') &&
                _get(user, 'role.name') === 'contentrunner',
            })}
          >
            {_has(user, 'role.description')
              ? _get(user, 'role.description')
              : 'User'}
          </div>
          <div className={styles.created}>
            <h5>User Since: </h5>
            {DateTime.fromISO(user.timeInserted).toLocaleString(
              DateTime.DATE_FULL
            )}
          </div>
        </div>
      </div>
      <div className={styles.manage}>
        <button
          type="button"
          className={styles.edit}
          onClick={() => {
            history.push(`${basePath}/${user.id}/edit`)
          }}
          disabled={
            _has(user, 'role.name') &&
            _get(user, 'role.name') === 'admin' &&
            !isAuthorized('admin')
          }
        >
          <FontAwesomeIcon
            icon={['fas', 'pencil-alt']}
            className={styles.icon}
          />
          Edit
        </button>
        <button
          type="button"
          className={styles.status}
          onClick={async e => {
            await handleStatusToggle(e)
          }}
          disabled={disableToggleStatus}
        >
          {user.active === true ? (
            <div className={classnames(styles.indicator, styles.active)}>
              <span className={styles.label}>{'ACTIVE'}</span>
              <FontAwesomeIcon icon={['fas', 'toggle-on']} />
            </div>
          ) : (
            <div className={classnames(styles.indicator, styles.inactive)}>
              <span className={styles.label}>{'INACTIVE'}</span>
              <FontAwesomeIcon icon={['fas', 'toggle-off']} />
            </div>
          )}
        </button>
      </div>
    </div>
  )
}

export default withRouter(UserListPanel)
