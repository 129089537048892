import React, { FunctionComponent, useEffect, useState } from 'react'
import classnames from 'classnames'

import WebsiteSelector from './WebsiteSelector'
import UserMenu from './UserMenu'
import Svg from '../../Svg'

import { Website } from '../../../typings/website'

import styles from './styles.module.css'
import { fetchGetPublic } from '../../../utils/fetchWrapper'
import { useLocation } from 'react-router-dom'

interface TopProps {
  className: string
  userName: string
  userInitials: string
  activeWebsite: Website
  websites: Array<Website>
  updateActiveWebsite(w: Website): void
}

const Top: FunctionComponent<TopProps> = ({
  className,
  userName,
  userInitials,
  activeWebsite,
  websites,
  updateActiveWebsite,
}) => {
  const [notificationEvent, setNotificationEvent] = useState(null)
  const location = useLocation()

  useEffect(() => {
    async function getActiveNotification() {
      try {
        const { ok, errorParsed, bodyParsed } = await fetchGetPublic(
          fetch,
          '/notifications/active'
        )
        if (ok) {
          if (bodyParsed.length > 0) {
            const notification = bodyParsed[0]
            if (notification.active === true) {
              setNotificationEvent(notification)
            } else {
              setNotificationEvent(null)
            }
          } else {
            setNotificationEvent(null)
          }
        } else {
          console.log(errorParsed)
        }
      } catch (error) {
        console.log(error)
      }
    }

    getActiveNotification()
  }, [location])

  return (
    <div>
      <header
        className={classnames(className, styles.container)}
        data-rv="header"
      >
        <div className={styles.sizer}>
          <div className={styles.logo} data-rv="logo-container">
            <Svg name="rv-logo" />
          </div>
          <div className={styles.innerContainer} data-rv="header-menu">
            <WebsiteSelector
              activeWebsite={activeWebsite}
              websites={websites}
              updateActiveWebsite={updateActiveWebsite}
            />
            <UserMenu userName={userName} userInitials={userInitials} />
          </div>
        </div>
      </header>
      {notificationEvent && notificationEvent.active && (
        <div className={styles.notificationBanner}>
          <div
            className={styles.notificationContent}
            dangerouslySetInnerHTML={{ __html: notificationEvent.description }}
          />
        </div>
      )}
    </div>
  )
}

export default Top
