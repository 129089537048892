import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'

import { Error } from '../../../typings/stores/AppStore'

import styles from './styles.module.css'
import 'react-toastify/dist/ReactToastify.css'

interface ToastProps {
  error: Error
}

const ErrorToast: FunctionComponent<ToastProps> = ({ error }) => {
  const errorTitle = error.reference ? `Error: ${error.reference}` : 'Error'

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingText}>{errorTitle}</div>
        <div className={styles.time}>
          {error.time.toLocaleString(DateTime.TIME_WITH_SECONDS)}
        </div>
      </div>
      <div className={styles.message}>{error.message}</div>
    </div>
  )
}

export default ErrorToast
