import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { Advertisers } from '../../pages'
import { Err404 } from '../../components'

interface AdvertisersRouterProps
  extends RouteComponentProps<{ page: string }> {}

const AdvertisersRouter: FunctionComponent<AdvertisersRouterProps> = ({
  match,
}) => (
  <Switch>
    <Route path={`${match.path}`} component={Advertisers} />
    <Route component={Err404} />
  </Switch>
)

export default AdvertisersRouter
