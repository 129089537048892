/* eslint-disable prettier/prettier */
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { ApolloError } from '@apollo/client'

import { Col } from '../../../../components/Table/next'
import VerticalSummaryTable from './Table'

import TableContext, {
  createTableContext,
} from '../../../../contexts/TableContext'
import { triggerError } from '../../../../actions/app'

import {
  VerticalSummaryReportingSorts,
  VerticalSummaryReportingPaginatedPayload,
  VerticalSummaryReportingQueryVariables,
} from '../../../../generated/types'
import { useLocation } from 'react-router'

export type VerticalSummaryGroupBy = keyof Omit<
  VerticalSummaryReportingPaginatedPayload,
  '__typename'
>
export type VerticalSummaryVariables = VerticalSummaryReportingQueryVariables

export type VerticalSummarySorts = keyof VerticalSummaryReportingSorts

export type VerticalSummary = {
  groupBy: string
  dateRange: string
  startDate: string
  endDate: string
  productIds: string
  externalPropertyId: string
  externalPublisherId: string
  userVariables: string
  vertical?: string
  program?: string
  product?: string
  productType?: number
  campaign?: string
  clickedProduct?: string
  subTracking?: string
  includeImpressions: string
  offset: number
  event?: string
  clickedProductName?: string
  displayedProductName?: string
}

export const VerticalSummaryTableContext = createTableContext<
  VerticalSummary,
  VerticalSummarySorts
>()

interface VerticalSummaryTableContainerProps {
  groupBy: VerticalSummaryGroupBy
  cols: Col[]
  footerPadding?: number
  getData: ({ variables }: { variables: VerticalSummaryVariables }) => void
  data: any
  loading: boolean
  error?: ApolloError
  getExportData: ({
    variables,
  }: {
    variables: VerticalSummaryVariables
  }) => void
  exportData: any
  exportLoading: boolean
  exportError?: ApolloError
  abort: () => void
  reportCallback: (param: string)=> void
}

const VerticalSummaryTableContainer: FunctionComponent<VerticalSummaryTableContainerProps> = ({
  groupBy,
  cols,
  footerPadding,
  getData,
  data,
  loading,
  error,
  getExportData,
  exportData,
  exportLoading,
  exportError,
  abort,
  reportCallback,
}) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vertical = queryParams.get('verticalId');
  
  // TODO: We should refactor this out with RVMN-235
  if (error) {
    dispatch(triggerError(error.message, 'Vertical Summary'))
  }
  return (
    <TableContext<VerticalSummary, VerticalSummarySorts>
      Context={VerticalSummaryTableContext}
      queryParams={[
        'groupBy',
        'dateRange',
        'startDate',
        'endDate',
        'includeImpressions',
        'vertical',
        'event',
      ]}
      defaultFilters={{
        groupBy: 'byDate',
        ...(vertical !== null && { vertical })
      }}
      defaultSort={{
        by: 'day',
        dir: 'desc',
      }}
    >
      <VerticalSummaryTable
        groupBy={groupBy}
        cols={cols}
        footerPadding={footerPadding}
        getData={getData}
        data={data}
        loading={loading}
        error={error != null}
        getExportData={getExportData}
        exportData={exportData}
        exportLoading={exportLoading}
        exportError={exportError != null}
        reportCallback={reportCallback}
      />
    </TableContext>
  )
}

export default VerticalSummaryTableContainer
