import { Reducer } from 'redux'

import { PerformanceAction, PerformanceConstants } from '../actions/performance'
import { PerformanceStore } from '../typings/stores/PerformanceStore'

const initialState: PerformanceStore = {
  data: null,
  filters: null,
  fetching: false,
  error: null,
}

export const performanceReducer: Reducer = (
  state = { ...initialState },
  action: PerformanceAction
): PerformanceStore => {
  switch (action.type) {
    case PerformanceConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        fetching: true,
        error: null,
      }
    case PerformanceConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        filters: action.filters,
        fetching: false,
        error: null,
      }
    case PerformanceConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        fetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
