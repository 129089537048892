import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import usStates from '../../utils/states'

interface StateSelectProps {
  value: string
  onChange: Function
  className?: string
}

// TODO: Move to <Select> compontent
const StateSelect: FunctionComponent<StateSelectProps> = ({
  value,
  onChange,
  className,
  ...rest
}) => {
  const classes = classnames(className)

  return (
    <>
      <div>
        <label htmlFor="state-select">State *</label>
      </div>
      <select
        value={value}
        onBlur={e => onChange(e)}
        onChange={e => onChange(e)}
        className={classes}
        id="state-select"
        {...rest}
      >
        <option value="" disabled>
          -- Select State --
        </option>
        {usStates.map(s => (
          <option value={s.abbreviation} key={s.abbreviation}>
            {s.name}
          </option>
        ))}
      </select>
    </>
  )
}

export default StateSelect
