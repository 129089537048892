import { Reducer } from 'redux'

import { PasswordAction, PasswordConstants } from '../actions/password'

import { PasswordStore } from '../typings/password'

const initialState: PasswordStore = {
  username: null,
  email: null,
  token: null,
  dateCreated: null,
  submitting: false,
  submitted: false,
  error: null,
}

export const passwordReducer: Reducer = (
  state = { ...initialState },
  action: PasswordAction
): PasswordStore => {
  switch (action.type) {
    case PasswordConstants.REQUEST_RESET:
    case PasswordConstants.VALIDATE_TOKEN:
    case PasswordConstants.RESET_PASSWORD:
      return {
        ...initialState,
        submitting: true,
        submitted: false,
      }

    case PasswordConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitted: true,
        error: initialState.error,
      }

    case PasswordConstants.VALIDATE_TOKEN_SUCCESS:
    case PasswordConstants.REQUEST_RESET_SUCCESS:
      return {
        ...state,
        ...action.requestInfo,
        submitting: false,
        submitted: true,
        error: initialState.error,
      }

    case PasswordConstants.REQUEST_RESET_FAILURE:
    case PasswordConstants.VALIDATE_TOKEN_FAILURE:
    case PasswordConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        submitted: true,
        error: action.error,
      }

    default:
      return state
  }
}
