import React, {
  FunctionComponent,
  ClassAttributes,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react'
import { History } from 'history'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import classnames from 'classnames'
import camelcase from 'camelcase-keys'
import kebabcase from 'kebabcase-keys'
import { parse, stringify } from 'query-string'
import _uniqueId from 'lodash/uniqueId'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'

import { Loading, Pagination2 } from '..'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { useEventListener } from '../../utils/customHooks'

import { ReportFilters, SortDirection, SortStatus } from '../../typings/report'
import { FetchStatus } from '../../typings/status'

import styles from './styles.module.css'

export interface TableHeader {
  className?: string
  text: string | ReactNode
  isSortable?: boolean
  sortField?: string
}

export interface TableCol {
  colSpan?: number
  className?: string
  text: ReactNode
}

export interface TableContent {
  className?: string
  linkTo?: string
  cols: TableCol[]
}

export interface TableFooter {
  text: string | number
  className?: string
  colSpan?: number
}

export interface DataPagination {
  total?: number
  currentPage?: number
  pageSize?: number
}

export interface TableProps
  extends RouteComponentProps,
    ClassAttributes<HTMLTableElement> {
  className?: string
  headers: TableHeader[]
  content: TableContent[]
  totalRow?: TableFooter[]
  pagination?: DataPagination
  status: FetchStatus
  error?: string
  sortColumn?(): void
}

// NOTE: We preserve query params here just until we can get rid of history in the Table
const navigateToRow = (history: History, query: string, linkTo: string) => {
  if (linkTo != null) {
    history.push({
      pathname: linkTo,
      search: query,
    })
  }
}

const sortIcon = (dir?: SortDirection) => {
  let sortIcon

  switch (dir) {
    case 'asc':
      sortIcon = 'sort-up'
      break
    case 'desc':
      sortIcon = 'sort-down'
      break
    default:
      sortIcon = 'sort'
      break
  }

  return <FontAwesomeIcon icon={sortIcon} className={styles.sortIndicator} />
}

const incrementSort = dir => {
  switch (dir) {
    case 'asc':
      return 'desc'
    case 'desc':
      return undefined
    default:
      return 'asc'
  }
}

export const sortIndicator = (sortField: string, sortInfo: SortStatus) => {
  return sortField === sortInfo.sortBy ? sortIcon(sortInfo.dir) : sortIcon(null)
}

const Table: FunctionComponent<TableProps> = ({
  location,
  history,
  className,
  headers,
  content,
  totalRow,
  pagination,
  status,
  error,
  staticContext,
  ...rest
}) => {
  const [sortInfo, setSortInfo] = useState<SortStatus>({
    sortBy: '',
    dir: null,
  })
  // const [hasHorizontalScrollbar, setHorizontalScrollbar] = useState(false)

  const tableRef = useRef<HTMLTableElement>()

  // const checkScrollbar = useCallback(() => {
  //   if (_has(tableRef, 'current') && tableRef.current) {
  //     const tableClientWidth = tableRef.current.clientWidth
  //     const tableScrollWidth = tableRef.current.scrollWidth
  //
  //     setHorizontalScrollbar(tableClientWidth < tableScrollWidth)
  //   }
  // }, [])
  //
  // useEffect(() => {
  //   checkScrollbar()
  //
  //   return () => {
  //     tableRef.current = undefined
  //   }
  // }, [content])
  //
  // useEventListener('resize', checkScrollbar, true)

  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const currentFilters: ReportFilters = camelcase(parse(location.search))

      if (!_isEmpty(currentFilters)) {
        let newPath = location.pathname

        const newFilters = {
          ...currentFilters,
          sortBy: _has(sortInfo, 'sortBy') ? sortInfo.sortBy : undefined,
          dir: _has(sortInfo, 'dir') ? sortInfo.dir : undefined,
        }

        if (!_isEmpty(newFilters)) {
          newPath += `?${stringify(kebabcase(newFilters))}`
          history.push(newPath)
        }
      }
    }

    // We do NOT want to trigger on every history/location change

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortInfo])

  useEffect(() => {
    if (status === 'fetched' && !_isEmpty(content)) {
      const currentFilters: ReportFilters = camelcase(parse(location.search))

      const sortObj: SortStatus = {
        sortBy:
          _has(currentFilters, 'sortBy') && _has(currentFilters, 'dir')
            ? currentFilters.sortBy
            : undefined,
        dir: _has(currentFilters, 'dir') ? currentFilters.dir : undefined,
      }

      setSortInfo(sortObj)
    }

    // We do NOT want to trigger on every history/location change

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, content])

  return (
    <>
      <div
        className={classnames(
          styles.container,
          // {
          //   [styles.scrollable]: hasHorizontalScrollbar,
          // },
          className
        )}
      >
        <table ref={tableRef} className={styles.table} {...rest}>
          {(status === 'fetched' || status === 'fetching') && (
            <thead className={styles.headerRow}>
              <tr>
                {headers.map(h => (
                  <th
                    className={classnames(h.className, {
                      [styles.sortedField]:
                        h.isSortable && sortInfo.sortBy === h.sortField,
                    })}
                    key={_uniqueId('header-')}
                  >
                    {h.isSortable ? (
                      <button
                        onClick={() =>
                          setSortInfo(prevSort => {
                            const newDir =
                              prevSort.sortBy === h.sortField
                                ? incrementSort(prevSort.dir)
                                : 'asc'

                            return {
                              sortBy: h.sortField,
                              dir: newDir,
                            }
                          })
                        }
                      >
                        {h.text}
                        {sortIndicator(h.sortField, sortInfo)}
                      </button>
                    ) : (
                      <div>{h.text}</div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {!_isEmpty(content) && status === 'fetched' && (
            <tbody>
              {content.map(con => (
                <tr
                  className={classnames(con.className, styles.contentRows, {
                    [styles.clickable]: !!con.linkTo,
                  })}
                  key={_uniqueId('content-')}
                  onClick={() =>
                    navigateToRow(history, location.search, con.linkTo)
                  }
                  tabIndex={0}
                >
                  {con.cols.map(col => (
                    <td
                      colSpan={col.colSpan || 1}
                      key={_uniqueId('col-')}
                      className={classnames(col.className)}
                    >
                      {col.text}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          {status === 'fetching' && (
            <tbody>
              <tr>
                <td
                  colSpan={headers.length}
                  className={classnames(styles.statusContainer, styles.loading)}
                >
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}
          {!_isEmpty(error) && (
            <tbody>
              <tr>
                <td
                  colSpan={headers.length}
                  className={classnames(styles.statusContainer, styles.error)}
                >
                  Unable to load data.
                </td>
              </tr>
            </tbody>
          )}
          {!_isEmpty(headers) &&
            status === 'fetched' &&
            _isEmpty(error) &&
            _isEmpty(content) && (
              <tbody>
                <tr>
                  <td
                    colSpan={headers.length}
                    className={classnames(styles.statusContainer, styles.done)}
                  >
                    No data is available to display.
                  </td>
                </tr>
              </tbody>
            )}
          {!_isEmpty(content) && !_isEmpty(totalRow) && (
            <tfoot>
              <tr>
                {totalRow.map(col => (
                  <td
                    colSpan={col.colSpan}
                    key={_uniqueId('total-')}
                    className={classnames(col.className)}
                  >
                    {col.text}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {pagination && !_isEmpty(content) && (
        <Pagination2
          total={pagination.total}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage}
        />
      )}
    </>
  )
}

export default withRouter(Table)
