import * as React from 'react'
import { FunctionComponent, useEffect, useState } from 'react'
import TableContext, {
  createTableContext,
} from '../../../contexts/TableContext'
import ClicksTable from './ClicksTable'
import {
  ClickDetailReportingSorts,
  ClickDetailReportingQueryVariables,
  useClickDetailReportingLazyQuery,
} from '../../../generated/types'
import { useDispatch } from 'react-redux'
import { checkHttpError, triggerError } from '../../../actions/app'
import { useApolloClient } from '@apollo/client'

type ClicksSorts =
  | 'clickId'
  | 'clickDate'
  | 'programName'
  | 'productName'
  | 'productTypeName'
  | 'productId'
  | 'subTracking'
  | 'state'
  | 'campaignName'
  | 'sales'
  | 'commissions'
  | 'pagePath'

interface ClicksFilters {
  dateRange: string
  startDate: string
  endDate: string
  vertical: string
  productType: string
  program: string
  product: string
  campaign: string
  clickedProduct: string
  subTracking: string
  hasReferralClick: boolean
  clickedProductName?: string
  displayedProductName?: string
  integrationType: string
  deviceType?: string
}

export type ClickDetailReportVariables = ClickDetailReportingQueryVariables

export type ClickDetailReportSorts = keyof ClickDetailReportingSorts

export const ClicksTableContext = createTableContext<
  ClicksFilters,
  ClicksSorts
>()

const ClicksReport: FunctionComponent = () => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [aborterRef, setAbortRef] = useState(new AbortController())
  const [requestCancelled, setRequestCancelled] = useState(false)
  const [spinner, setSpinner] = useState(true)
  const [
    ClickDetailReportingResult,
    { data, loading, error },
  ] = useClickDetailReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })

  const [
    ClickDetailReportingExportResult,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useClickDetailReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })

  if (error) {
    dispatch(triggerError(checkHttpError(error.message), 'Clicks Report'))
  }

  const abort = () => {
    aborterRef.abort()
    setAbortRef(new AbortController())
  }

  const reportCallback = (param: string) => {
    if (param === 'run') {
      setRequestCancelled(false)
      setSpinner(true)

      // set spinner for 3/4 of a second for better user experience
      setTimeout(() => {
        setSpinner(false)
      }, 750)
    } else if (param === 'cancel') {
      abort()
      setRequestCancelled(true)
      client.clearStore()
    }
  }

  useEffect(() => {
    if (data) {
      setSpinner(false)
    }
  })

  return (
    <TableContext<ClicksFilters, ClicksSorts>
      Context={ClicksTableContext}
      queryParams={[
        'dateRange',
        'startDate',
        'endDate',
        'vertical',
        'productType',
        'program',
        'product',
        'campaign',
        'clickedProduct',
        'subTracking',
        'hasReferralClick',
        'clickedProductName',
        'displayedProductName',
        'integrationType',
        'deviceType',
      ]}
    >
      <ClicksTable
        getData={ClickDetailReportingResult}
        data={data}
        loading={(loading && !requestCancelled) || spinner}
        error={error != null}
        getExportData={ClickDetailReportingExportResult}
        exportData={exportData}
        exportLoading={exportLoading}
        exportError={exportError != null}
        abort={abort}
        reportCallback={reportCallback}
      />
    </TableContext>
  )
}

export default ClicksReport
