import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'

import styles from './styles.module.css'

interface DateTimeDisplayProps {
  datetime: DateTime
}

const DateTimeDisplay: FunctionComponent<DateTimeDisplayProps> = ({
  datetime,
}) => {
  return (
    <div className={styles.display}>
      <span>{datetime.toLocaleString(DateTime.DATE_MED)}</span>
      <span>{datetime.toLocaleString(DateTime.TIME_SIMPLE)}</span>
    </div>
  )
}

export default DateTimeDisplay
