import accounting from 'accounting'
import { AdvertisersStore } from '../typings/stores/AdvertiserStore'

export const formatCommissions = amount =>
  amount > 0 && amount < 1
    ? `${accounting.formatNumber(amount * 100, 2)}%`
    : accounting.formatMoney(amount, '$')

export const formatAdvertisers = (advertisers: AdvertisersStore) =>
  advertisers.preloaded.map(advertiser => ({
    text: advertiser.name,
    value: advertiser.id.toString(),
  }))

export const formatJsonToCsv = (items: any) => {
  if (!Array.isArray(items) || !items.length) {
    return ''
  }
  const header = Object.keys(items[0])
  const headerString = header.join(',')
  // handle null or undefined values here
  const replacer = (_key: any, value: any) => {
    if (!value) {
      return ''
    }
    if (typeof value === 'string') {
      return value.replace(new RegExp('"', 'g'), '')
    }
    return value
  }
  const rowItems = items.map((row: { [x: string]: any }) =>
    header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')
  )
  // join header and body, and break into separate lines
  const csv = [headerString, ...rowItems].join('\r\n')
  return csv
}

const objectWithoutProperties = (obj, keys) => {
  var target = {}
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue
    target[i] = obj[i]
  }
  return target
}

const removeExternalPrefix = obj => {
  var target = {}
  for (var i in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue
    if (i.startsWith('external')) {
      target[i.replace('external', '')] = obj[i]
    } else {
      target[i] = obj[i]
    }
  }
  return target
}

export const cleanArray = (array: any) => {
  const arrayCleaned = []
  for (const obj of array) {
    arrayCleaned.push(
      removeExternalPrefix(objectWithoutProperties(obj, '__typename'))
    )
  }
  return arrayCleaned
}
