import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

interface CloseProps {
  className?: string
}

const Close: FunctionComponent<CloseProps> = ({ className }) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    className={classnames(className)}
  >
    <title>close</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        id="c"
        fill="#3B4042"
        fillRule="nonzero"
        points="15.6425781 1.90234375 9.76367188 7.78125 15.6425781 13.6601562 14.0976562 15.2050781 8.21875 9.32617188 2.33984375 15.2050781 0.794921875 13.6601562 6.67382812 7.78125 0.794921875 1.90234375 2.33984375 0.357421875 8.21875 6.23632812 14.0976562 0.357421875"
      />
    </g>
  </svg>
)

export default Close
