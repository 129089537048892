import { SelectOption } from '../components/Select'

const deviceTypeOptions: SelectOption[] = [
  { value: 'desktop', text: 'Desktop' },
  { value: 'tablet', text: 'Tablet' },
  { value: 'wearable', text: 'Wearable' },
  { value: 'smarttv', text: 'Smart TV' },
  { value: 'mobile', text: 'Mobile' },
  { value: 'embedded', text: 'Embedded' },
  { value: 'console', text: 'Console' },
  { value: 'unknown', text: 'Unknown' },
]

export default deviceTypeOptions
