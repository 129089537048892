import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { Category } from '../typings/category'

export enum CategoriesConstants {
  FETCH = 'categories/FETCH',
  FETCH_SUCCESS = 'categories/FETCH_SUCCESS',
  FETCH_FAILURE = 'categories/FETCH_FAILURE',
}

export interface CategoriesAction extends Action {
  type: CategoriesConstants
  payload?: Array<Category>
  error?: string
}

function fetchCategories(): CategoriesAction {
  return {
    type: CategoriesConstants.FETCH,
  }
}

function fetchCategoriesSuccess(payload: Array<Category>): CategoriesAction {
  return {
    type: CategoriesConstants.FETCH_SUCCESS,
    payload,
  }
}

function fetchCategoriesFailure(error: string): CategoriesAction {
  return {
    type: CategoriesConstants.FETCH_FAILURE,
    error,
  }
}

export const getCategories = cancelFetchOnReentrySync(fetch => params =>
  swallowCancellation(async dispatch => {
    dispatch(fetchCategories())

    const { ok, errorParsed, bodyParsed } = await fetchGetApi(
      fetch,
      '/categories'
    )

    if (ok) {
      dispatch(fetchCategoriesSuccess(bodyParsed.data))
    } else {
      dispatch(triggerError(errorParsed, 'Categories'))
      dispatch(fetchCategoriesFailure(errorParsed))
    }
  })
)
