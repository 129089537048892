import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface ReportMenuProps {
  className?: string
}

// TODO: update this after swapping to new GQL-reports
// NOTE: These get built into the route paths!
// Example: Click Performance -> click-performance

const ReportMenu: FunctionComponent<ReportMenuProps> = ({ className }) => {
  return (
    <nav
      className={classnames(styles.container, className)}
      data-rv="reports-menu"
    >
      {' '}
    </nav>
  )
}

export default ReportMenu
