import { Action } from '../typings/reducer'
import { AppStore } from '../typings/stores/AppStore'

export enum AppConstants {
  TRIGGER_ERROR = 'app/TRIGGER_ERROR',
  SHOW_ERROR = 'app/SHOW_ERROR',
}

const httpErrorMessages: { [code: string]: string } = {
  '400': 'Bad Request. Please check your request parameters and try again.',
  '401':
    'Unauthorized. Please ensure you are logged in and have proper authentication credentials.',
  '403':
    'Forbidden. You do not have sufficient permissions to access this resource.',
  '404': 'Not Found. The requested resource could not be found.',
  '500': 'Internal Server Error. An unexpected error occurred on the server.',
  '504':
    'Your report timed out. Try reducing the number of days of data your looking at, or try utilizing our reporting API to get the info you need',
}

export interface AppAction extends Action {
  type: AppConstants
  errors?: AppStore
  reference?: string
  message?: string
  index?: number
}

export function triggerError(message: string, reference?: string): AppAction {
  return {
    type: AppConstants.TRIGGER_ERROR,
    reference,
    message,
  }
}

export function showError(index: number): AppAction {
  return {
    type: AppConstants.SHOW_ERROR,
    index,
  }
}

export function checkHttpError(errorMessage: string): string {
  const errorCodes: string[] = ['400', '401', '403', '404', '500', '504']

  for (const code of errorCodes) {
    if (errorMessage.includes(code)) {
      return httpErrorMessages[code]
    }
  }

  return errorMessage
}
