import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'

import accounting from 'accounting'
import styles from '../styles.module.css'
import { useSelector } from 'react-redux'
import Table from '../../../../components/Table/next'
import { OutstandingBalanceTableContext } from './index'
import { OutstandingBalanceReportingQueryVariables } from '../../../../generated/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDefinition } from '../../../../utils/dictionary'
import { DateTimeUTCDisplay } from '../../../../components'
import { DateTime } from 'luxon'
import { get } from 'lodash'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'
import { decode } from 'he'

interface OutstandingBalance {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
}

const OutstandingBalance: FunctionComponent<OutstandingBalance> = ({
  getData,
  data,
  loading,
  error,
}) => {
  const {
    sort,
    pagination: { page, size },
    changePage,
    changePageSize,
    changeSort,
  } = useContext(OutstandingBalanceTableContext)

  const allColumns = [
    {
      text: 'Click ID',
      sortable: true,
      field: 'clickId',
    },
    {
      text: 'Process Date (UTC)',
      field: 'processedAt',
      sortable: true,
      render: row =>
        row.processedAt && (
          <DateTimeUTCDisplay
            datetime={DateTime.fromISO(row.processedAt, { zone: 'utc' })}
          />
        ),
    },
    {
      text: 'Vertical',
      field: 'verticalName',
      sortable: true,
    },
    {
      text: 'Product',
      field: 'productName',
      sortable: true,
      render: ({ productName }) => (productName ? decode(productName) : ''),
    },
    {
      text: 'Program',
      field: 'programName',
      sortable: true,
    },
    {
      text: 'Commission',
      field: 'commission',
      sortable: true,
      className: styles.right,
      render: ({ commission }) => accounting.formatMoney(commission, '$'),
    },
    {
      text: 'Adjustments',
      className: styles.right,
      render: ({ adjustments }) => accounting.formatMoney(adjustments, '$'),
    },
    {
      text: 'Total Amount',
      className: styles.right,
      sortable: true,
      render: ({ total }) => accounting.formatMoney(total, '$'),
    },
    {
      text: 'Cross Sale',
      className: styles.crossSale,
      render: row =>
        row.crossSale && (
          <div data-tip={getDefinition('cross sale')} data-for="global-tooltip">
            <FontAwesomeIcon
              icon={['fas', 'expand-arrows-alt']}
              className={styles.icon}
            />
          </div>
        ),
    },
  ]
  const [tableColumns, setTableColumns] = useState(allColumns)

  const user = useSelector<State, UserStore>(state => state.user)

  const variables = () => {
    return {
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPublisherId: user.affiliateId,
    } as OutstandingBalanceReportingQueryVariables
  }

  const fetchData = () => {
    getData({ variables: variables() }), setTableColumns(allColumns)
  }

  useEffect(fetchData, [sort, page, size, user.affiliateId])

  const pagination = {
    currentPage: get(
      data,
      `outstandingBalanceReporting.pagination.currentPage`,
      1
    ),
    pageSize: get(data, `outstandingBalanceReporting.pagination.pageSize`, 10),
    total: get(data, `outstandingBalanceReporting.pagination.totalEntries`, 0),
  }

  return (
    <Table
      className={styles.table}
      cols={tableColumns}
      isLoading={loading}
      onSortChange={changeSort}
      sort={sort}
      data={data && get(data, `outstandingBalanceReporting.page`)}
      pagination={{
        onPageSizeUpdate: changePageSize,
        onPaginate: changePage,
        ...pagination,
      }}
    />
  )
}

export default OutstandingBalance
