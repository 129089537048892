import React, { FunctionComponent, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { AppUsers } from '../components'

import { getUserFromSession } from '../actions/user'
import {
  getAppUsers,
  validateUser,
  editUser,
  addUser,
  getCircuitUsers,
  addCircuitUser,
  editCircuitUser,
  validateCircuitUser,
} from '../actions/appUsers'

import { State } from '../reducers'
import { AppUser, AppUsersStore } from '../typings/user'
import { Website } from '../typings/website'

export type formMethods = 'edit' | 'add'
interface AppUsersProps extends RouteComponentProps {
  basePath: string
  userId?: string
  formMethod?: formMethods
  appUsers?: AppUsersStore
  activeWebsite: Website
  getUserFromSession(): void
  getAppUsers(): void
  validateUser(): boolean
  editUser(): void
  addUser(): void
}

const AppUsersContainer: FunctionComponent<AppUsersProps> = ({
  history,
  basePath,
  userId,
  formMethod,
  appUsers,
  activeWebsite,
  getUserFromSession,
  getAppUsers,
  validateUser,
  editUser,
  addUser,
}) => {
  const [selectedUser, setSelectedUser] = useState<AppUser>(null)

  useEffect(() => {
    if (!appUsers.fetching && !appUsers.fetched) {
      getAppUsers()
    }
  }, [activeWebsite, appUsers, getAppUsers])

  useEffect(() => {
    setSelectedUser(appUsers.data.records.find(user => user.id == userId))
  }, [appUsers, userId])

  return (
    <AppUsers
      basePath={basePath}
      appUsers={appUsers}
      formMethod={formMethod}
      selectedUser={selectedUser}
      close={() => {
        history.push(basePath)
        getAppUsers()

        // Re-pulling the session user in case it was updated as part of an
        // edit. It shouldn't be an issue to call on every close, but if it
        // becomes a problem we can revisit.
        if (formMethod === 'edit' && !appUsers.fetching) {
          getUserFromSession()
        }
      }}
      validateUser={validateUser}
      editUser={editUser}
      addUser={addUser}
    />
  )
}

const mapStateToProps = (state: State) => ({
  appUsers: state.appUsers,
  activeUser: state.user,
  activeWebsite: state.user.activeWebsite,
})

const mapDispatchToProps = dispatch => ({
  getUserFromSession: () => dispatch(getUserFromSession()),
  getAppUsers: () => dispatch(getCircuitUsers()),
  validateUser: (e: string) => dispatch(validateCircuitUser(e)),
  editUser: (o: AppUser, e: AppUser) => dispatch(editCircuitUser(o, e)),
  addUser: (u: AppUser) => dispatch(addCircuitUser(u)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppUsersContainer)
)
