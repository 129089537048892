import { Reducer } from 'redux'

import {
  BannerReferralsAction,
  BannerReferralsConstants,
} from '../actions/bannerReferrals'
import { BannerReferralsStore } from '../typings/stores/BannerReferralsStore'

const initialState: BannerReferralsStore = {
  data: {
    transactions: [],
    pagination: null,
    totals: null,
  },
  export: {
    hasData: false,
    filters: null,
    status: null,
    error: null,
  },
  hasData: false,
  filters: null,
  status: null,
  error: null,
}

export const bannerReferralsReducer: Reducer = (
  state = { ...initialState },
  action: BannerReferralsAction
): BannerReferralsStore => {
  switch (action.type) {
    case BannerReferralsConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        filters: initialState.filters,
        hasData: false,
        status: 'fetching',
        error: null,
      }
    case BannerReferralsConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        filters: action.filters,
        hasData: action.data.transactions.length > 0,
        status: 'fetched',
        error: null,
      }
    case BannerReferralsConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: null,
        error: action.error,
      }
    case BannerReferralsConstants.RESET:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: null,
        error: null,
      }
    case BannerReferralsConstants.EXPORT_FETCH:
      return {
        ...state,
        export: {
          ...initialState.export,
          status: 'fetching',
        },
      }
    case BannerReferralsConstants.EXPORT_FETCH_SUCCESS:
      return {
        ...state,
        export: {
          hasData: true,
          filters: action.filters,
          status: 'fetched',
          error: null,
        },
      }
    case BannerReferralsConstants.EXPORT_FETCH_FAILURE:
      return {
        ...state,
        export: {
          hasData: false,
          status: null,
          error: action.error,
        },
      }
    case BannerReferralsConstants.EXPORT_FETCH_RESET:
      return {
        ...state,
        export: initialState.export,
      }
    default:
      return state
  }
}
