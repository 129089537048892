import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from 'react'
import accounting from 'accounting'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { decode } from 'he'
import { DateTimeDisplay, PageHeading } from '../../../components'
import Table from '../../../components/Table/next'
import ReportActions from '../../../components/ReportActions'
import { useSelector } from 'react-redux'
import { State } from '../../../reducers'
import { UserStore } from '../../../typings/stores/UserStore'
import { ClicksTableContext } from './index'
import { ClickDetail } from '../../../typings/detailReport'
import { prepareDatesForApi } from '../../../utils/dates'
import { ClickDetailReportVariables } from './index'
import TableFilters from '../Filters/TableFilters'
import { dumpData } from '../../../actions/reports'
import TotalsDisplayBar from '../../../components/TotalDisplayBar'
import { isEmpty, isNumber } from 'lodash'
import { ReportsStore } from '../../../typings/stores/ReportsStore'

interface TotalsDisplayFields {
  totalDisplayProductClicks: number
  totalClickedProductClicks: number
  totalSales: number
  totalCommission: number
}

interface ClicksTableProps {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
  exportData: any
  exportLoading: boolean
  exportError: boolean
  getExportData: ({ variables }) => void
  abort: () => void
  reportCallback: (param: string) => void
}

const ClicksTable: FunctionComponent<ClicksTableProps> = ({
  getData,
  data,
  loading,
  error,
  getExportData,
  exportData,
  exportLoading,
  exportError,
  abort,
  reportCallback,
}) => {
  const {
    filters,
    pagination: { size, page },
    sort,
    changePageSize,
    changePage,
    changeSort,
  } = useContext(ClicksTableContext)

  const user = useSelector<State, UserStore>(state => state.user)
  const report = useSelector<State, ReportsStore>(state => state.reports)
  const [isExporting, setIsExporting] = useState(false)
  const [pointer, setPointer] = useState(0)
  const [dataToExport, setDataToExport] = useState([])
  const recordsPerPage = 10000

  let verticalId = ''
  let verticalIdExists = false
  if (report.filters?.vertical) {
    let needProductFilter = false
    // If we have clickedProductName or displayedProductName filters, we need to wait until we load the products
    if (
      (filters.clickedProductName && !filters.clickedProduct) ||
      (filters.displayedProductName && !filters.product)
    ) {
      // We have filter defined for product but we still don't have the products load, wait...
      needProductFilter = true
    }
    if (!needProductFilter) {
      verticalId = report.filters?.vertical.toString()
      verticalIdExists = true
    }
  }

  const updateVariables = () => {
    return {
      ...prepareDatesForApi(filters.startDate, filters.endDate, true),
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPropertyId: user.activeWebsite.id.toString(),
      externalPublisherId: user.affiliateId,
      externalVerticalId: verticalId,
      externalProductTypeId: parseInt(filters.productType),
      externalProgramId: filters.program,
      externalDisplayedProductId: filters.product,
      externalClickedProductId: filters.clickedProduct,
      externalCampaignId: filters.campaign,
      subTracking: filters.subTracking,
      onlyReferralClicks: filters.hasReferralClick === 'true',
      integrationTypeId: filters.integrationType,
      deviceType: filters.deviceType,
      includeTotals: true,
    } as ClickDetailReportVariables
  }

  const fetchData = () => {
    if (verticalIdExists && verticalId != '') {
      getData({ variables: updateVariables() })
      updateTableColumns()
    }
  }

  const fetchExport = () => {
    setDataToExport([])
    setPointer(0)
    setIsExporting(true)
    getExportData({
      variables: {
        ...updateVariables(),
        take: recordsPerPage,
        includeTotals: false,
      },
    })
  }

  useEffect(() => {
    const pagedData = exportData?.clickDetailReporting?.page
    if (pagedData) {
      setDataToExport(v => [...v, ...pagedData])
      setPointer(v => v + pagedData.length)
    }
  }, [exportData])

  useEffect(() => {
    if (
      isExporting &&
      pointer < get(data, `clickDetailReporting.pagination.totalEntries`, 0)
    ) {
      getExportData({
        variables: {
          ...updateVariables(),
          skip: pointer,
          take: recordsPerPage,
          includeTotals: false,
        },
      })
    } else {
      if (dataToExport.length) {
        dumpData('clickDetails', dataToExport, {
          ...filters,
        })
        setIsExporting(false)
        setDataToExport([])
        setPointer(0)
      }
    }
  }, [pointer])

  useEffect(fetchData, [
    sort,
    page,
    size,
    user.activeWebsite,
    filters.hasReferralClick,
    verticalIdExists,
  ])

  const pagination = {
    currentPage: get(data, `clickDetailReporting.pagination.currentPage`, 1),
    pageSize: get(data, `clickDetailReporting.pagination.pageSize`, 10),
    total: get(data, `clickDetailReporting.pagination.totalEntries`, 0),
  }

  const totalReferralData = {
    totalDisplayProductClicks: get(
      data,
      `clickDetailReporting.totals.totalDisplayProductClicks`,
      0
    ),
    totalClickedProductClicks: get(
      data,
      `clickDetailReporting.totals.totalClickedProductClicks`,
      0
    ),
    totalSales: get(data, `clickDetailReporting.totals.totalSales`, 0),
    totalCommission: get(
      data,
      `clickDetailReporting.totals.totalCommission`,
      0
    ),
  }

  const defaultHiddenColumns = [
    'subTracking2',
    'subTracking3',
    'subTracking4',
    'subTracking5',
  ]

  const allColumns = [
    {
      text: 'Click Date',
      sortable: true,
      field: 'clickDate',
      tooltip: true,
      tooltipText: 'You are seeing data in UTC time',
      render: row =>
        row.clickDate && (
          <DateTimeDisplay
            datetime={DateTime.fromISO(row.clickDate, { zone: 'utc' })}
          />
        ),
    },
    {
      text: 'Click ID',
      sortable: true,
      field: 'clickId',
    },
    {
      text: 'Product Type',
      sortable: true,
      field: 'productTypeName',
      render: row => row.productTypeName && decode(row.productTypeName),
    },
    {
      text: 'Program',
      sortable: true,
      field: 'advertiserName',
    },
    {
      text: 'Displayed product ID',
      sortable: true,
      field: 'displayedProductId',
    },
    {
      text: 'displayed Product',
      sortable: true,
      field: 'displayedProductName',
      render: row =>
        row.displayedProductName && decode(row.displayedProductName),
    },
    {
      text: 'Clicked Product ID',
      sortable: true,
      field: 'clickedProductId',
    },
    {
      text: 'Clicked Product',
      sortable: true,
      field: 'clickedProductName',
      render: row => row.clickedProductName && decode(row.clickedProductName),
    },
    {
      text: 'Campaign',
      sortable: true,
      field: 'campaignName',
      render: row => row.campaignName && decode(row.campaignName),
    },
    {
      text: 'Integration Type',
      sortable: false,
      field: 'integrationTypeName',
    },
    {
      text: 'Page',
      sortable: true,
      field: 'pagePath',
      render: row => row.pagePath && decode(row.pagePath),
    },
    {
      text: 'State',
      sortable: true,
      field: 'state',
      render: row => row.state && decode(row.state),
    },
    {
      text: 'Sub ID',
      sortable: true,
      field: 'subTracking',
    },
    {
      text: 'Sub ID 2',
      field: 'subTracking2',
    },
    {
      text: 'Sub ID 3',
      field: 'subTracking3',
    },
    {
      text: 'Sub ID 4',
      field: 'subTracking4',
    },
    {
      text: 'Sub ID 5',
      field: 'subTracking5',
    },
    {
      text: 'Sales',
      sortable: true,
      field: 'sales',
    },
    {
      text: 'Commissions',
      sortable: true,
      field: 'commission',
      render: ({ commission }) => accounting.formatMoney(commission, '$'),
    },
    {
      text: 'True Conversion',
      sortable: true,
      field: 'trueConversion',
      render: row => (row.trueConversion ? 'Yes' : 'No'),
    },
    {
      text: 'Loan Amount',
      sortable: true,
      field: 'loanAmount',
      render: ({ loanAmount }) =>
        loanAmount ? accounting.formatMoney(loanAmount, '$') : '-',
    },
    {
      text: 'Credit Score',
      sortable: true,
      field: 'creditScoreExact',
    },
    {
      text: 'Credit Bucket',
      sortable: true,
      field: 'creditBucket',
    },
    {
      text: 'Referral',
      sortable: false,
      field: 'hasReferralClick',
      render: row => (row.hasReferralClick ? 'Yes' : 'No'),
    },
    {
      text: 'Device Type',
      sortable: true,
      field: 'deviceType',
    },
  ]

  const [tableColumns, setTableColumns] = useState(allColumns)
  const [propertyHasData, setPropertyHasData] = useState(true)
  const [pageDescription, setPageDescription] = useState(
    'This report contains click details for the filters selected.'
  )

  useEffect(() => {
    if (!propertyHasData) {
      setPageDescription('No reporting data available for this property yet.')
    }
  }, [propertyHasData])

  useEffect(() => {
    if (!propertyHasData) {
      window.location.reload()
    }
  }, [user.activeWebsite])

  const updateTableColumns = () => {
    if (filters.vertical === '3') {
      // Mortgages
      const availableColumns = [
        'clickDate',
        'clickId',
        'productTypeName',
        'clickedProductId',
        'displayedProductId',
        'clickedProductName',
        'displayedProductName',
        'campaignName',
        'integrationTypeName',
        'pagePath',
        'state',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'sales',
        'commission',
        'hasReferralClick',
        'trueConversion',
        'deviceType',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else if (
      filters.vertical === '5' ||
      filters.vertical === '7' ||
      filters.vertical === '8' ||
      filters.vertical === '10' ||
      filters.vertical === '11' ||
      filters.vertical === '13'
    ) {
      // Credit Cards || Investing || Health || Retail || Home Services || Personal Finance
      const availableColumns = [
        'clickDate',
        'clickId',
        'productTypeName',
        'programName',
        'displayedProductId',
        'displayedProductName',
        'clickedProductName',
        'clickedProductId',
        'integrationTypeName',
        'campaignName',
        'pagePath',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'sales',
        'commission',
        'hasReferralClick',
        'trueConversion',
        'deviceType',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else if (
      filters.vertical === '6' ||
      filters.vertical === '9' ||
      filters.vertical === '12'
    ) {
      // Deposits || Insurance || Home Equity
      const availableColumns = [
        'clickDate',
        'clickId',
        'productTypeName',
        'displayedProductId',
        'displayedProductName',
        'clickedProductName',
        'clickedProductId',
        'integrationTypeName',
        'campaignName',
        'pagePath',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'sales',
        'commission',
        'hasReferralClick',
        'trueConversion',
        'deviceType',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else if (filters.vertical === '4') {
      // Loans
      const availableColumns = [
        'clickDate',
        'clickId',
        'productTypeName',
        'displayedProductId',
        'displayedProductName',
        'clickedProductName',
        'clickedProductId',
        'integrationTypeName',
        'campaignName',
        'loanAmount',
        'creditScoreExact',
        'deviceType',
        'creditBucket',
        'pagePath',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'sales',
        'commission',
        'hasReferralClick',
        'trueConversion',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else {
      setTableColumns(allColumns)
    }
  }

  return (
    <>
      <PageHeading title="Click Details" description={pageDescription}>
        {propertyHasData && (
          <TableFilters
            tableContext={ClicksTableContext}
            showClickedProducts={true}
            setPropertyHasData={setPropertyHasData}
            showHasReferralClicks={true}
            showIntegrationTypes={true}
            showDeviceType={true}
          />
        )}
      </PageHeading>
      {propertyHasData && (
        <>
          <ReportActions
            fetchReport={fetchData}
            fetchExport={fetchExport}
            disabled={
              !get(data, `clickDetailReporting.pagination.totalEntries`, 0)
            }
            reportStatus={{ loading: loading, hasError: error }}
            exportStatus={{ loading: exportLoading, hasError: exportError }}
            reportCallback={reportCallback}
          />
          {filters.hasReferralClick === 'true' &&
            !isEmpty(totalReferralData) &&
            !loading && (
              <TotalsDisplayBar<TotalsDisplayFields>
                fields={[
                  {
                    text: 'Displayed Product Clicks',
                    field: 'totalDisplayProductClicks',
                  },
                  {
                    text: 'Clicked Product Clicks',
                    field: 'totalClickedProductClicks',
                  },
                  {
                    text: 'Sales',
                    field: 'totalSales',
                  },
                  {
                    text: 'Commissions',
                    field: 'totalCommission',
                    render: totalReferralData => {
                      return (
                        (!isEmpty(totalReferralData.totalCommission) ||
                          isNumber(totalReferralData.totalCommission)) &&
                        accounting.formatMoney(
                          totalReferralData.totalCommission,
                          '$'
                        )
                      )
                    },
                  },
                ]}
                totals={totalReferralData}
              />
            )}
          <Table<ClickDetail[]>
            cols={tableColumns}
            data={data && get(data, `clickDetailReporting.page`)}
            onSortChange={changeSort}
            sort={sort}
            defaultHiddenColumns={defaultHiddenColumns}
            pagination={{
              onPageSizeUpdate: changePageSize,
              onPaginate: changePage,
              ...pagination,
            }}
            isLoading={loading}
          />
        </>
      )}
    </>
  )
}

export default ClicksTable
