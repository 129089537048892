import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import ReportActions from '../../../../components/ReportActions'
import { PageDescription } from '../../../../components'
import PaymentSummary from './PaymentSummary'
import { DetailsTableContext } from './index'
import { dumpData } from '../../../../actions/reports'
import { PaymentDetailReportingQueryVariables } from '../../../../generated/types'
import styles from '../styles.module.css'
import {
  usePaymentDetailReportingLazyQuery,
  usePaymentDetailReportingSummaryLazyQuery,
} from '../../../../generated/types'
import PaymentDetails from './PaymentDetails'
import { checkHttpError, triggerError } from '../../../../actions/app'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

interface PaymentDetailsProps {
  paymentId: string
  paymentTotal: string
}

const Details: FunctionComponent<PaymentDetailsProps> = ({
  paymentId,
  paymentTotal,
}) => {
  const user = useSelector<State, UserStore>(state => state.user)
  const location = useLocation()
  const dispatch = useDispatch()
  const [aborterRef] = useState(new AbortController())
  const [dataToExport, setDataToExport] = useState([])
  const [isExporting, setIsExporting] = useState(false)
  const [pointer, setPointer] = useState(0)
  const recordsPerPage = 10000

  const {
    sort,
    pagination: { page, size },
  } = useContext(DetailsTableContext)

  const previousPageUrl = get(location, 'state.previousUrl', '../history')

  const [
    paymentDetails,
    { data, loading, error },
  ] = usePaymentDetailReportingLazyQuery()

  const [
    paymentDetailsExport,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = usePaymentDetailReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })

  const [
    paymentSummary,
    { data: dataSummary, loading: loadingSummary, error: errorSummary },
  ] = usePaymentDetailReportingSummaryLazyQuery()

  const fetchExport = () => {
    setDataToExport([])
    setPointer(0)
    setIsExporting(true)
    paymentDetailsExport({
      variables: {
        ...variables(),
        take: recordsPerPage,
      },
    })
  }

  useEffect(() => {
    if (
      isExporting &&
      pointer < get(data, `paymentDetailReporting.pagination.totalEntries`, 0)
    ) {
      paymentDetailsExport({
        variables: {
          ...variables(),
          skip: pointer,
          take: recordsPerPage,
        },
      })
    } else {
      if (dataToExport.length) {
        dumpData(`paymentDetails_${paymentId}`, dataToExport)
        setIsExporting(false)
        setDataToExport([])
        setPointer(0)
      }
    }
  }, [pointer])

  useEffect(() => {
    const pagedData = exportData?.paymentDetailReporting?.page
    if (pagedData) {
      setDataToExport(v => [...v, ...pagedData])
      setPointer(v => v + pagedData.length)
    }
  }, [exportData])

  useEffect(() => {
    setDataToExport([])
    setPointer(0)
    setIsExporting(false)
  }, [exportError])

  if (error) {
    dispatch(triggerError(checkHttpError(error.message), 'Payment Report'))
  }

  const variables = () => {
    return {
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPaymentId: paymentId,
      externalPublisherId: user.affiliateId,
    } as PaymentDetailReportingQueryVariables
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <Link className={styles.backLink} to={previousPageUrl}>
            Back to Payment History
          </Link>

          <h2>Payment Details</h2>

          <div className={styles.paymentInfo}>
            <strong>Payment ID: </strong>
            {paymentId}
          </div>
        </div>

        <ReportActions
          className={styles.actions}
          fetchExport={fetchExport}
          disabled={
            !get(data, `paymentDetailReporting.pagination.totalEntries`, 0)
          }
          exportStatus={{
            loading: exportLoading,
            hasError: exportError != null,
          }}
          reportStatus={{ loading: loading, hasError: error != null }}
          totalsStatus={{
            loading: loadingSummary,
            hasError: errorSummary != null,
          }}
        />
      </div>

      <PageDescription
        content={`This report reflects each individual commission included in the payment: ${paymentId}`}
      />

      <PaymentSummary
        getData={paymentSummary}
        data={dataSummary}
        loading={loadingSummary}
        error={errorSummary != null}
        paymentId={paymentId}
        paymentTotal={paymentTotal}
      />

      <PaymentDetails
        getData={paymentDetails}
        data={data}
        loading={loading}
        error={error != null}
        paymentId={paymentId}
      />
    </>
  )
}

export default Details
