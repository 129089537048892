import React, {
  FormEvent,
  FunctionComponent,
  forwardRef,
  RefObject,
} from 'react'
import uniqueId from 'lodash/uniqueId'
import classnames from 'classnames'
import _isEmpty from 'lodash/isEmpty'

import styles from './styles.module.css'

type Ref = HTMLSelectElement

export interface SelectOption {
  value?: string
  text: string
}

export interface SelectProps {
  id?: string
  className?: string
  placeholder?: string
  selected?: string | number
  options: SelectOption[]
  isLoading?: boolean
  loadingMessage?: string
  ref?: RefObject<any>
  onChange?(event?: FormEvent<HTMLSelectElement>): void
}

const Select: FunctionComponent<SelectProps> = (
  {
    className,
    placeholder,
    selected,
    options,
    isLoading,
    loadingMessage,
    onChange,
    ...rest
  },
  ref
) => (
  <>
    {isLoading === true ? (
      <div className={classnames(styles.loading, className)}>
        {!_isEmpty(loadingMessage) ? loadingMessage : 'Loading'}
      </div>
    ) : (
      <div className={classnames(styles.container, className)}>
        <select
          required={!_isEmpty(placeholder)}
          className={styles.main}
          onChange={onChange}
          onBlur={() => {}}
          value={selected}
          ref={ref}
          {...rest}
        >
          {!_isEmpty(placeholder) ? (
            <option value="">{placeholder}</option>
          ) : null}
          {options.map(o => (
            <option key={uniqueId('select-')} value={!o.value ? '' : o.value}>
              {o.text}
            </option>
          ))}
        </select>
      </div>
    )}
  </>
)

export default forwardRef<Ref, SelectProps>(Select)
