import * as React from 'react'
import { FunctionComponent, useState } from 'react'
import TableContext, {
  createTableContext,
} from '../../../contexts/TableContext'
import { ReportGroupBy } from '../../../typings/report'
import TransactionsTable from './TransactionsTable'

import {
  TransactionDetailsReportingSorts,
  TransactionDetailsReportingQueryVariables,
  useTransactionDetailsReportingLazyQuery,
} from '../../../generated/types'
import { useDispatch } from 'react-redux'
import { checkHttpError, triggerError } from '../../../actions/app'

import { useApolloClient } from '@apollo/client'

type TransactionsSorts = 'clickId' | 'clickAt' | 'campaign' | 'page'

type GroupBy = { [key in ReportGroupBy]: string }

interface TransactionsFilters extends GroupBy {
  dateType: string
  dateRange: string
  startDate: string
  endDate: string
  vertical: string
  productType: string
  program: string
  product: string
  campaign: string
  event: string
  clickedProduct: string
  subTracking: string
  clickedProductName: string
  displayedProductName: string
  integrationType: string
  deviceType: string
}

export type TransactionDetailsReportVariables = TransactionDetailsReportingQueryVariables

export type TransactionDetailsReportSorts = keyof TransactionDetailsReportingSorts

export const TransactionsTableContext = createTableContext<
  TransactionsFilters,
  TransactionsSorts
>()

const TransactionsReport: FunctionComponent = () => {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [aborterRef, setAbortRef] = useState(new AbortController())
  const [requestCancelled, setRequestCancelled] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [
    TransactionDetailsReportingResult,
    { data, loading, error },
  ] = useTransactionDetailsReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })
  const [
    TransactionDetailsReportingExportResult,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useTransactionDetailsReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })
  if (error) {
    dispatch(
      triggerError(checkHttpError(error.message), 'Transaction Details Report')
    )
  }
  const abort = () => {
    aborterRef.abort()
    setAbortRef(new AbortController())
  }

  const reportCallback = (param: string) => {
    if (param === 'run') {
      setRequestCancelled(false)

      // set spinner for 3/4 of second for better user experience
      setSpinner(true)
      setTimeout(() => {
        setSpinner(false)
      }, 750)
    } else if (param === 'cancel') {
      abort()
      setRequestCancelled(true)
      client.clearStore()
    }
  }

  return (
    <TableContext<TransactionsFilters, TransactionsSorts>
      Context={TransactionsTableContext}
      queryParams={[
        'dateRange',
        'startDate',
        'endDate',
        'vertical',
        'productType',
        'program',
        'product',
        'clickedProduct',
        'campaign',
        'dateType',
        'event',
        'subTracking',
        'displayedProductName',
        'clickedProductName',
        'integrationType',
        'deviceType',
      ]}
    >
      <TransactionsTable
        getData={TransactionDetailsReportingResult}
        data={data}
        loading={(loading && !requestCancelled) || spinner}
        error={error != null}
        getExportData={TransactionDetailsReportingExportResult}
        exportData={exportData}
        exportLoading={exportLoading}
        exportError={exportError != null}
        abort={abort}
        reportCallback={reportCallback}
      />
    </TableContext>
  )
}

export default TransactionsReport
