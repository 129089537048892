import React, { FunctionComponent, useState, useEffect } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom'

import { Loading, CopyrightFooter } from '../../index'
import ExpiredDisplay from './Expired'
import ResetPasswordForm from './Form'

import { PasswordStore } from '../../../typings/password'
import { FormMethods } from '../../../containers/ResetPassword'

import loadingTransitions from '../../../utils/transitions/loading.module.css'
import linkTransitions from '../../../utils/transitions/additionalLinks.module.css'
import dialogTransitions from '../../../utils/transitions/dialog.module.css'
import styles from './styles.module.css'

interface ResetPasswordProps {
  formMethod: FormMethods
  passwordStore: PasswordStore
  isTokenValid: boolean
  resetPassword(): void
}

const ResetPassword: FunctionComponent<ResetPasswordProps> = ({
  formMethod,
  passwordStore,
  isTokenValid,
  resetPassword,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [showLinks, setShowLinks] = useState(false)

  useEffect(() => {
    const loadDelay = setTimeout(() => {
      setShowLinks(true)
    }, 600)

    return () => {
      setShowLinks(false)
      clearTimeout(loadDelay)
    }
  }, [passwordStore.submitted])

  useEffect(() => {
    if (passwordStore.submitted) {
      setShowDialog(true)
    }
  }, [passwordStore.submitted])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!showDialog ? (
          <CSSTransition
            in={!showDialog}
            classNames={loadingTransitions}
            timeout={600}
            appear
            mountOnEnter
            unmountOnExit
          >
            <Loading className={styles.loading} />
          </CSSTransition>
        ) : (
          <>
            <SwitchTransition>
              <CSSTransition
                key={isTokenValid}
                addEndListener={(node, done) => {
                  node.addEventListener('transitioned', done, false)
                }}
                classNames={dialogTransitions}
                timeout={300}
                appear
                mountOnEnter
                unmountOnExit
              >
                {isTokenValid ? (
                  <ResetPasswordForm
                    formMethod={formMethod}
                    passwordStore={passwordStore}
                    resetPassword={resetPassword}
                  />
                ) : (
                  <ExpiredDisplay formMethod={formMethod} />
                )}
              </CSSTransition>
            </SwitchTransition>
            <CSSTransition
              in={showLinks}
              timeout={300}
              classNames={linkTransitions}
              appear
              mountOnEnter
              unmountOnExit
            >
              <div className={styles.additionalLinks}>
                <Link className={styles.link} to="/login" data-rv="reset-login">
                  Back to Login
                </Link>
              </div>
            </CSSTransition>
          </>
        )}
      </div>
      <CopyrightFooter delay={900} />
    </div>
  )
}

export default ResetPassword
