import { Reducer } from 'redux'
import concat from 'lodash/concat'
import set from 'lodash/set'
import { DateTime } from 'luxon'

import { AppAction, AppConstants } from '../actions/app'
import { AppStore } from '../typings/stores/AppStore'

const initialState: AppStore = {
  errors: [],
}

export const appReducer: Reducer = (
  state = { ...initialState },
  action: AppAction
): AppStore => {
  switch (action.type) {
    case AppConstants.TRIGGER_ERROR:
      return {
        ...state,
        errors: concat(state.errors, {
          shown: false,
          message: action.message,
          reference: action.reference,
          time: DateTime.local(),
          index: action.index,
        }),
      }
    case AppConstants.SHOW_ERROR:
      return {
        ...state,
        errors: set(state.errors, `[${action.index}].shown`, true),
      }
    default:
      return state
  }
}
