import { Dispatch } from 'redux'
import _has from 'lodash/has'

import {
  fetchGetPublic,
  fetchPostPublic,
  fetchPutPublic,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { PasswordRequestInfo } from '../typings/password'

export enum PasswordConstants {
  REQUEST_RESET = 'password/REQUEST_RESET',
  REQUEST_RESET_SUCCESS = 'password/REQUEST_RESET_SUCCESS',
  REQUEST_RESET_FAILURE = 'password/REQUEST_RESET_FAILURE',
  VALIDATE_TOKEN = 'password/VALIDATE_TOKEN',
  VALIDATE_TOKEN_SUCCESS = 'password/VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_FAILURE = 'password/VALIDATE_TOKEN_FAILURE',
  RESET_PASSWORD = 'password/RESET',
  RESET_PASSWORD_SUCCESS = 'password/RESET_SUCCESS',
  RESET_PASSWORD_FAILURE = 'password/RESET_FAILURE',
}

export interface PasswordAction extends Action {
  type: PasswordConstants
  requestInfo?: PasswordRequestInfo
  error?: string
}

function requestReset(): PasswordAction {
  return {
    type: PasswordConstants.REQUEST_RESET,
  }
}

function requestResetSuccess(requestInfo: PasswordRequestInfo): PasswordAction {
  return {
    type: PasswordConstants.REQUEST_RESET_SUCCESS,
    requestInfo,
  }
}

function requestResetFailure(error: string): PasswordAction {
  return {
    type: PasswordConstants.REQUEST_RESET_FAILURE,
    error,
  }
}

function validateToken(): PasswordAction {
  return {
    type: PasswordConstants.VALIDATE_TOKEN,
  }
}

function validateTokenSuccess(
  requestInfo: PasswordRequestInfo
): PasswordAction {
  return {
    type: PasswordConstants.VALIDATE_TOKEN_SUCCESS,
    requestInfo,
  }
}

function validateTokenFailure(error: string): PasswordAction {
  return {
    type: PasswordConstants.VALIDATE_TOKEN_FAILURE,
    error,
  }
}

function resetPassword(): PasswordAction {
  return {
    type: PasswordConstants.RESET_PASSWORD,
  }
}

function resetPasswordSuccess(): PasswordAction {
  return {
    type: PasswordConstants.RESET_PASSWORD_SUCCESS,
  }
}

function resetPasswordFailure(error: string): PasswordAction {
  return {
    type: PasswordConstants.RESET_PASSWORD_FAILURE,
    error,
  }
}

export const requestPasswordReset = (username: string) => async (
  dispatch: Dispatch
) => {
  dispatch(requestReset())
  const { ok, errorParsed, bodyParsed } = await fetchPostPublic(
    fetch,
    '/request-reset',
    {
      username,
    }
  )

  if (ok) {
    dispatch(requestResetSuccess(bodyParsed.data))
  } else {
    dispatch(requestResetFailure(errorParsed))
  }
}

export const requestCircuitPasswordReset = (username: string) => async (
  dispatch: Dispatch
) => {
  dispatch(requestReset())
  const { ok, errorParsed, bodyParsed } = await fetchPostPublic(
    fetch,
    '/circuit/request-reset',
    {
      username,
    }
  )

  if (ok) {
    dispatch(requestResetSuccess(bodyParsed.data))
  } else {
    dispatch(requestResetFailure(errorParsed))
  }
}

export const verifyResetToken = (token: string) => async (
  dispatch: Dispatch
) => {
  dispatch(validateToken())

  const { ok, errorParsed, bodyParsed } = await fetchGetPublic(
    fetch,
    `/token/${token}`
  )
  if (ok) {
    dispatch(validateTokenSuccess(bodyParsed.data))
  } else {
    dispatch(validateTokenFailure(errorParsed))
  }
}

export const updatePassword = (userId: number, newPassword: string) => async (
  dispatch: Dispatch
) => {
  dispatch(resetPassword())

  const { ok, errorParsed } = await fetchPutPublic(fetch, '/reset-password', {
    userId,
    newPassword,
  })

  if (ok) {
    dispatch(resetPasswordSuccess())
  } else {
    dispatch(resetPasswordFailure(errorParsed))
  }
}
