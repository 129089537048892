import { Dispatch } from 'redux'

import { triggerError } from './app'
import { fetchGetApi, fetchPostApi } from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { Property, PropertiesData } from '../typings/property'

export enum PropertiesConstants {
  FETCH = 'properties/FETCH',
  FETCH_SUCCESS = 'properties/FETCH_SUCCESS',
  FETCH_FAILURE = 'properties/FETCH_FAILURE',
  CREATE = 'properties/CREATE',
  CREATE_SUCCESS = 'properties/CREATE_SUCCESS',
  CREATE_FAILURE = 'property/CREATE_FAILURE',
}

export interface PropertiesAction extends Action {
  type: PropertiesConstants
  data?: PropertiesData | Property
  error?: string
}

function fetchProperties(): PropertiesAction {
  return {
    type: PropertiesConstants.FETCH,
  }
}

function fetchPropertiesSuccess(data: PropertiesData): PropertiesAction {
  return {
    type: PropertiesConstants.FETCH_SUCCESS,
    data,
  }
}

function fetchPropertiesFailure(error: string): PropertiesAction {
  return {
    type: PropertiesConstants.FETCH_FAILURE,
    error,
  }
}

function createProperty(): PropertiesAction {
  return {
    type: PropertiesConstants.CREATE,
  }
}

function createPropertySuccess(property: Property): PropertiesAction {
  return {
    type: PropertiesConstants.CREATE_SUCCESS,
    data: property,
  }
}

function createPropertyFailure(error: string): PropertiesAction {
  return {
    type: PropertiesConstants.CREATE_FAILURE,
    error,
  }
}

export const getProperties = () => async (dispatch: Dispatch) => {
  dispatch(fetchProperties())

  const endpoint = '/properties'

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchPropertiesSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Properties'))
    dispatch(fetchPropertiesFailure(errorParsed))
  }
}

export const addProperty = (
  property: Property,
  publisherName: string
) => async (dispatch: Dispatch) => {
  dispatch(createProperty())

  const newProperty = { ...property, publisherName }

  const endpoint = '/properties'

  const { ok, bodyParsed, errorParsed } = await fetchPostApi(
    fetch,
    endpoint,
    newProperty
  )

  if (ok) {
    dispatch(createPropertySuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'New Property'))
    dispatch(createPropertyFailure(errorParsed))
  }
}
