import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { has, get, isEmpty, debounce } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'

import { Loading, PageHeading, Markdown } from '../..'
import { parseSnippet } from './parser'
import { State } from '../../../reducers'

import { LinkInfoRequest, LinkInfoStore } from '../../../typings/link'

import alertTransitions from './alert.module.css'
import expansionTransitions from './expansion.module.css'
import infoTransitions from './info.module.css'

import styles from './styles.module.css'
import { useSelector } from 'react-redux'
import unescape from 'lodash/unescape'
import markdown from './linkinfo.md'

interface LinkInfoProps {
  linkInfo?: LinkInfoStore
  getLinkInfo(params: LinkInfoRequest): void
}

const LinkInfoPane = ({ info }) => (
  <div className={styles.info}>
    <strong>Product ID:</strong>
    <div className={styles.data}>{get(info, 'productId')}</div>

    <strong>Product Name:</strong>
    <div className={styles.data}>{unescape(get(info, 'productName', ''))}</div>

    <strong>Approved Website:</strong>
    <div className={styles.data}>{get(info, 'website')}</div>

    <strong>Status:</strong>
    <div className={styles.data}>{get(info, 'status')}</div>

    {has(info, 'userVariable') && !isEmpty(info.userVariable) && (
      <>
        <strong>User Variable:</strong>
        <div className={styles.data}>{get(info, 'userVariable')}</div>
      </>
    )}

    {has(info, 'category') && !isEmpty(info.category) && (
      <>
        <strong>Category:</strong>
        <div className={styles.data}>{get(info, 'category')}</div>
      </>
    )}

    {has(info, 'position') && !isEmpty(info.position) && (
      <>
        <strong>Position:</strong>
        <div className={styles.data}>{get(info, 'position')}</div>
      </>
    )}
  </div>
)

const LinkInfo: FunctionComponent<LinkInfoProps> = ({
  linkInfo,
  getLinkInfo,
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [showParams, setShowParams] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    if (linkInfo && linkInfo.error) {
      setShowError(true)
    }
  }, [linkInfo])

  let isLoading = useSelector<State, boolean>(
    state => get(state, 'links.linkInfo.status') === 'fetching'
  )

  const debounced = useCallback(
    debounce((code: string) => {
      if (code) {
        const { isValid, params } = parseSnippet(code)

        if (isValid) {
          getLinkInfo({
            linkId: get(params, 'lid'),
            userVariable: get(params, 'tid'),
            category: get(params, 'fid'),
            position: get(params, 'pos'),
          })
          setShowError(false)
          setShowParams(true)
        } else {
          setShowError(true)
          setShowParams(false)
        }
      }
      return
    }, 700),
    []
  )

  return (
    <>
      <PageHeading title="Link Information" />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.form}>
            <label htmlFor="codeSnippet">Paste Link or Code Snippet:</label>
            <textarea
              id="codeSnippet"
              name="codeSnippet"
              className={styles.code}
              onChange={e => {
                const code = e.currentTarget.value
                setShowError(false)
                setShowParams(false)
                debounced(code)
              }}
              data-rv="link-info-code"
            />
            <CSSTransition
              in={showInfo}
              timeout={600}
              classNames={expansionTransitions}
              mountOnEnter
              unmountOnExit
            >
              <Markdown data={markdown} className={styles.markdown} />
            </CSSTransition>
            <div className={styles.infoBtn}>
              <button onClick={() => setShowInfo(!showInfo)}>
                <FontAwesomeIcon
                  icon={['fas', showInfo ? 'sort-up' : 'info-circle']}
                />
              </button>
            </div>
            <CSSTransition
              in={showError}
              timeout={600}
              classNames={alertTransitions}
              mountOnEnter
              unmountOnExit
            >
              <div className={styles.error} data-rv="link-info-error">
                Invalid link or code snippet
              </div>
            </CSSTransition>
          </div>

          <CSSTransition
            in={showParams && get(linkInfo, 'hasData', false)}
            timeout={600}
            classNames={infoTransitions}
            mountOnEnter
            unmountOnExit
          >
            <LinkInfoPane info={get(linkInfo, 'data')} />
          </CSSTransition>
          {isLoading && (
            <div className={styles.loading}>
              <Loading className={styles.spinner} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default LinkInfo
