import React, { ReactNode, FunctionComponent } from 'react'
import ReactModal from 'react-modal'
import classnames from 'classnames'

import styles from './styles.module.css'
import Svg from '../Svg'

interface ModalProps {
  isOpen?: boolean
  children: ReactNode
  className?: string
  shouldCloseOnOverlayClick?: boolean
  close: Function
}

const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  shouldCloseOnOverlayClick,
  close,
  className,
  children,
  ...rest
}) => (
  <>
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => close()}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={!isOpen}
      className={classnames(styles.modal, className)}
      style={{
        overlay: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        content: {
          border: '1px solid rgba(0, 0, 0, 0.5)',
          borderRadius: '2px',
          boxShadow: '0 2px rgba(25,123,207,0.24)',
        },
      }}
      {...rest}
    >
      <div className={styles.container}>
        <div className={styles.close}>
          <button
            onClick={e => {
              e.preventDefault()
              close()
            }}
            data-rv="modal-close-btn"
          >
            <Svg name="close" className={styles.icon} />
          </button>
        </div>
        <div className={styles.content} data-rv="model-content">
          {children}
        </div>
      </div>
    </ReactModal>
  </>
)

Modal.defaultProps = {
  isOpen: false,
  className: null,
  shouldCloseOnOverlayClick: true,
}

export default Modal
