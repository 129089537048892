import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { get } from 'lodash'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../reducers'
import { UserStore } from '../../../typings/stores/UserStore'
import {
  DateRangeFilter,
  FilterBox,
  Select,
  GenericInput,
} from '../../../components'
import { convertPeriodToDates } from '../../../utils/dates'

import styles from './filters.module.css'
import { useProductFamiliesLazyQuery } from '../../../generated/types'
import { decode, encode } from 'he'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { rebuild } from 'react-tooltip'
import { ReportsStore } from '../../../typings/stores/ReportsStore'
import { filterChange } from '../../../actions/reports'
import deviceTypeOptions from '../../../utils/deviceTypeOptions'

interface TableFiltersProps {
  tableContext: any
  showDateType?: boolean
  showEvent?: boolean
  showClickedProducts?: boolean
  showSubTracking?: boolean
  setPropertyHasData?: any
  showIncludeImpressions?: boolean
  showHasReferralClicks?: boolean
  showIntegrationTypes?: boolean
  showDeviceType?: boolean
}

const TableFilters: FunctionComponent<TableFiltersProps> = ({
  tableContext,
  showDateType = false,
  showEvent = false,
  showClickedProducts = false,
  showSubTracking = false,
  setPropertyHasData = null,
  showIncludeImpressions = false,
  showHasReferralClicks = false,
  showIntegrationTypes = false,
  showDeviceType = false,
}) => {
  const { setFilter, setFilters, filters } = useContext(tableContext)
  const [verticals, setVerticals] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [programs, setPrograms] = useState([])
  const [products, setProducts] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [events, setEvents] = useState([])

  const [deviceTypes, setDeviceType] = useState([])
  const [clickedProduct, setClickedProduct] = useState([])
  const [integrationTypes, setIntegrationType] = useState([])
  const defaultPeriod = 'this_month'
  const defaultStartDate = convertPeriodToDates(defaultPeriod).startDate
  const defaultEndDate = convertPeriodToDates(defaultPeriod).endDate

  const queryParams = new URLSearchParams(location.search)
  const verticalParam = queryParams.get('verticalId')

  const allEvents = [
    {
      value: 'sale',
      text: 'Sale',
    },
    {
      value: 'app',
      text: 'Application',
    },
    {
      value: 'dec',
      text: 'Decline',
    },
  ]

  const user = useSelector<State, UserStore>(state => state.user)
  const reportStore = useSelector<State, ReportsStore>(state => state.reports)
  const dispatch = useDispatch()

  const [
    ProductFamiliesResult,
    { data, loading },
  ] = useProductFamiliesLazyQuery()

  const fetchData = () => {
    ProductFamiliesResult({
      variables: {
        take: 3000,
        externalPropertyId: user.activeWebsite.id.toString(),
      },
    })
  }

  // Update the filterStore if the startDate, endDate, or dateRange have changed.
  useEffect(() => {
    dispatch(filterChange(filters))
  }, [filters.startDate, filters.endDate, filters.dateRange, filters.vertical])

  useEffect(() => {
    if (verticalParam === '5') {
      setEvents(allEvents)
    } else {
      setEvents([allEvents[0]])
      setFilter('event', 'sale')
    }
  }, [verticalParam])

  // Rebind tooltips
  useEffect(() => {
    rebuild()
  })

  useEffect(fetchData, [user.activeWebsite])

  const findProduct = (productsSet, productName) => {
    let selected = []
    // Make sure to convert back quotes, since we store with this in the database
    const encodedProductName = encode(get(filters, productName, ''), {
      decimal: true,
    })
      .replace(/&#34;/g, '"')
      .replace(/&#39;/g, "'")
    if (encodedProductName && encodedProductName != '') {
      // We have different encoding process for special characters:
      // Symbol & can have two different encoding
      const nameFilter1 = encodedProductName.replace(/&#38;/g, '&amp;')
      // Symbol ® (registered) can have two different encoding
      const nameFilter2 = encodedProductName.replace(/&#174;/g, '&reg;')
      // Symbol ™ (trademark) can have two different encoding
      const nameFilter3 = encodedProductName.replace(/&#8482;/g, '&trade;')
      // Symbol ™ (trademark) can have two different encoding - combining with filter1
      const nameFilter4 = nameFilter1.replace(/&#8482;/g, '&trade;')
      // Symbol ™ (trademark) can have two different encoding - combining with filter2
      const nameFilter5 = nameFilter2.replace(/&#8482;/g, '&trade;')
      // Symbol ™ (trademark) can have two different encoding - combining with filter3
      const nameFilter6 = nameFilter3.replace(/&#8482;/g, '&trade;')
      // Symbol ® (registered) can have two different encoding - combining with filter1
      const nameFilter7 = nameFilter1.replace(/&#174;/g, '&reg;')

      selected = productsSet.filter(
        p =>
          p.name == nameFilter1 ||
          p.name == nameFilter2 ||
          p.name == nameFilter3 ||
          p.name == nameFilter4 ||
          p.name == nameFilter5 ||
          p.name == nameFilter6 ||
          p.name == nameFilter7
      )
    }
    return selected.length > 0 ? selected[0] : null
  }

  useEffect(() => {
    if (setPropertyHasData) {
      if (data?.productFamilies?.page?.length === 0) {
        setPropertyHasData(false)
      } else if (data?.productFamilies?.page?.length > 0) {
        setPropertyHasData(true)
      }
    }

    if (data && data.productFamilies) {
      const verticalsSet = []
      const productTypesSet = []
      const programsSet = []
      const productsSet = []
      const campaignsSet = []
      const clickedProductSet = []
      const integrationTypeSet = []
      const deviceTypeSet = []
      for (const propertyProduct of data.productFamilies.page) {
        if (
          !verticalsSet.find(
            x => x.id === propertyProduct.externalVerticalId
          ) &&
          propertyProduct.verticalName
        ) {
          verticalsSet.push({
            id: propertyProduct.externalVerticalId,
            name: propertyProduct.verticalName,
          })
        }
        if (
          !productTypesSet.find(
            x => x.id === propertyProduct.externalProductTypeId
          ) &&
          propertyProduct.productTypeName
        ) {
          productTypesSet.push({
            id: propertyProduct.externalProductTypeId,
            name: propertyProduct.productTypeName,
            verticalId: propertyProduct.externalVerticalId,
          })
        }
        if (
          !programsSet.find(x => x.id === propertyProduct.externalProgramId) &&
          propertyProduct.programName
        ) {
          programsSet.push({
            id: propertyProduct.externalProgramId,
            name: propertyProduct.programName,
            verticalId: propertyProduct.externalVerticalId,
            productTypeId: propertyProduct.externalProductTypeId,
          })
        }
        if (
          !productsSet.find(
            x => x.name === propertyProduct.displayedProductName
          ) &&
          propertyProduct.displayedProductName
        ) {
          productsSet.push({
            ids: [propertyProduct.externalDisplayedProductId],
            name: propertyProduct.displayedProductName,
            verticalId: propertyProduct.externalVerticalId,
            productTypeId: propertyProduct.externalProductTypeId,
            programId: propertyProduct.externalProgramId,
          })
        } else if (
          productsSet.find(x => x.name === propertyProduct.displayedProductName)
        ) {
          const product = productsSet.find(
            x => x.name === propertyProduct.displayedProductName
          )
          if (product) {
            if (
              !product.ids.includes(propertyProduct.externalDisplayedProductId)
            ) {
              product.ids.push(propertyProduct.externalDisplayedProductId)
            }
          }
        }
        if (
          !campaignsSet.find(
            x => x.id === propertyProduct.externalCampaignId
          ) &&
          propertyProduct.campaignName
        ) {
          campaignsSet.push({
            id: propertyProduct.externalCampaignId,
            name: propertyProduct.campaignName,
            verticalId: propertyProduct.externalVerticalId,
            productTypeId: propertyProduct.externalProductTypeId,
            programId: propertyProduct.externalProgramId,
            productId: propertyProduct.externalClickedProductId,
          })
        }
        if (
          !clickedProductSet.find(
            x => x.name === propertyProduct.clickedProductName
          ) &&
          propertyProduct.clickedProductName
        ) {
          clickedProductSet.push({
            ids: [propertyProduct.externalClickedProductId],
            name: propertyProduct.clickedProductName,
            verticalId: propertyProduct.externalVerticalId,
          })
        } else if (
          clickedProductSet.find(
            x => x.name === propertyProduct.clickedProductName
          )
        ) {
          const clickedProduct = clickedProductSet.find(
            x => x.name === propertyProduct.clickedProductName
          )
          if (clickedProduct) {
            if (
              !clickedProduct.ids.includes(
                propertyProduct.externalClickedProductId
              )
            ) {
              clickedProduct.ids.push(propertyProduct.externalClickedProductId)
            }
          }
        }
        if (
          !integrationTypeSet.find(
            x => x.id === propertyProduct.integrationTypeId
          ) &&
          propertyProduct.integrationTypeId
        ) {
          integrationTypeSet.push({
            id: propertyProduct.integrationTypeId,
            name: propertyProduct.integrationTypeName,
            verticalId: propertyProduct.externalVerticalId,
            productTypeId: propertyProduct.externalProductTypeId,
            programId: propertyProduct.externalProgramId,
          })
        }
      }
      verticalsSet.sort((a, b) => a.name.localeCompare(b.name))
      productTypesSet.sort((a, b) => a.name.localeCompare(b.name))
      programsSet.sort((a, b) => a.name.localeCompare(b.name))
      productsSet.sort((a, b) => a.name.localeCompare(b.name))
      campaignsSet.sort((a, b) => a.name.localeCompare(b.name))
      clickedProductSet.sort((a, b) => a.name.localeCompare(b.name))
      integrationTypeSet.sort((a, b) => a.name.localeCompare(b.name))
      setVerticals(verticalsSet)
      setProductTypes(productTypesSet)
      setPrograms(programsSet)
      setProducts(productsSet)
      setCampaigns(campaignsSet)
      setClickedProduct(clickedProductSet)
      setIntegrationType(integrationTypeSet)
      setDeviceType(deviceTypeSet)

      // Check selected product for Displayed product
      const displayedProductFilter = get(filters, 'product', '')
      if (!displayedProductFilter || displayedProductFilter == '') {
        let selectedDisplayedProduct = null
        const selectedProduct = findProduct(productsSet, 'displayedProductName')
        if (selectedProduct) {
          selectedDisplayedProduct = selectedProduct.ids
          // Set displayed product filter
          setFilter('product', selectedDisplayedProduct.join(','))
        }
      }

      // Check selected product for Clicked product
      const clickedProductFilter = get(filters, 'clickedProduct', '')
      if (!clickedProductFilter || clickedProductFilter == '') {
        let selectedClickedProduct = null
        const selectedProduct = findProduct(
          clickedProductSet,
          'clickedProductName'
        )
        if (selectedProduct) {
          selectedClickedProduct = selectedProduct.ids
          // Set clicked product filter
          setFilter('clickedProduct', selectedClickedProduct.join(','))
        }
      }

      if (verticalParam && verticalParam !== '') {
        setFilter('vertical', verticalParam)
      } else if (verticalsSet.length > 0) {
        if (filters.vertical && filters.vertical !== '') {
          setFilter('vertical', filters.vertical)
        } else {
          setFilter('vertical', verticalsSet[0].id)
        }
      } else {
        setFilter('vertical', '')
        setPropertyHasData(false)
      }
    }
  }, [data])

  /*
  if there is no filter, set the default filter, otherwise use the filter from the store
  */
  useEffect(() => {
    if (!filters.startDate || !filters.endDate) {
      if (reportStore.filters?.startDate && reportStore.filters?.endDate) {
        filters.startDate = reportStore.filters?.startDate
        filters.endDate = reportStore.filters?.endDate
        filters.dateRange = reportStore.filters?.dateRange
      } else {
        filters.startDate = defaultStartDate
        filters.endDate = defaultEndDate
        filters.dateRange = defaultPeriod
        dispatch(filterChange(filters))
      }
      filters.dateType = 'clickDate'
      filters.event = 'sale'

      if (showClickedProducts) {
        filters.groupBy = 'byDate'
      }

      // only set filters.vertical if exist into the store
      if (reportStore.filters?.vertical) {
        filters.vertical = reportStore.filters?.vertical
      }
    }
  }, [user.activeWebsite])

  const handleSelectChange = e => {
    const newValue = e.currentTarget.value
    setFilter('vertical', newValue)
    dispatch(filterChange({ ...filters, vertical: newValue }))
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {showDateType && (
          <FilterBox label="Date type">
            <div className={styles.dateTypeContainer}>
              <button
                className={classnames(styles.toggleBtn, {
                  [styles.selected]: filters.dateType === 'clickDate',
                })}
                onClick={() => setFilter('dateType', 'clickDate')}
              >
                Click Date
              </button>
              <button
                className={classnames(styles.toggleBtn, {
                  [styles.selected]: filters.dateType === 'processDate',
                })}
                onClick={() => setFilter('dateType', 'processDate')}
              >
                Process Date
              </button>
            </div>
          </FilterBox>
        )}
        <DateRangeFilter
          onChange={setFilters}
          dates={{
            startDate: filters.startDate ?? defaultEndDate,
            endDate: filters.endDate ?? defaultStartDate,
            dateRange: filters.dateRange ?? defaultPeriod,
          }}
        />
        {showIncludeImpressions && (
          <FilterBox label="">
            <div className={styles.revenueContainer}>
              <input
                id="impressionsFilter"
                type="checkbox"
                value={get(filters, 'includeImpressions', 'false')}
                className={styles.revenueFilter}
                onChange={e => {
                  setFilter(
                    'includeImpressions',
                    e.currentTarget.checked ? 'true' : 'false'
                  )
                }}
                checked={filters.includeImpressions === 'true'}
              />
              <label htmlFor="impressionsFilter">Fetch impressions data</label>
              <div
                data-tip="Including impression data will increase runtime for the report."
                data-for="global-tooltip"
                data-place="right"
              >
                <FontAwesomeIcon
                  icon={['fas', 'info-circle']}
                  className={styles.icon}
                  style={{ marginLeft: '6px' }}
                />
              </div>
            </div>
          </FilterBox>
        )}
      </div>
      <div className={styles.row}>
        <FilterBox label="Filter by" className={styles.filterBy}>
          <div className={styles.filterByContainer}>
            <Select
              className={styles.dropdown}
              placeholder={null}
              loadingMessage="Loading verticals"
              isLoading={loading}
              selected={reportStore.filters?.vertical}
              options={[
                ...verticals.map(pt => ({
                  value: pt.id,
                  text: decode(pt.name),
                })),
              ]}
              onChange={handleSelectChange}
            />
            {showEvent && (
              <Select
                className={styles.dropdown}
                selected={get(filters, 'event', 'sale')}
                options={events}
                onChange={e => setFilter('event', e.currentTarget.value)}
              />
            )}
            <Select
              className={styles.dropdown}
              placeholder="All Product Types"
              loadingMessage="Loading product types"
              isLoading={loading}
              selected={get(filters, 'productType', '')}
              options={
                filters.vertical
                  ? productTypes
                      .filter(pt => pt.verticalId === filters.vertical)
                      .map(pt => ({
                        value: pt.id,
                        text: decode(pt.name),
                      }))
                  : productTypes.map(pt => ({
                      value: pt.id,
                      text: decode(pt.name),
                    }))
              }
              onChange={e => setFilter('productType', e.currentTarget.value)}
            />

            <Select
              className={styles.dropdown}
              placeholder="All Displayed Products"
              loadingMessage="Loading products"
              isLoading={loading}
              selected={get(filters, 'product', '')}
              options={
                filters.vertical
                  ? products
                      .filter(pt => pt.verticalId === filters.vertical)
                      .map(pt => ({
                        value: pt.ids.join(','),
                        text: decode(pt.name),
                      }))
                  : products.map(pt => ({
                      value: pt.ids.join(','),
                      text: decode(pt.name),
                    }))
              }
              onChange={e =>
                setFilters({
                  ...filters,
                  product: e.currentTarget.value,
                  displayedProductName: '',
                })
              }
            />
            <Select
              className={styles.dropdown}
              placeholder="All Programs"
              loadingMessage="Loading programs"
              isLoading={loading}
              selected={get(filters, 'program', '')}
              options={
                filters.vertical
                  ? programs
                      .filter(pt => pt.verticalId === filters.vertical)
                      .map(pt => ({
                        value: pt.id,
                        text: decode(pt.name),
                      }))
                  : programs.map(pt => ({
                      value: pt.id,
                      text: decode(pt.name),
                    }))
              }
              onChange={e => setFilter('program', e.currentTarget.value)}
            />
            <Select
              className={styles.dropdown}
              placeholder="All Clicked Products"
              loadingMessage="Loading Clicked products"
              isLoading={loading}
              selected={get(filters, 'clickedProduct', '')}
              options={
                filters.vertical
                  ? clickedProduct
                      .filter(pt => pt.verticalId === filters.vertical)
                      .map(pt => ({
                        value: pt.ids.join(','),
                        text: decode(pt.name),
                      }))
                  : clickedProduct.map(pt => ({
                      value: pt.ids.join(','),
                      text: decode(pt.name),
                    }))
              }
              onChange={e =>
                setFilters({
                  ...filters,
                  clickedProduct: e.currentTarget.value,
                  clickedProductName: '',
                })
              }
            />
            <Select
              className={styles.dropdown}
              placeholder="All Campaigns"
              loadingMessage="Loading campaigns"
              isLoading={loading}
              selected={get(filters, 'campaign', '')}
              options={
                filters.vertical
                  ? campaigns
                      .filter(pt => pt.verticalId === filters.vertical)
                      .map(pt => ({
                        value: pt.id,
                        text: decode(pt.name),
                      }))
                  : campaigns.map(pt => ({
                      value: pt.id,
                      text: decode(pt.name),
                    }))
              }
              onChange={e => setFilter('campaign', e.currentTarget.value)}
            />
            {showIntegrationTypes && (
              <Select
                className={styles.dropdown}
                placeholder="All Integration Types"
                loadingMessage="Loading integration types"
                isLoading={loading}
                selected={get(filters, 'integrationType', '')}
                options={
                  filters.vertical
                    ? integrationTypes
                        .filter(pt => pt.verticalId === filters.vertical)
                        .map(pt => ({
                          value: pt.id,
                          text: decode(pt.name),
                        }))
                    : integrationTypes.map(pt => ({
                        value: pt.id,
                        text: decode(pt.name),
                      }))
                }
                onChange={e =>
                  setFilter('integrationType', e.currentTarget.value)
                }
              />
            )}
            {showDeviceType && (
              <Select
                className={styles.dropdown}
                placeholder="All Device Types"
                loadingMessage="Loading device types"
                isLoading={loading}
                selected={get(filters, 'deviceType', '')}
                options={deviceTypeOptions}
                onChange={e => setFilter('deviceType', e.currentTarget.value)}
              />
            )}
            <GenericInput
              className={styles.dropdown}
              placeholder="SubTracking"
              value={get(filters, 'subTracking', '')}
              onChange={e => setFilter('subTracking', e.currentTarget.value)}
            />
            {showHasReferralClicks && (
              <div className={styles.revenueContainer}>
                <input
                  type="checkbox"
                  id="referralClick"
                  name="referralClick"
                  checked={filters.hasReferralClick === 'true' ? true : false}
                  value={get(filters, 'hasReferralClick', false)}
                  className={styles.revenueFilter}
                  onChange={e =>
                    setFilter('hasReferralClick', e.currentTarget.checked)
                  }
                />
                <label htmlFor="referralClick"> Only Show Referrals</label>
              </div>
            )}
          </div>
        </FilterBox>
      </div>
    </div>
  )
}

export default TableFilters
