import React, {
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from 'react'
import accounting from 'accounting'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { DateTimeDisplay, PageHeading } from '../../../components'
import Table from '../../../components/Table/next'
import ReportActions from '../../../components/ReportActions'
import { TransactionsTableContext } from './index'
import { TransactionDetail } from '../../../typings/detailReport'
import { State } from '../../../reducers'
import { UserStore } from '../../../typings/stores/UserStore'
import { prepareDatesForApi } from '../../../utils/dates'
import { TransactionDetailsReportVariables } from './index'
import TableFilters from '../Filters/TableFilters'
import { dumpData } from '../../../actions/reports'
import { decode } from 'he'
import { ReportsStore } from '../../../typings/stores/ReportsStore'

interface TransactionsTableProps {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
  exportData: any
  exportLoading: boolean
  exportError: boolean
  getExportData: ({ variables }) => void
  abort: () => void
  reportCallback: (param: string) => void
}

const TransactionsTable: FunctionComponent<TransactionsTableProps> = ({
  getData,
  data,
  loading,
  error,
  getExportData,
  exportData,
  exportLoading,
  exportError,
  abort,
  reportCallback,
}) => {
  const user = useSelector<State, UserStore>(state => state.user)
  const report = useSelector<State, ReportsStore>(state => state.reports)
  const {
    filters,
    pagination: { size, page },
    sort,
    changePageSize,
    changePage,
    changeSort,
  } = useContext(TransactionsTableContext)

  let verticalId = ''
  let verticalIdExists = false
  if (report.filters?.vertical) {
    let needProductFilter = false
    // If we have clickedProductName or displayedProductName filters, we need to wait until we load the products
    if (
      (filters.clickedProductName && !filters.clickedProduct) ||
      (filters.displayedProductName && !filters.product)
    ) {
      // We have filter defined for product but we still don't have the products load, wait...
      needProductFilter = true
    }
    if (!needProductFilter) {
      verticalId = report.filters?.vertical.toString()
      verticalIdExists = true
    }
  }

  const updateVariables = () => {
    return {
      ...prepareDatesForApi(filters.startDate, filters.endDate, true),
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPropertyId: user.activeWebsite.id.toString(),
      dateType: filters.dateType,
      event: filters.event,
      filters: {
        externalPublisherId: user.affiliateId,
        ...(verticalId &&
          verticalId !== '' && { externalVerticalId: verticalId }),
        ...(filters.productType && {
          externalProductTypeId: filters.productType,
        }),
        ...(filters.program && { externalProgramId: filters.program }),
        ...(filters.product && { externalDisplayedProductId: filters.product }),
        ...(filters.clickedProduct && {
          externalClickedProductId: filters.clickedProduct,
        }),
        ...(filters.campaign && { externalCampaignId: filters.campaign }),
        ...(filters.subTracking && { subTracking: filters.subTracking }),
        ...(filters.integrationType && {
          externalIntegrationTypeId: filters.integrationType,
        }),
        ...(filters.deviceType && { deviceType: filters.deviceType }),
      },
    } as TransactionDetailsReportVariables
  }

  const fetchData = () => {
    if (verticalIdExists && verticalId != '') {
      getData({ variables: updateVariables() })
      updateTableColumns()
    }
  }

  const fetchExport = () => {
    getExportData({
      variables: {
        ...updateVariables(),
        take: get(
          data,
          `transactionDetailsReporting.pagination.totalEntries`,
          0
        ),
      },
    })
  }

  useEffect(() => {
    dumpData(
      'transactionDetails',
      exportData?.transactionDetailsReporting?.page,
      {
        ...filters,
      }
    )
  }, [exportData])

  useEffect(fetchData, [sort, page, size, user.activeWebsite, verticalIdExists])

  const pagination = {
    currentPage: get(
      data,
      `transactionDetailsReporting.pagination.currentPage`,
      1
    ),
    pageSize: get(data, `transactionDetailsReporting.pagination.pageSize`, 10),
    total: get(data, `transactionDetailsReporting.pagination.totalEntries`, 0),
  }

  const defaultHiddenColumns = [
    'subTracking2',
    'subTracking3',
    'subTracking4',
    'subTracking5',
  ]

  const allColumns = [
    {
      text: 'Click Date',
      sortable: true,
      field: 'clickedAt',
      tooltip: true,
      tooltipText: 'You are seeing data in UTC time',
      render: row =>
        row.clickedAt && (
          <DateTimeDisplay
            datetime={DateTime.fromISO(row.clickedAt, { zone: 'utc' })}
          />
        ),
    },
    {
      text: 'Process Date',
      sortable: true,
      field: 'processedAt',
      tooltip: true,
      tooltipText: 'You are seeing data in UTC time',
      render: row =>
        row.processedAt && (
          <DateTimeDisplay
            datetime={DateTime.fromISO(row.processedAt, { zone: 'utc' })}
          />
        ),
    },
    {
      text: 'Click ID',
      sortable: true,
      field: 'externalClickId',
    },
    {
      text: 'Vertical',
      sortable: true,
      field: 'verticalName',
    },
    {
      text: 'Product Type',
      sortable: true,
      field: 'typeName',
    },
    {
      text: 'Program',
      sortable: true,
      field: 'programName',
    },
    {
      text: 'Displayed product ID',
      sortable: true,
      field: 'externalDisplayedProductId',
    },
    {
      text: 'displayed Product',
      sortable: true,
      field: 'displayedProductName',
      render: row =>
        row.displayedProductName && decode(row.displayedProductName),
    },
    {
      text: 'Clicked Product ID',
      sortable: true,
      field: 'externalClickedProductId',
    },
    {
      text: 'Clicked Product',
      sortable: true,
      field: 'clickedProductName',
      render: row => row.clickedProductName && decode(row.clickedProductName),
    },
    {
      text: 'Integration Type',
      sortable: false,
      field: 'integrationTypeName',
    },
    {
      text: 'Sale ID',
      sortable: true,
      field: 'saleId',
    },
    {
      text: 'Commissions',
      sortable: true,
      field: 'commission',
      render: ({ commission }) => accounting.formatMoney(commission, '$'),
    },
    {
      text: 'True Conversion',
      sortable: true,
      field: 'trueConversion',
      render: row => (row.trueConversion ? 'Yes' : 'No'),
    },
    {
      text: 'Event Type',
      sortable: true,
      field: 'eventType',
    },
    {
      text: 'IP Address',
      field: 'ipAddress',
    },
    {
      text: 'Page',
      field: 'page',
    },
    {
      text: 'Sub ID',
      field: 'subTracking',
    },
    {
      text: 'Sub ID 2',
      field: 'subTracking2',
    },
    {
      text: 'Sub ID 3',
      field: 'subTracking3',
    },
    {
      text: 'Sub ID 4',
      field: 'subTracking4',
    },
    {
      text: 'Sub ID 5',
      field: 'subTracking5',
    },
    {
      text: 'Credit Bucket',
      field: 'creditBucket',
    },
    {
      text: 'Credit Score',
      field: 'creditScoreExact',
    },
    {
      text: 'Loan Amount',
      sortable: true,
      field: 'loanAmount',
      render: ({ loanAmount }) =>
        loanAmount ? accounting.formatMoney(loanAmount, '$') : '-',
    },
    {
      text: 'Cross Sale Indicator',
      field: 'crossSale',
    },
    {
      text: 'State',
      field: 'postalState',
    },
    {
      text: 'Device Type',
      field: 'deviceType',
    },
  ]

  const [tableColumns, setTableColumns] = useState(allColumns)
  const [propertyHasData, setPropertyHasData] = useState(true)
  const [pageDescription, setPageDescription] = useState(
    'This report contains conversion event details for the filters selected.'
  )

  useEffect(() => {
    if (!propertyHasData) {
      setPageDescription('No reporting data available for this property yet.')
    }
  }, [propertyHasData])

  useEffect(() => {
    if (!propertyHasData) {
      window.location.reload()
    }
  }, [user.activeWebsite])

  const updateTableColumns = () => {
    if (filters.vertical === '3') {
      // Mortgages
      const availableColumns = [
        'clickedAt',
        'externalClickedId',
        'verticalName',
        'typeName',
        'externalClickedProductId',
        'clickedProductName',
        'externalDisplayedProductId',
        'displayedProductName',
        'productName',
        'externalProductId',
        'campaignName',
        'integrationTypeName',
        'page',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'commission',
        'processedAt',
        'creditBucket',
        'postalState',
        'trueConversion',
        'deviceType',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else if (filters.vertical === '5') {
      // Credit Cards
      const availableColumns = [
        'clickedAt',
        'externalClickedId',
        'verticalName',
        'typeName',
        'externalClickedProductId',
        'clickedProductName',
        'externalDisplayedProductId',
        'displayedProductName',
        'programName',
        'productName',
        'externalProductId',
        'campaignName',
        'integrationTypeName',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'page',
        'saleId',
        'commission',
        'processedAt',
        'eventType',
        'trueConversion',
        'deviceType',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else if (
      filters.vertical === '7' ||
      filters.vertical === '8' ||
      filters.vertical === '10' ||
      filters.vertical === '11' ||
      filters.vertical === '13'
    ) {
      // Investing || Health || Retail || Home Services || Personal Finance
      const availableColumns = [
        'clickedAt',
        'externalClickedId',
        'verticalName',
        'typeName',
        'externalClickedProductId',
        'clickedProductName',
        'externalDisplayedProductId',
        'displayedProductName',
        'programName',
        'productName',
        'externalProductId',
        'campaignName',
        'integrationTypeName',
        'page',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'commission',
        'processedAt',
        'trueConversion',
        'deviceType',
      ]
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else if (
      filters.vertical === '4' ||
      filters.vertical === '6' ||
      filters.vertical === '9' ||
      filters.vertical === '12'
    ) {
      // Loans || Deposits || Insurance || Home Equity
      const availableColumns = [
        'clickedAt',
        'externalClickedId',
        'verticalName',
        'typeName',
        'externalClickedProductId',
        'clickedProductName',
        'externalDisplayedProductId',
        'displayedProductName',
        'campaignName',
        'integrationTypeName',
        'page',
        'subTracking',
        'subTracking2',
        'subTracking3',
        'subTracking4',
        'subTracking5',
        'commission',
        'processedAt',
        'trueConversion',
        'deviceType',
      ]
      // Loans
      if (filters.vertical === '4') {
        availableColumns.push('creditBucket')
        availableColumns.push('loanAmount')
        availableColumns.push('creditScoreExact')
        availableColumns.push('deviceType')
      }
      setTableColumns(
        allColumns.filter(c => availableColumns.includes(c.field))
      )
    } else {
      setTableColumns(allColumns)
    }
  }

  return (
    <>
      <PageHeading title="Event Details" description={pageDescription}>
        {propertyHasData && (
          <TableFilters
            tableContext={TransactionsTableContext}
            showEvent={true}
            showClickedProducts={true}
            setPropertyHasData={setPropertyHasData}
            showIntegrationTypes={true}
            showDeviceType={true}
          />
        )}
      </PageHeading>
      {propertyHasData && (
        <>
          <ReportActions
            fetchReport={fetchData}
            fetchExport={fetchExport}
            disabled={
              !get(
                data,
                `transactionDetailsReporting.pagination.totalEntries`,
                0
              )
            }
            reportStatus={{ loading: loading, hasError: error }}
            exportStatus={{ loading: exportLoading, hasError: exportError }}
            reportCallback={reportCallback}
          />

          <Table<TransactionDetail[]>
            cols={tableColumns}
            data={data && get(data, `transactionDetailsReporting.page`)}
            onSortChange={changeSort}
            sort={sort}
            defaultHiddenColumns={defaultHiddenColumns}
            pagination={{
              onPageSizeUpdate: changePageSize,
              onPaginate: changePage,
              ...pagination,
            }}
            isLoading={loading}
          />
        </>
      )}
    </>
  )
}

export default TransactionsTable
