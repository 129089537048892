import React, { FunctionComponent, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { CSSTransition } from 'react-transition-group'

import footerTransitions from './transitions.module.css'
import styles from './styles.module.css'

interface CopyrightFooterProps {
  delay?: number
}

const CopyrightFooter: FunctionComponent<CopyrightFooterProps> = ({
  delay,
}) => {
  const [showFooter, setShowFooter] = useState(false)

  useEffect(() => {
    const loadDelay = setTimeout(() => {
      setShowFooter(true)
    }, delay)

    return () => {
      clearTimeout(loadDelay)
    }
  }, [delay])

  return (
    <CSSTransition
      in={showFooter}
      timeout={600}
      classNames={footerTransitions}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.copyright}>
        {`Copyright © ${DateTime.local().year} Red Ventures Media Network | All
      rights reserved.`}
      </div>
    </CSSTransition>
  )
}

export default CopyrightFooter
