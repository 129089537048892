import _isNil from 'lodash/isNil'
import _has from 'lodash/has'
import _get from 'lodash/get'

// This helper function will only return a param if it exists in the
// browser's window object! See `/server/publicConfig.js`
const getConfig = (paramName, defaultParam = '') => {
  if (_isNil(paramName) || paramName === false) {
    return false
  }

  if (typeof window !== 'undefined' && _has(window, 'rvmn.app')) {
    const { app: config } = window.rvmn

    if (_has(config, paramName)) {
      return _get(config, paramName)
    }
  }
  console.log(defaultParam)
  return defaultParam
}

export default getConfig
