import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { parse, parseUrl } from 'query-string'
import _pick from 'lodash/pick'
import _keys from 'lodash/keys'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import { AppContext } from '../../../../contexts'

import styles from '../styles.module.css'
import Svg from '../../../Svg'
import { Icons } from '../../../Svg/icons'

export interface MenuItem {
  name: string
  icon: Icons
  className?: string
  path: string
  minimumRequiredRole?: string
}

interface ItemProps extends MenuItem, RouteComponentProps {}

const Item: FunctionComponent<ItemProps> = ({
  name,
  icon,
  className,
  path,
  location,
  minimumRequiredRole,
}) => {
  const { isAuthorized } = useContext(AppContext)

  const [isSelected, setIsSelected] = useState()

  useEffect(() => {
    const { url, query } = parseUrl(path)
    const expectedParams = _pick(parse(location.search), _keys(query))

    const checkPath = new RegExp(`${url}.*`)
    const isMatchingPath =
      path === '/app'
        ? path === location.pathname
        : checkPath.test(location.pathname)

    setIsSelected(
      !_isEmpty(query)
        ? isMatchingPath && _isEqual(query, expectedParams)
        : isMatchingPath
    )
  }, [location.pathname, location.search, path])

  return (
    (_isEmpty(minimumRequiredRole) ||
      (!_isEmpty(minimumRequiredRole) &&
        isAuthorized(minimumRequiredRole))) && (
      <li className={classnames(styles.item, className)} data-rv="menu-item">
        <Link
          to={path}
          className={classnames({
            [`${styles.selected}`]: isSelected,
          })}
        >
          <Svg name={icon} className={styles.icon} />
          <span className={styles.name} data-rv="menu-item-name">
            {name}
          </span>
        </Link>
      </li>
    )
  )
}

export default withRouter(Item)
