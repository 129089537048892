import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { IconsProps } from './iconsProps'

const Support: FunctionComponent<IconsProps> = ({ className }) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    className={classnames(className)}
  >
    <title>support</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="LeftRail/overview"
        transform="translate(-29.000000, -557.000000)"
        fill="#FFFFFF"
      >
        <g id="LeftRail">
          <g id="Tabs" transform="translate(0.000000, 90.000000)">
            <g id="SUPPORT" transform="translate(0.000000, 450.000000)">
              <g id="Support" transform="translate(29.000000, 17.000000)">
                <path
                  d="M12.9196777,22.0024414 C11.9606934,22.0024414 11.1804199,22.7824707 11.1804199,23.7414551 C11.1804199,24.7004394 11.9606934,25.4804688 12.9196777,25.4804688 L19.1176758,25.4804688 C20.0766601,25.4804688 20.8566894,24.7004394 20.8566894,23.7414551 C20.8566894,22.7824707 20.0766601,22.0024414 19.1176758,22.0024414 L17.7578125,22.0024414 L17.7578125,14.0966797 C17.7578125,13.1376953 16.9775391,12.3574219 16.0185547,12.3574219 L12.9196778,12.3574219 C11.9606934,12.3574219 11.1804199,13.1376953 11.1804199,14.0966797 C11.1804199,15.0554199 11.9606934,15.8356934 12.9196778,15.8356934 L14.279541,15.8356934 L14.279541,22.0024414 L12.9196777,22.0024414 Z M14.7490234,14.8969726 L12.9196777,14.8969726 C12.4782715,14.8969726 12.1191406,14.5378418 12.1191406,14.0966797 C12.1191406,13.6552734 12.4782715,13.2961426 12.9196777,13.2961426 L16.0187988,13.2961426 C16.4599609,13.2961426 16.8190918,13.6552734 16.8190918,14.0966797 L16.8190918,22.4716797 C16.8190918,22.730957 17.0292969,22.9411621 17.2885743,22.9411621 L19.1176758,22.9411621 C19.559082,22.9411621 19.9182129,23.3000488 19.9182129,23.7414551 C19.9182129,24.1828613 19.559082,24.5419922 19.1176758,24.5419922 L12.9196778,24.5419922 C12.4782715,24.5419922 12.1191406,24.1828613 12.1191406,23.7414551 C12.1191406,23.3000488 12.4782715,22.9411621 12.9196778,22.9411621 L14.7490234,22.9411621 C15.0083008,22.9411621 15.2182618,22.730957 15.2182618,22.4716797 L15.2182618,15.3664551 C15.2182618,15.1071777 15.0080566,14.8969726 14.7490234,14.8969726 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M13.4394531,9.06127931 C13.4394531,10.4836426 14.5964356,11.640625 16.0185547,11.640625 C17.440918,11.640625 18.5979004,10.4836426 18.5979004,9.06127931 C18.5979004,7.63916012 17.440918,6.48217775 16.0185547,6.48217775 C14.5964356,6.48217775 13.4394531,7.63916012 13.4394531,9.06127931 Z M16.0185547,7.42089844 C16.9230957,7.42089844 17.6591797,8.15673825 17.6591797,9.06127931 C17.6591797,9.96582031 16.9230957,10.7019043 16.0185547,10.7019043 C15.1140137,10.7019043 14.3781738,9.96582031 14.3781738,9.06127931 C14.3781738,8.15673825 15.1140137,7.42089844 16.0185547,7.42089844 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M17.0812988,0.937744125 C24.6047363,1.46118162 30.5395507,7.40722656 31.0625,14.9189453 C31.0795899,15.1662598 31.2856445,15.3554688 31.5300293,15.3554688 C31.5410156,15.3554688 31.5522461,15.3552246 31.5632324,15.3544922 C31.8217774,15.3364258 32.0168457,15.1120606 31.9987793,14.8535156 C31.4426269,6.86254881 25.1245118,0.5563965 17.1462403,0.0012207 C16.8879394,-0.0168456875 16.6635743,0.178222625 16.6455078,0.436767562 C16.6274414,0.6953125 16.8225098,0.91967775 17.0812988,0.937744125 Z"
                  id="Path"
                />
                <path
                  d="M2.3532715,24.8540039 L0.640869125,30.4504394 C0.470214875,31.0073243 0.992431625,31.5297851 1.54931638,31.359375 L7.14599613,29.6467285 C7.347168,29.5852051 7.56811525,29.6135254 7.75292969,29.7241211 C10.2387695,31.2148438 13.1237793,32.0002441 16.0356445,32 C24.4992676,31.9995117 31.4162598,25.4863281 31.9990234,17.1091309 C32.0168457,16.8505859 31.8217774,16.6264649 31.5632324,16.6083984 C31.3044434,16.590332 31.0805664,16.7856445 31.0625,17.0441894 C30.2839356,28.2368164 17.9467774,34.7424316 8.23559569,28.9191894 C7.82324219,28.671875 7.32592775,28.6101074 6.87133787,28.7490234 L1.654541,30.345459 L3.25097656,25.1289062 C3.39013675,24.6740722 3.328125,24.1770019 3.08081056,23.7646484 C-2.73730469,14.0612793 3.751709,1.717041 14.9560547,0.9375 C15.2145996,0.91967775 15.409668,0.6953125 15.3916016,0.436767563 C15.3737793,0.178222625 15.1486816,-0.0163574375 14.8908691,0.0012207 C7.17578125,0.537841813 0.98999025,6.45166013 0.105712875,14.1206055 C-0.300048813,17.6401367 0.470703125,21.2365723 2.27587887,24.2473144 C2.38671875,24.4318848 2.41479494,24.6530762 2.3532715,24.8540039 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Support
