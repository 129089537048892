import React, { FunctionComponent } from 'react'

import { PageHeading, Tabs, Markdown, SearchInput } from '../../'
import { Tab } from '../../Tabs'
import Contact from './Contact'

import { SupportType, TermsStore } from '../../../typings/support'
import { ContactStore } from '../../../typings/contact'
import markdown from './faq.md'

import styles from './styles.module.css'

interface SupportProps {
  basePath: string
  supportType: SupportType
  content?: TermsStore
  contact?: ContactStore
  sendSubmission(): void
}

const supportTabs = (path: string, type: SupportType): Tab[] => [
  {
    title: 'FAQ',
    isSelected: type === null || type == 'faq',
    to: `${path}/faq`,
  },
  {
    title: 'Contact Us',
    isSelected: type === 'contact',
    to: `${path}/contact`,
  },
]

const Support: FunctionComponent<SupportProps> = ({
  basePath,
  supportType,
  content,
  contact,
  sendSubmission,
}) => (
  <>
    <PageHeading title="Support" className={styles.heading}>
      <Tabs items={supportTabs(basePath, supportType)} />

      <div className={styles.searchContainer}>
        <SearchInput onChange={() => null} />
      </div>
    </PageHeading>
    {supportType === 'faq' && (
      <Markdown data={markdown} className={styles.markdown} />
    )}
    {supportType === 'contact' && (
      <Contact contact={contact} sendSubmission={sendSubmission} />
    )}
  </>
)

export default Support
