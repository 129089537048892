import React, { FunctionComponent, useEffect, useState } from 'react'
import localforage from 'localforage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './styles.module.css'
import { useToggle } from '../../utils/customHooks'
import { isEmpty } from 'lodash'

export interface Props {
  tableHash: string
  cols: any
  defaultHiddenCols?: string[]
  onSetHiddenCols: (cols: string[]) => void
}

const TableColFilter: FunctionComponent<Props> = ({
  tableHash,
  cols,
  defaultHiddenCols,
  onSetHiddenCols,
}) => {
  const localStorageKey = `table.${tableHash}.settings.hiddenCols`
  const [hiddenCols, setHiddenCols] = useState<string[]>([])
  const [showCols, toggleShowCols] = useToggle(false)

  useEffect(() => {
    getStorageHiddenFields().then(hiddenColsRet => {
      // If both arrays are the same, we can stop here
      if (
        hiddenColsRet.every(c => hiddenCols.indexOf(c) !== -1) &&
        hiddenCols.every(c => hiddenColsRet.indexOf(c) !== -1)
      ) {
        return
      }
      setHiddenCols(hiddenColsRet)
      localforage.setItem(localStorageKey, hiddenColsRet)
      onSetHiddenCols(hiddenColsRet)
    })
  }, [hiddenCols])

  const getStorageHiddenFields = async () => {
    const existing = await localforage.getItem<string[]>(localStorageKey)
    let mergedHidden = []
    if (existing) {
      mergedHidden = [...new Set(existing)]
    } else {
      mergedHidden = [...new Set(hiddenCols)]
    }
    return mergedHidden || []
  }

  useEffect(() => {
    getStorageHiddenFields().then(hiddenColsRet => {
      if (!hiddenColsRet || hiddenColsRet.length === 0) {
        if (!isEmpty(defaultHiddenCols)) {
          localforage.setItem(localStorageKey, defaultHiddenCols).then(r => {
            onSetHiddenCols(r)
            setHiddenCols(defaultHiddenCols)
          })
        }
      }
    })
  }, [])

  const setFieldFilter = async ({ target: { value, checked } }) => {
    const existing =
      (await localforage.getItem<string[]>(localStorageKey)) || []
    let tempHiddenCols = [...new Set([...hiddenCols, ...existing])]
    if (!checked) {
      tempHiddenCols.push(value)
    } else {
      tempHiddenCols = hiddenCols.filter(v => v !== value)
    }
    tempHiddenCols = [...new Set(tempHiddenCols)]
    setHiddenCols(tempHiddenCols)
    await localforage.setItem(localStorageKey, tempHiddenCols)
    onSetHiddenCols(tempHiddenCols)
  }

  return (
    <div className={styles.hiddenCols}>
      {hiddenCols.length > 0 && (
        <div className={styles.hiddenColCount}>
          Hiding {hiddenCols.length} column{hiddenCols.length > 1 && 's'}
        </div>
      )}
      <FontAwesomeIcon
        icon={['fas', 'cog']}
        className={styles.cog}
        onClick={toggleShowCols}
      />
      {showCols && (
        <div className={styles.colList}>
          <span>Show Columns</span>
          {cols.map(c => {
            if (!c.field || !c.text) return null
            return (
              <div key={c.field}>
                <input
                  type="checkbox"
                  onChange={setFieldFilter}
                  value={c.field}
                  checked={!hiddenCols.includes(c.field)}
                  id={c.field}
                />
                <label htmlFor={c.field}>{c.text}</label>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TableColFilter
