import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import history from '../../browserHistory'

import styles from './styles.module.css'

export interface PaginationProps {
  className?: string
  total: number
  pageSize: number
  currentPage?: number
  path: string
}

const changePage = (path, page) => {
  const basePath = path.replace(/\/$/, '')

  history.push(`${basePath}/${page}`)
}

/**
 * Calculate the page list to show
 */
const buildPageList = (total, pageSize, currentPage) => {
  // hardcode this to allievate even number issues
  const showPages = 5
  // The lastPage is either 1 or the total pages divided by the page size
  const lastPage = pageSize >= total ? 1 : Math.ceil(total / pageSize)

  // Pre-calculate the half way mark of the number of pages to show
  const halfPages = Math.floor(showPages / 2)
  const pageList = []

  let start = 1
  let stop = lastPage

  if (currentPage - halfPages < 2 || lastPage <= showPages) {
    stop = lastPage <= showPages ? lastPage : showPages
  } else if (lastPage >= showPages) {
    pageList.push('...')

    // start the list at either from the end down to the number of pages shown
    // or half way between the show pages
    start =
      currentPage + showPages > lastPage
        ? lastPage - showPages
        : currentPage - halfPages

    // stop at either half way the pages up or the last page
    stop =
      currentPage + halfPages < lastPage - 2
        ? currentPage + halfPages
        : lastPage
  }

  // Add all pages to the list
  for (let i = start; i <= stop; i++) {
    pageList.push(i)
  }

  if (stop !== lastPage) {
    pageList.push('...')
    pageList.push(lastPage)
  }

  return pageList
}

const Pagination: FunctionComponent<PaginationProps> = ({
  className,
  total,
  pageSize,
  currentPage,
  path,
}) => {
  const lastPage = pageSize >= total ? 1 : Math.ceil(total / pageSize)
  const pageList = buildPageList(total, pageSize, currentPage)

  return (
    <div
      className={classnames(styles.container, { [styles.disabled]: total < 1 })}
    >
      <ul className={className}>
        <li>
          <button
            onClick={() => changePage(path, 1)}
            disabled={currentPage <= 2}
          >
            <FontAwesomeIcon icon="chevron-left" />
            <FontAwesomeIcon icon="chevron-left" />
          </button>
        </li>
        <li>
          <button
            disabled={currentPage === 1}
            onClick={() =>
              changePage(path, currentPage > 1 ? currentPage - 1 : 1)
            }
          >
            <FontAwesomeIcon icon="chevron-left" />
          </button>
        </li>
        {pageList.map(num => (
          <li key={uniqueId('page-list-')}>
            {typeof num === 'number' && (
              <button
                onClick={() => changePage(path, num)}
                className={classnames({
                  [styles.selected]: currentPage === num,
                })}
                disabled={currentPage === num}
              >
                {num}
              </button>
            )}
            {typeof num === 'string' && <span>{num}</span>}
          </li>
        ))}
        <li>
          <button
            disabled={currentPage === lastPage}
            onClick={() => changePage(path, currentPage + 1)}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </li>
        <li>
          <button
            disabled={currentPage >= lastPage - 1}
            onClick={() => changePage(path, lastPage)}
          >
            <FontAwesomeIcon icon="chevron-right" />
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </li>
      </ul>
    </div>
  )
}

Pagination.defaultProps = {
  currentPage: 1,
}

export default Pagination
