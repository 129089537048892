import Prism from 'prismjs'
import prismTheme from 'prismjs/themes/prism.css'

let styleNode

if (process.browser) {
  styleNode = document.createElement('style')
  styleNode.setAttribute('data-prism', true)
  if (document.head) {
    document.head.appendChild(styleNode)
  }
}

const setPrismTheme = theme => {
  styleNode.textContext = theme
}

export { Prism, prismTheme, setPrismTheme }
