import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { PageDescription } from '../../../../components'
import Table, { Col, TableFooter } from '../../../../components/Table/next'
import ReportActions from '../../../../components/ReportActions'
import ReportMenu from '../../Menu'
import OverviewReportFilterDisplay from '../Filters'

import { prepareDatesForApi } from '../../../../utils/dates'

import {
  OverviewReportGroupBy,
  OverviewReportTableContext,
  OverviewReportVariables,
} from './index'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

import styles from './styles.module.css'
import accounting from 'accounting'
import { dumpData, filterChange } from '../../../../actions/reports'
import { rebuild } from 'react-tooltip'
import { ReportsStore } from '../../../../typings/stores/ReportsStore'
interface OverviewReportTableProps {
  groupBy: OverviewReportGroupBy
  cols: Col[]
  footerPadding: number
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables: OverviewReportVariables }) => void
  exportData: any
  exportLoading: boolean
  exportError: boolean
  getExportData: ({ variables }) => void
  reportCallback: (param: string) => void
}

const OverviewReportTable: FunctionComponent<OverviewReportTableProps> = ({
  groupBy,
  cols,
  footerPadding,
  getData,
  data,
  loading,
  error,
  getExportData,
  exportData,
  exportLoading,
  exportError,
  reportCallback,
}) => {
  const dispatch = useDispatch()

  const user = useSelector<State, UserStore>(state => state.user)
  const reportStore = useSelector<State, ReportsStore>(state => state.reports)
  const {
    changePage,
    changePageSize,
    filters,
    pagination: { size, page },
    setFilter,
    setFilters,
    sort,
    changeSort,
  } = useContext(OverviewReportTableContext)

  /*
  if there is filter in the store, use it
  */
  useEffect(() => {
    if (
      reportStore.filters?.startDate &&
      reportStore.filters?.endDate &&
      reportStore.filters?.dateRange
    ) {
      filters.start = reportStore.filters.startDate
      filters.end = reportStore.filters.endDate
      filters.dateRange = reportStore.filters.dateRange
    }
  }, [])

  //Update filter store
  useEffect(() => {
    dispatch(
      filterChange({
        ...filters,
        dateRange: filters.dateRange,
        startDate: filters.start,
        endDate: filters.end,
      })
    )
  }, [filters.start, filters.end, filters.dateRange])

  const fetchExport = () => {
    getExportData({
      variables: {
        ...updateVariables(),
        take: get(data, `overviewReporting.pagination.totalEntries`, 0),
        includeTotals: false,
      },
    })
  }

  const updateVariables = () => {
    const { start, end, includeImpressions } = filters
    return {
      ...prepareDatesForApi(start, end, true),
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by
        ? [
            {
              [sort.by]: sort.dir.toUpperCase(),
            },
          ]
        : [
            {
              verticalName: 'ASC',
            },
          ],
      externalPropertyId: user.activeWebsite.id.toString(),
      externalPublisherId: user.affiliateId,
      includeImpressions: includeImpressions === 'true',
      includeTotals: true,
    } as OverviewReportVariables
  }
  const fetchData = useCallback(() => {
    getData({ variables: updateVariables() })
  }, [filters, size, page, sort, user.activeWebsite, user.affiliateId])

  useEffect(() => {
    dumpData('overviewReport', exportData?.overviewReporting?.page, {
      ...filters,
    })
  }, [exportData])

  useEffect(fetchData, [sort, page, size, user.activeWebsite])

  // Rebind tooltips
  useEffect(() => {
    rebuild()
  })

  const pagination = {
    currentPage: get(data, `overviewReporting.pagination.currentPage`, 1),
    pageSize: get(data, `overviewReporting.pagination.pageSize`, 10),
    total: get(data, `overviewReporting.pagination.totalEntries`, 0),
  }

  const totalsData = {
    totalProductClicks: get(
      data,
      'overviewReporting.totals.totalProductClicks',
      0
    ),
    totalUniqueClicks: get(
      data,
      'overviewReporting.totals.totalUniqueClicks',
      0
    ),
    totalSales: get(data, 'overviewReporting.totals.totalSales', 0),
    totalCommission: get(data, 'overviewReporting.totals.totalCommission', 0),
    totalConversionRate: get(
      data,
      'overviewReporting.totals.totalConversionRate',
      0
    ),
    totalEpc: get(data, 'overviewReporting.totals.totalEpc', 0),
    totalImpressions: get(data, 'overviewReporting.totals.totalImpressions', 0),
    viewableImpressions: get(
      data,
      'overviewReporting.totals.viewableImpressions',
      0
    ),
  }

  const hiddenCols = [
    'totalImpressions',
    'viewableImpressions',
    'totalCtr',
    'viewableCtr',
  ]

  if (filters.includeImpressions !== 'true') {
    cols = cols.filter(col => !hiddenCols.includes(col.field.toString()))
  }

  const totalRowBuilder: TableFooter[] = [
    {
      text: 'Totals',
      colSpan: footerPadding - 4,
      className: styles.footerHeader,
    },
    filters.includeImpressions === 'true' && {
      text: accounting.formatNumber(totalsData.totalImpressions),
      className: styles.right,
    },
    filters.includeImpressions === 'true' && {
      text: accounting.formatNumber(totalsData.viewableImpressions),
      className: styles.right,
    },
    {
      text: totalsData
        ? accounting.formatNumber(totalsData.totalProductClicks)
        : 0,
      className: styles.right,
    },
    {
      text: totalsData
        ? accounting.formatNumber(totalsData.totalUniqueClicks)
        : 0,
      className: styles.right,
    },
    filters.includeImpressions === 'true' && {
      text: '-', // totalCTR
      className: styles.right,
    },
    filters.includeImpressions === 'true' && {
      text: '-', // totalViewableCTR
      className: styles.right,
    },
    {
      text: totalsData ? accounting.formatNumber(totalsData.totalSales) : 0,
      className: styles.right,
    },
    {
      text: totalsData
        ? (totalsData.totalConversionRate * 100).toFixed(2) + '%'
        : 0,
      className: styles.right,
    },
    {
      text:
        totalsData && accounting.formatMoney(totalsData.totalCommission, '$'),
      className: styles.right,
    },
    {
      text: totalsData && accounting.formatMoney(totalsData.totalEpc, '$'),
      className: styles.right,
    },
  ].filter(el => !!el)

  return (
    <>
      <ReportMenu />

      <PageDescription content="This is an aggregated report that provides sales funnel data based on click date." />

      <OverviewReportFilterDisplay
        filters={filters}
        setFilter={setFilter}
        setFilters={setFilters}
      />

      <ReportActions
        fetchReport={fetchData}
        fetchExport={fetchExport}
        disabled={!get(data, `overviewReporting.pagination.totalEntries`, 0)}
        reportStatus={{ loading: loading, hasError: error }}
        exportStatus={{ loading: exportLoading, hasError: exportError }}
        totalsStatus={{ loading: loading, hasError: error }}
        reportCallback={reportCallback}
      />

      <Table
        className={styles.table}
        data={data && get(data, `overviewReporting.page`)}
        cols={cols}
        totalRow={totalRowBuilder}
        sort={sort}
        onSortChange={changeSort}
        pagination={{
          onPageSizeUpdate: changePageSize,
          onPaginate: changePage,
          ...pagination,
        }}
        isLoading={loading}
      />
    </>
  )
}

export default OverviewReportTable
