import React, { FunctionComponent, useMemo } from 'react'
import classnames from 'classnames'
import { uniqueId } from 'lodash'
import { Select } from '..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectOption } from '../Select'
import styles from './v2.module.css'

export interface PaginationData {
  currentPage: number
  pageSize: number
  total: number
  onPaginate: (page: number) => void
  onPageSizeUpdate: (size: number) => void
}

const pageSelectOptions: SelectOption[] = [
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '25', text: '25' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
]

export interface PaginationProps extends PaginationData {
  className?: string
}

const Pagination: FunctionComponent<PaginationProps> = ({
  className,
  total,
  pageSize,
  currentPage,
  onPaginate,
  onPageSizeUpdate,
}) => {
  const lastPage = useMemo(
    () => (pageSize >= total ? 1 : Math.ceil(total / pageSize)),
    [total, pageSize]
  )

  const pageList = useMemo(() => {
    // hard code this to alleviate even number issues
    const showPages = 5

    // pre-calculate the halfway mark
    const halfway = Math.floor(showPages / 2)
    const pageArray = []

    let start = 1
    let stop = lastPage

    if (currentPage - halfway < 2 || lastPage <= showPages) {
      stop = lastPage <= showPages ? lastPage : showPages
    } else if (lastPage >= showPages) {
      pageArray.push('...')

      start =
        currentPage + showPages > lastPage
          ? lastPage - showPages
          : currentPage - halfway

      stop =
        currentPage + halfway < lastPage - 2 ? currentPage + halfway : lastPage
    }

    for (let i = start; i <= stop; i++) {
      pageArray.push(i)
    }

    if (stop !== lastPage) {
      pageArray.push('...')
      pageArray.push(lastPage)
    }

    return pageArray
  }, [currentPage, lastPage])

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.paginator, {
          [styles.disabled]: total < 1,
        })}
      >
        <ul className={className}>
          <li>
            <button onClick={() => onPaginate(1)} disabled={currentPage <= 2}>
              <FontAwesomeIcon icon="angle-double-left" />
            </button>
          </li>
          <li>
            <button
              onClick={() => onPaginate(currentPage > 1 ? currentPage - 1 : 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon="angle-left" />
            </button>
          </li>
          {pageList.map(num => (
            <li key={uniqueId(`page-item-${num}-`)}>
              {typeof num === 'number' && (
                <button
                  className={classnames({
                    [styles.selected]: currentPage === num,
                  })}
                  onClick={() => onPaginate(num)}
                  disabled={currentPage === num}
                >
                  {num}
                </button>
              )}
              {typeof num === 'string' && <span>{num}</span>}
            </li>
          ))}
          <li>
            <button
              onClick={() => onPaginate(currentPage + 1)}
              disabled={currentPage === lastPage}
            >
              <FontAwesomeIcon icon="angle-right" />
            </button>
          </li>
          <li>
            <button
              onClick={() => onPaginate(lastPage)}
              disabled={currentPage >= lastPage - 1}
            >
              <FontAwesomeIcon icon="angle-double-right" />
            </button>
          </li>
        </ul>
      </div>
      <div className={styles.pageSize}>
        <div className={styles.show}>Show:</div>
        <Select
          className={styles.select}
          selected={pageSize}
          options={pageSelectOptions}
          onChange={e => {
            const newSize = parseInt(e.currentTarget.value, 10)

            onPageSizeUpdate(newSize)
          }}
        />
      </div>
    </div>
  )
}

export default Pagination
