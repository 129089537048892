import { Reducer } from 'redux'

import {
  DetailReportAction,
  DetailReportConstants,
} from '../actions/detailReport'
import { DetailReportStore } from '../typings/stores/DetailReportStore'

const initialState: DetailReportStore = {
  data: {
    transactions: [],
    pagination: null,
  },
  export: {
    hasData: false,
    status: null,
    error: null,
  },
  reportType: null,
  filters: null,
  hasData: false,
  status: null,
  error: null,
}

export const detailReportReducer: Reducer = (
  state = { ...initialState },
  action: DetailReportAction
): DetailReportStore => {
  switch (action.type) {
    case DetailReportConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        export: initialState.export,
        reportType: initialState.reportType,
        filters: initialState.filters,
        hasData: false,
        status: 'fetching',
        error: null,
      }
    case DetailReportConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        reportType: action.reportType,
        filters: action.filters,
        hasData: action.data[action.reportType].length > 0,
        status: 'fetched',
        error: null,
      }
    case DetailReportConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: null,
        error: action.error,
      }
    case DetailReportConstants.EXPORT_FETCH:
      return {
        ...state,
        export: {
          ...initialState.export,
          status: 'fetching',
        },
      }
    case DetailReportConstants.EXPORT_FETCH_SUCCESS:
      return {
        ...state,
        reportType: action.reportType,
        export: {
          hasData: true,
          filters: action.filters,
          status: 'fetched',
          error: null,
        },
      }
    case DetailReportConstants.EXPORT_FETCH_FAILURE:
      return {
        ...state,
        export: {
          hasData: false,
          status: null,
          error: action.error,
        },
      }
    default:
      return state
  }
}
