import React, { FunctionComponent, useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'

import { AppContext } from '../../../contexts'

interface RestrictedRouteProps extends RouteProps {
  minimumRequiredRole?: string
}

const RestrictedRoute: FunctionComponent<RestrictedRouteProps> = ({
  minimumRequiredRole,
  component: Component,
  render: Render,
  ...rest
}) => {
  const { activeUser, isAuthorized } = useContext(AppContext)

  return (
    <Route
      {...rest}
      render={props => {
        if (activeUser.active !== false) {
          if (
            _isEmpty(minimumRequiredRole) ||
            (!_isEmpty(minimumRequiredRole) &&
              isAuthorized(minimumRequiredRole))
          ) {
            if (typeof Component !== 'undefined') {
              return <Component {...props} />
            } else if (typeof Render(props) !== 'undefined') {
              return Render(props)
            }
          }
        }

        return <Redirect to="/app/unauthorized" />
      }}
    />
  )
}

export default RestrictedRoute
