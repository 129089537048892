import { Reducer } from 'redux'

import { RecentSalesStore } from '../typings/stores/RecentSalesStore'
import { RecentSalesAction, RecentSalesConstants } from '../actions/recentSales'

const initialState: RecentSalesStore = {
  data: [],
  hasData: false,
  status: null,
  error: null,
}

export const recentSalesReducer: Reducer = (
  state = { ...initialState },
  action: RecentSalesAction
): RecentSalesStore => {
  switch (action.type) {
    case RecentSalesConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: 'fetching',
        error: null,
      }
    case RecentSalesConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data.recentSales,
        hasData: action.data.recentSales.length > 0,
        status: 'fetched',
        error: null,
      }
    case RecentSalesConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: null,
        error: action.error,
      }
    default:
      return state
  }
}
