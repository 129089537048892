import { Reducer } from 'redux'

import {
  MyFiReportsLookerAction,
  MyFiReportsLookerConstants,
} from '../actions/myfiReportsLooker'
import { MyFiReportsLookerStore } from '../typings/stores/MyFiReportsLookerStore'

const initialState: MyFiReportsLookerStore = {
  data: {
    url: '',
  },
  fetching: false,
  hasData: false,
  error: null,
}

export const myfiReportsLookerReducer: Reducer = (
  state = { ...initialState },
  action: MyFiReportsLookerAction
): MyFiReportsLookerStore => {
  switch (action.type) {
    case MyFiReportsLookerConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        fetching: true,
        hasData: false,
        error: null,
      }
    case MyFiReportsLookerConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        hasData: true,
        error: null,
      }
    case MyFiReportsLookerConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        fetching: false,
        hasData: false,
        error: action.error,
      }
    default:
      return state
  }
}
