import { Dispatch } from 'redux'

import { triggerError } from './app'
import {
  fetchGetApi,
  fetchPostApi,
  fetchDeleteApi,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { PublisherStore } from '../typings/publisher'

export enum PublisherConstants {
  FETCH = 'Publisher/FETCH',
  FETCH_SUCCESS = 'Publisher/FETCH_SUCCESS',
  FETCH_FAILURE = 'Publisher/FETCH_FAILURE',
  ENABLE = 'Publisher/ENABLE',
  ENABLE_SUCCESS = 'Publisher/ENABLE_SUCCESS',
  ENABLE_FAILURE = 'Publisher/ENABLE_FAILURE',
  DISABLE = 'Publisher/DISABLE',
  DISABLE_SUCCESS = 'Publisher/DISABLE_SUCCESS',
  DISABLE_FAILURE = 'Publisher/DISABLE_FAILURE',
  ROTATE = 'Publisher/ROTATE',
  ROTATE_SUCCESS = 'Publisher/ROTATE_SUCCESS',
  ROTATE_FAILURE = 'Publisher/ROTATE_FAILURE',
}

export interface PublisherAction extends Action {
  type: PublisherConstants
  data?: PublisherStore
  error?: string
}

export function fetchPublisher(): PublisherAction {
  return {
    type: PublisherConstants.FETCH,
  }
}

export function fetchPublisherSuccess(data: PublisherStore): PublisherAction {
  return {
    type: PublisherConstants.FETCH_SUCCESS,
    data,
  }
}

export function fetchPublisherFailure(error: string): PublisherAction {
  return {
    type: PublisherConstants.FETCH_FAILURE,
    error,
  }
}

export function fetchEnableReporting(): PublisherAction {
  return {
    type: PublisherConstants.ENABLE,
  }
}

export function fetchEnableReportingSuccess(
  data: PublisherStore
): PublisherAction {
  return {
    type: PublisherConstants.ENABLE_SUCCESS,
    data,
  }
}

export function fetchEnableReportingFailure(error: string): PublisherAction {
  return {
    type: PublisherConstants.ENABLE_FAILURE,
    error,
  }
}

export function fetchDisableReporting(): PublisherAction {
  return {
    type: PublisherConstants.DISABLE,
  }
}

export function fetchDisableReportingSuccess(
  data: PublisherStore
): PublisherAction {
  return {
    type: PublisherConstants.DISABLE_SUCCESS,
    data,
  }
}

export function fetchDisableReportingFailure(error: string): PublisherAction {
  return {
    type: PublisherConstants.DISABLE_FAILURE,
    error,
  }
}

export function fetchRotatePublisherSecret(): PublisherAction {
  return {
    type: PublisherConstants.ROTATE,
  }
}

export function fetchRotatePublisherSecretSuccess(
  data: PublisherStore
): PublisherAction {
  return {
    type: PublisherConstants.ROTATE_SUCCESS,
    data,
  }
}

export function fetchRotatePublisherSecretFailure(
  error: string
): PublisherAction {
  return {
    type: PublisherConstants.ROTATE_FAILURE,
    error,
  }
}

export const getPublisher = (publisherId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(fetchPublisher())

  const endpoint = `/publisher/${publisherId}`

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchPublisherSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'Publisher'))
    dispatch(fetchPublisherFailure(errorParsed))
  }
}

export const enableReporting = (
  publisherId: string,
  resource: string
) => async (dispatch: Dispatch) => {
  dispatch(fetchEnableReporting())

  const endpoint = `/publisher/${publisherId}/oauth/client`
  const body = { resource }

  const { ok, errorParsed, bodyParsed } = await fetchPostApi(
    fetch,
    endpoint,
    body
  )

  if (ok) {
    dispatch(fetchEnableReportingSuccess(bodyParsed))
    getPublisher(publisherId)
  } else {
    dispatch(triggerError(errorParsed, 'Publisher'))
    dispatch(fetchEnableReportingFailure(errorParsed))
  }
}

export const disableReporting = (
  publisherId: string,
  resource: string
) => async (dispatch: Dispatch) => {
  dispatch(fetchDisableReporting())

  const endpoint = `/publisher/${publisherId}/oauth/client/?resource=${resource}`
  const { ok, errorParsed, bodyParsed } = await fetchDeleteApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchDisableReportingSuccess(bodyParsed))
  } else {
    dispatch(triggerError(errorParsed, 'Publisher'))
    dispatch(fetchDisableReportingFailure(errorParsed))
  }
}

export const rotateSecret = (publisherId: string, resource: string) => async (
  dispatch: Dispatch
) => {
  dispatch(fetchRotatePublisherSecret())

  const endpoint = `/publisher/${publisherId}/oauth/client/secret`
  const body = { resource }

  const { ok, errorParsed, bodyParsed } = await fetchPostApi(
    fetch,
    endpoint,
    body
  )

  if (ok) {
    dispatch(fetchRotatePublisherSecretSuccess(bodyParsed))
    getPublisher(publisherId)
  } else {
    dispatch(triggerError(errorParsed, 'Publisher'))
    dispatch(fetchRotatePublisherSecretFailure(errorParsed))
  }
}
