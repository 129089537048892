import { Dispatch } from 'redux'

import { fetchPostApi } from '../utils/fetchWrapper'
import { triggerError } from './app'

import { Action } from '../typings/reducer'
import { Submission } from '../typings/contact'

export enum ContactConstants {
  SEND = 'contact/SEND',
  SEND_SUCCESS = 'contact/SEND_SUCCESS',
  SEND_FAILURE = 'contact/SEND_FAILURE',
}

export interface ContactAction extends Action {
  type: ContactConstants
  data?: Submission
  error?: string
}

function sendMessage(): ContactAction {
  return {
    type: ContactConstants.SEND,
  }
}

function sendMessageSuccess(data: Submission): ContactAction {
  return {
    type: ContactConstants.SEND_SUCCESS,
    data,
  }
}

function sendMessageFailure(error: string): ContactAction {
  return {
    type: ContactConstants.SEND_FAILURE,
    error,
  }
}

export const sendSubmission = (submission: Submission) => async (
  dispatch: Dispatch
) => {
  dispatch(sendMessage())

  const endpoint = '/contact'

  const { ok, bodyParsed, errorParsed } = await fetchPostApi(
    fetch,
    endpoint,
    submission
  )

  if (ok) {
    dispatch(sendMessageSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Contact Us'))
    dispatch(sendMessageFailure(errorParsed))
  }
}
