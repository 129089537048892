import React, {
  FunctionComponent,
  ReactNode,
  createContext,
  useMemo,
  useCallback,
} from 'react'
import _isEmpty from 'lodash/isEmpty'

import { AppUser } from '../../typings/user'

interface AppContextProps {
  activeUser: AppUser
  isAuthorized(minimumRequiredRole?: string): boolean
}

interface AppProviderProps {
  activeUser: AppUser
  children: ReactNode
}

// Order is important: least to most access!
const roleList = [
  'portalintegration',
  'user',
  'contentrunner',
  'manager',
  'admin',
  'disabled',
]

export const AppContext = createContext<AppContextProps>({
  activeUser: null,
  isAuthorized: () => false,
})

const AppProvider: FunctionComponent<AppProviderProps> = ({
  activeUser,
  children,
}) => {
  const isAuthorized = useCallback(
    (minRole?: string) => {
      if (!_isEmpty(minRole)) {
        const userLevel = roleList.indexOf(activeUser.role.name)
        const requiredLevel = roleList.indexOf(minRole)

        return userLevel >= requiredLevel
      }
      return activeUser.role.name === roleList[1]
    },
    [activeUser]
  )

  const value = useMemo(() => ({ activeUser, isAuthorized }), [
    activeUser,
    isAuthorized,
  ])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppProvider
