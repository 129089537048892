import React, { FunctionComponent } from 'react'

import Alerts from './Alerts'
import TableContext, { createTableContext } from '../../contexts/TableContext'

export type AlertSorts = 'subject' | 'timeInserted' | 'timeSent'

export interface AlertFilters {
  subject?: string
  search?: string
}

export const AlertsTableContext = createTableContext<AlertFilters, AlertSorts>()

const AlertsContainer: FunctionComponent = () => {
  return (
    <TableContext<AlertFilters, AlertSorts>
      Context={AlertsTableContext}
      queryParams={['subject', 'search']}
      defaultFilters={{
        subject: '',
      }}
    >
      <Alerts />
    </TableContext>
  )
}

export default AlertsContainer
