import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { find, get } from 'lodash'

import { Modal } from '../../components'
import { State } from '../../reducers'
import { getAlert } from '../../actions/alerts'
import { Alert } from '../../typings/alert'

import styles from './styles.module.css'

interface ViewAlertProps {
  alertId: string
  close(): void
}

const formatHtmlContent = content => {
  const div = document.createElement('div')

  div.innerHTML = content

  const scripts = div.getElementsByTagName('script')

  for (let i = 0; i < scripts.length; i++) {
    scripts[i].parentNode.removeChild(scripts[i])
  }

  return div.innerHTML
}

const ViewAlert: FunctionComponent<ViewAlertProps> = ({ alertId, close }) => {
  const tempAlert = useSelector<State, Alert>(state =>
    find(state.alerts.data, { id: alertId })
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAlert(alertId, tempAlert.contentId))
  }, [alertId])

  const alert = useSelector<State, Alert>(state =>
    find(state.alerts.data, { id: alertId })
  )

  return (
    <Modal isOpen close={close} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>{alert.subject}</h2>
          <h3>{alert.timeSent}</h3>
        </div>
        <div className={styles.content}>
          <div
            className={styles.box}
            dangerouslySetInnerHTML={{
              __html: formatHtmlContent(get(alert, 'content', '')),
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ViewAlert
