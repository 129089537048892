import React, { FunctionComponent } from 'react'

import getConfig from '../../config'

import styles from './styles.module.css'

const MissingWebsiteNotice: FunctionComponent = () => {
  const email = getConfig('supportEmail')

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        <h1>Error</h1>

        <h2>
          It looks like you don't have any websites linked to your account.
        </h2>

        <p>
          Please contact&nbsp;
          <a className={styles.email} href={`mailto:${email}`}>
            {email}
          </a>
        </p>
      </div>
    </div>
  )
}

export default MissingWebsiteNotice
