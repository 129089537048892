export const mapPropertyNames = (value: string) => {
  switch (value) {
    case 'WEB':
      return 'Website'
    case 'APP':
      return 'App'
    case 'MARKETPLACE':
      return 'Marketplace'
    default:
      return 'Website'
  }
}
