import React, { FunctionComponent } from 'react'
import { marked } from 'marked'
import { Prism, prismTheme, setPrismTheme } from './prism'

import classnames from 'classnames'

interface MarkdownProps {
  data: string
  className?: string
}

setPrismTheme(prismTheme)

const markedOptions = {
  breaks: true,
  gfm: true,
  highlight: (code, lang) => {
    let language

    switch (lang) {
      case 'javascript':
        language = Prism.languages.javascript
        break
      case 'html':
      default:
        language = Prism.languages.html
        break
    }

    return Prism.highlight(code, language)
  },
}

const Markdown: FunctionComponent<MarkdownProps> = ({ data, className }) => {
  return (
    <div
      className={classnames(className, 'mdPage')}
      data-rv="static-md-page"
      dangerouslySetInnerHTML={{ __html: marked(data, markedOptions) }}
    />
  )
}

export default Markdown
