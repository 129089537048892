import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface DatePickerProps {
  className?: string
  defaultValue?: string
  min?: string
  max?: string
  onChange(e: string): void
  value?: string
}

const DatePicker: FunctionComponent<DatePickerProps> = ({
  className,
  defaultValue,
  min,
  max,
  onChange,
  value,
}) => (
  <div className={classnames(styles.container, className)}>
    <input
      type="date"
      placeholder="mm/dd/yyyy"
      className={styles.main}
      defaultValue={defaultValue}
      value={value}
      min={min}
      max={max}
      onChange={e => onChange(e.currentTarget.value)}
    />
  </div>
)

export default DatePicker
