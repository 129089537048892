import React, { ReactNode } from 'react'
import { isEmpty, isNumber, get, uniqueId } from 'lodash'
import classnames from 'classnames'

import styles from './styles.module.css'

type Fields<FieldsT> = { [key in keyof FieldsT]: string }

interface Field<FieldsT> {
  className?: string
  text: ReactNode
  field: keyof Fields<FieldsT>
  render?: (totals: FieldsT) => any
}

interface TotalsDisplayBarProps<FieldsT> {
  className?: string
  fields: Field<FieldsT>[]
  totals: FieldsT
}

const TotalsDisplayBar = <FieldsT extends {}>({
  className,
  fields,
  totals,
}: TotalsDisplayBarProps<FieldsT>) => {
  const renderField = (field: Field<FieldsT>, totals: FieldsT): ReactNode => {
    const r = field.render ? field.render(totals) : get(totals, field.field)
    return !isEmpty(r) || isNumber(r) ? r : '---'
  }

  return (
    <>
      {!isEmpty(totals) && (
        <div className={classnames(styles.container, className)}>
          <div className={styles.header}>
            <h3>Totals</h3>
          </div>
          <div className={styles.fieldContainer}>
            {fields.map(field => (
              <div
                className={classnames(styles.field, field.className)}
                key={uniqueId('field-')}
              >
                <h4>{field.text}:</h4>
                {renderField(field, totals)}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default TotalsDisplayBar
