import { useApolloClient } from '@apollo/client'
import { useEffect } from 'react'
import { FRONTEND_TTL } from './featureFlags'

const CacheCleanup = () => {
  const client = useApolloClient()
  useEffect(() => {
    setInterval(() => {
      const fieldNamesToRemove = [
        'clickDetailReporting',
        'overviewReporting',
        'transactionDetailsReporting',
        'verticalSummaryReporting',
      ]

      fieldNamesToRemove.forEach(fieldName => {
        client.cache.evict({ fieldName })
      })

      client.cache.gc()
    }, FRONTEND_TTL * 60 * 1000)
  }, [])

  return null
}

export default CacheCleanup
