import React, { FunctionComponent, useState, useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { CSSTransition } from 'react-transition-group'
import validator from 'validator'

import { Modal, Loading, Select } from '../../..'

import { Property } from '../../../../typings/property'

import fieldAlertTransitions from '../../../../utils/transitions/fieldAlert.module.css'
import styles from './styles.module.css'
import { useSelector } from 'react-redux'
import { PublisherStore } from '../../../../typings/publisher'
import { State } from '../../../../reducers'

interface AddFormProps {
  close(): void
  addProperty(property: Property, publisherName: string): void
}

interface FieldErrors {
  name?: string
  url?: string
  description?: string
}

const AddForm: FunctionComponent<AddFormProps> = ({ close, addProperty }) => {
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const publisher = useSelector<State, PublisherStore>(state => state.publisher)

  const [fields, setFields] = useState<Property>({
    name: '',
    url: '',
    type: 'WEB',
  })
  const [displayErrors, setDisplayErrors] = useState<FieldErrors>({
    name: '',
    url: '',
  })

  // Name field errors
  useEffect(() => {
    if (_isEmpty(displayErrors.name) && _isEmpty(fields.name)) {
      setDisplayErrors(prev => ({
        ...prev,
        name: 'Please enter a name',
      }))
    }
  }, [fields.name, displayErrors.name])

  // URL field errors
  useEffect(() => {
    if (_isEmpty(displayErrors.url)) {
      if (_isEmpty(fields.url)) {
        setDisplayErrors(prev => ({
          ...prev,
          url: 'Please enter a URL',
        }))
      } else if (!validator.isURL(fields.url)) {
        setDisplayErrors(prev => ({
          ...prev,
          url: 'Invalid URL',
        }))
      } else if (!validator.isURL(fields.url, { require_protocol: true })) {
        setDisplayErrors(prev => ({
          ...prev,
          url: 'http:// or https:// is required',
        }))
      }
    }
  }, [fields.url, displayErrors.url])

  // Reset if errors are addressed
  useEffect(() => {
    if (
      _isEmpty(displayErrors.name) &&
      _isEmpty(displayErrors.url) &&
      submitted
    ) {
      setSubmitted(false)
    }
  }, [submitted, displayErrors])

  // Disable submit until form is valid
  useEffect(() => {
    if (
      !submitted &&
      !isSubmitting &&
      _isEmpty(displayErrors.name) &&
      _isEmpty(displayErrors.url)
    ) {
      setEnableSubmit(true)
    } else {
      setEnableSubmit(false)
    }
  }, [displayErrors.name, displayErrors.url, isSubmitting, submitted])

  const handleSubmit = async e => {
    e.preventDefault()

    if (
      !submitted &&
      !isSubmitting &&
      _isEmpty(displayErrors.name) &&
      _isEmpty(displayErrors.url)
    ) {
      setIsSubmitting(true)
      await addProperty(fields, publisher.publisherName)
      await setTimeout(close, 5000)
    }
  }

  return (
    <Modal isOpen close={close} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Add a Property</h2>
        </div>
        <div className={styles.content}>
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            defaultValue={fields.name}
            onChange={e => {
              const name = e.currentTarget.value

              // clear any existing field errors on change
              if (!_isEmpty(displayErrors.name)) {
                setDisplayErrors(prev => ({
                  ...prev,
                  name: '',
                }))
              }

              setFields(prev => ({
                ...prev,
                name,
              }))
            }}
            data-rv="property-edit-name"
          />

          <label htmlFor="url">URL:</label>
          <input
            id="url"
            defaultValue={fields.url}
            onChange={e => {
              const url = e.currentTarget.value

              // clear any existing field errors on change
              if (!_isEmpty(displayErrors.url)) {
                setDisplayErrors(prev => ({
                  ...prev,
                  url: '',
                }))
              }

              setFields(prev => ({
                ...prev,
                url,
              }))
            }}
            data-rv="property-edit-url"
          />

          <label htmlFor="type">Type:</label>
          <Select
            selected={fields.type}
            onChange={e => {
              const type = e.currentTarget.value
              setFields(prev => ({
                ...prev,
                type,
              }))
            }}
            options={[
              { text: 'Website', value: 'WEB' },
              { text: 'App', value: 'APP' },
              { text: 'Marketplace', value: 'MARKETPLACE' },
            ]}
          />
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            defaultValue={fields.description}
            onChange={e => {
              const description = e.currentTarget.value

              setFields(prev => ({
                ...prev,
                description,
              }))
            }}
            data-rv="property-edit-description"
          />
        </div>
        <div className={styles.buttonContainer}>
          {isSubmitting ? (
            <button className={styles.loading} disabled>
              <Loading className={styles.spinner} />
            </button>
          ) : (
            <>
              <CSSTransition
                in={
                  !_isEmpty(displayErrors.name) || !_isEmpty(displayErrors.url)
                }
                timeout={600}
                classNames={fieldAlertTransitions}
                appear
                mountOnEnter
                unmountOnExit
              >
                <div className={styles.error} data-rv="property-edit-error">
                  {displayErrors.name || displayErrors.url}
                </div>
              </CSSTransition>
              <button
                className={styles.button}
                onClick={async e => {
                  setSubmitted(true)
                  await handleSubmit(e)
                }}
                disabled={enableSubmit === false}
                data-rv="property-edit-submit"
              >
                Save Changes
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AddForm
