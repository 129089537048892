import React, { FunctionComponent, useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _uniqueId from 'lodash/uniqueId'
import classnames from 'classnames'

import { PageHeading } from '../..'
import AddForm from './AddForm'

import { AppContext } from '../../../contexts'

import styles from './styles.module.css'

import { PropertiesStore } from '../../../typings/property'
import { mapPropertyNames } from '../../../utils/properties'

interface PropertiesProps extends RouteComponentProps {
  basePath: string
  properties: PropertiesStore
  showAddForm?: boolean
  closeAdd(): void
  addProperty(): void
}

const Properties: FunctionComponent<PropertiesProps> = ({
  history,
  basePath,
  properties,
  showAddForm,
  closeAdd,
  addProperty,
}) => {
  const { isAuthorized } = useContext(AppContext)
  return (
    <>
      <PageHeading title="Properties" className={styles.heading}>
        <span className={styles.titleNote}>
          <strong>NOTE:</strong> You are only allowed to use approved program
          links on the specific property they have been approved for. This is
          compliance reasons and advertiser requirements. Due to regular
          monitoring of links, in the event approved links are used on a
          non-approved property, your account risks the possibility of being
          removed from our network.
        </span>
        {isAuthorized('disabled') && (
          <div className={styles.actionContainer}>
            <button
              className={styles.add}
              onClick={() => {
                history.push(`${basePath}/add`)
              }}
              data-rv="property-add"
            >
              Add New Property
            </button>
          </div>
        )}
      </PageHeading>
      <div className={styles.listContainer}>
        {properties.data.records.map(property => (
          <div key={_uniqueId(`${property.id}-`)} className={styles.panel}>
            <div className={styles.label}>ID:</div>
            <div className={styles.data}>{property.id}</div>
            <div className={styles.label}>Name:</div>
            <div className={styles.data}>{property.name}</div>
            <div className={styles.label}>URL:</div>
            <div className={styles.data}>
              <a href={property.url} target="_blank">
                {property.url}
              </a>
            </div>
            <div className={styles.label}>Type:</div>
            <div className={styles.data}>{mapPropertyNames(property.type)}</div>
            <div className={styles.label}>API Key:</div>
            <div className={styles.data}>{property.apiKey}</div>
            <div className={styles.label}>Description:</div>
            <div className={styles.data}>{property.description}</div>
            <div className={styles.right}>
              <div className={styles.label}>Status:</div>
              <div
                className={classnames(styles.status, {
                  [styles.approved]:
                    property.approvalStatus.toLowerCase() === 'active',
                  [styles.declined]:
                    property.approvalStatus.toLowerCase() === 'inactive',
                })}
              >
                {property.approvalStatus}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showAddForm && <AddForm close={closeAdd} addProperty={addProperty} />}
    </>
  )
}

export default withRouter(Properties)
