import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { Links } from '../../pages'
import { Err404 } from '../../components'

interface LinksRouterProps extends RouteComponentProps<{ page: string }> {}

const LinksRouter: FunctionComponent<LinksRouterProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/view/:programId`} component={Links} />
    <Route path={`${match.path}`} component={Links} />
    <Route component={Err404} />
  </Switch>
)

export default LinksRouter
