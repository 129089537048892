import React, { FunctionComponent } from 'react'

import TableContext, {
  createTableContext,
} from '../../../../contexts/TableContext'
import Table from './Table'

export type HistorySorts = 'total' | 'processedAt' | 'paymentId' | 'status'

export interface HistoryFilters {}

export const HistoryTableContext = createTableContext<
  HistoryFilters,
  HistorySorts
>()

const HistoryContainer: FunctionComponent = () => {
  return (
    <TableContext<HistoryFilters, HistorySorts>
      Context={HistoryTableContext}
      defaultSort={{
        by: 'processedAt',
        dir: 'desc',
      }}
      defaultPagination={{
        size: 10,
        page: 1,
      }}
    >
      <Table />
    </TableContext>
  )
}

export default HistoryContainer
