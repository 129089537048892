import React, { FunctionComponent } from 'react'
import { Route, Switch, Redirect, Router } from 'react-router-dom'

import {
  PublicTermsContainer,
  ForgotPasswordContainerNew,
  ResetPasswordContainer,
  TosContainer,
  LoginContainerNew,
  LogoutContainerNew,
} from '../containers'

import { PublicWrapper, Err404 } from '../components'
import NewAppRouter from './app/new'

import { logoutUser } from '../actions/user'
import { store } from '../store'
import browserHistory from '../browserHistory'
import ErrorBoundary from '../components/ErrorBoundary'

const MainRouter: FunctionComponent = () => (
  <ErrorBoundary>
    <Router history={browserHistory}>
      <Switch>
        <Route
          path="/*/"
          exact
          strict
          render={props => (
            <Redirect to={`${props.location.pathname.replace(/\/$/, '')}`} />
          )}
        />
        <Route
          exact
          path="/new/forgot-password"
          component={ForgotPasswordContainerNew}
        />
        <Route
          path="/create-password/:token"
          render={() => <ResetPasswordContainer formMethod="create" />}
        />
        <Route
          path="/reset-password/:token"
          render={() => <ResetPasswordContainer formMethod="reset" />}
        />
        <Route exact path="/tos" component={TosContainer} />
        <Route exact path="/terms" component={PublicTermsContainer} />
        {/*   The name LoginContainerNew is just an alias for LoginContainer --> */}
        <Route exact path="/new/login" component={LoginContainerNew} />
        <Route exact path="/new/logout" component={LogoutContainerNew} />
        <Route path="/new" component={NewAppRouter} />
        <Route path="" component={NewAppRouter} />
        <Route exact path="/app" component={() => <Redirect to={'/new'} />} />
        <Route exact path="/" component={() => <Redirect to={'/new'} />} />
        <Route
          exact
          path="/logout"
          render={() => {
            store.dispatch(logoutUser())
            return null
          }}
        />
        <Route
          render={() => (
            <PublicWrapper>
              <Err404 isInternal={false} />
            </PublicWrapper>
          )}
        />
      </Switch>
    </Router>
  </ErrorBoundary>
)

export default MainRouter
