import React, { ReactElement, useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { DocumentNode } from 'graphql'

import { ExportContext } from '../../contexts/ExportContext'
import { ReportStatus } from '../../typings/report'

import styles from './styles.module.css'

type PartialReportStatus = Pick<ReportStatus, 'hasError' | 'loading'>

interface Props<VariablesT> {
  fetchReport?(): void
  fetchExport?(): void
  reportStatus?: PartialReportStatus
  exportStatus?: PartialReportStatus
  totalsStatus?: PartialReportStatus
  disabled?: boolean
  className?: string
  subscriptionDocument?: DocumentNode
  subscriptionReportName?: string
  subscriptionVariables?: VariablesT
  reportCallback?(param: string): void
}

const ReportActions = <VariablesT extends {}>({
  fetchReport,
  fetchExport,
  reportStatus,
  exportStatus,
  totalsStatus,
  disabled,
  className,
  subscriptionDocument,
  subscriptionReportName,
  subscriptionVariables,
  reportCallback,
}: Props<VariablesT>): ReactElement => {
  const { runExport, exportState } = useContext(ExportContext)

  const subscriptionExportButtonContent = () => {
    switch (true) {
      case exportState.running:
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'spinner']}
              pulse
              className={styles.icon}
            />
            Exporting Report
          </>
        )
      default:
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'file-csv']}
              className={styles.icon}
            />
            Export Report
          </>
        )
    }
  }
  const exportButtonContent = () => {
    switch (true) {
      case exportStatus.loading:
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'spinner']}
              pulse
              className={styles.icon}
            />
            Exporting Report
          </>
        )
      case exportStatus.hasError:
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'exclamation-circle']}
              className={styles.icon}
            />
            Unable to Export
          </>
        )
      default:
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'file-csv']}
              className={styles.icon}
            />
            Export Report
          </>
        )
    }
  }
  const reportButtonContent = () => {
    switch (true) {
      case (reportStatus.loading || totalsStatus?.loading) ?? false:
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'spinner']}
              pulse
              className={styles.icon}
            />
            Retrieving Report
          </>
        )
      case reportStatus.hasError || (totalsStatus?.hasError ?? false):
        return (
          <>
            <FontAwesomeIcon
              icon={['fas', 'exclamation-circle']}
              className={styles.icon}
            />
            Unable to Run Report
          </>
        )
      default:
        return 'Run Report'
    }
  }

  return (
    <div className={classnames(styles.actions, className)}>
      {subscriptionDocument && subscriptionReportName && (
        <button
          onClick={() =>
            runExport(
              subscriptionReportName,
              subscriptionDocument,
              subscriptionVariables
            )
          }
          className={styles.export}
          disabled={exportState.running}
        >
          {subscriptionExportButtonContent()}
        </button>
      )}
      {fetchExport && (
        <button
          onClick={fetchExport}
          className={classnames(styles.export, {
            [styles.fail]: exportStatus.hasError,
          })}
          disabled={exportStatus.loading || disabled}
        >
          {exportButtonContent()}
        </button>
      )}
      <div className="run-cancel">
        {fetchReport && (
          <button
            className={classnames(styles.report, {
              [styles.fail]: reportStatus.hasError,
            })}
            onClick={() => {
              reportCallback ? reportCallback('run') : null
              fetchReport()
            }}
            disabled={(reportStatus.loading || totalsStatus?.loading) ?? false}
          >
            {reportButtonContent()}
          </button>
        )}
        <button
          className={classnames(styles.report, {
            [styles.fail]: reportStatus.hasError,
          })}
          onClick={() => {
            reportCallback ? reportCallback('cancel') : null
          }}
          disabled={!reportStatus.loading}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ReportActions
