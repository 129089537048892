import React, { FunctionComponent, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'

import { ResetPassword, PublicWrapper } from '../components'

import { getUserFromSession } from '../actions/user'
import { updatePassword, verifyResetToken } from '../actions/password'
import { useStoredUser } from '../utils/sorcery'

import { State } from '../reducers'
import { PasswordStore } from '../typings/password'
import { UserStore } from '../typings/stores/UserStore'

interface ResetPasswordRouteParams {
  token: string
}

export type FormMethods = 'reset' | 'create'

interface ResetPasswordProps
  extends RouteComponentProps<ResetPasswordRouteParams> {
  formMethod: FormMethods
  user: UserStore
  passwordStore: PasswordStore
  verifyResetToken(token: string): void
  resetPassword(): void
  getUserFromSession(): void
}

const ResetPasswordContainer: FunctionComponent<ResetPasswordProps> = ({
  history,
  match,
  formMethod,
  user,
  passwordStore,
  verifyResetToken,
  resetPassword,
  getUserFromSession,
}) => {
  const [resetToken, setResetToken] = useState('')
  const [isTokenValid, setIsTokenValid] = useState(false)

  const sessionChecked = useStoredUser(getUserFromSession, user)

  useEffect(() => {
    // If user exists in memory and is logged in, redirect to main app.
    if (sessionChecked && !user.loggingIn && user.loggedIn) {
      history.push('/')
    }
  }, [sessionChecked, user.loggingIn, user.loggedIn, history])

  useEffect(() => {
    if (_has(match, 'params.token') && !_isEmpty(match.params.token)) {
      setResetToken(match.params.token)
    }
  }, [match])

  useEffect(() => {
    if (
      sessionChecked &&
      !user.loggingIn &&
      !user.loggedIn &&
      !_isEmpty(resetToken)
    ) {
      verifyResetToken(resetToken)
    }
  }, [
    sessionChecked,
    user.loggingIn,
    user.loggedIn,
    resetToken,
    verifyResetToken,
  ])

  useEffect(() => {
    if (
      !passwordStore.submitting &&
      passwordStore.submitted &&
      _isEmpty(passwordStore.error)
    ) {
      setIsTokenValid(true)
    }
  }, [passwordStore])

  return (
    <PublicWrapper>
      <ResetPassword
        formMethod={formMethod}
        passwordStore={passwordStore}
        isTokenValid={isTokenValid}
        resetPassword={resetPassword}
      />
    </PublicWrapper>
  )
}

const mapStateToProps = (state: State) => ({
  user: state.user,
  passwordStore: state.password,
})

const mapDispatchToProps = dispatch => ({
  getUserFromSession: () => dispatch(getUserFromSession()),
  verifyResetToken: (t: string) => dispatch(verifyResetToken(t)),
  resetPassword: (u: number, p: string) => dispatch(updatePassword(u, p)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)
)
