import React, { FunctionComponent, ReactNode } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface FormHeaderProps {
  children: ReactNode
  className?: string
}

const FormHeader: FunctionComponent<FormHeaderProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes: string = classnames(className, styles.header)

  return (
    <div className={classes} role="heading" data-rv="form-header" {...rest}>
      {children}
    </div>
  )
}

export default FormHeader
