import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { CSSTransition } from 'react-transition-group'

import { Select, Loading } from '../../../'

import { Submission, ContactStore } from '../../../../typings/contact'

import getConfig from '../../../../config'

import fieldAlertTransitions from '../../../../utils/transitions/fieldAlert.module.css'
import styles from './styles.module.css'

interface ContactProps {
  contact: ContactStore
  sendSubmission(submission: Submission): void
}

const subjectList = [
  'General Affiliate Q & A',
  'Payment',
  'Reporting',
  'Linking',
  'Tracking',
  'Data Feed Request',
  'Account Closure',
  'Product Recommendations',
  'Other',
]

const Contact: FunctionComponent<ContactProps> = ({
  contact,
  sendSubmission,
}) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [triggered, setTriggered] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)

  const [submissionDetails, setSubmissionDetails] = useState<Submission>({
    subject: subjectList[0],
    message: '',
  })

  const subjectRef = useRef<HTMLSelectElement>()
  const messageRef = useRef<HTMLTextAreaElement>()

  useEffect(() => {
    if (submitted && isSubmitting) {
      if (_isEmpty(contact.error)) {
        // Show success message
        setShowSuccess(true)
        setTimeout(() => {
          setShowSuccess(false)
        }, 4000)

        // Reset the things
        setSubmissionDetails({
          subject: subjectList[0],
          message: '',
        })
        setTriggered(false)
        setIsSubmitting(false)
        setSubmitted(false)
      } else {
        setTriggered(false)
        setIsSubmitting(false)
        setSubmitted(false)
      }
    }
  }, [submitted, isSubmitting, contact])

  // Disable submit until message is provided
  useEffect(() => {
    if (!triggered && !isSubmitting && !_isEmpty(submissionDetails.message)) {
      setEnableSubmit(true)
    } else {
      setEnableSubmit(false)
    }
  }, [submissionDetails.message, triggered, isSubmitting])

  const handleSubmit = async e => {
    e.preventDefault()

    if (
      !triggered &&
      !isSubmitting &&
      !_isEmpty(submissionDetails.subject) &&
      !_isEmpty(submissionDetails.message)
    ) {
      setIsSubmitting(true)
      await sendSubmission(submissionDetails)
      await setTimeout(() => {
        setSubmitted(true)
      }, 500)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>
          We are committed to maintaining the confidentiality of your personal
          information. Since email travels over the Internet without any
          protection, we ask you to refrain from submitting
          personal/confidential information such as your social security number
          via email.
        </p>
        <CSSTransition
          in={showSuccess || !_isEmpty(contact.error)}
          timeout={600}
          classNames={fieldAlertTransitions}
          appear
          mountOnEnter
          unmountOnExit
        >
          <>
            {showSuccess && (
              <div className={styles.success} data-rv="contact-success">
                Message was successfully sent!
              </div>
            )}
            {!_isEmpty(contact.error) && (
              <div className={styles.error} data-rv="contact-error">
                Unable to send message. Try again later or email support
                directly.
              </div>
            )}
          </>
        </CSSTransition>
        <div className={styles.form} data-rv="contact-form">
          <label htmlFor="subject">Subject:</label>
          <Select
            id="subject"
            ref={subjectRef}
            className={styles.subject}
            options={subjectList.map(text => ({
              value: text,
              text,
            }))}
            selected={submissionDetails.subject}
            onChange={e => {
              const subject = e.currentTarget.value

              setSubmissionDetails(prev => ({
                ...prev,
                subject,
              }))
            }}
            data-rv="contact-subject"
          />
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            ref={messageRef}
            value={submissionDetails.message}
            onChange={e => {
              const message = e.currentTarget.value

              setSubmissionDetails(prev => {
                return {
                  ...prev,
                  message,
                }
              })
            }}
            data-rv="contact-message"
          />
          <div className={styles.buttonContainer}>
            {isSubmitting ? (
              <button type="button" className={styles.loading} disabled>
                <Loading className={styles.spinner} />
              </button>
            ) : (
              <button
                type="button"
                className={styles.submit}
                onClick={async e => {
                  setTriggered(true)
                  await handleSubmit(e)
                }}
                disabled={enableSubmit === false}
                data-rv="contact-submit"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.info} data-rv="contact-info">
        <h3>Contact Info</h3>
        <div className={styles.table}>
          <strong className={styles.label}>Name / Company:</strong>
          <span className={styles.data}>RV Media Network</span>

          <strong className={styles.label}>Address:</strong>
          <span className={styles.data}>9600 N Mopac Expy Ste 500</span>
          <span className={styles.data}>Austin, TX 78759</span>
          <span className={styles.data}>USA</span>

          <strong className={styles.label}>Support Email:</strong>
          <span className={styles.data}>{getConfig('supportEmail')}</span>

          <strong className={styles.label}>Office Hours:</strong>
          <span className={styles.data}>Monday - Friday</span>
          <span className={styles.data}>8 AM to 5 PM</span>
          <span className={styles.data}> (Central Standard Time)</span>
        </div>
      </div>
    </div>
  )
}

export default Contact
