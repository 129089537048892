import { Reducer } from 'redux'

import { ReportsAction, ReportsConstants } from '../actions/reports'
import { ReportsStore } from '../typings/stores/ReportsStore'

const initialState: ReportsStore = {
  data: {
    clickDate: {
      transactions: [],
      totals: null,
      pagination: null,
    },
    processDate: {
      transactions: [],
      totals: null,
      pagination: null,
    },
  },
  export: {
    hasData: false,
    status: null,
    error: null,
  },
  reportType: 'performance',
  filters: null,
  hasData: false,
  status: null,
  error: null,
}

export const reportsReducer: Reducer = (
  state = { ...initialState },
  action: ReportsAction
): ReportsStore => {
  switch (action.type) {
    case ReportsConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        reportType: initialState.reportType,
        filters: initialState.filters,
        hasData: false,
        status: 'fetching',
        error: null,
      }
    case ReportsConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        filters: action.filters,
        hasData: action.data.clickDate.transactions.length > 0,
        status: 'fetched',
        error: null,
      }
    case ReportsConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: null,
        error: action.error,
      }
    case ReportsConstants.RESET:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: null,
        error: null,
      }
    case ReportsConstants.EXPORT_FETCH:
      return {
        ...state,
        export: {
          ...initialState.export,
          status: 'fetching',
        },
      }
    case ReportsConstants.EXPORT_FETCH_SUCCESS:
      return {
        ...state,
        export: {
          hasData: true,
          filters: action.filters,
          status: 'fetched',
          error: null,
        },
      }
    case ReportsConstants.EXPORT_FETCH_FAILURE:
      return {
        ...state,
        export: {
          hasData: false,
          status: null,
          error: action.error,
        },
      }
    case ReportsConstants.EXPORT_FETCH_RESET:
      return {
        ...state,
        export: initialState.export,
      }
    case ReportsConstants.FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    case ReportsConstants.RESET_VERTICAL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          vertical: '',
        },
      }
    default:
      return state
  }
}
