import React, { FunctionComponent, ReactNode } from 'react'
import classnames from 'classnames'

import { PageDescription } from '../index'

import styles from './styles.module.css'

interface PageHeadingProps {
  title: string
  description?: string
  children?: ReactNode
  className?: string
}

const PageHeading: FunctionComponent<PageHeadingProps> = ({
  title,
  description,
  children,
  className,
}) => (
  <div className={classnames(styles.heading, className)}>
    <h2>{title}</h2>
    {description && <PageDescription content={description} />}
    {children && <div className={styles.subPanel}>{children}</div>}
  </div>
)

PageHeading.defaultProps = {
  children: null,
}

export default PageHeading
