import _has from 'lodash/has'
import _get from 'lodash/get'

import { Reducer } from 'redux'

import { MyFiReportsFiltersStore } from '../typings/myFiReportsFilters'
import {
  MyFiReportsFiltersAction,
  MyFiReportsFiltersConstants,
} from '../actions/myFiReportsFilters'

const initialState: MyFiReportsFiltersStore = {
  verticalOptions: {
    data: [],
    fetching: false,
    error: null,
  },
  trafficSourceOptions: {
    data: [],
    fetching: false,
    error: null,
  },
  campaignOptions: {
    data: [],
    fetching: false,
    error: null,
  },
  productOptions: {
    data: [],
    fetching: false,
    error: null,
  },
}

export const myFiReportsFiltersReducer: Reducer = (
  state = { ...initialState },
  action: MyFiReportsFiltersAction
): MyFiReportsFiltersStore => {
  switch (action.type) {
    case MyFiReportsFiltersConstants.FETCH_VERTICAL_OPTIONS:
      return {
        ...state,
        verticalOptions: {
          ...state.verticalOptions,
          fetching: true,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_VERTICAL_OPTIONS_SUCCESS:
      return {
        ...state,
        verticalOptions: {
          data: action.data,
          fetching: false,
          error: null,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_VERTICAL_OPTIONS_FAILURE:
      return {
        ...state,
        trafficSourceOptions: {
          fetching: false,
          error: action.error,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_TRAFFIC_SOURCE:
      return {
        ...state,
        trafficSourceOptions: {
          ...state.trafficSourceOptions,
          fetching: true,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_TRAFFIC_SOURCE_SUCCESS:
      return {
        ...state,
        trafficSourceOptions: {
          data: action.data,
          fetching: false,
          error: null,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_TRAFFIC_SOURCE_FAILURE:
      return {
        ...state,
        trafficSourceOptions: {
          fetching: false,
          error: action.error,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_CAMPAIGN:
      return {
        ...state,
        campaignOptions: {
          ...state.campaignOptions,
          fetching: true,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignOptions: {
          data: action.data,
          fetching: false,
          error: null,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        campaignOptions: {
          fetching: false,
          error: action.error,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_PRODUCT:
      return {
        ...state,
        productOptions: {
          ...state.productOptions,
          fetching: true,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        productOptions: {
          data: action.data,
          fetching: false,
          error: null,
        },
      }
    case MyFiReportsFiltersConstants.FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        productOptions: {
          fetching: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}
