import { useMemo } from 'react'
import { get, sortBy } from 'lodash'

import { Sort } from '.'

/**
 * Sort the data according to `by` and `dir`.
 */
export const sortTableData = <FilterableT extends {}, SortsT extends string>(
  data: FilterableT[],
  { by, dir }: Sort<SortsT>
): FilterableT[] => {
  if (!by || !dir) {
    return data
  }

  const asc = sortBy(data, e => get(e, by, ''))

  return dir == 'asc' ? asc : asc.reverse()
}

/**
 * Simple hook to paginate data.
 */
export const usePaginatedData = <PaginatedT>(
  data: PaginatedT[],
  page: number,
  size: number,
  dependencies?: readonly []
) =>
  useMemo(() => {
    const p = page ? page : 1
    const start = (p - 1) * size

    return data.slice(start, start + size)
  }, [page, size, data, ...(dependencies || [])])
