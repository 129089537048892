import { Dispatch } from 'redux'
import { stringify } from 'query-string'

import { triggerError } from './app'
import { fetchGetApi, fetchPutApi, fetchPostApi } from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { AppUser, AppUsersData } from '../typings/user'

export enum AppUsersConstants {
  FETCH = 'appUsers/FETCH',
  FETCH_SUCCESS = 'appUsers/FETCH_SUCCESS',
  FETCH_FAILURE = 'appUsers/FETCH_FAILURE',
  VALIDATE_USER = 'appUsers/VALIDATE',
  VALIDATE_USER_FAILURE = 'appUsers/VALIDATE_FAILURE',
  ADD = 'appUsers/ADD',
  ADD_SUCCESS = 'appUsers/ADD_SUCCESS',
  ADD_FAILURE = 'appUsers/ADD_FAILURE',
  EDIT = 'appUsers/EDIT',
  EDIT_SUCCESS = 'appUsers/EDIT_SUCCESS',
  EDIT_FAILURE = 'appUsers/EDIT_FAILURE',
}

export interface AppUsersAction extends Action {
  type: AppUsersConstants
  data?: AppUsersData
  error?: string
}

export function fetchAppUsers(): AppUsersAction {
  return {
    type: AppUsersConstants.FETCH,
  }
}

export function fetchAppUsersSuccess(data: AppUsersData): AppUsersAction {
  return {
    type: AppUsersConstants.FETCH_SUCCESS,
    data,
  }
}

export function fetchAppUsersFailure(error: string): AppUsersAction {
  return {
    type: AppUsersConstants.FETCH_FAILURE,
    error,
  }
}

export function validateAppUser(): AppUsersAction {
  return {
    type: AppUsersConstants.VALIDATE_USER,
  }
}

export function validateAppUserFailure(error: string): AppUsersAction {
  return {
    type: AppUsersConstants.VALIDATE_USER_FAILURE,
    error,
  }
}

export function addAppUser(): AppUsersAction {
  return {
    type: AppUsersConstants.ADD,
  }
}

export function addAppUserSuccess(): AppUsersAction {
  return {
    type: AppUsersConstants.ADD_SUCCESS,
  }
}

export function addAppUserFailure(error: string): AppUsersAction {
  return {
    type: AppUsersConstants.ADD_FAILURE,
    error,
  }
}

export function editAppUser(): AppUsersAction {
  return {
    type: AppUsersConstants.EDIT,
  }
}

export function editAppUserSuccess(): AppUsersAction {
  return {
    type: AppUsersConstants.EDIT_SUCCESS,
  }
}

export function editAppUserFailure(error: string): AppUsersAction {
  return {
    type: AppUsersConstants.EDIT_FAILURE,
    error,
  }
}

export const getAppUsers = () => async (dispatch: Dispatch) => {
  dispatch(fetchAppUsers())

  const endpoint = '/app-users'

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchAppUsersSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'App Users'))
    dispatch(fetchAppUsersFailure(errorParsed))
  }
}

export const getCircuitUsers = () => async (dispatch: Dispatch) => {
  dispatch(fetchAppUsers())

  const endpoint = '/app-users/portal-users'

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    dispatch(fetchAppUsersSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'App Users'))
    dispatch(fetchAppUsersFailure(errorParsed))
  }
}

export const validateUser = (email: string, userId?: string) => async (
  dispatch: Dispatch
) => {
  dispatch(validateAppUser())

  const queryParams = { id: userId, email }
  const endpoint = `/app-users/validate?${stringify(queryParams)}`

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    return bodyParsed.data.isEmailAvailable
  } else {
    dispatch(triggerError(errorParsed, 'Validate User Email'))
    dispatch(validateAppUserFailure(errorParsed))
  }
}

export const validateCircuitUser = (email: string) => async (
  dispatch: Dispatch
) => {
  dispatch(validateAppUser())
  const queryParams = { email }
  const endpoint = `/app-users/portal-users/validate?${stringify(queryParams)}`

  const { ok, bodyParsed, errorParsed } = await fetchGetApi(fetch, endpoint)

  if (ok) {
    return bodyParsed.data.isEmailAvailable
  } else {
    dispatch(triggerError(errorParsed, 'Validate User Email'))
    dispatch(validateAppUserFailure(errorParsed))
  }
}

export const addUser = (newUser: AppUser) => async (dispatch: Dispatch) => {
  dispatch(addAppUser())

  const endpoint = '/app-users'
  const { ok, errorParsed } = await fetchPostApi(fetch, endpoint, newUser)

  if (ok) {
    dispatch(addAppUserSuccess())
  } else {
    dispatch(triggerError(errorParsed, 'Add App User'))
    dispatch(addAppUserFailure(errorParsed))
  }
}

export const addCircuitUser = (newUser: AppUser) => async (
  dispatch: Dispatch
) => {
  dispatch(addAppUser())

  const endpoint = '/app-users/portal-users'
  const { ok, errorParsed } = await fetchPostApi(fetch, endpoint, newUser)

  if (ok) {
    dispatch(addAppUserSuccess())
  } else {
    dispatch(triggerError(errorParsed, 'Add circuit User'))
    dispatch(addAppUserFailure(errorParsed))
  }
}

export const editUser = (originalUser: AppUser, editedUser: AppUser) => async (
  dispatch: Dispatch
) => {
  dispatch(editAppUser())

  // Update the username if the previous username and email address are the
  // same. This makes sure that username/email are in sync for the users that
  // are correctly using their email address as username.
  if (originalUser.email === originalUser.username) {
    editedUser.username = editedUser.email
  }

  const endpoint = `/app-users/${originalUser.id}`
  const { ok, errorParsed } = await fetchPutApi(fetch, endpoint, {
    originalUser,
    editedUser,
  })

  if (ok) {
    dispatch(editAppUserSuccess())
  } else {
    dispatch(triggerError(errorParsed, 'Edit App User'))
    dispatch(editAppUserFailure(errorParsed))
  }
}

export const editCircuitUser = (
  originalUser: AppUser,
  editedUser: AppUser
) => async (dispatch: Dispatch) => {
  dispatch(editAppUser())

  // Update the username if the previous username and email address are the
  // same. This makes sure that username/email are in sync for the users that
  // are correctly using their email address as username.
  if (originalUser.email === originalUser.username) {
    editedUser.username = editedUser.email
  }

  const endpoint = `/app-users/portal-users/${originalUser.id}`
  const { ok, errorParsed } = await fetchPutApi(fetch, endpoint, {
    originalUser,
    editedUser,
  })

  if (ok) {
    dispatch(editAppUserSuccess())
  } else {
    dispatch(triggerError(errorParsed, 'Edit circuit User'))
    dispatch(editAppUserFailure(errorParsed))
  }
}
