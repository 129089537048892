import { AlertFilters } from '../../typings/alert'

export interface AlertTableRow {
  id: string
  subject: string
  content?: any
  contentId?: string
  timeInserted: string
  timeSent: string
}
/**
 * Filter out the alerts based on the `AlertFilters`.
 */
export const applyAlertFilters = (
  rows: AlertTableRow[],
  { search }: Partial<Pick<AlertFilters, 'search'>>
): AlertTableRow[] =>
  rows.filter(({ subject }) => {
    if (search == null) {
      return true
    }

    let subjectMatch = true

    if (subject != null) {
      const n = subject.toLowerCase()
      const s = search.toLowerCase()

      subjectMatch = n.includes(s)
    }

    return subjectMatch
  })
