import { Dispatch } from 'redux'

import { triggerError } from './app'
import { fetchGetApiWithFullUrl } from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { IntegrationDoc } from '../typings/integrationDoc'

export enum integrationDocsConstants {
  FETCH = 'integrationDocs/FETCH',
  FETCH_SUCCESS = 'integrationDocs/FETCH_SUCCESS',
  FETCH_FAILURE = 'integrationDocs/FETCH_FAILURE',
}

export interface IntegrationDocsAction extends Action {
  payload?: Array<IntegrationDoc>
  error?: string
}

function fetchIntegrationDocs(): IntegrationDocsAction {
  return {
    type: integrationDocsConstants.FETCH,
  }
}

function fetchIntegrationDocsSuccess(
  payload: Array<IntegrationDoc>
): IntegrationDocsAction {
  return {
    type: integrationDocsConstants.FETCH_SUCCESS,
    payload,
  }
}

function fetchIntegrationDocsFailure(error: string): IntegrationDocsAction {
  return {
    type: integrationDocsConstants.FETCH_FAILURE,
    error,
  }
}

export const getIntegrationDocs = activeProperty => async (
  dispatch: Dispatch
) => {
  dispatch(fetchIntegrationDocs())

  const activeWebsiteId = activeProperty?.id
  const { ok, errorParsed, bodyParsed } = await fetchGetApiWithFullUrl(
    fetch,
    `https://www.myfinance.com/api/integration/${activeWebsiteId}/`
  )

  if (ok) {
    dispatch(fetchIntegrationDocsSuccess(bodyParsed.results))
  } else {
    dispatch(triggerError(errorParsed, 'integrationDocs'))
    dispatch(fetchIntegrationDocsFailure(errorParsed))
  }
}
