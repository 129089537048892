import { Reducer } from 'redux'

import { IntegrationDocsStore } from '../typings/stores/IntegrationDocsStore'
import {
  IntegrationDocsAction,
  integrationDocsConstants,
} from '../actions/integrationDocs'

const initialState: IntegrationDocsStore = {
  status: null,
  data: [],
  hasData: false,
  error: null,
}

// NOTE: This is the correct type signature, but we have incompatible types below.
export const integrationDocsReducer: Reducer<
  IntegrationDocsStore,
  IntegrationDocsAction
> = (state = { ...initialState }, action): IntegrationDocsStore => {
  switch (action.type) {
    case integrationDocsConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: 'fetching',
        error: initialState.error,
      }
    case integrationDocsConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        hasData: action.payload.length > 0,
        status: 'fetched',
        error: initialState.error,
      }
    case integrationDocsConstants.FETCH_FAILURE:
      return {
        ...initialState,
        hasData: false,
        status: null,
        error: action.error,
      }
    default:
      return state
  }
}
