import React, { FunctionComponent } from 'react'

import { DateRangeFilter, FilterBox } from '../../../../components'

import { OverviewReportFilters } from '../Table'
import {
  TableChangeFilter,
  TableChangeFilters,
} from '../../../../contexts/TableContext'

import styles from './styles.module.css'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OverviewReportFiltersProps {
  filters: Partial<OverviewReportFilters>
  setFilter: TableChangeFilter<OverviewReportFilters>
  setFilters: TableChangeFilters<OverviewReportFilters>
}

const OverviewReportFilterDisplay: FunctionComponent<OverviewReportFiltersProps> = ({
  filters,
  setFilters,
  setFilter,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <DateRangeFilter
          onChange={({ dateRange, startDate, endDate }) => {
            setFilters({
              dateRange,
              start: startDate,
              end: endDate,
            })
          }}
          dates={{
            startDate: filters.start,
            endDate: filters.end,
            dateRange: filters.dateRange,
          }}
        />
        <FilterBox label="">
          <div className={styles.revenueContainer}>
            <input
              id="impressionsFilter"
              type="checkbox"
              value={get(filters, 'includeImpressions', 'false')}
              className={styles.revenueFilter}
              onChange={e => {
                setFilter(
                  'includeImpressions',
                  e.currentTarget.checked ? 'true' : 'false'
                )
              }}
              checked={filters.includeImpressions === 'true'}
            />
            <label htmlFor="impressionsFilter">Fetch impressions data</label>
            <div
              data-tip="Including impression data will increase runtime for the report."
              data-for="global-tooltip"
              data-place="right"
            >
              <FontAwesomeIcon
                icon={['fas', 'info-circle']}
                className={styles.icon}
                style={{ marginLeft: '6px' }}
              />
            </div>
          </div>
        </FilterBox>
      </div>
    </div>
  )
}

export default OverviewReportFilterDisplay
