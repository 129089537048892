import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'

import { AppFrame } from '../components'
import { AppProvider } from '../contexts'
import { State } from '../reducers'
import { getUserFromSession, changeActiveWebsite } from '../actions/user'
import { useStoredUser } from '../utils/sorcery'

import { UserStore } from '../typings/stores/UserStore'
import { Website } from '../typings/website'
import { PushReplaceHistory, QueryParamProvider } from 'use-query-params'
import MissingWebsiteNotice from '../components/MissingWebsiteNotice'

// @ts-ignore
import introspectionQueryResultData from '../generated/fragmentTypes'
import { getMainDefinition } from '@apollo/client/utilities'
import ExportIndicator from '../components/ExportIndicator'
import ExportContext from '../contexts/ExportContext'
import { fetchGetApi } from '../utils/fetchWrapper'
import CacheCleanup from '../utils/CacheCleanup'

interface AppProps extends RouteComponentProps {
  children: ReactNode
  user: UserStore

  getUserFromSession(): void
  changeActiveWebsite(w: Website): void
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL || ''

const httpLink = new HttpLink({
  uri: `${SERVER_URL}/api/graphql`,
})

const wsParams = async () => {
  const res = await fetchGetApi(fetch, '/token', false)

  return { authorization: `bearer ${res.bodyParsed.token}` }
}

export const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_WS_URL
      : `wss://${window.location.host}/api/graphql`,
  options: {
    reconnect: true,
    connectionParams: wsParams,
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const apolloClient = new ApolloClient({
  link: splitLink,
  credentials: 'include',
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
  }),
})

const App: FunctionComponent<AppProps> = ({
  history,
  user,
  children,
  changeActiveWebsite,
  getUserFromSession,
}) => {
  const sessionChecked = useStoredUser(getUserFromSession, user)

  useEffect(() => {
    // We need to rely on sessionChecked otherwise we risk infinite looping
    if (sessionChecked && !user.loggingIn && !user.loggedIn) {
      history.push('/new/logout')
    } else if (
      sessionChecked &&
      !user.loggingIn &&
      user.loggedIn &&
      user.active === false
    ) {
      // NOTE: This path will only fire if the user stored in session reflects
      // the active status properly. We probably won't ever trigger this path
      // until we have a mechanism to periodically re-auth/re-validate the user
      // while they are logged in
      history.push('/app/unauthorized')
    }
  }, [sessionChecked, user, history])

  if (!user || !user.username) {
    return null
  }

  return !user.activeWebsite ? (
    <MissingWebsiteNotice />
  ) : (
    <ApolloProvider client={apolloClient}>
      <CacheCleanup />
      <AppProvider activeUser={user}>
        <QueryParamProvider
          history={(history as unknown) as PushReplaceHistory}
        >
          <ExportContext>
            <AppFrame user={user} updateActiveWebsite={changeActiveWebsite}>
              {children}
            </AppFrame>

            <ExportIndicator />
          </ExportContext>
        </QueryParamProvider>
      </AppProvider>
    </ApolloProvider>
  )
}

const mapStateToProps = (state: State) => ({
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  getUserFromSession: () => dispatch(getUserFromSession()),
  changeActiveWebsite: (website: Website) =>
    dispatch(changeActiveWebsite(website)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
