import { useEffect, useState } from 'react'

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}

export const useEventListener = (
  event: keyof WindowEventMap,
  element: HTMLElement | HTMLDocument | Window,
  handler: EventListener,
  passive?: boolean
) => {
  useEffect(() => {
    if (element) {
      element.addEventListener(event, handler, { passive })
    }

    return () => {
      if (element) {
        element.removeEventListener(event, handler)
      }
    }
  })
}

/**
 * Simple abstration for toggling a boolean state.
 *
 * @param {boolean} initial
 * @returns {[boolean, function]}
 */
export const useToggle = (initial: boolean): [boolean, () => void] => {
  const [value, setValue] = useState(initial)
  const toggle = () => setValue(state => !state)

  return [value, toggle]
}
