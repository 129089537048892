import React, { FunctionComponent, useState } from 'react'
import { get } from 'lodash'

import { Col } from '../../../../components/Table/next'
import OverviewReportTableContainer from '../Table'

import { useOverviewReportingLazyQuery } from '../../../../generated/types'
import accounting from 'accounting'
import { GenerateDetailReportLink } from '../../../../components/LinkRow'
import styles from '../Table/styles.module.css'
import classnames from 'classnames'
import { useApolloClient } from '@apollo/client'

const OverviewReportContainer: FunctionComponent = () => {
  const client = useApolloClient()
  const [requestCancelled, setRequestCancelled] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [aborterRef, setAbortRef] = useState(new AbortController())
  const [
    OverviewReportingResult,
    { data, loading, error, variables },
  ] = useOverviewReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })

  const [
    OverviewReportingExportResult,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useOverviewReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
  })

  const abort = () => {
    aborterRef.abort()
    setAbortRef(new AbortController())
  }

  const reportCallback = (param: string) => {
    if (param === 'run') {
      setRequestCancelled(false)
      setSpinner(true)

      // set spinner for 3/4 of a second for better user experience
      setTimeout(() => {
        setSpinner(false)
      }, 750)
    } else if (param === 'cancel') {
      abort()
      setRequestCancelled(true)
      client.clearStore()
    }
  }

  const cols: Col[] = [
    {
      text: 'Vertical',
      field: 'verticalName',
      sortable: true,
      className: classnames(styles.fill, styles.center),
      render: row => (
        <GenerateDetailReportLink
          whereCriteria="verticalId"
          id={get(row, 'verticalId') !== null ? get(row, 'verticalId', '') : 0}
          value={
            get(row, 'verticalName') !== null
              ? get(row, 'verticalName', '')
              : 'Unknown'
          }
          detailPath="/reports/vertical-summary"
          queryParams={[
            {
              startDate: variables.start?.toISODate(),
              endDate: variables.end?.toISODate(),
              dateRange: 'custom',
              verticalId: get(row, 'verticalId', ''),
            },
          ]}
        />
      ),
    },
    {
      text: 'Total Impressions',
      field: 'totalImpressions',
      sortable: true,
      render: row => accounting.formatNumber(get(row, 'totalImpressions', '')),
      className: styles.right,
    },
    {
      text: 'Viewable Impressions',
      field: 'viewableImpressions',
      sortable: true,
      render: row =>
        accounting.formatNumber(get(row, 'viewableImpressions', '')),
      className: styles.right,
    },
    {
      text: 'Product Clicks',
      field: 'productClicks',
      sortable: true,
      render: row => accounting.formatNumber(get(row, 'productClicks', '')),
      className: styles.right,
      tooltip: true,
      tooltipText: 'All clicks aggregated on the system',
    },
    {
      text: 'Unique Clicks',
      field: 'uniqueClicks',
      sortable: true,
      render: row => accounting.formatNumber(get(row, 'uniqueClicks', '')),
      className: styles.right,
      tooltip: true,
      tooltipText:
        'Product clicks filtered down to eliminate duplicates and bot clicks',
    },
    {
      text: 'Total Ctr',
      field: 'totalCtr',
      sortable: true,
      render: row => `${(get(row, 'totalCtr', '') * 100).toFixed(2)}%`,
      className: styles.right,
    },
    {
      text: 'Viewable Ctr',
      field: 'viewableCtr',
      sortable: true,
      render: row => `${(get(row, 'viewableCtr', '') * 100).toFixed(2)}%`,
      className: styles.right,
    },
    {
      text: 'Sales',
      field: 'sales',
      sortable: true,
      render: row => accounting.formatNumber(get(row, 'sales', '')),
      className: styles.right,
      tooltip: true,
      tooltipText: 'An aggregation of commissionable events',
    },
    {
      text: 'Conversion Rate',
      field: 'conversionRate',
      sortable: true,
      render: row => `${(get(row, 'conversionRate', '') * 100).toFixed(2)}%`,
      className: styles.right,
      tooltip: true,
      tooltipText: 'Sales per unique click',
    },
    {
      text: 'Commissions',
      field: 'commission',
      sortable: true,
      render: row => accounting.formatMoney(get(row, 'commission', ''), '$'),
      className: styles.right,
    },
    {
      text: 'EPC',
      field: 'epc',
      sortable: true,
      render: row => accounting.formatMoney(get(row, 'epc', ''), '$'),
      className: styles.right,
      tooltip: true,
      tooltipText: 'Effective CPC (Cost per Product Click)',
    },
  ]

  return (
    <OverviewReportTableContainer
      groupBy="page"
      cols={cols}
      footerPadding={2}
      getData={OverviewReportingResult}
      data={data}
      loading={(loading && !requestCancelled) || spinner}
      error={error}
      getExportData={OverviewReportingExportResult}
      exportData={exportData}
      exportLoading={exportLoading}
      exportError={exportError}
      abort={abort}
      reportCallback={reportCallback}
    />
  )
}

export default OverviewReportContainer
