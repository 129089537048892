import React, { FunctionComponent, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { State } from '../reducers'
import { getUserFromSession, loginPortalUser } from '../actions/user'
import { useStoredUser } from '../utils/sorcery'
import getConfig from '../config'

import { UserStore } from '../typings/stores/UserStore'
import { Err401 } from '../components'

interface LoginContainerProps extends RouteComponentProps {
  user?: UserStore
  loginPortalUser(): void
  getUserFromSession(): void
}

const LoginContainer: FunctionComponent<LoginContainerProps> = ({
  history,
  user,
  loginPortalUser,
  getUserFromSession,
}) => {
  const sessionChecked = useStoredUser(getUserFromSession, user)
  const isCallback = window.location.href.includes('?callback')
  const isFailed = window.location.href.includes('?failed')

  useEffect(() => {
    if (isFailed) {
      return
    }
    if (isCallback) {
      loginPortalUser()
      return
    }
    const baseUrl = getConfig('bffHostname')
    window.location.href = baseUrl + '/api/auth/auth0/login'
  }, [])

  useEffect(() => {
    if (sessionChecked && !user.loggingIn && user.loggedIn) {
      history.push('/reports/overview-report')
    }
  }, [sessionChecked, user.loggingIn, user.loggedIn])

  const errorMessage =
    'Your account is not authorized for the RV Media Network Portal at this time. ' +
    'Please contact your RV Media Network support person to correct the issue.'

  return (
    (isFailed && (
      <Err401
        message={errorMessage}
        linkTo="/"
        linkText="Click here to return to the login page."
      ></Err401>
    )) ||
    null
  )
}

const mapStateToProps = (state: State) => ({
  user: state.user,
  error: state.user.error,
})

const mapDispatchToProps = dispatch => ({
  loginPortalUser: () => dispatch(loginPortalUser()),
  getUserFromSession: () => dispatch(getUserFromSession()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
)
