import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { RecentSales } from '../typings/stores/RecentSalesStore'

export enum RecentSalesConstants {
  FETCH = 'recentSales/FETCH',
  FETCH_SUCCESS = 'recentSales/FETCH_SUCCESS',
  FETCH_FAILURE = 'recentSales/FETCH_FAILURE',
}

interface RecentSalesData {
  recentSales: RecentSales[]
}

export interface RecentSalesAction extends Action {
  type: RecentSalesConstants
  data?: RecentSalesData
  error?: string
}

function fetchRecentSales(): RecentSalesAction {
  return {
    type: RecentSalesConstants.FETCH,
  }
}

function fetchRecentSalesSuccess(data: RecentSalesData): RecentSalesAction {
  return {
    type: RecentSalesConstants.FETCH_SUCCESS,
    data,
  }
}

function fetchRecentSalesFailure(error: string): RecentSalesAction {
  return {
    type: RecentSalesConstants.FETCH_FAILURE,
    error,
  }
}

export const getRecentSales = cancelFetchOnReentrySync(fetch => params =>
  swallowCancellation(async dispatch => {
    dispatch(fetchRecentSales())

    const { ok, bodyParsed, errorParsed } = await fetchGetApi(
      fetch,
      '/recent-sales'
    )

    if (ok) {
      dispatch(fetchRecentSalesSuccess(bodyParsed.data))
    } else {
      dispatch(triggerError(errorParsed, 'Recent Sales'))
      dispatch(fetchRecentSalesFailure(errorParsed))
    }
  })
)
