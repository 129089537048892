import React, { FunctionComponent } from 'react'
import { IconsProps } from './iconsProps'

const Logo: FunctionComponent<IconsProps> = ({}) => (
  <img
    src={`${process.env.PUBLIC_URL}/images/RVMN_Logo_2022.png`}
    alt="RVMN_logo"
    style={{ height: '40px' }}
  />
)

export default Logo
