import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useReducer,
  Reducer,
} from 'react'

import {
  useQueryParams,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
} from 'use-query-params'
import { omit, pick, merge } from 'lodash'
import produce from 'immer'

import { SortDirection } from '../../components/Table/next'
import { pruneObject } from '../../utils/objectManagement'

export function createTableContext<FiltersT, SortsT>() {
  return React.createContext<TableContext<FiltersT, SortsT>>({
    filters: {},
    sort: {
      by: null,
      dir: null,
    },
    pagination: {
      size: 10,
      page: 0,
    },
    setFilters: () => {},
    setFilter: () => {},
    changePage: () => {},
    changePageSize: () => {},
    changeSort: () => {},
  })
}

type QueryParam<FilterT> = Extract<keyof FilterT, string>

interface TableContextProps<FiltersT, SortsT> {
  Context: React.Context<TableContext<FiltersT, SortsT>>
  defaultPagination?: Partial<Pagination>
  defaultSort?: Partial<Sort<SortsT>>
  defaultFilters?: Partial<Filters<FiltersT>>
  children?: ReactNode
  queryParams?: QueryParam<FiltersT>[]
}

type Filters<FiltersT> = { [key in keyof FiltersT]: string }

export interface Sort<SortsT> {
  by: SortsT
  dir?: SortDirection
}

interface Pagination {
  page: number
  size: number
}

type RouteUpdateType = 'push' | 'replace'

interface SetFilterOptions {
  routeUpdate?: RouteUpdateType
  resetSort?: boolean
}

export type TableChangeFilter<FiltersT> = (
  key: keyof FiltersT,
  value: string,
  options?: SetFilterOptions
) => void
export type TableChangeFilters<FiltersT> = (
  filters: Partial<Filters<FiltersT>>,
  options?: SetFilterOptions
) => void
export type TableChangeSort<SortsT> = (by: SortsT, dir: SortDirection) => void
type TableChangePage = (page: Pagination['page']) => void
type TableChangePageSize = (pageSize: Pagination['size']) => void

interface TableState<FiltersT, SortsT> {
  filters: Partial<Filters<FiltersT>> // { dateType: 'fpp' }
  sort: Sort<SortsT>
  pagination: Pagination
  syncState: boolean
  routeUpdate: RouteUpdateType
}

interface TableContext<FiltersT, SortsT>
  extends Omit<TableState<FiltersT, SortsT>, 'syncState' | 'routeUpdate'> {
  setFilters: TableChangeFilters<FiltersT>
  setFilter: TableChangeFilter<FiltersT>
  changeSort: TableChangeSort<SortsT>
  changePage: TableChangePage
  changePageSize: TableChangePageSize
}

interface SetPaginationAction {
  type: 'setPagination'
  payload: {
    page: number
    size: number
  }
}

interface SetFiltersAction<FiltersT> {
  type: 'setFilters'
  payload: Partial<Filters<FiltersT>>
  options?: SetFilterOptions
}

interface SyncStateWithParamsAction {
  type: 'syncStateWithParams'
  payload: { [key: string]: number | string }
}

interface SetSortAction<SortsT> {
  type: 'setSort'
  payload: Sort<SortsT>
}

type Action<FiltersT, SortsT> =
  | SetPaginationAction
  | SetFiltersAction<FiltersT>
  | SyncStateWithParamsAction
  | SetSortAction<SortsT>

// Convert our internal types / param whitelist in the proper type for useQueryParams
const mapParamTypes = <FiltersT, SortsT>(
  params: QueryParam<FiltersT>[]
): QueryParamConfigMap =>
  params.reduce<QueryParamConfigMap>(
    (map, p) => {
      map[p] = StringParam
      return map
    },
    {
      page: NumberParam,
      size: NumberParam,
      by: StringParam,
      dir: StringParam,
    }
  )

const TableContext = <FiltersT extends {}, SortsT extends string>({
  children,
  Context,
  defaultPagination,
  defaultSort,
  defaultFilters,
  queryParams,
}: TableContextProps<FiltersT, SortsT>): ReactElement => {
  const decodeParamMap = mapParamTypes<FiltersT, SortsT>(queryParams || [])

  const [params, setQueryParams] = useQueryParams(decodeParamMap)

  const initialFilters = (): TableState<FiltersT, SortsT>['filters'] => {
    const filterKeys = Object.keys(
      omit(decodeParamMap, ['page', 'size', 'by', 'dir'])
    )
    const fromParams = pruneObject(pick<object>(params, filterKeys))

    return pruneObject({ ...defaultFilters, ...fromParams })
  }

  const initialState: TableState<FiltersT, SortsT> = {
    syncState: false,
    routeUpdate: 'push',
    pagination: {
      page: params.page || (defaultPagination ? defaultPagination.page : 1),
      size: params.size || (defaultPagination ? defaultPagination.size : 10),
    },
    sort: {
      by: params.by || (defaultSort ? defaultSort.by : null),
      dir: params.dir || (defaultSort ? defaultSort.dir : null),
    },
    filters: initialFilters(), //filtersFromParams || (defaultFilters || {}),
  }

  const reducer = (
    state: TableState<FiltersT, SortsT>,
    action: Action<FiltersT, SortsT>
  ): TableState<FiltersT, SortsT> => {
    switch (action.type) {
      case 'setPagination':
        return produce(state, draft => {
          draft.syncState = true
          draft.routeUpdate = initialState.routeUpdate

          draft.pagination = action.payload
        })
      case 'setFilters':
        return produce(state, draft => {
          const { payload, options } = action
          draft.syncState = true
          draft.pagination.page = 1

          if (options) {
            if (options.routeUpdate) {
              draft.routeUpdate = options.routeUpdate
            }

            if (options.resetSort) {
              draft.sort = defaultSort || {
                by: null,
                dir: null,
              }
            }
          }

          draft.filters = { ...state.filters, ...payload }
        })
      case 'setSort':
        return produce(state, draft => {
          draft.syncState = true
          draft.routeUpdate = initialState.routeUpdate
          draft.pagination.page = 1

          draft.sort = action.payload
        })
      case 'syncStateWithParams':
        return produce(state, draft => {
          const { page, size, by, dir, ...rest } = action.payload

          draft.syncState = false
          if (size) draft.pagination.size = size
          if (page) draft.pagination.page = page
          if (by) draft.sort.by = by
          if (dir) draft.sort.dir = dir
          draft.filters = merge(draft.filters, pruneObject(rest))
        })
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer<
    Reducer<TableState<FiltersT, SortsT>, Action<FiltersT, SortsT>>
  >(reducer, initialState)

  const setFilter: TableChangeFilter<FiltersT> = (key, value, options) =>
    dispatch({
      type: 'setFilters',
      payload: { ...state.filters, [key]: value },
      options,
    })
  const setFilters: TableChangeFilters<FiltersT> = (filters, options) =>
    dispatch({ type: 'setFilters', payload: filters, options })
  const changeSort: TableChangeSort<SortsT> = (by, dir) =>
    dispatch({ type: 'setSort', payload: { by: dir ? by : null, dir } })
  const changePage: TableChangePage = page =>
    dispatch({
      type: 'setPagination',
      payload: { size: state.pagination.size, page },
    })
  const changePageSize: TableChangePageSize = size =>
    dispatch({
      type: 'setPagination',
      payload: { page: 1, size },
    })

  useEffect(() => {
    if (!state.syncState) return
    setQueryParams(
      {
        page: state.pagination.page,
        size: state.pagination.size,
        by: state.sort.by,
        dir: state.sort.dir,
        ...state.filters,
      },
      state.routeUpdate
    )
  }, [state])

  useEffect(() => {
    dispatch({ type: 'syncStateWithParams', payload: params })
  }, [params])

  return (
    <Context.Provider
      value={{
        ...state,
        setFilters,
        setFilter,
        changeSort,
        changePage,
        changePageSize,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default TableContext
