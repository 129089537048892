import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'

import styles from './styles.module.css'

interface DateDisplayProps {
  datetime: DateTime
}

const DateDisplay: FunctionComponent<DateDisplayProps> = ({ datetime }) => {
  return (
    <div className={styles.display}>
      <span>{datetime.toLocaleString(DateTime.DATE_MED)}</span>
    </div>
  )
}

export default DateDisplay
