import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'

import accounting from 'accounting'
import styles from '../styles.module.css'
import Table, { TableFooter } from '../../../../components/Table/next'
import { DetailsTableContext } from './index'
import { PaymentDetailReportingQueryVariables } from '../../../../generated/types'
import { DateTimeDisplay } from '../../../../components'
import { DateTime } from 'luxon'
import { get } from 'lodash'
import { decode } from 'he'

import { useSelector } from 'react-redux'
import { State } from '../../../../reducers'
import { UserStore } from '../../../../typings/stores/UserStore'

interface PaymentDetails {
  data: any
  loading: boolean
  error: boolean
  getData: ({ variables }) => void
  paymentId: string
}

const PaymentDetails: FunctionComponent<PaymentDetails> = ({
  getData,
  data,
  loading,
  error,
  paymentId,
}) => {
  const {
    sort,
    pagination: { page, size },
    changePage,
    changePageSize,
    changeSort,
  } = useContext(DetailsTableContext)

  const getTotal = (data, param) =>
    data.reduce((sum, page) => sum + page[param], 0)

  const totalRowBuilder: TableFooter[] = [
    {
      text: 'Total',
      colSpan: 7,
      className: styles.footerHeader,
    },
    {
      text: data
        ? accounting.formatMoney(
            getTotal(data.paymentDetailReporting.page, 'commission'),
            '$'
          )
        : 0,
      className: styles.right,
    },
    {
      text: data
        ? accounting.formatMoney(
            getTotal(data.paymentDetailReporting.page, 'adjustment'),
            '$'
          )
        : 0,
      className: styles.right,
    },
    {
      text: data
        ? accounting.formatMoney(
            getTotal(data.paymentDetailReporting.page, 'total'),
            '$'
          )
        : 0,
      className: styles.right,
    },
  ]

  const allColumns = [
    {
      text: 'Click ID',
      field: 'clickId',
      sortable: true,
    },
    {
      text: 'Process Date (UTC)',
      field: 'processedAt',
      sortable: true,
      render: row =>
        row.processedAt && (
          <DateTimeDisplay
            datetime={DateTime.fromISO(row.processedAt, { zone: 'utc' })}
          />
        ),
    },
    {
      text: 'Product',
      field: 'productName',
      sortable: true,
      render: ({ productName }) => productName && decode(productName),
    },
    {
      text: 'Program',
      field: 'programName',
      sortable: true,
    },
    {
      text: 'SubTracking1',
      field: 'subTracking1',
      sortable: true,
    },
    {
      text: 'Property ID',
      field: 'propertyId',
      sortable: true,
    },
    {
      text: 'Sale ID',
      field: 'eventId',
      sortable: true,
    },
    {
      text: 'Commission',
      field: 'commission',
      sortable: true,
      className: styles.right,
      render: ({ commission }) => accounting.formatMoney(commission, '$'),
    },
    {
      text: 'Adjustments',
      field: 'adjustments',
      sortable: true,
      className: styles.right,
      render: ({ adjustments }) => accounting.formatMoney(adjustments, '$'),
    },
    {
      text: 'Total Amount',
      field: 'total',
      className: styles.right,
      render: ({ total }) => accounting.formatMoney(total, '$'),
    },
  ]
  const [tableColumns, setTableColumns] = useState(allColumns)
  const user = useSelector<State, UserStore>(state => state.user)

  const variables = () => {
    return {
      skip: (page - 1) * size,
      take: size,
      sorts: sort.by && [
        {
          [sort.by]: sort.dir.toUpperCase(),
        },
      ],
      externalPaymentId: paymentId,
      externalPublisherId: user.affiliateId,
    } as PaymentDetailReportingQueryVariables
  }

  const fetchData = () => {
    getData({ variables: variables() }), setTableColumns(allColumns)
  }

  useEffect(fetchData, [sort, page, size, paymentId, user.affiliateId])

  const pagination = {
    currentPage: get(data, `paymentDetailReporting.pagination.currentPage`, 1),
    pageSize: get(data, `paymentDetailReporting.pagination.pageSize`, 10),
    total: get(data, `paymentDetailReporting.pagination.totalEntries`, 0),
  }

  return (
    <Table
      className={styles.table}
      cols={tableColumns}
      isLoading={loading}
      onSortChange={changeSort}
      totalRow={totalRowBuilder}
      sort={sort}
      data={data && get(data, `paymentDetailReporting.page`)}
      pagination={{
        onPageSizeUpdate: changePageSize,
        onPaginate: changePage,
        ...pagination,
      }}
    />
  )
}

export default PaymentDetails
