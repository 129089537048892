import { stringify } from 'query-string'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { MyFiReportsLooker } from '../typings/myfiReportsLooker'

export enum MyFiReportsLookerConstants {
  FETCH = 'myFiReportsLooker/FETCH',
  FETCH_SUCCESS = 'myFiReportsLooker/FETCH_SUCCESS',
  FETCH_FAILURE = 'myFiReportsLooker/FETCH_FAILURE',
}

export interface MyFiReportsLookerAction extends Action {
  type: MyFiReportsLookerConstants
  payload?: MyFiReportsLooker
  error?: string
}

function fetchMyFiReportsLooker(): MyFiReportsLookerAction {
  return {
    type: MyFiReportsLookerConstants.FETCH,
  }
}

function fetchMyFiReportsLookerSuccess(
  payload: MyFiReportsLooker
): MyFiReportsLookerAction {
  return {
    type: MyFiReportsLookerConstants.FETCH_SUCCESS,
    payload,
  }
}

function fetchMyFiReportsLookerFailure(error: string): MyFiReportsLookerAction {
  return {
    type: MyFiReportsLookerConstants.FETCH_FAILURE,
    error,
  }
}

export const getMyFiReportsLooker = cancelFetchOnReentrySync(fetch => params =>
  swallowCancellation(async dispatch => {
    dispatch(fetchMyFiReportsLooker())
    const endpoint = `/myfi-reports/signed-embed-url?${stringify(params)}`
    const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

    if (ok) {
      dispatch(fetchMyFiReportsLookerSuccess(bodyParsed.data))
    } else {
      dispatch(triggerError(errorParsed, 'MyFiReportsLooker'))
      dispatch(fetchMyFiReportsLookerFailure(errorParsed))
    }
  })
)
