import { Reducer } from 'redux'

import { UserStore } from '../typings/stores/UserStore'
import { UserAction, UserConstants } from '../actions/user'

const initialState: UserStore = {
  id: null,
  accountId: null,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  tosConfirmed: null,
  websites: [],
  affiliateId: null,
  activeWebsite: null,
  activeProperty: null,
  ip: null,
  loggingIn: false,
  loggedIn: false,
  error: null,
}

export const userReducer: Reducer = (
  state = { ...initialState },
  action: UserAction
): UserStore => {
  switch (action.type) {
    case UserConstants.LOGIN:
    case UserConstants.CHECK_SESSION:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
      }
    case UserConstants.LOGIN_SUCCESS:
    case UserConstants.UPDATE_SUCCESS:
    case UserConstants.RELOAD:
      return {
        ...state,
        ...action.payload,
        loggingIn: false,
        loggedIn: true,
      }
    case UserConstants.LOGIN_FAILURE:
    case UserConstants.CHECK_SESSION_FAILURE:
    case UserConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loggingIn: false,
        loggedIn: false,
      }
    case UserConstants.CHANGE_WEBSITE:
      return {
        ...state,
        activeWebsite: action.activeWebsite,
      }
    case UserConstants.LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
