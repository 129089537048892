import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _some from 'lodash/some'
import { CSSTransition } from 'react-transition-group'
import validator from 'validator'

import { Modal, Loading } from '../../../../components'

import { useDebounce } from '../../../../utils/customHooks'

import { AppContext } from '../../../../contexts'

import fieldAlertTransitions from '../../../../utils/transitions/fieldAlert.module.css'
import styles from './styles.module.css'

import { AppUser, AppUserFields } from '../../../../typings/user'
import { formMethods } from '../../../../containers/AppUsers'
import { is } from 'immer/dist/common'

interface UserFormProps {
  formMethod: formMethods
  selectedUser?: AppUser
  close(): void
  validateUser(email: string): boolean
  editUser?(originalUser: AppUser, editedUser: AppUserFields): void
  addUser?(user: AppUserFields): void
}

interface FieldErrors {
  firstName?: string
  lastName?: string
  email?: string
  role?: string
}

const UserForm: FunctionComponent<UserFormProps> = ({
  formMethod,
  selectedUser,
  close,
  validateUser,
  editUser,
  addUser,
}) => {
  const { activeUser, isAuthorized } = useContext(AppContext)

  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [isEmailAvailable, setIsEmailAvailable] = useState(null)
  const [checkingEmail, setCheckingEmail] = useState(false)

  const [fields, setFields] = useState<AppUserFields>({
    firstName: '',
    lastName: '',
    email: '',
    role: 'contentrunner',
    active: true,
  })

  const [displayErrors, setDisplayErrors] = useState<FieldErrors>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  })

  const isInitialMount = useRef(true)

  // Set initial form object if we're in edit mode
  useEffect(() => {
    if (
      formMethod === 'edit' &&
      !_isEmpty(selectedUser.firstName) &&
      !_isEmpty(selectedUser.lastName) &&
      !_isEmpty(selectedUser.email)
    ) {
      const { firstName, lastName, email, role } = selectedUser

      setFields({
        firstName,
        lastName,
        email,
        role: _get(role, 'name'),
        active: selectedUser.active,
      })
    }
  }, [formMethod, selectedUser])

  // Check if email is available (email addresses should be unique)
  const debouncedEmail = useDebounce(fields.email, 700)
  useEffect(() => {
    const validate = async (emailToValidate: string) =>
      validateUser(emailToValidate)

    if (
      !_isEmpty(debouncedEmail) &&
      validator.isEmail(debouncedEmail) &&
      (formMethod === 'add' ||
        (!_isEmpty(selectedUser) && debouncedEmail !== selectedUser.email))
    ) {
      setCheckingEmail(true)

      validate(debouncedEmail).then(emailAvailable => {
        setIsEmailAvailable(emailAvailable)
        setCheckingEmail(false)
      })
    }
  }, [debouncedEmail, formMethod, selectedUser, validateUser])

  // First Name field errors
  useEffect(() => {
    if (
      _isEmpty(displayErrors.firstName) &&
      _isEmpty(fields.firstName) &&
      (!isInitialMount.current || formMethod === 'add')
    ) {
      setDisplayErrors(prev => ({
        ...prev,
        firstName: 'Please enter a first name',
      }))
    }
  }, [
    fields.firstName,
    displayErrors.firstName,
    isInitialMount,
    formMethod,
    fields.role,
  ])

  // Last Name field errors
  useEffect(() => {
    if (
      _isEmpty(displayErrors.lastName) &&
      _isEmpty(fields.lastName) &&
      (!isInitialMount.current || formMethod === 'add')
    ) {
      setDisplayErrors(prev => ({
        ...prev,
        lastName: 'Please enter a last name',
      }))
    }
  }, [
    displayErrors.lastName,
    fields.lastName,
    formMethod,
    selectedUser,
    fields.role,
  ])

  // Email field errors
  useEffect(() => {
    if (
      !checkingEmail &&
      _isEmpty(displayErrors.email) &&
      (!isInitialMount.current || formMethod === 'add')
    ) {
      let errorMessage = ''

      if (_isEmpty(fields.email)) {
        errorMessage = 'Please enter an email address'
      } else if (!validator.isEmail(fields.email)) {
        errorMessage = 'Invalid email address'
      } else if (
        isEmailAvailable === false &&
        (formMethod === 'add' ||
          (formMethod === 'edit' && selectedUser.email !== fields.email))
      ) {
        errorMessage = 'Email address is already in use'
      }

      setDisplayErrors(prev => ({
        ...prev,
        email: errorMessage,
      }))
    }
  }, [
    checkingEmail,
    displayErrors.email,
    fields.email,
    formMethod,
    isEmailAvailable,
    selectedUser,
  ])

  // Role field errors
  useEffect(() => {
    if (
      _isEmpty(displayErrors.role) &&
      _isEmpty(fields.role) &&
      (!isInitialMount.current || formMethod === 'add')
    ) {
      setDisplayErrors(prev => ({
        ...prev,
        role: 'Please assign a role',
      }))
    }
  }, [displayErrors.role, fields.role, formMethod])

  // Reset if errors are addressed
  useEffect(() => {
    if (!isInitialMount.current && _some(displayErrors, _isEmpty)) {
      setSubmitted(false)
    }
  }, [submitted, displayErrors])

  // Disable submit until form is valid
  useEffect(() => {
    // break out logic checks because there's a lot
    const notAlreadySubmitting = !submitted && !isSubmitting

    const hasNoErrors = _some(displayErrors, _isEmpty)

    const firstNameChanged =
      !_isEmpty(fields.firstName) &&
      (formMethod === 'add' ||
        (!_isEmpty(selectedUser) &&
          selectedUser.firstName !== fields.firstName))

    const lastNameChanged =
      !_isEmpty(fields.lastName) &&
      (formMethod === 'add' ||
        (!_isEmpty(selectedUser) && selectedUser.lastName !== fields.lastName))

    const emailChanged =
      !_isEmpty(debouncedEmail) &&
      (formMethod === 'add' ||
        (!_isEmpty(selectedUser) && selectedUser.email !== debouncedEmail))

    const roleChanged =
      !_isEmpty(fields.role) &&
      (formMethod === 'add' ||
        (!_isEmpty(selectedUser) &&
          _get(selectedUser, 'role.name') !== fields.role))

    const fieldsChanged =
      firstNameChanged || lastNameChanged || emailChanged || roleChanged

    const emailIsAvailable = !checkingEmail && isEmailAvailable !== false

    if (
      notAlreadySubmitting &&
      hasNoErrors &&
      fieldsChanged &&
      emailIsAvailable &&
      !isInitialMount.current
    ) {
      setEnableSubmit(true)
    } else {
      setEnableSubmit(false)
    }
  }, [
    checkingEmail,
    displayErrors,
    debouncedEmail,
    fields,
    formMethod,
    isEmailAvailable,
    isSubmitting,
    selectedUser,
    submitted,
  ])

  // We run this at the end because hooks run sequentially, and this will fire
  // after all the hooks above run on initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
  }, [isInitialMount])

  const handleSubmit = async e => {
    e.preventDefault()
    if (!submitted && !isSubmitting && _some(displayErrors, _isEmpty)) {
      setIsSubmitting(true)

      if (formMethod === 'add') {
        await addUser(fields)
      } else {
        await editUser(selectedUser, fields)
      }

      await close()
    }
  }

  const disableRoleManagement =
    !isAuthorized('admin') ||
    (!_isEmpty(selectedUser) && selectedUser.id === activeUser.id)

  return (
    <Modal isOpen close={close} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>{`${formMethod[0].toUpperCase() + formMethod.slice(1)}`} User</h2>
          {!_isEmpty(selectedUser) && <h3>{selectedUser.username}</h3>}
        </div>
        <div className={styles.content}>
          <label htmlFor="firstName">First Name:</label>
          <input
            id="firstName"
            defaultValue={!_isEmpty(selectedUser) ? selectedUser.firstName : ''}
            onChange={e => {
              const firstName = e.currentTarget.value

              if (!_isEmpty(displayErrors.firstName)) {
                setDisplayErrors(prev => ({
                  ...prev,
                  firstName: '',
                }))
              }

              setFields(prev => ({
                ...prev,
                firstName,
              }))
            }}
            data-rv="user-form-first-name"
          />

          <label htmlFor="lastName">Last Name:</label>
          <input
            id="lastName"
            defaultValue={!_isEmpty(selectedUser) ? selectedUser.lastName : ''}
            onChange={e => {
              const lastName = e.currentTarget.value

              if (!_isEmpty(displayErrors.lastName)) {
                setDisplayErrors(prev => ({
                  ...prev,
                  lastName: '',
                }))
              }

              setFields(prev => ({
                ...prev,
                lastName,
              }))
            }}
            data-rv="user-form-last-name"
          />

          <label htmlFor="email">Email:</label>
          <input
            id="email"
            defaultValue={!_isEmpty(selectedUser) ? selectedUser.email : ''}
            onChange={e => {
              const email = e.currentTarget.value

              // reset email availability status on change
              if (!_isNil(isEmailAvailable)) {
                setIsEmailAvailable(null)
              }

              // clear existing errors
              if (!_isEmpty(displayErrors.email)) {
                setDisplayErrors(prev => ({
                  ...prev,
                  email: '',
                }))
              }

              setFields(prev => ({
                ...prev,
                email,
              }))
            }}
            data-rv="user-form-email"
          />

          <span className={styles.label}>Assigned Role:</span>
          <div className={styles.optionsList}>
            <input
              type="radio"
              id="circuitnewsletter"
              name="assignedRole"
              value="circuitnewsletter"
              defaultChecked={
                _isEmpty(selectedUser) ||
                (!_isEmpty(selectedUser) &&
                  _has(selectedUser, 'role.name') &&
                  _get(selectedUser, 'role.name') === 'circuitnewsletter')
              }
              onChange={e => {
                const selected = e.currentTarget.checked

                // clear existing errors
                if (!_isEmpty(displayErrors.role)) {
                  setDisplayErrors(prev => ({
                    ...prev,
                    role: '',
                  }))
                }

                if (selected) {
                  setFields(prev => ({
                    ...prev,
                    role: 'circuitnewsletter',
                  }))
                }
              }}
              disabled={disableRoleManagement}
            />
            <label htmlFor="circuitnewsletter">
              Circuit Newsletter - Limited Access
            </label>

            <input
              type="radio"
              id="contentrunner"
              name="assignedRole"
              value="contentrunner"
              defaultChecked={
                _isEmpty(selectedUser) ||
                (!_isEmpty(selectedUser) &&
                  _has(selectedUser, 'role.name') &&
                  _get(selectedUser, 'role.name') === 'contentrunner')
              }
              onChange={e => {
                const selected = e.currentTarget.checked

                // clear existing errors
                if (!_isEmpty(displayErrors.role)) {
                  setDisplayErrors(prev => ({
                    ...prev,
                    role: '',
                  }))
                }

                if (selected) {
                  setFields(prev => ({
                    ...prev,
                    role: 'contentrunner',
                  }))
                }
              }}
              disabled={disableRoleManagement}
            />
            <label htmlFor="contentrunner">
              Portal Content Runner - Limited Access
            </label>

            <input
              type="radio"
              id="portalintegration"
              name="assignedRole"
              value="portalintegration"
              defaultChecked={
                _isEmpty(selectedUser) ||
                (!_isEmpty(selectedUser) &&
                  _has(selectedUser, 'role.name') &&
                  _get(selectedUser, 'role.name') === 'portalintegration')
              }
              onChange={e => {
                const selected = e.currentTarget.checked

                // clear existing errors
                if (!_isEmpty(displayErrors.role)) {
                  setDisplayErrors(prev => ({
                    ...prev,
                    role: '',
                  }))
                }

                if (selected) {
                  setFields(prev => ({
                    ...prev,
                    role: 'portalintegration',
                  }))
                }
              }}
              disabled={disableRoleManagement}
            />
            <label htmlFor="portalintegration">
              Portal Integration - Restricted Access
            </label>

            <input
              type="radio"
              id="manager"
              name="assignedRole"
              value="manager"
              defaultChecked={
                !_isEmpty(selectedUser) &&
                _has(selectedUser, 'role') &&
                _get(selectedUser, 'role.name') === 'manager'
              }
              onChange={e => {
                const selected = e.currentTarget.checked

                // clear existing errors
                if (!_isEmpty(displayErrors.role)) {
                  setDisplayErrors(prev => ({
                    ...prev,
                    role: '',
                  }))
                }

                if (selected) {
                  setFields(prev => ({
                    ...prev,
                    role: 'manager',
                  }))
                }
              }}
              disabled={disableRoleManagement}
            />
            <label htmlFor="manager">Portal Manager - Restricted Access</label>

            <input
              type="radio"
              id="admin"
              name="assignedRole"
              value="admin"
              defaultChecked={
                !_isEmpty(selectedUser) &&
                _has(selectedUser, 'role') &&
                _get(selectedUser, 'role.name') === 'admin'
              }
              onChange={e => {
                const selected = e.currentTarget.checked

                // clear existing errors
                if (!_isEmpty(displayErrors.role)) {
                  setDisplayErrors(prev => ({
                    ...prev,
                    role: '',
                  }))
                }

                if (selected) {
                  setFields(prev => ({
                    ...prev,
                    role: 'admin',
                  }))
                }
              }}
              disabled={disableRoleManagement}
            />
            <label htmlFor="admin">Portal Admin - Full Access</label>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {isSubmitting || checkingEmail ? (
            <button className={styles.loading} disabled>
              <Loading className={styles.spinner} />
            </button>
          ) : (
            <>
              <CSSTransition
                in={
                  !_isEmpty(displayErrors.firstName) ||
                  !_isEmpty(displayErrors.lastName) ||
                  !_isEmpty(displayErrors.email) ||
                  !_isEmpty(displayErrors.role)
                }
                timeout={600}
                classNames={fieldAlertTransitions}
                appear
                mountOnEnter
                unmountOnExit
              >
                <div className={styles.error} data-rv="website-edit-error">
                  {displayErrors.firstName ||
                    displayErrors.lastName ||
                    displayErrors.email ||
                    displayErrors.role}
                </div>
              </CSSTransition>
              <button
                className={styles.button}
                onClick={async e => {
                  setSubmitted(true)
                  await handleSubmit(e)
                }}
                disabled={enableSubmit === false}
                data-rv="website-form-submit"
              >
                Save Changes
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UserForm
