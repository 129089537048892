/* eslint-disable prettier/prettier */
import React, { FunctionComponent, useState } from 'react'
import { get } from 'lodash'

import { Col } from '../../../../components/Table/next'
import VerticalSummaryTableContainer from '../Table'

import accounting from 'accounting'
import styles from '../Table/styles.module.css'
import { useVerticalSummaryReportingLazyQuery } from '../../../../generated/types';
import { GenerateDetailReportLink } from '../../../../components/LinkRow'
import classnames from 'classnames'
import { decode } from 'he'
import { useDispatch } from 'react-redux'
import { checkHttpError, triggerError } from '../../../../actions/app'
import { useApolloClient } from '@apollo/client'

const VerticalSummaryReportingContainer: FunctionComponent = () => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [aborterRef, setAbortRef] = useState(new AbortController());
  const [requestCancelled, setRequestCancelled] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [
    VerticalSummaryingResult,
    { data, loading, error, variables },
  ] = useVerticalSummaryReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    }
  })

  const [
    VerticalSummaryingExportResult,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useVerticalSummaryReportingLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    }
  })

  if (error) {
    dispatch(
      triggerError(
        checkHttpError(error.message),
        'Vertical Summary Report'
      )
    )
  }

  const abort = () => {
    aborterRef.abort()
    setAbortRef(new AbortController());
  }

    const reportCallback = (param: string) => {
    if (param === 'run') {
      setRequestCancelled(false)

      // set spinner for 3/4 of a second for better user experience
      setSpinner(true)
      setTimeout(() => {
        setSpinner(false)
      }, 750)
    } else if (param === 'cancel') {
      abort()
      setRequestCancelled(true)
      client.clearStore()
    }
  }

  const cols: Col[] = [
    {
      text: 'Name',
      field: 'productTypeName',
      sortable: true,
      render: ({ productTypeName }) =>
        productTypeName ? decode(productTypeName) : 'N/A',
      className: styles.left,
    },
    {
      text: 'Total Impressions',
      field: 'totalImpressions',
      sortable: true,
      render: row =>
        accounting.formatNumber(get(row, 'totalImpressions', 'N/A')),
      className: styles.right,
    },
    {
      text: 'Viewable Impressions',
      field: 'viewableImpressions',
      sortable: true,
      render: row =>
        accounting.formatNumber(get(row, 'viewableImpressions', 'N/A')),
      className: styles.right,
    },
    {
      text: 'Product Clicks',
      field: 'productClicks',
      sortable: true,
      className: classnames(styles.fill, styles.center),
      tooltip: true,
      tooltipText: 'All clicks aggregated on the system',
      render: row => (
        <GenerateDetailReportLink
          whereCriteria="startDate"
          id={get(row, 'day', '')}
          value={accounting.formatNumber(get(row, 'productClicks', 'N/A'))}
          detailPath="/reports/click-details"
          rightAligment={true}
          queryParams={[
            {
              startDate: get(row, 'day', '') ?? formatDate(variables.start),
              endDate: get(row, 'day', '') ?? formatDate(variables.end),
              start: get(row, 'day', '') ?? formatDate(variables.start),
              end: get(row, 'day', '') ?? formatDate(variables.end),
              dateRange: 'custom',
              vertical: variables.externalVerticalId,
              program: get(row, 'programId', '') ?? variables.externalProgramId,
              productType:
                get(row, 'productTypeId', '') ??
                +variables.externalProductTypeId,
              campaign:
                get(row, 'campaignId', '') ?? variables.externalCampaignId,
              product: variables.externalDisplayedProductId,
              clickedProduct: variables.externalClickedProductId,
              displayedProductName: get(row, 'displayedProductName')? decode(get(row, 'displayedProductName', '')).replace('&', '%26') : '',
              clickedProductName: get(row, 'clickedProductName')? decode(get(row, 'clickedProductName', '')).replace('&', '%26') : '',
              integrationType: get(row, 'integrationTypeId', ''),
              subTracking: get(row, 'subTracking', '') ?? variables.subTracking,
            },
          ]}
        />
      ),
    },
    {
      text: 'Unique Clicks',
      field: 'uniqueClicks',
      sortable: true,
      render: row => accounting.formatNumber(get(row, 'uniqueClicks', 'N/A')),
      className: styles.right,
      tooltip: true,
      tooltipText:
        'Product clicks filtered down to eliminate duplicates and bot clicks',
    },
    {
      text: 'Total Ctr',
      field: 'totalCtr',
      sortable: true,
      render: row => `${(get(row, 'totalCtr', '') * 100).toFixed(2)}%`,
      className: styles.right,
    },
    {
      text: 'Viewable Ctr',
      field: 'viewableCtr',
      sortable: true,
      render: row => `${(get(row, 'viewableCtr', '') * 100).toFixed(2)}%`,
      className: styles.right,
    },
    {
      text: 'Sales',
      field: 'sales',
      sortable: true,
      className: classnames(styles.fill, styles.right),
      tooltip: true,
      tooltipText: 'The aggregation of commissionable events',
      render: row => (
        <GenerateDetailReportLink
          whereCriteria="sales"
          id={get(row, 'sales', '')}
          value={accounting.formatNumber(get(row, 'sales', 'N/A'))}
          detailPath="/reports/event-details"
          rightAligment={true}
          queryParams={[
            {
              startDate: get(row, 'day', '') ?? formatDate(variables.start),
              endDate: get(row, 'day', '') ?? formatDate(variables.end),
              start: get(row, 'day', '') ?? formatDate(variables.start),
              end: get(row, 'day', '') ?? formatDate(variables.end),
              dateRange: 'custom',
              dateType: 'clickDate',
              event: 'sale',
              vertical: variables.externalVerticalId,
              program: get(row, 'programId', '') ?? variables.externalProgramId,
              productType:
                get(row, 'productTypeId', '') ??
                +variables.externalProductTypeId,
              campaign:
                get(row, 'campaignId', '') ?? variables.externalCampaignId,
              product: variables.externalDisplayedProductId,
              clickedProduct: variables.externalClickedProductId,
              displayedProductName: get(row, 'displayedProductName')? decode(get(row, 'displayedProductName', '')).replace('&', '%26') : '',
              clickedProductName: get(row, 'clickedProductName')? decode(get(row, 'clickedProductName', '')).replace('&', '%26') : '',
              integrationType: get(row, 'integrationTypeId', ''),
              subTracking: get(row, 'subTracking', '') ?? variables.subTracking,
            },
          ]}
        />
      ),
    },
    {
      text: 'Conversion Rate',
      field: 'conversionRate',
      sortable: true,
      render: row => `${(get(row, 'conversionRate', '') * 100).toFixed(2)}%`,
      className: styles.right,
      tooltip: true,
      tooltipText: 'Sales per unique click'
    },
    {
      text: 'Commissions',
      field: 'commission',
      sortable: true,
      render: row => accounting.formatMoney(get(row, 'commission', ''), '$'),
      className: styles.right,
    },
    {
      text: 'EPC',
      field: 'epc',
      sortable: true,
      render: row => accounting.formatMoney(get(row, 'epc', 'N/A'), '$'),
      className: styles.right,
      tooltip: true,
      tooltipText: 'Effective CPC (Cost per Product Click)'
    },
  ]

  return (
    <VerticalSummaryTableContainer
      groupBy="page"
      cols={cols}
      footerPadding={2}
      getData={VerticalSummaryingResult}
      data={data}
      loading={(loading && !requestCancelled) || spinner}
      error={error}
      getExportData={VerticalSummaryingExportResult}
      exportData={exportData}
      exportLoading={exportLoading}
      exportError={exportError}
      abort={abort}
      reportCallback={reportCallback}
    />
  )
}

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr)
  // Check if date is valid
  if (isNaN(date.getTime())) {
    return ''
  }
  return new Date(date.getTime()).toISOString().split('T')[0]
}

export default VerticalSummaryReportingContainer
