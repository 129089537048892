// ------- Note ------------------
// A heavy portion of this component does data manipulation
// this should probably be refactored to be the component
// I did not realize I would be doing so much when I created it initially.
// -------------------------------

import React, { Component } from 'react'
import classnames from 'classnames'
import naturalCompare from 'natural-compare-lite'

import getConfig from '../../../config'

import {
  Modal,
  Tabs,
  Select,
  GenericButton,
  GenericInput,
} from '../../../components'
import styles from './styles.module.css'

import { Product } from '../../../typings/product'
import { Tab } from '../../../components/Tabs'
import { Link } from '../../../typings/link'

const WIDGET_URL = getConfig('widgetUrl')

interface ViewLinkProps {
  close: Function
  product: Product
}

// Store indexes and modified text, instead links themselves
type LinkReference = {
  value: string
  text?: string
  type?: '34' | '38' | '156203'
}

type TabTypes = 'banner' | 'text'

interface ViewLinkState {
  tabs: Tab[]
  activeTab: TabTypes
  copyBtnText: string
  bannerLinks: LinkReference[]
  textLinks: LinkReference[]
  selectedLink: LinkReference
  subId?: string
}

export default class ViewLink extends Component<ViewLinkProps, ViewLinkState> {
  private textAreaRef = React.createRef<HTMLTextAreaElement>()

  baseCopyBtnText = 'Copy to Clipboard'

  state = {
    tabs: [],
    activeTab: null,
    copyBtnText: this.baseCopyBtnText,
    bannerLinks: [],
    textLinks: [],
    selectedLink: null,
    subId: '',
  }

  componentDidMount = () => {
    this.updateLinkArrays()
  }

  componentDidUpdate = () => {
    const { selectedLink, activeTab } = this.state
    const { product } = this.props

    if (selectedLink && activeTab === 'javascript') {
      // direct dom interaction to avoid issues with react & script tags
      const index = this.getJsLinkTuple(selectedLink.value)[0]
      const lid = product.links[index].id

      const div = document.createElement('script')
      div.src = this.buildScriptUrl(selectedLink.type, lid)

      // TODO: not working because of async loading
      // possible fix: https://github.com/krux/postscribe
      document.getElementById('react-script-workaround').appendChild(div)
    }
  }

  /**
   * Create the arrays where the links are stored
   */
  updateLinkArrays = () => {
    const { product } = this.props

    // --- Create a list of banners
    const bannerLinks = product.links
      .map(({ type, media }, i) => {
        if (type !== 'banner') {
          return null
        }

        return {
          text: `${media.width}x${media.height}`,
          value: `${i}`,
        }
      })
      .filter(link => link)
      .sort((a, b) => naturalCompare(a.text, b.text))

    // --- Create a list of text links
    const textLinks = product.links
      .map((link, i) => {
        if (link.type !== 'text') {
          return null
        }

        return {
          text: product.commission.value,
          value: `${i}`,
        }
      })
      .filter(link => link)

    let activeTab: TabTypes
    let selectedLink: LinkReference

    if (textLinks.length > 0) {
      activeTab = 'text'
      selectedLink = textLinks[0]
    } else if (bannerLinks.length > 0) {
      activeTab = 'banner'
      selectedLink = bannerLinks[0]
    }

    // Set the state, then generate the appropriate tabs
    this.setState(
      { bannerLinks, textLinks, activeTab, selectedLink },
      this.generateTabs
    )
  }

  /**
   * Generate tabs based on which types of links we have
   */
  generateTabs = () => {
    const tabs: Tab[] = []
    const { bannerLinks, textLinks, activeTab } = this.state

    if (textLinks.length > 0) {
      tabs.push({
        title: 'Text',
        isSelected: activeTab === 'text',
        to: () => this.handleTabChange('text'),
      })
    }

    if (bannerLinks.length > 0) {
      tabs.push({
        title: 'Banner',
        isSelected: activeTab === 'banner',
        to: () => this.handleTabChange('banner'),
      })
    }
    this.setState({ tabs })
  }

  /**
   * Handler for clicking the copy link button
   */
  handleCopyBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const ta = this.textAreaRef.current

    ta.focus()
    ta.select()
    document.execCommand('copy')
    this.setState({ copyBtnText: 'Copied!' })

    setTimeout(() => {
      this.resetBtnText()
    }, 2500)
  }

  /**
   * Handler for changing the link dropdown
   */
  handleSelectChange = e => {
    const { activeTab, bannerLinks, textLinks } = this.state
    const val = e.currentTarget.value

    const links =
      activeTab === 'banner'
        ? bannerLinks
        : activeTab === 'text'
        ? textLinks
        : textLinks

    const selectedLink = links.find(link => link.value === val)

    this.setState({ selectedLink })
  }

  /**
   * Simply resets the button text
   * - used in mutliple places
   */
  resetBtnText = () => {
    this.setState({ copyBtnText: this.baseCopyBtnText })
  }

  /**
   * Handle tab changes by updating tab rendering & link view
   */
  handleTabChange = (activeTab: TabTypes) => {
    const tabs = this.state.tabs.map(tab => ({
      ...tab,
      isSelected: tab.title.toLowerCase() === activeTab,
    }))

    const { bannerLinks, textLinks } = this.state

    const selectedLink =
      activeTab === 'banner'
        ? bannerLinks[0]
        : activeTab === 'text'
        ? textLinks[0]
        : textLinks[0]

    this.setState({ activeTab, tabs, selectedLink })
  }

  /**
   * Creates the code for the user to copy
   */
  createLinkCode = (link: Link) => {
    const {
      product: { name: productName },
    } = this.props
    const { subId } = this.state
    const { type, url, text: textLink, media } = link

    const img =
      type === 'banner' &&
      `<img src="${media.url}" width="${media.width}" height="${media.height}" alt="${productName}" />`

    const text =
      (type === 'text' && textLink) || (type === 'text' && productName)

    const linkCode =
      subId.length > 0 ? `${url}&s1=${encodeURIComponent(subId)}` : url

    return `<a href="${linkCode}${
      type === 'banner' ? `&cr=${media.creativeId}` : ''
    }" rel="sponsored">${img || text}</a>`
  }

  getJsLinkTuple = (val: string) => {
    const valArr = val.split('-')
    const index = parseInt(valArr[0], 10)
    const typeId = valArr[1]

    return [index, typeId]
  }

  buildScriptUrl = (type, lid) => `${WIDGET_URL}?type=${type}&lid=${lid}`

  render = () => {
    const { product, close } = this.props
    const {
      tabs,
      bannerLinks,
      textLinks,
      selectedLink,
      activeTab,
      copyBtnText,
      subId,
    } = this.state

    let selectedIndex

    if (activeTab === 'javascript') {
      selectedIndex = selectedLink && this.getJsLinkTuple(selectedLink.value)[0]
    } else {
      selectedIndex = selectedLink && parseInt(selectedLink.value, 10)
    }

    const showingLink = selectedLink && product.links[selectedIndex]
    const linkCode = showingLink && this.createLinkCode(showingLink)

    if (showingLink) {
      const tag = document.getElementById('react-script-workaround')

      if (tag) {
        tag.innerHTML = linkCode
      }
    }

    return (
      <Modal isOpen close={close} className={styles.modal}>
        {!showingLink ? null : (
          <div className={styles.container} key={`links-${product.id}`}>
            <div className={styles.header}>
              <h2>{product.name}</h2>
              <h3>
                Product ID: {product.id}
                <span className={styles.bar}> | </span>
                {product.program.name}
              </h3>
            </div>
            <div className={styles.tabs}>
              <Tabs items={tabs} />
            </div>
            <div className={styles.content}>
              <div className={styles.left}>
                <div className={classnames(styles.label, styles.spacing)}>
                  Link ID: {showingLink.id}
                </div>
                {activeTab === 'banner' && (
                  <>
                    <div>
                      <div className={styles.label}>Select size:</div>
                      <Select
                        selected={selectedLink.value}
                        options={bannerLinks}
                        onChange={this.handleSelectChange}
                        className={styles.size}
                      />
                    </div>
                    <div className={styles.note}>
                      <strong>NOTE:</strong> Larger images may be scaled down.
                      Click it to see the full size version in a new tab.
                    </div>
                    <div className={styles.preview}>
                      <a
                        href={showingLink.media.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          key={showingLink.media.url}
                          src={showingLink.media.url}
                          alt="banner preview"
                        />
                      </a>
                    </div>
                  </>
                )}
                {activeTab === 'text' && (
                  <>
                    {textLinks.length > 1 && (
                      <div>
                        <div className={styles.label}>Select link:</div>
                        <Select
                          selected={selectedLink.value}
                          options={textLinks}
                          onChange={this.handleSelectChange}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.right}>
                <div className={styles.label}>Link code:</div>
                <textarea
                  readOnly
                  className={styles.linkCode}
                  onBlur={this.resetBtnText}
                  ref={this.textAreaRef}
                  value={linkCode}
                />
                {activeTab !== 'javascript' && (
                  <GenericInput
                    placeholder="subtracking value"
                    value={subId}
                    onChange={e => {
                      const newSubId = e.currentTarget.value

                      this.setState({ subId: newSubId })
                    }}
                  />
                )}
                <div className={styles.btnContainer}>
                  {/* TODO: make this fade on text change */}
                  <GenericButton onClick={this.handleCopyBtnClick}>
                    {copyBtnText}
                  </GenericButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}
