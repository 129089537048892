import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { AppUsersContainer } from '../../containers'
import { Err404 } from '../../components'

interface UsersRouterProps extends RouteComponentProps {}

const AppUsersRouter: FunctionComponent<UsersRouterProps> = ({ match }) => (
  <Switch>
    <Route
      path={`${match.path}/:id/edit`}
      render={props => (
        <AppUsersContainer
          basePath={match.path}
          formMethod="edit"
          userId={props.match.params.id}
        />
      )}
    />
    <Route
      path={`${match.path}/add`}
      render={() => <AppUsersContainer basePath={match.url} formMethod="add" />}
    />
    <Route
      path={match.path}
      render={props => <AppUsersContainer basePath={props.match.url} />}
    />
    <Route component={Err404} />
  </Switch>
)

export default AppUsersRouter
