import React, { FunctionComponent, useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import _get from 'lodash/get'
import qs from 'query-string'
import camelcase from 'camelcase-keys'
import validator from 'validator'

import { Link } from 'react-router-dom'

import { GenericButton, Loading, CopyrightFooter } from '../..'
import Svg from '../../Svg'

import { UserStore } from '../../../typings/stores/UserStore'

import logoTransitions from './logo.module.css'
import linkTransitions from '../../../utils/transitions/additionalLinks.module.css'
import fieldAlertTransitions from '../../../utils/transitions/fieldAlert.module.css'
import dialogTransitions from '../../../utils/transitions/dialog.module.css'
import styles from './styles.module.css'

interface LoginProps extends RouteComponentProps {
  user: UserStore
  submitLogin(user: string, pass: string): void
  error?: string
}

const Login: FunctionComponent<LoginProps> = ({
  history,
  location,
  user,
  submitLogin,
  error,
}) => {
  const [showElements, setShowElements] = useState(user.loggingIn)
  const [showLinks, setShowLinks] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [resultStatus, setResultStatus] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  useEffect(() => {
    setShowElements(true)
  }, [])

  useEffect(() => {
    const loadDelay = setTimeout(() => {
      setShowLinks(true)
    }, 300)

    return () => {
      setShowLinks(false)
      clearTimeout(loadDelay)
    }
  }, [])

  useEffect(() => {
    if (user.loggedIn === true) {
      setShowElements(false)
    }
  }, [user.loggedIn])

  useEffect(() => {
    const queryParams = camelcase(qs.parse(location.search))

    let showDelay
    let hideDelay

    if (
      _has(queryParams, 'username') &&
      !_isEmpty(queryParams.username) &&
      validator.isEmail(queryParams.username)
    ) {
      const incomingUser = _get(queryParams, 'username')

      setUsername(incomingUser)
    }

    if (_has(queryParams, 'result') && queryParams.result === 'success') {
      showDelay = setTimeout(() => {
        setResultStatus(true)
      }, 600)

      hideDelay = setTimeout(() => {
        setResultStatus(false)
        history.push('/login')
      }, 6000)
    }

    return () => {
      clearTimeout(showDelay)
      clearTimeout(hideDelay)
    }
  }, [history, location.search])

  useEffect(() => {
    if (resultStatus) {
      setSuccessMessage('Your password has been successfully updated!')
    }
  }, [resultStatus])

  const handleSubmit = e => {
    e.preventDefault()

    submitLogin(username, password)
  }

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <CSSTransition
          in={showElements}
          timeout={600}
          classNames={logoTransitions}
          appear
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.logoContainer}>
            <Svg name="rv-logo" />
          </div>
        </CSSTransition>
        <CSSTransition
          in={showElements}
          classNames={dialogTransitions}
          timeout={300}
          appear
          mountOnEnter
          unmountOnExit
        >
          <form
            className={styles.formContainer}
            onSubmit={() => submitLogin(username, password)}
          >
            <h3 className={styles.formTitle}>Login</h3>
            <div className={styles.formRow}>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.currentTarget.value)}
                data-rv="login-username"
              />
            </div>
            <div className={styles.formRow}>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.currentTarget.value)}
                data-rv="login-password"
              />
            </div>
            <CSSTransition
              in={!_isEmpty(error) && !user.loggingIn}
              timeout={300}
              classNames={fieldAlertTransitions}
              mountOnEnter
              unmountOnExit
            >
              <div
                className={classnames(styles.formRow, styles.error)}
                data-rv="login-error"
              >
                {error}
              </div>
            </CSSTransition>
            <CSSTransition
              in={resultStatus && !_isEmpty(successMessage)}
              timeout={300}
              classNames={fieldAlertTransitions}
              mountOnEnter
              unmountOnExit
            >
              <div
                className={classnames(styles.formRow, styles.success)}
                data-rv="login-success"
              >
                {successMessage}
              </div>
            </CSSTransition>
            <div className={classnames(styles.formRow, styles.center)}>
              {user.loggingIn ? (
                <button className={styles.loading} disabled>
                  <Loading className={styles.spinner} />
                </button>
              ) : (
                <GenericButton
                  onClick={e => handleSubmit(e)}
                  data-rv="login-submit"
                >
                  Login
                </GenericButton>
              )}
            </div>
          </form>
        </CSSTransition>
        <CSSTransition
          in={showLinks}
          timeout={600}
          classNames={linkTransitions}
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.additionalLinks}>
            <a
              className={styles.link}
              href="/pages/signup"
              data-rv="login-signup"
            >
              New affiliate sign up
            </a>
            <Link
              className={styles.link}
              to="/new/forgot-password"
              data-rv="login-forgot-password"
            >
              Forgot your password?
            </Link>
          </div>
        </CSSTransition>
      </div>
      <CopyrightFooter delay={600} />
    </div>
  )
}

export default withRouter(Login)
