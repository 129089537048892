import { stringify } from 'query-string'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { PerformanceStore } from '../typings/stores/PerformanceStore'
import { DateRange } from '../typings/dates'
import { convertPeriodToDates } from '../utils/dates'

export enum PerformanceConstants {
  FETCH = 'performance/FETCH',
  FETCH_SUCCESS = 'performance/FETCH_SUCCESS',
  FETCH_FAILURE = 'performance/FETCH_FAILURE',
}

export interface PerformanceAction extends Action {
  type: PerformanceConstants
  data?: PerformanceStore
  filters?: object
  error?: string
}

function fetchPerformance(): PerformanceAction {
  return {
    type: PerformanceConstants.FETCH,
  }
}

function fetchPerformanceSuccess(
  data: PerformanceStore,
  filters?: object
): PerformanceAction {
  return {
    type: PerformanceConstants.FETCH_SUCCESS,
    data,
    filters,
  }
}

function fetchPerformanceFailure(error: string): PerformanceAction {
  return {
    type: PerformanceConstants.FETCH_FAILURE,
    error,
  }
}

export const getOverview = cancelFetchOnReentrySync(
  fetch => (dateRange?: DateRange) =>
    swallowCancellation(async dispatch => {
      dispatch(fetchPerformance())

      // set a default date range if one wasn't provided
      const dateRangeParams = dateRange ? dateRange : convertPeriodToDates()
      const queryObject = {
        ...dateRangeParams,
      }

      const endpoint = `/reports/overview?${stringify(queryObject)}`
      const { ok, errorParsed, bodyParsed } = await fetchGetApi(fetch, endpoint)

      if (ok) {
        dispatch(fetchPerformanceSuccess(bodyParsed.data, queryObject))
      } else {
        dispatch(triggerError(errorParsed, 'Overview'))
        dispatch(fetchPerformanceFailure(errorParsed))
      }
    })
)
