import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { LinkInfo } from '../components'

import { getLinkInfo } from '../actions/links'

import { State } from '../reducers'
import { LinkInfoRequest, LinkInfoStore } from '../typings/link'

interface LinkInfoProps {
  linkInfo: LinkInfoStore
  getLinkInfo(): void
}

const LinkInfoContainer: FunctionComponent<LinkInfoProps> = ({
  linkInfo,
  getLinkInfo,
}) => {
  return <LinkInfo linkInfo={linkInfo} getLinkInfo={getLinkInfo} />
}

const mapStateToProps = (state: State) => ({
  linkInfo: state.links.linkInfo,
})

const mapDispatchToProps = dispatch => ({
  getLinkInfo: (p: LinkInfoRequest) => dispatch(getLinkInfo(p)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkInfoContainer)
