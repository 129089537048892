import { Reducer } from 'redux'
import _isEmpty from 'lodash/isEmpty'

import { TermsAction, TermsConstants } from '../actions/terms'
import { TermsStore } from '../typings/support'

const initialState: TermsStore = {
  data: {
    id: null,
    terms: null,
    dateTimeCreated: null,
    deleted: false,
  },
  hasData: false,
  fetching: false,
  fetched: false,
  error: null,
}

export const termsReducer: Reducer = (
  state = { ...initialState },
  action: TermsAction
): TermsStore => {
  switch (action.type) {
    case TermsConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        fetching: true,
        fetched: true,
        error: null,
      }
    case TermsConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        hasData: !_isEmpty(action.data.terms),
        fetching: false,
        fetched: true,
        error: null,
      }
    case TermsConstants.FETCH_FAILURE:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        fetching: false,
        fetched: false,
        error: action.error,
      }
    default:
      return state
  }
}
