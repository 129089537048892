import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faBars,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCoins,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faFileCsv,
  faGlobeAmericas,
  faInfoCircle,
  faLaptopCode,
  faPencilAlt,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faTimes,
  faToggleOff,
  faToggleOn,
  faCog,
} from '@fortawesome/free-solid-svg-icons'

export default function registerIcons() {
  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowUp,
    faBars,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCoins,
    faDownload,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandArrowsAlt,
    faFileCsv,
    faGlobeAmericas,
    faInfoCircle,
    faLaptopCode,
    faPencilAlt,
    faSearch,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTimes,
    faToggleOff,
    faToggleOn,
    faCog
  )
}
