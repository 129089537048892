import React, { FunctionComponent } from 'react'

import styles from './styles.module.css'
import { Link } from 'react-router-dom'

interface Err401Props {
  message?: string
  linkTo?: string
  linkText?: string
}

const Err401: FunctionComponent<Err401Props> = ({
  message = `We're sorry, but you don't have access to this page.`,
  linkTo,
  linkText,
}) => (
  <div className={styles.container} data-rv="err401-container">
    <div className={styles.bar}>
      <h2 className={styles.errorCode}>401</h2>
      <div className={styles.message}>
        {message}&nbsp;
        {linkTo && linkText && <Link to={linkTo}>{linkText}</Link>}
      </div>
    </div>
  </div>
)

export default Err401
