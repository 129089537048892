import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { PropertiesContainer } from '../../containers'
import { Err404 } from '../../components'

import RestrictedRoute from '../util/RestrictedRoute'

interface PropertiesRouterProps extends RouteComponentProps {}

const PropertiesRouter: FunctionComponent<PropertiesRouterProps> = ({
  match,
}) => (
  <Switch>
    <RestrictedRoute
      path={`${match.path}/add`}
      minimumRequiredRole="manager"
      render={() => <PropertiesContainer basePath={match.url} showAddForm />}
    />
    <Route
      path={match.path}
      render={props => <PropertiesContainer basePath={props.match.url} />}
    />
    <Route component={Err404} />
  </Switch>
)

export default PropertiesRouter
