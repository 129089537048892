import { Reducer } from 'redux'

import { AlertsStore } from '../typings/stores/AlertsStore'
import { AlertsAction, AlertsConstants } from '../actions/alerts'

const initialState: AlertsStore = {
  data: [],
  hasData: false,
  status: null,
  error: null,
  pagination: null,
}

export const alertsReducer: Reducer = (
  state = { ...initialState },
  action: AlertsAction
): AlertsStore => {
  switch (action.type) {
    case AlertsConstants.FETCH:
      return {
        ...state,
        data: initialState.data,
        hasData: false,
        status: 'fetching',
        error: null,
        pagination: null,
      }
    case AlertsConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data.alerts,
        hasData: action.data.alerts.length > 0,
        pagination: action.data.pagination,
        status: 'fetched',
        error: null,
      }
    case AlertsConstants.FETCH_FAILURE:
      return {
        ...initialState,
        hasData: false,
        status: null,
        error: action.error,
      }
    default:
      return state
  }
}
