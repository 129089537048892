import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LookerEmbedLook, LookerEmbedSDK } from '@looker/embed-sdk'
import { uniqBy, orderBy } from 'lodash'
import classnames from 'classnames'
import { DateTime } from 'luxon'

import {
  FilterBox,
  GenericInput,
  Select,
  PageHeading,
  DateRangeFilter,
  PageDescription,
} from '../../components'
import { MyFiReportsFiltersStore } from '../../typings/myFiReportsFilters'
import {
  getVerticalFilters,
  getTrafficSourceFilters,
  getCampaignFilters,
  getProductFilters,
} from '../../actions/myFiReportsFilters'
import { State } from '../../reducers'
import { SERVER_URL } from '../../utils/fetchWrapper'
import { ENABLE_MYFI_REPORTS } from '../../utils/featureFlags'
import getConfig from '../../config'

import styles from './styles.module.css'

const EMBED_DOMAIN = getConfig('embedDomain')
interface MyFiReportsPageProps {
  myFiReportsLooker?: MyFiReportsFiltersStore
}

const MyFiReportsPage: FunctionComponent<MyFiReportsPageProps> = () => {
  const dispatch = useDispatch()

  const [dateType, setDateType] = useState('clickDate')
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .startOf('month')
      .toFormat('y-MM-dd')
  )
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .endOf('month')
      .toFormat('y-MM-dd')
  )
  const maxBacktrackForStartDate = DateTime.local()
    .minus({ months: 24 })
    .toFormat('y-MM-dd')
  const [dateRange, setDateRange] = useState('this_month')
  const [dropdownFilters, setDropDownFilters] = useState({})
  const [summaryLookInstance, setSummaryLookInstance] = useState(null)
  const [detailedLookInstance, setDetailedLookInstance] = useState(null)
  const [updatedFilters, updateFilters] = useState({})
  const [viewType, setViewType] = useState('summary')

  const filters = useSelector<State, MyFiReportsFiltersStore>(
    state => state.myFiReportsFilters
  )

  const affiliateId = useSelector<State, string>(
    state => state.user.affiliateId
  )
  const websiteId = useSelector<State, string>(
    state => state.user.activeWebsite.id
  )

  const setupSummaryLook = (look: LookerEmbedLook) => {
    setSummaryLookInstance(look)
  }
  const setupDetailedLook = (look: LookerEmbedLook) => {
    setDetailedLookInstance(look)
  }
  // load filter options from MyFi API
  const loadFilters = () => {
    // load vertical options
    dispatch(getVerticalFilters(affiliateId))
    // load traffic source options
    dispatch(getTrafficSourceFilters(affiliateId))
    // load campaign options
    dispatch(getCampaignFilters(affiliateId))
    // load product options
    dispatch(getProductFilters(affiliateId))
  }
  // re-run the look with updated filters
  const updateLook = () => {
    const lookInstance =
      viewType === 'summary' ? summaryLookInstance : detailedLookInstance
    // re-runs look instance based on updated filter values
    lookInstance.updateFilters({
      ...updatedFilters,
      'reporting_trafficsource.rvmn_website_id': websiteId,
    })
    lookInstance.run()
  }
  // update filters handler
  const filtersUpdate = event => {
    const filters = {
      'traffic-source': 'reporting_trafficsource.id',
      vertical: 'reporting_affiliatevertical.id',
      product: 'reporting_affiliateproduct.id',
      campaign: 'reporting_campaign.reporting_id',
      subID: 'reporting_click.sub_id',
    }
    const type = event.target.name
    const value = event.currentTarget.value
    setDropDownFilters({
      ...dropdownFilters,
      [type]: value,
    })
    updateFilters({
      ...updatedFilters,
      [filters[type]]: value,
    })
  }
  // date range filter handler
  const dateFilterUpdate = ({ dateRange, startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setDateRange(dateRange)
    // adding an additional day because Looker excludes the end date day
    endDate = DateTime.fromFormat(endDate, 'y-MM-dd')
      .plus({ days: 1 })
      .toISODate()
    if (startDate && endDate) {
      //depend of the dateType(clickDate) value, we must send star Date Opposite(processDate) but a year ago
      let startDateOpposite = DateTime.fromFormat(startDate, 'y-MM-dd')
        .minus({ years: 1 })
        .toISODate()
      // Remove the restriction and show all the data we have till now with now() + 1 Day
      // Ensure we don’t restrict the results by a date we are not filtering by
      let endDateOpposite = DateTime.fromFormat(
        DateTime.local().toFormat('y-MM-dd'),
        'y-MM-dd'
      )
        .plus({ days: 1 })
        .toISODate()
      if (dateType === 'clickDate') {
        updateFilters({
          ...updatedFilters,
          'reporting_click.click_date': `${startDate} to ${endDate}`,
          'reporting_affiliaterevenue_affiliate.revenue_affiliate_date': `${startDateOpposite} to ${endDateOpposite}`,
        })
      } else {
        updateFilters({
          ...updatedFilters,
          'reporting_affiliaterevenue_affiliate.revenue_affiliate_date': `${startDate} to ${endDate}`,
          'reporting_click.click_date': `${startDateOpposite} to ${endDateOpposite}`,
        })
      }
    }
  }
  // has Revenue filter handler
  const revenueFilterUpdate = event => {
    const checked = event.target.checked
    const query = checked ? '>0' : ''
    updateFilters({
      ...updatedFilters,
      'reporting_affiliaterevenue.revenue': `${query}`,
    })
  }
  // remove duplicates and order filter options
  const getFilterValues = type => {
    return orderBy(
      uniqBy(
        filters[type].data.map(({ display_text, value }) => {
          return { value, text: display_text }
        }),
        'value'
      ),
      'text'
    )
  }
  // initialize look
  const createLook = (lookId, lookDivId, storeLookFunc) => {
    if (lookId) {
      const url = `${SERVER_URL}/api/myfi-reports/signed-embed-url`
      // Initialize looker
      LookerEmbedSDK.init('bankrate.looker.com', {
        url: url,
        params: [
          {
            name: 'embed_url',
            value: `/embed/looks/${lookId}?embed_domain=${EMBED_DOMAIN}&sdk=2`,
          },
          {
            name: 'external_user_id',
            value: affiliateId,
          },
        ],
        withCredentials: true,
      })
      // Create look
      // depend of the dateType(clickDate) value, we must set starDateOpposite(processDate) to last 1 year
      let startDateOpposite = DateTime.fromFormat(startDate, 'y-MM-dd')
        .minus({ years: 1 })
        .toISODate()
      // adding an additional day because Looker excludes the end date day
      let endDatePlusOneDay = DateTime.fromFormat(endDate, 'y-MM-dd')
        .plus({ days: 1 })
        .toISODate()
      // because the default date type set for initial load is clickDate we will set to last 1 year the start date from revenue_affiliate_date
      // both dates are need it to get more accurate results (clickDate and processDate)
      LookerEmbedSDK.createLookWithId(lookId)
        .appendTo(lookDivId)
        .withClassName(styles.lookerEmbed)
        .withFilters({
          'reporting_trafficsource.rvmn_publisher_id': affiliateId,
          'reporting_click.click_date': `${startDate} to ${endDatePlusOneDay}`,
          'reporting_affiliaterevenue_affiliate.revenue_affiliate_date': `${startDateOpposite} to ${endDatePlusOneDay}`,
          'reporting_trafficsource.rvmn_website_id': websiteId,
        })
        .build()
        .connect()
        .then(storeLookFunc)
        .catch((error: Error) => {
          console.error('Connection error', error)
        })
    }
  }

  useEffect(() => {
    if (!summaryLookInstance || !detailedLookInstance) {
      // Load summary look
      createLook(12962, '#summary-look', setupSummaryLook)
      // Load detailed look
      createLook(12866, '#detailed-look', setupDetailedLook)
      loadFilters()
    }
    // check if look instance is present as well as start date and end date to update the date filter
    if ((summaryLookInstance || detailedLookInstance) && startDate && endDate) {
      dateFilterUpdate({ dateRange, startDate, endDate })
    }
  }, [dateType])

  return (
    <>
      <PageHeading
        title="MyFi Reports"
        description="This report contains aggregated information for traffic sources, verticals, products, campaigns, and Sub ID filtered by a specified date range. The report is limited to 5,000 rows. Reference the ‘Support’ tab for a full list of definitions of Sales by product and/or vertical. Click date references the date on which the event began. Process date references the date on which the advertiser uploaded the sale."
      />
      {!ENABLE_MYFI_REPORTS && (
        <PageDescription content="WARNING: This page is disabled for public access" />
      )}
      <div id="controls" className={styles.container}>
        <div className={styles.row}>
          <FilterBox label="Date type">
            <div className={styles.dateTypeContainer}>
              <button
                className={classnames(styles.toggleBtn, {
                  [styles.selected]: dateType === 'clickDate',
                })}
                onClick={() => {
                  setDateType('clickDate')
                }}
              >
                Click Date
              </button>
              <button
                className={classnames(styles.toggleBtn, {
                  [styles.selected]: dateType === 'processDate',
                })}
                onClick={() => {
                  setDateType('processDate')
                }}
              >
                Process Date
              </button>
            </div>
          </FilterBox>
          <DateRangeFilter
            onChange={dateFilterUpdate}
            dates={{
              startDate: startDate,
              maxBacktrackForStartDate: maxBacktrackForStartDate,
              endDate: endDate,
              dateRange: dateRange,
            }}
          />
          <FilterBox label="View">
            <div className={styles.dateTypeContainer}>
              <button
                className={classnames(styles.toggleBtn, {
                  [styles.selected]: viewType === 'summary',
                })}
                onClick={() => {
                  setViewType('summary')
                }}
              >
                Summary
              </button>
              <button
                className={classnames(styles.toggleBtn, {
                  [styles.selected]: viewType === 'detailed',
                })}
                onClick={() => {
                  setViewType('detailed')
                }}
              >
                Detailed
              </button>
            </div>
          </FilterBox>
        </div>
        <div className={styles.row}>
          <FilterBox label="Filter by" className={styles.filterBy}>
            <div className={styles.filterByContainer}>
              <Select
                className={styles.dropdown}
                name="traffic-source"
                placeholder="Traffic Source"
                loadingMessage="Loading traffic sources"
                isLoading={filters.trafficSourceOptions.fetching}
                options={getFilterValues('trafficSourceOptions')}
                selected={dropdownFilters['traffic-source']}
                onChange={filtersUpdate}
              />
              <Select
                className={styles.dropdown}
                name="vertical"
                placeholder="Verticals"
                loadingMessage="Loading verticals"
                isLoading={filters.verticalOptions.fetching}
                options={getFilterValues('verticalOptions')}
                selected={dropdownFilters['vertical']}
                onChange={filtersUpdate}
              />
              <Select
                className={styles.dropdown}
                name="product"
                placeholder="Product"
                loadingMessage="Loading products"
                isLoading={filters.productOptions.fetching}
                options={getFilterValues('productOptions')}
                selected={dropdownFilters['product']}
                onChange={filtersUpdate}
              />
              <Select
                className={styles.dropdown}
                name="campaign"
                placeholder="Campaigns"
                loadingMessage="Loading campaigns"
                isLoading={filters.campaignOptions.fetching}
                options={getFilterValues('campaignOptions')}
                selected={dropdownFilters['campaign']}
                onChange={filtersUpdate}
              />
              <GenericInput
                name="subID"
                placeholder="Sub ID"
                onChange={filtersUpdate}
              />
              <div className={styles.revenueContainer}>
                <input
                  type="checkbox"
                  id="revenueFilter"
                  name="revenueFilter"
                  className={styles.revenueFilter}
                  onChange={revenueFilterUpdate}
                />
                <label htmlFor="revenueFilter">Has Revenue</label>
              </div>
            </div>
          </FilterBox>
        </div>
        <button
          className={classnames(styles.report)}
          onClick={updateLook}
          disabled={false}
        >
          Run Report
        </button>
      </div>
      <div id="myfi-reports-look">
        <div
          id="summary-look"
          className={classnames(styles.look, {
            [styles.hidden]: viewType === 'detailed',
          })}
        ></div>
        <div
          id="detailed-look"
          className={classnames(styles.look, {
            [styles.hidden]: viewType === 'summary',
          })}
        ></div>
      </div>
      <br />
    </>
  )
}

export default MyFiReportsPage
