import React, { FunctionComponent, Props } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface GenericButtonProps extends Props<HTMLButtonElement> {
  onClick(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
  className?: string
  capitalize?: boolean
  small?: boolean
  outlined?: boolean
  children: string
}

const GenericButton: FunctionComponent<GenericButtonProps> = ({
  onClick,
  className,
  children,
  capitalize,
  small,
  outlined,
  ...rest
}) => (
  <button
    onClick={onClick}
    className={classnames(
      className,
      styles.button,
      { [styles.small]: small },
      { [styles.outlined]: outlined }
    )}
    data-rv="generic-btn"
    {...rest}
  >
    {capitalize ? children.toUpperCase() : children}
  </button>
)

GenericButton.defaultProps = {
  className: null,
  capitalize: true,
  small: false,
  outlined: false,
}

export default GenericButton
