import { Reducer } from 'redux'

import { CategoriesAction, CategoriesConstants } from '../actions/categories'

import { CategoriesStore } from '../typings/stores/CategoriesStore'

const initialState: CategoriesStore = {
  data: [],
  fetching: false,
  error: null,
}

export const categoriesReducer: Reducer = (
  state = { ...initialState },
  action: CategoriesAction
): CategoriesStore => {
  switch (action.type) {
    case CategoriesConstants.FETCH:
      return {
        ...initialState,
        fetching: true,
      }
    case CategoriesConstants.FETCH_SUCCESS:
      return {
        data: action.payload,
        error: initialState.error,
        fetching: false,
      }
    case CategoriesConstants.FETCH_FAILURE:
      return {
        ...initialState,
        error: action.error,
      }
    default:
      return state
  }
}
