import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './styles.module.css'

interface LoadingProps {
  className?: string
}

const Loading: FunctionComponent<LoadingProps> = ({ className }) => {
  return (
    <div className={classnames(styles.container, className)} data-rv="loading">
      <FontAwesomeIcon
        icon={['fas', 'spinner']}
        pulse
        className={classnames(styles.loading, className)}
      />
    </div>
  )
}

export default Loading
