import React, { FunctionComponent } from 'react'
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'

import { OutstandingBalance, PaymentHistory, PaymentDetails } from '../../pages'
import { Err404 } from '../../components'

interface PaymentsRouterProps extends RouteComponentProps {}

const PaymentsRouter: FunctionComponent<PaymentsRouterProps> = ({ match }) => (
  <Switch>
    <Redirect
      exact
      path={match.path}
      to={`${match.path}/outstanding-balance`}
    />

    <Route
      path={`${match.path}/outstanding-balance`}
      component={OutstandingBalance}
    />

    <Route path={`${match.path}/history`} component={PaymentHistory} />

    <Route
      path={`${match.path}/details/:paymentId`}
      component={PaymentDetails}
    />

    <Route>
      <Err404 isInternal />
    </Route>
  </Switch>
)

export default PaymentsRouter
