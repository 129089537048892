import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import { ErrorToast } from '../components'

import { showError } from '../actions/app'

import { State } from '../reducers'

import { AppStore } from '../typings/stores/AppStore'

interface ErrorHandlerProps extends AppStore {
  customStyles?: string
  showError(index: number): void
}

const ErrorContainer: FunctionComponent<ErrorHandlerProps> = ({
  errors,
  customStyles,
  showError,
}) => {
  useEffect(() => {
    const showErrors = errors => {
      if (errors.length > 0) {
        errors.forEach((error, index) => {
          if (error.shown === false) {
            toast.error(<ErrorToast error={error} />)
            showError(index)
          } else {
            toast.dismiss(index)
          }
        })
      }
    }

    showErrors(errors)
  }, [errors, showError])

  return <ToastContainer autoClose={5000} className={customStyles} />
}

const mapStateToProps = (state: State) => ({
  errors: state.app.errors,
})

const mapDispatchToProps = dispatch => ({
  showError: (i: number) => dispatch(showError(i)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer)
