import React, { FunctionComponent, useEffect, useState } from 'react'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import _isEmpty from 'lodash/isEmpty'
import validator from 'validator'

import { GenericButton, Loading } from '../../..'

import getConfig from '../../../../config'

import fieldAlertTransitions from '../../../../utils/transitions/fieldAlert.module.css'
import styles from './styles.module.css'

interface ForgotPasswordFormProps {
  requestPasswordReset(username: string): void
}

const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = ({
  requestPasswordReset,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (_isEmpty(username) || !validator.isEmail(username)) {
      setError('E-mail address is required')
    } else {
      setError('')
      setSubmitted(false)
    }
  }, [username, error])

  const handleSubmit = e => {
    e.preventDefault()

    if (
      !submitted &&
      !isSubmitting &&
      !_isEmpty(username) &&
      validator.isEmail(username)
    ) {
      requestPasswordReset(username)
      setIsSubmitting(true)
    }
  }

  return (
    <form className={styles.container}>
      <h3 className={styles.title}>Forgot Password</h3>
      <div className={styles.row}>
        <div className={styles.description}>
          <p>
            To reset your password, please enter your email address below. The
            email address submitted must match the address you provided during
            registration.
          </p>
          <p>
            If you need additional support, contact us at{' '}
            <a href={`mailto:${getConfig('supportEmail')}`}>
              {getConfig('supportEmail')}
            </a>
            .
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <CSSTransition
          in={!_isEmpty(error) && submitted}
          timeout={600}
          mountOnEnter
          unmountOnExit
          classNames={fieldAlertTransitions}
        >
          <div className={styles.error} data-rv="forgot-error">
            {error}
          </div>
        </CSSTransition>
        <input
          type="text"
          name="email"
          placeholder="Email Address"
          value={username}
          onChange={e => setUsername(e.currentTarget.value)}
          data-rv="forgot-email"
        />
      </div>
      <div className={classnames(styles.row, styles.center)}>
        {submitted && isSubmitting ? (
          <button className={styles.loading} disabled>
            <Loading className={styles.spinner} />
          </button>
        ) : (
          <GenericButton
            onClick={e => {
              setSubmitted(true)
              handleSubmit(e)
            }}
            data-rv="forgot-submit"
          >
            Send Request
          </GenericButton>
        )}
      </div>
    </form>
  )
}

export default ForgotPasswordForm
