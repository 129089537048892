import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

import { Advertiser } from '../../typings/advertisers'
import { AdvertisersTableContext } from '.'
import { PageHeading, SearchInput, GenericButton } from '../../components'
import Table from '../../components/Table/next'

import { useHistory } from 'react-router'
import { getPrograms } from '../../actions/advertisers'
import { State } from '../../reducers'
import {
  usePaginatedData,
  sortTableData,
} from '../../contexts/TableContext/utils'

import styles from './styles.module.css'
import { Website } from '../../typings/website'

const Advertisers: FunctionComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const advertisersLoading = useSelector<State, boolean>(
    state => state.advertisers.status === 'fetching'
  )

  const advertisers = useSelector<State, Advertiser[]>(
    state => state.advertisers.preloaded
  )

  const activeWebsite = useSelector<State, Website>(
    state => state.user.activeWebsite
  )

  const {
    changePage,
    changePageSize,
    changeSort,
    filters,
    pagination: { page, size },
    setFilter,
    sort,
  } = useContext(AdvertisersTableContext)

  useEffect(() => {
    dispatch(getPrograms(activeWebsite, true))
  }, [activeWebsite])

  const filteredData = useMemo(() => {
    const search = filters.search ? filters.search.toLowerCase() : ''

    const filtered = advertisers.filter(({ name, status }) => {
      const matchesSearch = name.toLowerCase().includes(search)
      const matchesStatus =
        filters.status !== 'all' ? filters.status === status : true

      return matchesSearch && matchesStatus
    })

    return sortTableData(filtered, sort)
  }, [advertisers, sort, filters.search, filters.status])

  const paginatedAdvertisers = usePaginatedData(filteredData, page, size)

  return (
    <>
      <PageHeading title="Approved Programs" className={styles.heading}>
        <div></div>
        <div className={styles.searchContainer}>
          <SearchInput
            onChange={debounce(value => setFilter('search', value), 200)}
          />
        </div>
      </PageHeading>

      <Table
        className={styles.table}
        isLoading={advertisersLoading}
        data={paginatedAdvertisers}
        onSortChange={changeSort}
        sort={sort}
        cols={[
          {
            className: styles.name,
            field: 'name',
            sortable: true,
            text: 'Program',
          },
          {
            text: 'Status',
            className: styles.nostretch,
            render: ({ id, status }) => {
              switch (status) {
                case 'approved':
                  return (
                    <GenericButton
                      onClick={() => history.push(`/links?program=${id}`)}
                      small
                    >
                      Get links
                    </GenericButton>
                  )
                default:
                  return <div className={styles.available}>Available</div>
              }
            },
          },
        ]}
        pagination={{
          currentPage: page,
          pageSize: size,
          onPageSizeUpdate: changePageSize,
          onPaginate: changePage,
          total: filteredData.length,
        }}
        enableSettings={false}
      />
    </>
  )
}

export default Advertisers
