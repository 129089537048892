import React, { FunctionComponent, useState } from 'react'
import Table, { SortDirection } from '../../components/Table/next'
import styles from './styles.module.css'
import { PaginationData } from '../../components/Pagination/next'
import { DateTimeDisplay, GenericButton } from '../../components'
import ViewAlert from './ViewAlert'
import { AlertSorts } from './index'
import { DateTime } from 'luxon'
import { AlertTableRow } from './utils'

const AlertsTable: FunctionComponent<{
  loading: boolean
  alerts: AlertTableRow[]
  paginate: boolean
  sortable?: boolean
  onSortChange?: (by: AlertSorts, dir: SortDirection) => void
  sort?: any
  onPageSizeUpdate?: (pageSize: PaginationData['pageSize']) => void
  onPaginate?: (page: PaginationData['pageSize']) => void
}> = ({
  loading,
  alerts,
  paginate = true,
  sortable = true,
  onSortChange,
  sort,
  onPageSizeUpdate,
  onPaginate,
}) => {
  const [selectedAlert, setSelectedAlert] = useState(null)

  return (
    <>
      <Table
        className={styles.table}
        isLoading={loading}
        data={alerts}
        onSortChange={onSortChange}
        sort={sort}
        cols={[
          {
            className: styles.name,
            field: 'subject',
            sortable: sortable,
            text: 'Subject',
          },
          {
            field: 'timeSent',
            sortable: sortable,
            text: 'Sent',
            render: row => {
              const sentDate = DateTime.fromSQL(row.timeSent)

              return <DateTimeDisplay datetime={sentDate} />
            },
          },
          {
            text: 'View',
            className: styles.nostretch,
            render: alert => (
              <GenericButton
                onClick={() => {
                  setSelectedAlert(alert.id)
                }}
                small
              >
                Open
              </GenericButton>
            ),
          },
        ]}
        pagination={undefined}
        enableSettings={false}
      />
      {selectedAlert && (
        <ViewAlert
          alertId={selectedAlert}
          close={() => setSelectedAlert(null)}
        />
      )}
    </>
  )
}

export default AlertsTable
