import { Dispatch } from 'redux'
import _isNil from 'lodash/isNil'
import _kebabcase from 'lodash/kebabCase'
import { DateTime } from 'luxon'
import _capitalize from 'lodash/capitalize'
import filesaver from 'file-saver'
import { stringify } from 'query-string'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'
import { stripHyphens } from '../utils/fileExport'

import { Action } from '../typings/reducer'
import { PaymentSections, PaymentsData } from '../typings/payments'

const dateFormat = 'yMMdd'

export enum PaymentsConstants {
  FETCH = 'payments/FETCH',
  FETCH_SUCCESS = 'payments/FETCH_SUCCESS',
  FETCH_FAILURE = 'payments/FETCH_FAILURE',
  RESET = 'payments/RESET',
  EXPORT_FETCH = 'payments/EXPORT_FETCH',
  EXPORT_FETCH_SUCCESS = 'payments/EXPORT_FETCH_SUCCESS',
  EXPORT_FETCH_FAILURE = 'payments/EXPORT_FETCH_FAILURE',
  EXPORT_FETCH_RESET = 'payments/EXPORT_FETCH_RESET',
}

export interface PaymentsAction extends Action {
  type: PaymentsConstants
  data?: PaymentsData
  dataType?: PaymentSections
  error?: string
}

function fetchPayments(dataType: PaymentSections): PaymentsAction {
  return {
    type: PaymentsConstants.FETCH,
    dataType,
  }
}

function fetchPaymentsSuccess(
  dataType: PaymentSections,
  data: PaymentsData
): PaymentsAction {
  return {
    type: PaymentsConstants.FETCH_SUCCESS,
    dataType,
    data,
  }
}

function fetchPaymentsFailure(
  dataType: PaymentSections,
  error: string
): PaymentsAction {
  return {
    type: PaymentsConstants.FETCH_FAILURE,
    dataType,
    error,
  }
}

function resetPayments(dataType: PaymentSections): PaymentsAction {
  return {
    type: PaymentsConstants.RESET,
    dataType,
  }
}

function fetchPaymentsForExport(dataType: PaymentSections): PaymentsAction {
  return {
    type: PaymentsConstants.EXPORT_FETCH,
    dataType,
  }
}

function fetchPaymentsForExportSuccess(
  dataType: PaymentSections
): PaymentsAction {
  return {
    type: PaymentsConstants.EXPORT_FETCH_SUCCESS,
    dataType,
  }
}

function fetchPaymentsForExportFailure(
  dataType: PaymentSections,
  error: string
): PaymentsAction {
  return {
    type: PaymentsConstants.EXPORT_FETCH_FAILURE,
    dataType,
    error,
  }
}

function resetPaymentsForExport(dataType: PaymentSections): PaymentsAction {
  return {
    type: PaymentsConstants.EXPORT_FETCH_RESET,
    dataType,
  }
}

export const getPaymentDetails = cancelFetchOnReentrySync(
  fetch => (id: string, params?: { [key: string]: string | number }) =>
    swallowCancellation(async dispatch => {
      const dataType = 'paymentDetails'

      dispatch(resetPayments(dataType))
      dispatch(fetchPayments(dataType))

      const { ok, bodyParsed, errorParsed } = await fetchGetApi(
        fetch,
        `/payments/payment-details/${id}?${stringify(params)}`
      )

      if (ok) {
        dispatch(fetchPaymentsSuccess(dataType, bodyParsed.data))
      } else {
        dispatch(triggerError(errorParsed, 'Payments'))
        dispatch(fetchPaymentsFailure(dataType, errorParsed))
      }
    })
)

export const exportPayments = (
  dataType: PaymentSections,
  id: string = null,
  params: { [key: string]: string | number } = {}
) => async (dispatch: Dispatch) => {
  dispatch(resetPaymentsForExport(dataType))
  dispatch(fetchPaymentsForExport(dataType))

  const today = DateTime.local()
  const query = stringify(params)
  let url = `/payments/${_kebabcase(dataType)}`

  if (!_isNil(id)) {
    url += `/${id}`
  }

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    `${url}/export?${query}`
  )

  if (ok) {
    let filename = _kebabcase(dataType)

    if (!_isNil(id)) {
      filename += `_${id}`
    }

    filename += `_${today.toFormat(dateFormat)}.csv`

    const file = new Blob([bodyParsed], {
      type: 'text/csv;charset=utf8',
    })

    filesaver.saveAs(file, filename)

    dispatch(fetchPaymentsForExportSuccess(dataType))
  } else {
    dispatch(
      triggerError(
        errorParsed,
        `${_kebabcase(stripHyphens(_capitalize(dataType)))} Export`
      )
    )
    dispatch(fetchPaymentsForExportFailure(dataType, errorParsed))
  }
}

export const getFilteredPayments = cancelFetchOnReentrySync(
  fetch => (
    dataType: PaymentSections,
    params: { [key: string]: string | number }
  ) =>
    swallowCancellation(async dispatch => {
      dispatch(resetPayments(dataType))
      dispatch(fetchPayments(dataType))

      const { ok, bodyParsed, errorParsed } = await fetchGetApi(
        fetch,
        `/payments/${_kebabcase(dataType)}?${stringify(params)}`
      )

      if (ok) {
        dispatch(fetchPaymentsSuccess(dataType, bodyParsed.data))
      } else {
        dispatch(triggerError(errorParsed, 'Payments'))
        dispatch(fetchPaymentsFailure(dataType, errorParsed))
      }
    })
)
