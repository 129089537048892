import { ThunkAction } from 'redux-thunk'

import { Action } from '../typings/reducer'
import { Advertiser } from '../typings/advertisers'
import { fetchGetApi } from '../utils/fetchWrapper'
import { Pagination } from '../typings/report'
import { State } from '../reducers'
import { triggerError, AppAction } from './app'
import { Website } from '../typings/website'

export enum AdvertisersConstants {
  FETCH = 'advertisers/FETCH',
  FETCH_SUCCESS = 'advertisers/FETCH_SUCCESS',
  FETCH_FAILURE = 'advertisers/FETCH_FAILURE',
  ERROR = 'advertisers/ERROR',
}

interface PaginatedRecords {
  records: Advertiser[]
  pagination: Pagination
}

export interface AdvertiserAction extends Action {
  type: AdvertisersConstants
  data?: PaginatedRecords
  error?: string
}

const fetchAdvertisers = (): AdvertiserAction => ({
  type: AdvertisersConstants.FETCH,
})

const fetchAdvertisersSuccess = (data: PaginatedRecords): AdvertiserAction => ({
  type: AdvertisersConstants.FETCH_SUCCESS,
  data,
})

const fetchAdvertisersFailure = (error: string): AdvertiserAction => ({
  type: AdvertisersConstants.FETCH_FAILURE,
  error,
})

/**
 * Fetch all advertisers
 */
export const getAdvertisers = (
  force?: boolean
): ThunkAction<
  Promise<void>,
  State,
  unknown,
  AdvertiserAction | AppAction
> => async (dispatch, getState) => {
  dispatch(fetchAdvertisers())

  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    '/approved-advertisers'
  )

  if (ok) {
    dispatch(fetchAdvertisersSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Advertisers'))
    dispatch(fetchAdvertisersFailure(errorParsed))
  }
}

/**
 * Fetch all programs
 */
export const getPrograms = (
  activeProperty: Website,
  force?: boolean
): ThunkAction<
  Promise<void>,
  State,
  unknown,
  AdvertiserAction | AppAction
> => async (dispatch, getState) => {
  if (!force && getState().advertisers.hasData) {
    return
  }
  dispatch(fetchAdvertisers())

  const activeWebsiteId = activeProperty?.id
  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    `/approved-programs?id=${activeWebsiteId}`
  )

  if (ok) {
    dispatch(fetchAdvertisersSuccess(bodyParsed.data))
  } else {
    dispatch(triggerError(errorParsed, 'Advertisers'))
    dispatch(fetchAdvertisersFailure(errorParsed))
  }
}
