import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'

import { Support } from '../components'

import { getApprovedTerms } from '../actions/terms'
import { sendSubmission } from '../actions/contact'

import { State } from '../reducers'
import { SupportType, TermsStore } from '../typings/support'
import { Submission, ContactStore } from '../typings/contact'

interface SupportProps {
  basePath: string
  supportType: SupportType
  terms?: TermsStore
  contact?: ContactStore
  getApprovedTerms(): void
  sendSubmission(): void
}

const SupportContainer: FunctionComponent<SupportProps> = ({
  basePath,
  supportType,
  terms,
  contact,
  getApprovedTerms,
  sendSubmission,
}) => {
  useEffect(() => {
    if (supportType === 'terms') {
      getApprovedTerms()
    }
  }, [supportType, getApprovedTerms])

  return (
    <Support
      basePath={basePath}
      supportType={supportType}
      contact={contact}
      content={terms}
      sendSubmission={sendSubmission}
    />
  )
}

const mapStateToProps = (state: State) => ({
  terms: state.terms,
  contact: state.contact,
})

const mapDispatchToProps = dispatch => ({
  getApprovedTerms: () => dispatch(getApprovedTerms()),
  sendSubmission: (s: Submission) => dispatch(sendSubmission(s)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportContainer)
