import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import classnames from 'classnames'
import camelcase from 'camelcase-keys'
import kebabcase from 'kebabcase-keys'
import { parse, stringify } from 'query-string'
import _isEmpty from 'lodash/isEmpty'
import uniqueId from 'lodash/uniqueId'

import { Select } from '..'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './v2.module.css'
import { SelectOption } from '../Select'
import { DataPagination } from '../Table'

export interface PaginationProps extends RouteComponentProps, DataPagination {
  className?: string
}

const pageSelectOptions: SelectOption[] = [
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '25', text: '25' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
]

const Pagination: FunctionComponent<PaginationProps> = ({
  location,
  history,
  className,
  total,
  pageSize,
  currentPage,
}) => {
  const [lastPage, setLastPage] = useState()
  const [pageParams, setPageParams] = useState({
    page: 1,
    size: 10,
  })
  const [pageList, setPageList] = useState([])

  useEffect(() => {
    setPageParams({
      page: currentPage,
      size: pageSize,
    })
  }, [currentPage, pageSize])

  useEffect(() => {
    setLastPage(
      pageParams.size >= total ? 1 : Math.ceil(total / pageParams.size)
    )
  }, [pageParams.size, total])

  useEffect(() => {
    // hard code this to alleviate even number issues
    const showPages = 5

    // pre-calculate the halfway mark
    const halfway = Math.floor(showPages / 2)
    const pageArray = []

    let start = 1
    let stop = lastPage

    if (pageParams.page - halfway < 2 || lastPage <= showPages) {
      stop = lastPage <= showPages ? lastPage : showPages
    } else if (lastPage >= showPages) {
      pageArray.push('...')

      start =
        pageParams.page + showPages > lastPage
          ? lastPage - showPages
          : pageParams.page - halfway

      stop =
        pageParams.page + halfway < lastPage - 2
          ? pageParams.page + halfway
          : lastPage
    }

    for (let i = start; i <= stop; i++) {
      pageArray.push(i)
    }

    if (stop !== lastPage) {
      pageArray.push('...')
      pageArray.push(lastPage)
    }

    setPageList(pageArray)
  }, [lastPage, pageParams])

  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const currentFilters = camelcase(parse(location.search))

      let newPath = location.pathname

      const newFilters = {
        ...currentFilters,
        ...pageParams,
      }

      if (!_isEmpty(newFilters)) {
        newPath += `?${stringify(kebabcase(newFilters))}`
      }

      history.push(newPath)
    }
  }, [history, location.pathname, location.search, pageParams])

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.paginator, {
          [styles.disabled]: total < 1,
        })}
      >
        <ul className={className}>
          <li>
            <button
              onClick={() =>
                setPageParams(prevParams => ({
                  ...prevParams,
                  page: 1,
                }))
              }
              disabled={pageParams.page <= 2}
            >
              <FontAwesomeIcon icon="angle-double-left" />
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setPageParams(prevParams => ({
                  ...prevParams,
                  page: prevParams.page > 1 ? prevParams.page - 1 : 1,
                }))
              }}
              disabled={pageParams.page === 1}
            >
              <FontAwesomeIcon icon="angle-left" />
            </button>
          </li>
          {pageList.map(num => (
            <li key={uniqueId(`page-item-${num}-`)}>
              {typeof num === 'number' && (
                <button
                  className={classnames({
                    [styles.selected]: pageParams.page === num,
                  })}
                  onClick={() =>
                    setPageParams(prevParams => ({
                      ...prevParams,
                      page: num,
                    }))
                  }
                  disabled={pageParams.page === num}
                >
                  {num}
                </button>
              )}
              {typeof num === 'string' && <span>{num}</span>}
            </li>
          ))}
          <li>
            <button
              onClick={() =>
                setPageParams(prevParams => ({
                  ...prevParams,
                  page: prevParams.page + 1,
                }))
              }
              disabled={pageParams.page === lastPage}
            >
              <FontAwesomeIcon icon="angle-right" />
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                setPageParams(prevParams => ({
                  ...prevParams,
                  page: lastPage,
                }))
              }
              disabled={pageParams.page >= lastPage - 1}
            >
              <FontAwesomeIcon icon="angle-double-right" />
            </button>
          </li>
        </ul>
      </div>
      <div className={styles.pageSize}>
        <div className={styles.show}>Show:</div>
        <Select
          className={styles.select}
          selected={pageParams.size}
          options={pageSelectOptions}
          onChange={e => {
            const newSize = parseInt(e.currentTarget.value, 10)

            setPageParams({
              page: 1,
              size: newSize,
            })
          }}
        />
      </div>
    </div>
  )
}

export default withRouter(Pagination)
