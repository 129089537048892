import React, { ReactElement } from 'react'
import accounting from 'accounting'
import classnames from 'classnames'
import { LinkFilters } from '../../typings/link'
import { Product } from '../../typings/product'
import styles from './styles.module.css'

interface ToggleStates {
  showBannerLinks: boolean
  showTextLinks: boolean
}

interface TableRow {
  id: string
  name: string
  programId: string
  programName: string
  commission: {
    value: number
    dynamic: boolean
  }
  linkCounts: {
    text: number
    banner: number
  }
}

export const linksToTableRows = (links: Product[]): TableRow[] =>
  links.map(
    ({
      id,
      name,
      linkCounts,
      program: { id: programId, name: programName },
      commission,
    }) => ({
      id,
      name,
      programId,
      programName,
      commission,
      linkCounts,
    })
  )

/**
 * Filter out the links based on the `LinkFilters`.
 */
export const applyLinkFilters = (
  rows: TableRow[],
  {
    program,
    search,
    showBannerLinks,
    showTextLinks,
  }: Partial<Pick<LinkFilters, 'program' | 'search'>> & ToggleStates
): TableRow[] =>
  rows
    .filter(({ programId, name }) => {
      if (program == null && search == null) {
        return true
      }

      let advertiserMatch = true
      let searchMatch = true

      if (program != null) {
        // Loose equality is intended
        advertiserMatch = program == programId
      }

      if (search != null) {
        const n = name.toLowerCase()
        const s = search.toLowerCase()

        searchMatch = n.includes(s)
      }

      return advertiserMatch && searchMatch
    })
    .filter(({ linkCounts }) => {
      return (
        (showTextLinks === true && linkCounts.text > 0) ||
        (showBannerLinks === true && linkCounts.banner > 0)
      )
    })

/**
 * Renders the specific commission data cell
 */
export const renderLinkTypes = (text: number, banner: number) => (
  <div className={styles.type}>
    <div
      data-rv="link-type-indicator-text"
      aria-checked={text > 0}
      className={classnames(styles.text, {
        [styles.active]: text > 0,
      })}
    >
      T
    </div>
    <div
      data-rv="link-type-indicator-banner"
      aria-checked={banner > 0}
      className={classnames(styles.banner, {
        [styles.active]: banner > 0,
      })}
    >
      B
    </div>
  </div>
)

/**
 * If the number is between 0 and 1 this is a percentage commission, otherwise formats the value
 * as a dollar amount.
 */
export const renderCommissionValue = (
  commission: number,
  dynamic: boolean
): ReactElement => {
  const amount = commission || 0

  if (amount <= 0) {
    return `${0.6 * 100}%`
  }

  return amount > 0 && amount < 1 && dynamic
    ? `${amount * 100}%`
    : accounting.formatMoney(amount, '$')
}
