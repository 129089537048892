import { FilterBox } from '../../../../components'

import { VerticalSummarySorts, VerticalSummaryTableContext } from '../Table'
import {
  TableChangeFilter,
  TableChangeFilters,
  TableChangeSort,
} from '../../../../contexts/TableContext'

import styles from './styles.module.css'
import TableFilters from '../../Filters/TableFilters'
import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { OverviewReportFilters } from '../../OverviewReport/Table'

interface VerticalSummaryFiltersProps {
  filters: Partial<OverviewReportFilters>
  setFilter?: TableChangeFilter<OverviewReportFilters>
  setFilters: TableChangeFilters<OverviewReportFilters>
  changeSort: TableChangeSort<VerticalSummarySorts>
  setPropertyHasData?: any
}

const VerticalSummaryFilterDisplay: FunctionComponent<VerticalSummaryFiltersProps> = ({
  filters,
  setFilters,
  changeSort,
  setPropertyHasData = null,
}) => (
  <div className={styles.container}>
    <TableFilters
      tableContext={VerticalSummaryTableContext}
      showClickedProducts={true}
      showSubTracking={true}
      setPropertyHasData={setPropertyHasData}
      showIncludeImpressions={true}
    />
    <div className={styles.row}>
      <FilterBox label="Group by" className={styles.groupBy}>
        <div className={styles.groupContainer}>
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byDate',
            })}
            onClick={() => {
              changeSort('day', 'desc')
              setFilters({ groupBy: 'byDate' })
            }}
          >
            Date
          </button>
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byProductType',
            })}
            onClick={() => {
              changeSort('productTypeName', 'asc')
              setFilters({ groupBy: 'byProductType' })
            }}
          >
            Product Type
          </button>
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byDisplayedProduct',
            })}
            onClick={() => {
              changeSort('displayedProductName', 'asc')
              setFilters({ groupBy: 'byDisplayedProduct' })
            }}
          >
            Displayed Product
          </button>

          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byIntegrationType',
            })}
            onClick={() => {
              changeSort('integrationTypeName', 'asc')
              setFilters({ groupBy: 'byIntegrationType' })
            }}
          >
            Integration Type
          </button>
          {/* Only for Credit Card, Investing, Retail, Personal Finance and
        Home Services  */}
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byProgram',
            })}
            onClick={() => {
              changeSort('programName', 'asc')
              setFilters({ groupBy: 'byProgram' })
            }}
          >
            Program
          </button>
          {/* Only for Credit Card, Investing, Retail, Personal Finance and
        Home Services  */}
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byClickedProduct',
            })}
            onClick={() => {
              changeSort('clickedProductName', 'asc')
              setFilters({ groupBy: 'byClickedProduct' })
            }}
          >
            Clicked Product
          </button>
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'byCampaign',
            })}
            onClick={() => {
              changeSort('campaignName', 'asc')
              setFilters({ groupBy: 'byCampaign' })
            }}
          >
            Campaign
          </button>
          <button
            className={classnames(styles.toggleBtn, {
              [styles.selected]: filters.groupBy === 'bySubTracking',
            })}
            onClick={() => {
              changeSort('subTracking', 'asc')
              setFilters({ groupBy: 'bySubTracking' })
            }}
          >
            Subtracking
          </button>
        </div>
      </FilterBox>
    </div>
  </div>
)

export default VerticalSummaryFilterDisplay
