import React, { FunctionComponent } from 'react'
import accounting from 'accounting'

interface CurrencyDisplayProps {
  cents: number
  className?: string
}

const CurrencyDisplay: FunctionComponent<CurrencyDisplayProps> = ({
  cents,
  className,
}) => {
  let amount =
    cents === 0
      ? accounting.formatMoney(0, '$')
      : accounting.formatMoney(cents / 100, '$')

  return <div className={className}>{amount}</div>
}

export default CurrencyDisplay
