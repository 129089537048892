import { FunctionComponent, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { State } from '../reducers'
import getConfig from '../config'

import { UserStore } from '../typings/stores/UserStore'

interface LogoutContainerProps extends RouteComponentProps {
  user?: UserStore
  error?: string
}

const LogoutContainer: FunctionComponent<LogoutContainerProps> = ({}) => {
  useEffect(() => {
    const baseUrl = getConfig('bffHostname')
    window.location.href = baseUrl + '/api/auth/auth0/logout'
  })

  return null
}

const mapStateToProps = (state: State) => ({
  user: state.user,
  error: state.user.error,
})

export default withRouter(connect(mapStateToProps)(LogoutContainer))
