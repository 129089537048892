import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import { CopyrightFooter } from '../..'

import styles from './styles.module.css'

interface Err404Props {
  isInternal?: boolean
}

const Err404: FunctionComponent<Err404Props> = ({ isInternal }) => (
  <div
    className={classnames(styles.container, {
      [styles.public]: !isInternal,
    })}
    data-rv="err404-container"
  >
    <div
      className={classnames(styles.bar, {
        [styles.public]: !isInternal,
      })}
    >
      <h2 className={styles.errorCode}>404</h2>
      <div className={styles.message}>
        We're sorry, but we could not find that page.
      </div>
    </div>
    {!isInternal && <CopyrightFooter />}
  </div>
)

export default Err404
