import { Dispatch } from 'redux'
import { stringify } from 'query-string'
import kebabcase from 'kebabcase-keys'

import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { Link, LinkInfo, LinkInfoRequest } from '../typings/link'
import { DateTime } from 'luxon'
import filesaver from 'file-saver'

export enum LinksConstants {
  FETCH = 'links/FETCH',
  FETCH_SUCCESS = 'links/FETCH_SUCCESS',
  FETCH_FAILURE = 'links/FETCH_FAILURE',
  FILTER = 'links/FILTER',
  FETCH_INFO = 'link/FETCH',
  FETCH_INFO_SUCCESS = 'link/FETCH_SUCCESS',
  FETCH_INFO_FAILURE = 'link/FETCH_FAILURE',
  FETCH_EXPORT = 'links/EXPORT',
  FETCH_EXPORT_SUCCESS = 'links/EXPORT_SUCCESS',
}

export interface LinksAction extends Action {
  type: LinksConstants
  payload?: Array<Link>
  linkInfo?: LinkInfo
  error?: string
}

function fetchLinks(): LinksAction {
  return {
    type: LinksConstants.FETCH,
  }
}

function fetchLinksExport(): LinksAction {
  return {
    type: LinksConstants.FETCH_EXPORT,
  }
}

function fetchLinksExportSuccess(): LinksAction {
  return {
    type: LinksConstants.FETCH_EXPORT_SUCCESS,
  }
}

function fetchLinksSuccess(payload: Array<Link>): LinksAction {
  return {
    type: LinksConstants.FETCH_SUCCESS,
    payload,
  }
}

function fetchLinksFailure(error: string): LinksAction {
  return {
    type: LinksConstants.FETCH_FAILURE,
    error,
  }
}

function fetchLinkInfo(): LinksAction {
  return {
    type: LinksConstants.FETCH_INFO,
  }
}

function fetchLinkInfoSuccess(linkInfo: LinkInfo): LinksAction {
  return {
    type: LinksConstants.FETCH_INFO_SUCCESS,
    linkInfo,
  }
}

function fetchLinkInfoFailure(error: string): LinksAction {
  return {
    type: LinksConstants.FETCH_INFO_FAILURE,
    error,
  }
}

export const getLinks = activeProperty => async (dispatch: Dispatch) => {
  dispatch(fetchLinks())

  const activeWebsiteId = activeProperty?.id
  const { ok, errorParsed, bodyParsed } = await fetchGetApi(
    fetch,
    `/new/links?id=${activeWebsiteId}`
  )

  if (ok) {
    dispatch(fetchLinksSuccess(bodyParsed.data.products))
  } else {
    dispatch(triggerError(errorParsed, 'Links'))
    dispatch(fetchLinksFailure(errorParsed))
  }
}

export const getLinkInfo = cancelFetchOnReentrySync(
  fetch => (params: LinkInfoRequest) =>
    swallowCancellation(async dispatch => {
      dispatch(fetchLinkInfo())

      const endpoint = `/new/links/info?${stringify(kebabcase(params))}`

      const { ok, bodyParsed, errorParsed } = await fetchGetApi(fetch, endpoint)

      if (ok) {
        dispatch(
          fetchLinkInfoSuccess({
            ...bodyParsed.data,
            userVariable: params.userVariable,
            category: params.category,
            position: params.position,
          })
        )
      } else {
        // intentionally not triggering the error toast (triggerError) here
        // since we'll display a form error on the UI
        dispatch(fetchLinkInfoFailure(errorParsed))
      }
    })
)

export const getLinksExport = cancelFetchOnReentrySync(
  fetch => (pathname, activeProperty) =>
    swallowCancellation(async dispatch => {
      function throwExportError() {
        dispatch(triggerError('Error exporting products', `Products Export`))
      }

      dispatch(fetchLinksExport())

      const activeWebsiteId = activeProperty?.id
      const endpoint = `/new/links/export?id=${activeWebsiteId}`
      let response

      try {
        response = await fetchGetApi(fetch, endpoint)
      } catch (e) {}

      if (response && response.ok) {
        let filename = `products_${DateTime.local().toFormat('yMMdd')}.csv`

        if (pathname === '/app/links') {
          response.bodyParsed = response.bodyParsed
            .replace(/PROPERTY/g, 'WEBSITE')
            .replace(/PROGRAM/, 'ADVERTISER')
        }
        const file = new Blob([response.bodyParsed], {
          type: 'text/csv;charset=utf8',
        })

        filesaver.saveAs(file, filename)
      } else {
        throwExportError()
      }

      dispatch(fetchLinksExportSuccess())
    })
)
