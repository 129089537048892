import React, { FunctionComponent, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useEventListener } from '../../../../utils/customHooks'

import { AppContext } from '../../../../contexts'

import styles from './styles.module.css'

interface UserMenuProps {
  userName: string
  userInitials: string
}

const UserMenu: FunctionComponent<UserMenuProps> = ({
  userName,
  userInitials,
}) => {
  const { isAuthorized } = useContext(AppContext)

  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef<HTMLDivElement>()

  const handleGlobalClick = e => {
    const container: HTMLDivElement = containerRef.current
    const target: HTMLElement = e.target

    if (isOpen && container !== target && !container.contains(target)) {
      setIsOpen(false)
    }
  }

  useEventListener('click', document, handleGlobalClick)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.container} ref={containerRef} data-rv="user-menu">
      <button
        onClick={handleClick}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="user-menu"
        data-rv="user-menu-button"
      >
        <div className={styles.initials} data-rv="user-menu-initials">
          {userInitials.toUpperCase()}
        </div>
        <span className={styles.name} data-rv="user-menu-username">
          {userName}
        </span>
        <span className={styles.caret}>
          <FontAwesomeIcon icon={['fas', 'sort-down']} />
        </span>
      </button>
      {isOpen && (
        <ul
          id="user-menu"
          role="menu"
          className={styles.dropdown}
          data-rv="user-menu-dropdown"
        >
          {isAuthorized('manager') && (
            <>
              <li className={styles.item}>
                <Link
                  to="/users"
                  role="menuitem"
                  data-rv="user-menu-item"
                  onClick={handleClick}
                >
                  Manage Users
                </Link>
              </li>
              <li className={styles.item}>
                <Link
                  to="/publisher"
                  role="menuitem"
                  data-rv="user-menu-item"
                  onClick={handleClick}
                >
                  Manage Publisher
                </Link>
              </li>
            </>
          )}
          <li className={styles.logout}>
            <Link to="/new/logout" role="menuitem" data-rv="user-menu-item">
              Logout
            </Link>
          </li>
        </ul>
      )}
    </div>
  )
}

export default UserMenu
