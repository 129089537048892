import _has from 'lodash/has'
import _get from 'lodash/get'

import { Reducer } from 'redux'

import { PropertiesStore } from '../typings/property'
import { PropertiesAction, PropertiesConstants } from '../actions/properties'

const initialState: PropertiesStore = {
  data: {
    records: [],
    pagination: null,
  },
  hasData: false,
  fetching: false,
  fetched: false,
  error: null,
}

export const propertiesReducer: Reducer = (
  state = { ...initialState },
  action: PropertiesAction
): PropertiesStore => {
  switch (action.type) {
    case PropertiesConstants.FETCH:
      return {
        ...initialState,
        fetching: true,
      }
    case PropertiesConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        hasData: _has(action, 'data.records')
          ? _get(action, 'data.records').length > 0
          : _has(action, 'data.id'),
        fetching: false,
        fetched: true,
        error: null,
      }
    case PropertiesConstants.FETCH_FAILURE:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      }
    default:
      return state
  }
}
