import React, { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'

import getConfig from '../../../../config'

import styles from './styles.module.css'

import { FormMethods } from '../../../../containers/ResetPassword'

interface ExpiredDisplayProps {
  formMethod: FormMethods
}

const ExpiredDisplay: FunctionComponent<ExpiredDisplayProps> = ({
  formMethod,
}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {formMethod[0].toUpperCase() + formMethod.slice(1)} Password
      </h3>
      <div className={styles.description}>
        <p>
          The link to {formMethod} your password has expired. Click{' '}
          <Link
            className={styles.link}
            to={'/forgot-password'}
            data-rv="reset-forgot-link"
          >
            here
          </Link>{' '}
          to send a new one, or contact our{' '}
          <a href={`mailto:${getConfig('supportEmail')}`}>support team</a>.
        </p>
      </div>
    </div>
  )
}

export default ExpiredDisplay
