import React, { FunctionComponent, useEffect, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import { GenericButton, Loading } from '../../../components'

import styles from './styles.module.css'

interface TosApprovalProps {
  tosId: string
  acceptTerms(initials: string, tosId: string): void
}

interface TosApprovalErrors {
  initials: boolean
  accepted: boolean
}

const TosApproval: FunctionComponent<TosApprovalProps> = ({
  tosId,
  acceptTerms,
}) => {
  const [submitting, setSubmitting] = useState(false)

  const [initials, setInitials] = useState('')
  const [accepted, setAccepted] = useState(false)

  const [errors, setErrors] = useState<TosApprovalErrors>({
    initials: false,
    accepted: false,
  })

  useEffect(() => {
    if (!_isEmpty(initials)) {
      setErrors(prev => ({
        ...prev,
        initials: false,
      }))
    } else {
      setErrors(prev => ({
        ...prev,
        initials: true,
      }))
    }
  }, [initials, errors.initials])

  useEffect(() => {
    if (accepted === true) {
      setErrors(prev => ({
        ...prev,
        accepted: false,
      }))
    } else {
      setErrors(prev => ({
        ...prev,
        accepted: true,
      }))
    }
  }, [accepted, errors.accepted])

  const handleSubmit = e => {
    e.preventDefault()

    if (!errors.initials && !errors.accepted) {
      acceptTerms(initials, tosId)
      setSubmitting(true)
    }
  }

  return (
    <div className={styles.approval}>
      <p className={styles.initialsContainer}>
        <label htmlFor="initials">
          By entering your initials, you confirm that you agree to the Affiliate
          Publisher Terms &amp; Conditions.
        </label>
        <input
          type="text"
          id="initials"
          name="initials"
          className={classnames(styles.initials, {
            [styles.error]: errors.initials,
          })}
          placeholder="initials"
          value={initials}
          onChange={e => setInitials(e.currentTarget.value)}
          data-rv="accept-tos-initials"
        />
      </p>
      <p className={styles.acceptedContainer}>
        <input
          type="checkbox"
          id="accepted"
          name="accepted"
          className={classnames(styles.accepted, {
            [styles.error]: errors.accepted,
          })}
          onChange={e => setAccepted(!!e.currentTarget.checked)}
          data-rv="accept-tos-accepted"
        />
        <label htmlFor="accepted">
          I have read the Affiliate Publisher Terms &amp; Conditions.
        </label>
      </p>
      <div className={styles.buttonContainer}>
        {submitting ? (
          <button className={styles.loading} disabled>
            <Loading className={styles.spinner} />
          </button>
        ) : (
          <GenericButton
            onClick={e => handleSubmit(e)}
            data-rv="accept-tos-submit"
          >
            Submit
          </GenericButton>
        )}
      </div>
    </div>
  )
}

export default TosApproval
