import { triggerError } from './app'
import {
  cancelFetchOnReentrySync,
  fetchGetApi,
  swallowCancellation,
} from '../utils/fetchWrapper'

import { Action } from '../typings/reducer'
import { ProductType } from '../typings/productType'

export enum ProductTypesConstants {
  FETCH = 'productTypes/FETCH',
  FETCH_SUCCESS = 'productTypes/FETCH_SUCCESS',
  FETCH_FAILURE = 'productTypes/FETCH_FAILURE',
}

export interface ProductTypesAction extends Action {
  type: ProductTypesConstants
  payload?: Array<ProductType>
  error?: string
}

function fetchProductTypes(): ProductTypesAction {
  return {
    type: ProductTypesConstants.FETCH,
  }
}

function fetchProductTypesSuccess(
  payload: Array<ProductType>
): ProductTypesAction {
  return {
    type: ProductTypesConstants.FETCH_SUCCESS,
    payload,
  }
}

function fetchProductTypesFailure(error: string): ProductTypesAction {
  return {
    type: ProductTypesConstants.FETCH_FAILURE,
    error,
  }
}

export const getProductTypes = cancelFetchOnReentrySync(fetch => () =>
  swallowCancellation(async dispatch => {
    dispatch(fetchProductTypes())

    const { ok, errorParsed, bodyParsed } = await fetchGetApi(
      fetch,
      '/product-types'
    )

    if (ok) {
      dispatch(fetchProductTypesSuccess(bodyParsed.data))
    } else {
      dispatch(triggerError(errorParsed, 'Product Types'))
      dispatch(fetchProductTypesFailure(errorParsed))
    }
  })
)
