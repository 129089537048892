import React, { FunctionComponent, useState } from 'react'
import { DocumentNode, useApolloClient } from '@apollo/client'
import filesaver from 'file-saver'
import { kebabCase } from 'lodash'
import { DateTime } from 'luxon'

interface ExportState {
  progress: number
  name: string
}

interface ExportContext {
  exportState: ExportState & { running: boolean }
  runExport: (name: string, document: DocumentNode, variables: any) => void
  stopExport: () => void
}

const initialState = {
  name: '',
  progress: 0,
}

export const ExportContext = React.createContext<ExportContext>({
  exportState: {
    ...initialState,
    running: false,
  },
  runExport: () => {},
  stopExport: () => {},
})

let subscription = null

const generateFilename = (name, variables) => {
  if (variables.start && variables.end) {
    return `${kebabCase(name)}_${DateTime.fromISO(variables.start).toISODate({
      format: 'basic',
    })}-${DateTime.fromISO(variables.end).toISODate({
      format: 'basic',
    })}`
  }
  return `${kebabCase(name)}_${DateTime.local().toISODate()}`
}

const Exports: FunctionComponent = ({ children }) => {
  const [exportState, setExportState] = useState<ExportState>(initialState)
  const [running, setRunning] = useState<boolean>(false)

  const client = useApolloClient()

  const stopExport = () => {
    subscription.unsubscribe()
    setRunning(false)
  }

  const runExport: ExportContext['runExport'] = (name, document, variables) => {
    const reportParts = []

    setExportState({
      ...initialState,
      name,
    })
    setRunning(true)

    const filename = generateFilename(name, variables)

    subscription = client.subscribe({ query: document, variables }).subscribe({
      next({ data }) {
        const { csvChunk, currentRow, totalRows } = data[Object.keys(data)[0]]

        reportParts.push(csvChunk)

        const progress = totalRows > 0 ? currentRow / totalRows : 1

        setExportState({
          ...exportState,
          progress,
        })
      },
      complete() {
        subscription.unsubscribe()

        setRunning(false)

        const download = new Blob(reportParts, {
          type: 'text/csv;charset=utf8',
        })

        filesaver.saveAs(download, `${filename}.csv`)
      },
    })
  }

  return (
    <ExportContext.Provider
      value={{
        exportState: { ...exportState, running },
        runExport,
        stopExport,
      }}
    >
      {children}
    </ExportContext.Provider>
  )
}

export default Exports
